import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import chevronIcon from './chevron.svg';

export const Chevron = styled(JustIcon).attrs({
  alt: 'chevron',
  icon: chevronIcon,
  width: '9px',
  height: '16px',
})`` as JustIconStyledComponent<IconStaticProps>;
