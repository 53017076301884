import { useRef, useEffect } from 'react';

import { debounce } from '../utils';

/**
 * Returns a debounced version of the given `cb`.
 * The returned function will always remain the same, even if `cb` changes.
 *
 * Will always call the latest version of `cb` when time comes.
 * Changes of `delay` are ignored.
 * Will cancel scheduled `cb` call on unmount.
 *
 * @param cb - The callback to debounce
 * @param delay - The delay in milliseconds
 * @example
 * const debouncedCallback = useDebounce(() => console.log('debounced'), 500);
 * debouncedCallback();
 */
export const useDebounce = <T extends (...args: any[]) => void>(cb: T, delay = 300): T => {
  const cbRef = useRef<T | null>(cb);
  const debouncedCallerRef = useRef(
    debounce((...args: Parameters<T>) => {
      cbRef.current?.(...args);
    }, delay),
  );

  useEffect(() => {
    cbRef.current = cb;
    return () => {
      cbRef.current = null;
    };
  }, [cb]);

  return debouncedCallerRef.current.cb as any;
};
