import {
  AnalyticsEvent,
  Tracker,
  CommonPayload,
  isPaymentEvent,
  GtmBookAppointmentEventPayload,
} from '@water-web/view';

import { config } from '@app/config';

export class StylzTracker implements Tracker {
  brandId = 'cce706a3-c15a-4728-9d81-f2f8d8e57172';
  gclidMixin: Pick<GtmBookAppointmentEventPayload, 'gclid'> = { gclid: '' };

  constructor() {
    if (typeof window !== 'undefined') {
      const gclid = new URLSearchParams(window.location.search).get('gclid');
      if (gclid) {
        this.gclidMixin = { gclid };
      }
    }
  }

  getName(): string {
    return 'StylzTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    const exit = config.deploymentEnv !== 'production' || payload?.brandId !== this.brandId;

    if (exit || !isPaymentEvent(analyticsEvent, payload)) {
      return;
    }

    const totalWithDecimals = (payload.totalWithTips / 100).toFixed(2);

    window.stylzGtag?.('event', 'conversion', <GtmBookAppointmentEventPayload>{
      send_to: 'AW-10926152046/sXAsCL-G6sIDEO6y_9ko',
      value: totalWithDecimals,
      currency: payload.currency,
      transaction_id: payload.appointmentId,
      shop_name: payload.shop.name,
      shop_route: payload.shop.route,
      ...this.gclidMixin,
    });

    window.fbq?.('track', 'Purchase', {
      currency: payload.currency,
      value: totalWithDecimals,
    });
  }
}
