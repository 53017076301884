import { formatISO } from 'date-fns';

import { SelectedCookieSections, CookieConsentState } from '../../types';

// Change when adding new sections - to force re-show cookies popup to people who already accepted/declined
const CONSENT_VERSION = 1;

export const getUpdatedCookieConsentState = (selectedCookieSections: SelectedCookieSections): CookieConsentState => ({
  version: CONSENT_VERSION,
  date: formatISO(new Date()),
  ...selectedCookieSections,
});
