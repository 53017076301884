import { persistentKVStore } from '@water-web/view';
import './types';

export const saveUserLocale = (locale: string): void => {
  // Using try catch instead of checking for existence of an object,
  // because we may actually have it defined but get a security error
  try {
    window.SQUIRE_WIDGET_LOCALE = locale;
    persistentKVStore.setItem?.('SQUIRE_WIDGET_LOCALE', locale);
  } catch (e) {
    // suffer in silence
  }
};
