import { PhaseConfig, PhaseHookArgs } from '../types';

export const error: PhaseConfig = {
  name: 'error',
  init: (params: PhaseHookArgs) => {
    params.cartState.setLoader('error');
  },
  next: () => {
    // not available
  },
  cancel: (params) => {
    params.setCheckoutPhase(params.fallbackCheckoutPhase);
  },
};
