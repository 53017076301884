import { ServiceApiSearch, Service, Addon } from '@water-web/types';

import { Repository } from './base';
import { RepositoryResponse } from '../utils';
import { ServiceModel, ReservationModel } from '../models';

export class ServiceRepository extends Repository {
  getFromJson(json: Service): ServiceModel {
    return new ServiceModel(json);
  }

  async getById(serviceId: string, shopId: string, barberId?: string): Promise<RepositoryResponse<ServiceModel>> {
    const search: ServiceApiSearch = { include: ['addonServices'], limit: 500 };
    const response = barberId
      ? await this.api.service.getForBarberById(serviceId, shopId, barberId, search)
      : await this.api.service.getForShopById(serviceId, shopId, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<ServiceModel>;
    }

    return {
      data: new ServiceModel(response.data),
      error: null,
    } as RepositoryResponse<ServiceModel>;
  }

  async getForShop(shopId: string): Promise<RepositoryResponse<ServiceModel[]>> {
    const search: ServiceApiSearch = {
      sortBy: 'order',
      include: ['addonServices'],
      limit: 500,
    };
    const response = await this.api.service.getForShopByShopId(shopId, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<ServiceModel[]>;
    }

    return {
      data: response.data.map((service) => {
        /**
        @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
        Remove patch once we have addons implemented
       */
        return new ServiceModel(ServiceRepository.patchServiceAddonsWithRestOfServices(service, response.data));
      }),
      error: null,
    } as RepositoryResponse<ServiceModel[]>;
  }

  async getForBarber(shopId: string, barberId: string): Promise<RepositoryResponse<ServiceModel[]>> {
    const search: ServiceApiSearch = {
      sortBy: 'order',
      include: ['addonServices'],
      limit: 500,
    };
    const response = await this.api.service.getForBarberByShopIdAndBarberId(shopId, barberId, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<ServiceModel[]>;
    }

    return {
      data: response.data.map((service) => {
        /**
        @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
        Remove patch once we have addons implemented
       */
        return new ServiceModel(ServiceRepository.patchServiceAddonsWithRestOfServices(service, response.data));
      }),
      error: null,
    } as RepositoryResponse<ServiceModel[]>;
  }

  async getServicesForReservation(reservation: ReservationModel): Promise<RepositoryResponse<ServiceModel[]>> {
    const shop = reservation.getShop();

    if (reservation.getIsAnyBarber()) {
      if (shop.getServices()) {
        return {
          data: shop.getServices(),
          error: null,
        } as RepositoryResponse<ServiceModel[]>;
      }

      const response = await this.getForShop(shop.getId());

      if (response.data) {
        shop.setServices(response.data);
      }

      return response;
    }

    const barber = reservation.getBarber();
    if (barber.getServices()) {
      return {
        data: barber.getServices(),
        error: null,
      } as RepositoryResponse<ServiceModel[]>;
    }

    const response = await this.getForBarber(shop.getId(), barber.getId());
    if (response.data) {
      barber.setServices(response.data);
    }

    return response;
  }

  /**
   * @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
   * While we don't have addons implemented, we put rest of services instead of addons
   * Remove patch once we have addons implemented
   */
  private static patchServiceAddonsWithRestOfServices(service: Service, services: Service[]): Service {
    const barbersWhoOfferParentService = new Set(service.assignments?.map((a) => a.assigneeId) ?? []);
    const shopOrBrandAssignments = new Set(['shop', 'brand']);

    /**
     * @description Get addons that we can upsell with the currently selected service. Current
     * implementatation of addons doesn't put any constraints of what can be sold with what (even
     * inside Commander) so that users are allowed to select say "Senior haircut" and "Intern haircut"
     * at the same time, and this can never be sold as the doesn't exist a SINGLE barber that can do
     * both - so the list of times to pick will always be empy. This is a problem only in Book with Any
     * Barber flow.
     * In order to prevent from such combinations being selected, we need to filter out all addons
     * that aren't possible to book with any of the barbers who offer the main services.
     * @returns Filtered list of addons
     */
    const addonServices = services
      .filter((serviceAsAddon) => {
        if (serviceAsAddon.id === service.id) {
          return false;
        }

        if (!service.assignments?.length) {
          return true;
        }

        return serviceAsAddon.assignments.some((assignment) => {
          return (
            shopOrBrandAssignments.has(assignment.assignmentType) || // provided by brand/shop
            barbersWhoOfferParentService.has(assignment.assigneeId)
          ); // provided by parent service barbers
        });
      })
      .map((serviceAsAddon) => {
        const obj = { ...serviceAsAddon };
        delete obj.addonServices;
        return obj as unknown as Addon;
      });

    return {
      ...service,
      addonServices,
    };
  }
}
