import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { BarberModel } from '@water-web/repository';
import { RepositoryContext } from '@water-web/view';

export const useBarber = (shopId?: string, barberId?: string): UseQueryResult<BarberModel> => {
  const { barberRepository } = useContext(RepositoryContext);

  return useQuery(
    ['shop', shopId, 'barber', barberId],
    async () => {
      const response = await barberRepository.getByIdOrRoute(shopId, barberId);

      if (response.error) {
        throw response.error;
      }

      return response.data;
    },
    {
      enabled: !!(shopId && barberId),
    },
  );
};
