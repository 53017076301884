import { PhaseConfig, PhaseHookArgs } from '../types';

export const policies: PhaseConfig = {
  name: 'policies',
  init: (params: PhaseHookArgs) => {
    params.popupController.handlers.policies().open({
      set: 'policy',
      onClose: () => null,
      onComplete: () => null,
    });
  },
  next: (params: PhaseHookArgs) => {
    params.popupController.handlers.policies().close();
    params.setCheckoutPhase('userInfo');
  },
  cancel: (params: PhaseHookArgs) => {
    params.setCheckoutPhase('initial');
  },
};
