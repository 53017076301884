import { HttpResponse, SMSApi as ISMSApi } from '@water-web/types';

import { buildBotProtectionHeaders } from '../utils';
import { Namespace } from './namespace';

export class SMSApi extends Namespace implements ISMSApi {
  sendCommonLinkToApps(phone: string, botProtectionToken?: string): Promise<HttpResponse<boolean>> {
    return this.http.post<boolean, { phone: string }>(
      '/customer/sms-app-link',
      { phone },
      {
        headers: { ...buildBotProtectionHeaders(botProtectionToken) },
        resourceUrl: '/customer/sms-app-link',
      },
    );
  }

  sendBrandedLinkToApps(phone: string, brandId: string, botProtectionToken?: string): Promise<HttpResponse<boolean>> {
    return this.http.post<boolean, { phone: string }>(
      `/brand/${brandId}/sms-app-link`,
      {
        phone,
      },
      {
        headers: { ...buildBotProtectionHeaders(botProtectionToken) },
        resourceUrl: '/brand/:id/sms-app-link',
      },
    );
  }
}
