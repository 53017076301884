import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../../../../atoms/Icon/Icon';
import globeIcon from './globe.svg';

export const Globe = styled(JustIcon).attrs({
  alt: 'globe',
  icon: globeIcon,
  width: '12px',
  height: '12px',
})`` as JustIconStyledComponent<IconStaticProps>;
