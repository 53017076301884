import { AddonsControls, ScheduleControls, GiftCardControls } from '@app/components/organisms/CartControls';
import { ScheduleDisclaimer } from '@app/components/organisms/CartDisclaimer';

import { CartMode, CartModeName } from './types';

/**
 * Each mode is a representation of a cart of a certain configuration.
 * It describes what state the cart can take in that mode and each transition behaves.
 * This config is passed to the Cart component and content is rendered based on the config.
 * Modes are being set on each page by using the useCartController hook's setCartMode function.
 */
export const cartModes: Record<CartModeName, CartMode> = {
  hidden: {
    allowedStates: ['hidden'],
    defaultState: 'hidden',
    scroll: {
      react: false,
      bottomScrolledState: 'hidden',
      topScrolledState: 'hidden',
    },
    stateTransitions: {},
    canEdit: () => false,
    canViewSubtotal: () => false,
    canViewAppointments: () => false,
    canViewAndManageBreakdown: () => false,
    canAddAnotherGuest: () => false,
    canSetTip: () => false,
    Controls: () => null,
    Disclaimer: () => null,
    validateCartOnChange: false,
  },
  deeplinkPromo: {
    allowedStates: ['showCta', 'expanded'],
    defaultState: 'showCta',
    scroll: {
      react: true,
      bottomScrolledState: 'showCta',
      topScrolledState: 'showCta',
    },
    stateTransitions: {
      showCta: 'expanded',
      expanded: 'showCta',
    },
    canEdit: () => false,
    canAddAnotherGuest: () => false,
    canViewSubtotal: () => false,
    canViewAndManageBreakdown: () => false,
    canViewAppointments: () => false,
    canSetTip: () => false,
    Controls: () => null,
    Disclaimer: () => null,
    validateCartOnChange: false,
  },
  incompleteAppt: {
    allowedStates: ['showCta', 'expanded'],
    defaultState: 'showCta',
    scroll: {
      react: true,
      bottomScrolledState: 'showCta',
      topScrolledState: 'showCta',
    },
    stateTransitions: {
      showCta: 'expanded',
      expanded: 'showCta',
    },
    canEdit: () => false,
    canAddAnotherGuest: () => true,
    canViewSubtotal: () => true,
    canViewAndManageBreakdown: () => false,
    canViewAppointments: () => true,
    canSetTip: () => false,
    Controls: () => null,
    Disclaimer: () => null,
    validateCartOnChange: false,
  },
  addons: {
    allowedStates: ['showCta', 'expanded'],
    defaultState: 'showCta',
    scroll: {
      react: true,
      bottomScrolledState: 'showCta',
      topScrolledState: 'showCta',
    },
    stateTransitions: {
      showCta: 'expanded',
      expanded: 'showCta',
    },
    canEdit: () => false,
    canAddAnotherGuest: () => true,
    canViewSubtotal: () => true,
    canViewAndManageBreakdown: () => false,
    canViewAppointments: () => true,
    canSetTip: () => false,
    Controls: AddonsControls,
    Disclaimer: () => null,
    validateCartOnChange: false,
  },
  scheduleEmpty: {
    allowedStates: ['showCta', 'expanded'],
    defaultState: 'showCta',
    scroll: {
      react: true,
      bottomScrolledState: 'showCta',
      topScrolledState: 'showCta',
    },
    stateTransitions: {
      showCta: 'expanded',
      expanded: 'showCta',
    },
    canEdit: () => false,
    canAddAnotherGuest: () => false,
    canViewAppointments: () => true,
    canViewSubtotal: () => true,
    canViewAndManageBreakdown: () => false,
    canSetTip: () => false,
    Controls: ScheduleControls,
    Disclaimer: ScheduleDisclaimer,
    validateCartOnChange: true,
  },
  scheduleChosen: {
    allowedStates: ['showCtaCheckout', 'expanded', 'editing'],
    defaultState: 'showCtaCheckout',
    scroll: {
      react: true,
      bottomScrolledState: 'showCtaCheckout',
      topScrolledState: 'showCtaCheckout',
    },
    stateTransitions: {
      showCtaCheckout: 'expanded',
      expanded: 'showCtaCheckout',
      editing: 'showCtaCheckout',
    },
    canEdit: (props) => {
      if (props.cart.getReservations().length > 1) {
        return false;
      }
      const activeReservation = props.cart.getActiveReservation();
      const isTimeSelected = !!(activeReservation?.getDateTime() || activeReservation?.getWaitingListDate());
      return props.expanded && isTimeSelected;
    },
    canAddAnotherGuest: () => false,
    canViewAppointments: () => true,
    canViewSubtotal: () => true,
    canViewAndManageBreakdown: () => false,
    canSetTip: (props) => {
      return props.expanded;
    },
    Controls: ScheduleControls,
    Disclaimer: ScheduleDisclaimer,
    validateCartOnChange: true,
  },
  giftCard: {
    allowedStates: ['minimized', 'small', 'expanded'],
    defaultState: 'small',
    scroll: {
      react: true,
      bottomScrolledState: 'minimized',
      topScrolledState: 'small',
    },
    stateTransitions: {
      minimized: 'small',
      small: 'expanded',
      expanded: 'small',
    },
    canEdit: () => false,
    canAddAnotherGuest: () => false,
    canViewAppointments: () => true,
    canViewSubtotal: () => true,
    canViewAndManageBreakdown: () => false,
    canSetTip: () => false,
    Controls: GiftCardControls,
    Disclaimer: () => null,
    validateCartOnChange: true,
  },
};
