import { Barber, Photo, IgImage, BarberWithMeta, Professional } from '@water-web/types';

import { ANY_BARBER_ID_AND_ROUTE, ANY_BARBER_OBJECT } from '../getters';
import { BaseModel } from './base';
import { ServiceModel } from './service';
import { getShortName } from '../utils';

export class BarberModel extends BaseModel<BarberModel> {
  private services: ServiceModel[] = null;
  protected nextAvailableTime = null;
  protected dataValues: Readonly<BarberWithMeta>;

  constructor(barber: Barber | BarberWithMeta) {
    super();

    this.dataValues = {
      // TODO: drop default value if after API get fix
      defaultServiceName: '',
      instagramImages: [],
      nextAvailableTime: '',
      nextAvailableTimeText: '',
      ...barber,
    };

    if (this.dataValues.nextAvailableTime) {
      this.nextAvailableTime = new Date(this.dataValues.nextAvailableTime);
    }
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  getRoute(): string {
    return this.dataValues.route as string;
  }

  getAvatar(): Photo {
    const photos = (this.dataValues.photos || []) as Photo[];
    return photos[0];
  }

  setServices(services: ServiceModel[]): BarberModel {
    this.services = services;
    return this;
  }

  getServices(): ServiceModel[] {
    return this.services;
  }

  isAnyBarber(): boolean {
    return this.getId() === ANY_BARBER_ID_AND_ROUTE;
  }

  getFirstName(): string {
    return this.dataValues.firstName as string;
  }

  getLastName(): string {
    return this.dataValues.lastName as string;
  }

  getFullName(): string {
    return `${this.getFirstName()} ${this.getLastName()}`;
  }

  getShortName(): string {
    return getShortName(this.dataValues);
  }

  getInstagramImages(): IgImage[] {
    return this.dataValues.instagramImages as IgImage[];
  }

  getAdvanceBookDays(): number {
    return this.dataValues.advanceBookDays as number;
  }

  isMultipleServicesEnabled(): boolean {
    return this.dataValues.allowMultipleServices as boolean;
  }

  /**
   * UTC date time
   */
  getNextAvailableTime(): Date {
    return this.nextAvailableTime;
  }

  /**
   * Barber settings allow booking without providing bank card
   * Can only override this in barber if corresponding shop setting is ON
   */
  allowBookWithoutCard(): boolean {
    return this.dataValues.bookNoPay;
  }

  /**
   * Only valid, if shop has passcodes enabled
   * Use the `ReservationModel.isPasscodeRequired(shop, barber)` method
   */
  isRequirePasscode(): boolean {
    return this.dataValues.requirePasscode;
  }

  getDataValues(): Readonly<BarberWithMeta> {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }

  static fromJson(json: Professional): BarberModel {
    const barber: BarberWithMeta = {
      defaultServiceName: json.defaultServiceName,
      instagramImages: json.instagramImages,
      nextAvailableTime: json.nextAvailableTime,
      nextAvailableTimeText: json.nextAvailableTimeText,
      ...json.barber,
    };

    return new BarberModel(barber);
  }

  static createAnyBarber(): BarberModel {
    return new BarberModel(ANY_BARBER_OBJECT);
  }
}
