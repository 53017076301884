import { QueryKey } from 'react-query';
import { Barber } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { hashKey } from '../../utils/haskey';
import { queryKeys } from './queryKeys';

export interface PublicBarberPayload {
  shopIdOrRoute: string;
  idOrRoute: string;
}

const staticIncludes = ['photos', 'days_on', 'days_off', 'schedules'];

export class PublicBarberQuery extends EndpointQuery<Barber, PublicBarberPayload> {
  constructor(apiUrl: string) {
    super();

    this.baseUrl = apiUrl;
    this.meta = {
      cacheTime: 60 * 60 * 1000,
    };
  }

  getKey(payload: PublicBarberPayload): QueryKey {
    const params = { include: staticIncludes };
    return queryKeys.byBarber(payload).concat(hashKey(params));
  }

  request(payload: PublicBarberPayload): Promise<Barber> {
    return this.http.get(`/v1/shop/${payload.shopIdOrRoute}/barber/${payload.idOrRoute}`, {
      headers: this.buildHeaders(),
      search: { include: staticIncludes },
      resourceUrl: '/shop/:shopId/barber/:barberId',
    });
  }
}
