import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';

import { createEventTarget, PolyfillEventTarget } from './createEventTarget';

interface WidgetContainerContextValue {
  scrollableAreaElement: HTMLElement;
  setScrollableAreaElement: Dispatch<SetStateAction<HTMLElement>>;
  isScrolled?: boolean;
  isScrolling?: boolean;
  backUrl: string;
  setBackUrl: Dispatch<SetStateAction<string>>;
  animationExitMediator: EventTarget | PolyfillEventTarget;
}

export const WidgetContainerContext = createContext<WidgetContainerContextValue>({
  scrollableAreaElement: null,
  setScrollableAreaElement: () => null,
  isScrolled: false,
  isScrolling: false,
  backUrl: '',
  setBackUrl: () => null,
  animationExitMediator: null,
});

const RESET_IS_SCROLLING_TIMEOUT = 150;

const useScrollingHandler = (): [boolean, () => void] => {
  const resetPointerEventsTimeoutRef = useRef<number>();
  const [isScrolling, setIsScrolling] = useState(false);

  const handleScrolling = useCallback(() => {
    setIsScrolling(true);
    window.clearTimeout(resetPointerEventsTimeoutRef.current);
    resetPointerEventsTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, RESET_IS_SCROLLING_TIMEOUT);
  }, []);

  return [isScrolling, handleScrolling];
};

export const WidgetContainerContextProvider = ({ children }: PropsWithChildren) => {
  const [scrollableAreaElement, setScrollableAreaElement] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolling, handleScrolling] = useScrollingHandler();
  const [backUrl, setBackUrl] = useState('');
  const animationExitMediator = useRef(createEventTarget());
  const theme = useTheme();
  const timing = theme.animations.booking;

  const scrollHandler = useCallback((e) => {
    setIsScrolled(e.target.scrollTop > 0);
    handleScrolling();
  }, []);

  // Handle transitions between pages - the scroll container element ref will change
  useEffect(() => {
    scrollableAreaElement?.addEventListener('scroll', scrollHandler, false);
    // Set "isScrolled" to false after the page & title have animated out, to keep the widget title background blur
    // in sync with the title, not necesarily with the content beneath it.
    // Using CSS animation delay to achieve the roughly same effect, but in one particular case of scrolling DOWN
    // when scroll container content overlaps into widget title, the delay would look weird then - the title wouldn't
    // unblur for some time after the page has been scrolled to the top, even if the css delay would be depenedent on
    // the scrolled state (0 or some time).
    setTimeout(() => setIsScrolled(false), (timing.pageTransition.delay + timing.pageTransition.duration) * 1000);

    return () => scrollableAreaElement?.removeEventListener('scroll', scrollHandler, false);
  }, [scrollableAreaElement]);

  const value = useMemo(
    () => ({
      scrollableAreaElement,
      setScrollableAreaElement,
      isScrolled,
      isScrolling,
      backUrl,
      setBackUrl,
      animationExitMediator: animationExitMediator.current,
    }),
    [scrollableAreaElement, setScrollableAreaElement, isScrolled, isScrolling, backUrl, setBackUrl],
  );

  return <WidgetContainerContext.Provider value={value}>{children}</WidgetContainerContext.Provider>;
};
