import { CookieDef } from '../types';
import { BodyText } from '../styles';
import { Flex, Box, Translate } from '../../../atoms';
import { CookieDetailsContainer, CookieInfoText, LabelText, SectionTitle } from './styles';

interface CookieDetailsProps {
  cookie: CookieDef;
}

export const CookieDetails = (props: CookieDetailsProps) => {
  return (
    <CookieDetailsContainer>
      <SectionTitle>{props.cookie.name}</SectionTitle>
      <BodyText mt={2}>{props.cookie.description}</BodyText>
      <Flex mt={3}>
        <Box width="64px">
          <LabelText>
            <Translate i18nKey="components.cookieConsent.expiry" />
          </LabelText>
          <CookieInfoText mt={1}>{props.cookie.expiry}</CookieInfoText>
        </Box>
        <Box>
          <LabelText>
            <Translate i18nKey="components.cookieConsent.type" />
          </LabelText>
          <CookieInfoText mt={1}>{props.cookie.type}</CookieInfoText>
        </Box>
      </Flex>
    </CookieDetailsContainer>
  );
};
