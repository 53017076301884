import {
  ScheduleTimeRangeDay,
  BarberScheduleTimeRangeDay,
  ScheduleTimeItem,
  BarberScheduleTimeItem,
  HttpResponse,
  RelatedDates,
  RelatedDatesAndBarbers,
  BarberApiTimeSearch,
  ShopApiTimeSearch,
  ScheduleTimeRangeDayWithConflicts,
  ScheduleTimeItemWithConflicts,
  BarberScheduleTimeRangeDayWithConflicts,
  BarberScheduleTimeItemWithConflicts,
} from '@water-web/types';

import { ReservationModel, BarberModel, ScheduleTimeRangeModel, RelatedDatesAndBarbersModel } from '../models';
import { RepositoryResponse } from '../utils';
import { Repository } from './base';

export class ScheduleRepository extends Repository {
  /**
   * @deprecated Use `SingleApptScheduleQuery` or `AnyBarberApptScheduleQuery` instead
   */
  async getScheduleForReservation(
    reservation: ReservationModel,
    start: string,
    end: string,
    calendarAccessToken?: string,
  ): Promise<RepositoryResponse<ScheduleTimeRangeModel>> {
    const barber = reservation.getBarber();

    const schedule = barber.isAnyBarber()
      ? await this.getScheduleForShop(reservation, start, end, calendarAccessToken)
      : await this.getScheduleForBarber(reservation, start, end, calendarAccessToken);

    if (schedule.error) {
      return {
        error: schedule.error,
        data: null,
      };
    }

    return {
      error: null,
      data: new ScheduleTimeRangeModel(schedule.data),
    };
  }

  async getRelatedDatesAndBarbers(
    reservation: ReservationModel,
    utcDateTime: Date,
    excludeBarbers = false,
  ): Promise<RepositoryResponse<RelatedDatesAndBarbersModel>> {
    const shop = reservation.getShop();
    const barber = reservation.getBarber();

    const dateTime = utcDateTime.toISOString();
    const services = [reservation.getService(), ...reservation.getAddons()].map((serviceOrAddon) =>
      serviceOrAddon.getId(),
    );

    const promise =
      reservation.getIsAnyBarber() || excludeBarbers
        ? this.api.shop.getRelatedDates(shop.getId(), dateTime, services)
        : this.api.barber.getRelatedDatesAndBarbers(barber.getId(), dateTime, services);

    const related: HttpResponse<RelatedDates | RelatedDatesAndBarbers> = await promise;

    if (related.error) {
      return {
        error: related.error,
        data: null,
      };
    }

    return {
      error: null,
      data: new RelatedDatesAndBarbersModel(shop.getTimezone(), utcDateTime, related.data),
    };
  }

  private async getScheduleForShop(
    reservation: ReservationModel,
    start: string,
    end: string,
    calendarAccessToken?: string,
  ): Promise<RepositoryResponse<ScheduleTimeRangeDayWithConflicts[]>> {
    const shopId = reservation.getShop().getId();
    const services = [reservation.getService().getId(), ...reservation.getAddons().map((addon) => addon.getId())];
    const excludeAppointmentId = reservation.getAppointmentId();
    const search: ShopApiTimeSearch = {
      ...(excludeAppointmentId ? { excludeAppointmentId } : {}),
      ...(calendarAccessToken ? { calendarAccessToken } : {}),
    };

    const response = await this.api.shop.getScheduleTimeRange(shopId, start, end, services, search);
    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      error: null,
      data: this.normalizeShopDays(response.data),
    };
  }

  private async getScheduleForBarber(
    reservation: ReservationModel,
    start: string,
    end: string,
    calendarAccessToken?: string,
  ): Promise<RepositoryResponse<ScheduleTimeRangeDayWithConflicts[]>> {
    const barber = reservation.getBarber();
    const duration = reservation.getDuration();
    const excludeAppointmentId = reservation.getAppointmentId();
    const search: BarberApiTimeSearch = {
      ...(excludeAppointmentId ? { excludeAppointmentId } : {}),
      ...(calendarAccessToken ? { calendarAccessToken } : {}),
    };

    const response = await this.api.barber.getScheduleTimeRange(barber.getId(), start, end, duration, search);
    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      error: null,
      data: this.normalizeBarberDays(barber, response.data),
    };
  }

  private normalizeShopDays(
    days: ScheduleTimeRangeDay[] | ScheduleTimeRangeDayWithConflicts[],
  ): ScheduleTimeRangeDayWithConflicts[] {
    const normalizeTime = (time: ScheduleTimeItem | ScheduleTimeItemWithConflicts): ScheduleTimeItemWithConflicts => ({
      ...time,
      conflicts: (time as ScheduleTimeItemWithConflicts).conflicts ?? [],
    });

    return days.map((day) => ({
      ...day,
      times: {
        morning: day.times.morning.map(normalizeTime),
        afternoon: day.times.afternoon.map(normalizeTime),
        evening: day.times.evening.map(normalizeTime),
      },
    }));
  }

  private normalizeBarberDays(
    barber: BarberModel,
    days: BarberScheduleTimeRangeDay[] | BarberScheduleTimeRangeDayWithConflicts[],
  ): ScheduleTimeRangeDayWithConflicts[] {
    const barberIds = [barber.getId()];
    const barberRoutes = [barber.getRoute()];
    const normalizeTime = (
      time: BarberScheduleTimeItem | BarberScheduleTimeItemWithConflicts,
    ): ScheduleTimeItemWithConflicts => ({
      ...time,
      barberIds,
      barberRoutes,
      conflicts: (time as BarberScheduleTimeItemWithConflicts).conflicts ?? [],
    });

    return days.map((day) => ({
      ...day,
      times: {
        morning: day.times.morning.map(normalizeTime),
        afternoon: day.times.afternoon.map(normalizeTime),
        evening: day.times.evening.map(normalizeTime),
      },
    }));
  }
}
