import { QueryKey } from 'react-query';

import { EndpointQuery, EndpointQueryMeta } from '../../request';

export interface ApiInternalStatus {
  ok: boolean;
}

// this type represents a small slice of available properties
export interface WaitingRoomInfo {
  cfWaitingRoom: {
    inWaitingRoom: true;
    waitTimeKnown: boolean;
    waitTime: 0;
    refreshIntervalSeconds: number;
  };
}

export type ApiStatus = ApiInternalStatus | WaitingRoomInfo;

export const isWaitingRoomInfo = (data?: ApiStatus): data is WaitingRoomInfo => {
  return !!data && 'cfWaitingRoom' in data;
};

export class ApiStatusQuery extends EndpointQuery<ApiStatus, void> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(): QueryKey {
    return ['apiStatus'];
  }

  request(_: void, signal?: AbortSignal): Promise<ApiStatus> {
    try {
      return this.http.get('/v1/status', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        resourceUrl: '/status',
        signal,
      });
    } catch (error) {
      return Promise.resolve({ ok: false });
    }
  }

  getMeta(): EndpointQueryMeta<ApiStatus, void> {
    return {
      cacheTime: 0,
    };
  }
}
