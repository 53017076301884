import { QueryClient } from 'react-query';

import { EndpointMutation, GetToken } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  ids: string[];
}

interface Response {
  dismissed: boolean;
}

export class NotificationsDismissMutation extends EndpointMutation<Response[], Payload> {
  constructor(config: { baseUrl: string; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  async request(payload: Payload, signal?: AbortSignal): Promise<Response[]> {
    const headers = this.buildHeaders();

    const requests = payload.ids.map((id) => {
      return this.http.put<Response, void>(`/v1/notifications/${id}/dismiss`, undefined, {
        signal,
        headers,
        resourceUrl: '/notifications/:id/dismiss',
      });
    });
    const responses = await Promise.all(requests);

    return responses;
  }

  async afterMutationSuccess(queryClient: QueryClient): Promise<void> {
    await queryClient.invalidateQueries(queryKeys.all);
  }
}
