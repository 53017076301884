import { QueryKey } from 'react-query';
import { ValidatedCart } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

interface Payload {
  id: string;
  shopId: string;
}

export class CartQuery extends EndpointQuery<ValidatedCart, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.cart(payload);
  }

  async request(payload: Payload, signal?: AbortSignal): Promise<ValidatedCart | null> {
    return this.http.get(`/v2/shop/${payload.shopId}/cart/${payload.id}`, {
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      resourceUrl: '/shop/:shopId/cart/:cartId',
      signal,
    });
  }
}
