import { HttpPlatform, Logger, Nullable } from '@water-web/types';

import {
  ApiError,
  HttpError,
  HttpConfig,
  HttpGetOptions,
  HttpPostOptions,
  HttpSearchParams,
  HttpMethod,
} from './types';
import { notifyRequestError, notifyRequestStart, notifyRequestSuccess } from './utils';
import { buildTrackingHeaders } from '../utils/headers';

const IS_SSR = typeof window === 'undefined';

export class Http {
  static buildResponse<T>(data: T): T {
    return data;
  }

  static logger: Logger;

  static platform: HttpPlatform;

  // UTM params the app was opened with. Used for tracking purposes.
  static trackingParams: HttpConfig['trackingParams'];

  static setLogger(logger: Logger) {
    Http.logger = logger;
  }

  static setPlatform(platform: HttpPlatform) {
    Http.platform = platform;
  }

  static setTrackingParams(trackingParams: HttpConfig['trackingParams']) {
    Http.trackingParams = trackingParams;
  }

  static buildError(response: Response, error: ApiError): HttpError {
    return {
      status: response.status,
      code: error?.code ?? null,
      message: error?.message ?? null,
    };
  }

  static getDDSessionId(): string | undefined {
    let sessionId: string | undefined;

    if (!IS_SSR) {
      const ddCookie = document.cookie.split('; ').find((row) => row.startsWith('_dd_s='));

      if (ddCookie) {
        const cookieParams = new URLSearchParams(ddCookie.split('=')[1]);
        sessionId = cookieParams.get('id') || undefined;
      }
    }

    if (!sessionId && !IS_SSR) {
      // @ts-expect-error DD_RUM is added to window by the Datadog RUM SDK
      sessionId = window.DD_RUM?.getInternalContext()?.session_id;
    }
    return sessionId;
  }

  static getHeaders(headers?: Record<string, string>): Record<string, string> {
    const shared = {
      'Content-Type': 'application/json',
      'x-datadog-session-id': this.getDDSessionId(),
      ...buildTrackingHeaders(Http.trackingParams),
    };
    return headers ? { ...shared, ...headers } : shared;
  }

  // response.json() fails when response body is empty so we need to handle that
  static async getJsonResponse<T>(response: Response): Promise<Nullable<T>> {
    try {
      const json = await response.json();
      return json as T;
    } catch (e) {
      return null;
    }
  }

  protected baseUrl: string;

  constructor(config: HttpConfig) {
    this.baseUrl = config.baseUrl;
  }

  protected getUrl(path: string, search?: HttpSearchParams | string): string {
    if (!search) {
      return this.baseUrl.concat(path);
    }

    if (typeof search === 'string') {
      return this.baseUrl.concat(path).concat('?').concat(search);
    }

    const query = Object.keys(search)
      .map((key) => {
        const value = search[key];
        if (Array.isArray(value)) {
          return `${key}=${(search[key] as string[]).join(',')}`;
        }
        return `${key}=${search[key]}`;
      })
      .join('&');

    return this.baseUrl.concat(path).concat('?').concat(query);
  }

  async get<T>(path: string, options?: HttpGetOptions): Promise<T> {
    const url = this.getUrl(path, options?.search);
    const optionsForRequest = {
      ...(options || {}),
      headers: Http.getHeaders(options?.headers),
    };

    notifyRequestStart({
      url,
      method: 'GET',
    });

    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      Http.logger.error('Failed to execute request', {
        url,
        platform: Http.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });
      notifyRequestError({
        url,
        error,
        method: 'GET',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw Http.buildError(error as any, error as ApiError);
    }

    const dataOrError = await Http.getJsonResponse<T>(response);

    if (!response.ok) {
      const error = Http.buildError(response, dataOrError as ApiError);
      notifyRequestError({
        url,
        error,
        method: 'GET',
      });

      throw error;
    }

    notifyRequestSuccess({
      url,
      method: 'GET',
    });

    return Http.buildResponse(dataOrError as T);
  }

  async requestRaw<T>(path: string, method: HttpMethod, options?: HttpGetOptions): Promise<Blob> {
    const url = this.getUrl(path, options?.search);
    const optionsForRequest = {
      ...(options || {}),
      headers: Http.getHeaders(options?.headers),
    };

    notifyRequestStart({
      url,
      method,
    });

    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      Http.logger.error('Failed to execute request', {
        url,
        platform: Http.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });

      notifyRequestError({
        url,
        error,
        method,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw Http.buildError(error as any, error as ApiError);
    }

    if (!response.ok) {
      const errorData = await Http.getJsonResponse<T>(response);
      const error = Http.buildError(response, errorData as ApiError);

      notifyRequestError({
        url,
        error,
        method,
      });

      throw error;
    }

    const blob = await response.blob();

    notifyRequestSuccess({
      url,
      method,
    });

    return blob;
  }

  async request<T, K>(method: HttpMethod, path: string, data?: K, options?: HttpPostOptions): Promise<T> {
    let body: string | FormData;

    const url = this.getUrl(path, options?.search);
    const headers = Http.getHeaders(options?.headers);

    notifyRequestStart({ url, method, data });

    if (headers['Content-Type'] === 'multipart/form-data' && typeof data === 'object') {
      const dataToSend = new FormData();

      Object.keys(data).forEach((key) => {
        const value = data[key];

        if (typeof value === 'string') {
          dataToSend.append(key, value);
          return;
        }

        if (Array.isArray(value)) {
          value.forEach((valuePart) => dataToSend.append(`${key}[]`, valuePart));
          return;
        }

        if (value instanceof FileList) {
          dataToSend.append(key, value[0]);
        }
      });

      // Required, because otherwise we have to manually set the boundary
      delete headers['Content-Type'];

      body = dataToSend;
    } else {
      body = JSON.stringify(data);
    }

    const optionsForRequest = {
      ...(options || {}),
      method,
      body,
      headers,
    };

    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      Http.logger.error('Failed to execute request', {
        url,
        platform: Http.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });
      notifyRequestError({ url, method, data, error });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      throw Http.buildError(error as any, error as ApiError);
    }

    const dataOrError = await Http.getJsonResponse<T>(response);

    if (!response.ok) {
      const error = Http.buildError(response, dataOrError as ApiError);
      notifyRequestError({ url, method, data, error });
      throw error;
    }

    notifyRequestSuccess({ url, method, data });
    return Http.buildResponse(dataOrError as T);
  }

  async delete<T, K>(path: string, data?: K, options?: HttpPostOptions): Promise<T> {
    return this.request<T, K>('DELETE', path, data, options);
  }

  async post<T, K>(path: string, data?: K, options?: HttpPostOptions): Promise<T> {
    return this.request<T, K>('POST', path, data, options);
  }

  async put<T, K>(path: string, data?: K, options?: HttpPostOptions): Promise<T> {
    return this.request<T, K>('PUT', path, data, options);
  }

  async patch<T, K>(path: string, data?: K, options?: HttpPostOptions): Promise<T> {
    return this.request<T, K>('PATCH', path, data, options);
  }

  async getRaw<T>(path: string, options?: HttpPostOptions): Promise<Blob> {
    return this.requestRaw<T>(path, 'GET', options);
  }

  async postRaw<T>(path: string, options?: HttpPostOptions): Promise<Blob> {
    return this.requestRaw<T>(path, 'POST', options);
  }

  async putRaw<T>(path: string, options?: HttpPostOptions): Promise<Blob> {
    return this.requestRaw<T>(path, 'PUT', options);
  }

  async patchRaw<T>(path: string, options?: HttpPostOptions): Promise<Blob> {
    return this.requestRaw<T>(path, 'PATCH', options);
  }
}
