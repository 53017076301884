import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import checkmarkIcon from './checkmark.svg';

export const Checkmark = styled(JustIcon).attrs<{ stroke?: string }>({
  alt: 'checkmark',
  icon: checkmarkIcon,
  width: '11px',
  height: '8px',
})`` as JustIconStyledComponent<IconStaticProps>;
