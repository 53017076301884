import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePageInitialProps } from './usePageInitialProps';

export const useWindowTitleUpdate = (): void => {
  const { t } = useTranslation();
  const pageProps = usePageInitialProps();

  useEffect(() => {
    if (!pageProps.brand) {
      return; // Nothing loaded here, we can skip this
    }

    const name = pageProps.shop?.name || pageProps.brand?.name;
    document.title = name ? t('common.titleWithName', { name }) : t('common.title');
  }, [pageProps.brand, pageProps.shop]);
};
