import { MutationKey } from 'react-query';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';
import { buildBotProtectionHeaders } from '../../utils';

type Payload = {
  username: string;
  botProtectionToken?: string;
};

type Response = {
  customerId: string;
  /** last 4 digits of the phone */
  phone: string;
};

export class UserLoginOtpInitOnPhoneByEmailMutation extends EndpointMutation<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(): MutationKey {
    return queryKeys.loginOtpInit();
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    const { botProtectionToken, ...rest } = payload;
    return this.http.post('/v1/auth-code', rest, {
      signal,
      headers: this.buildHeaders(buildBotProtectionHeaders(botProtectionToken)),
      resourceUrl: '/auth-code',
    });
  }
}
