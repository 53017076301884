import { Flex, Box } from '../../../atoms';
import { CookiesSubsectionDef } from '../types';
import { CookieDetails } from './CookieDetails';
import { ArrowButton, BackArrow, SectionTitle } from './styles';

interface SubsectionDetailsProps {
  subsection: CookiesSubsectionDef;
  onGoBack: () => void;
}

export const SubsectionDetails = (props: SubsectionDetailsProps) => {
  return (
    <Box>
      <Flex>
        <ArrowButton onClick={props.onGoBack} mr="8px">
          <BackArrow />
        </ArrowButton>
        <SectionTitle>{props.subsection.name}</SectionTitle>
      </Flex>
      <Box>
        {props.subsection.cookies.map((cookie) => (
          <CookieDetails key={cookie.name} cookie={cookie} />
        ))}
      </Box>
    </Box>
  );
};
