import { TrackReserveWithGoogleMutation } from '@water-web/api';

import { Tracker, CommonPayload, isPaymentEvent } from '../types';
import { AnalyticsEvent } from '../events';
import { LocalStorageTTL } from '../../utils';
import { config } from '../../config';

const IS_SSR = typeof window === 'undefined';

interface RwgData {
  shopId: string;
  token: string;
}

/**
 * Will capture `rwg_token` from URL, store it on LocalStorage, and send tracking event to Google on successful booking
 */
export class ReserveWithGoogleTracker implements Tracker {
  deploymentEnv = 'local';
  storage = new LocalStorageTTL<RwgData>({ key: 'squire_rwg', ttl: 30 });
  stored = false;

  constructor(deploymentEnv: string) {
    this.deploymentEnv = deploymentEnv;
  }

  getName(): string {
    return 'ReserveWithGoogleTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    if (IS_SSR) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - CommonPayload is empty in `view` package scope
    const { shopId } = payload;

    if (isPaymentEvent(analyticsEvent, payload)) {
      this.sendEvent(shopId); // after payment
    } else {
      this.saveToken(shopId); // any other event
    }
  }

  protected saveToken(shopId?: string): void {
    if (this.stored || !shopId) {
      return;
    }

    const urlObject = new URL(window.location.href);
    const token = urlObject.searchParams.get('rwg_token');
    if (!token) {
      return;
    }

    this.storage.set({ token, shopId });
    this.stored = true;
  }

  protected sendEvent(shopId?: string): void {
    const data = this.storage.get();
    if (!data?.token || !data?.shopId) {
      return;
    }

    const endpoint = new TrackReserveWithGoogleMutation(this.deploymentEnv);
    endpoint.request({
      conversion_partner_id: config.reserveWithGooglePartnerId,
      merchant_changed: data.shopId !== shopId ? 1 : 2,
      rwg_token: data.token,
    });
  }
}
