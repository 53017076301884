import { PropsWithChildren } from 'react';
import { useFeatureFlags, RenderWhenCookieAccepted as OriginalRenderWhenCookieAccepted } from '@water-web/view';

export const RenderWhenCookieAccepted = ({ children }: PropsWithChildren) => {
  const { cookieConsent } = useFeatureFlags();

  if (!cookieConsent) {
    return <>{children}</>;
  }

  return <OriginalRenderWhenCookieAccepted>{children}</OriginalRenderWhenCookieAccepted>;
};
