import styled, { css } from 'styled-components';

import { ArrowIcon } from '../icons';
import { Box, TextBase } from '../../../atoms';
import { consentConfig } from '../config';
import { BodyText } from '../styles';

export const DetailsTabParentContainer = styled(Box)`
  position: relative;
  max-height: 60vh;
  overflow: hidden;
  margin-bottom: 10px;

  &:after {
    content: ' ';
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;
    height: 50px;
    background: linear-gradient(180deg, rgba(22, 22, 22, 0), rgba(22, 22, 22, 1));
    pointer-events: none;
  }
`;

export const DetailsTabScrollContainer = styled(Box)`
  max-height: 60vh;
  padding: 24px 32px 10px 32px;
  overflow: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${consentConfig.media.mobile}) {
    padding: 16px 16px 0 16px;
  }
`;

export const SubsectionCardContainer = styled(Box)`
  border: 1px solid ${consentConfig.borderColor};
  border-radius: 16px;
  padding: 16px 20px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    border-color: rgb(230, 230, 230);
  }

  transition-property: border-color;
  transition-duration: ${consentConfig.animationDurationSec}s;
`;

export const SubsectionIcon = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: rgb(238, 238, 238);
`;

export const AnimatedArrow = styled(ArrowIcon)<{ right?: boolean; left?: boolean }>`
  display: block;
  transition-property: transform;
  transition-duration: ${consentConfig.animationDurationSec}s;
  fill: #fff;

  ${({ right }) =>
    right &&
    css`
      transform: rotate(-90deg);
    `};

  @media (max-width: ${consentConfig.media.mobile}) {
    width: 8px;
    height: 14px;
  }
`;

export const BackArrow = styled(ArrowIcon)`
  display: block;
  width: 10px;
  height: 18px;
  fill: rgba(247, 247, 247, 0.6);
  transform: rotate(90deg) translateX(-1px);

  @media (max-width: ${consentConfig.media.mobile}) {
    width: 8px;
    height: 14px;
  }
`;

export const ArrowButton = styled(Box).attrs({ as: 'button' })`
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const CookieDetailsContainer = styled(Box)`
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${consentConfig.borderColor};
  }
`;

export const LabelText = styled(TextBase)`
  color: ${consentConfig.secondaryTextColor};
  font-family: 'SF Pro Text';
  font-size: 9px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.07px;
  text-transform: uppercase;
`;

export const CookieInfoText = styled(BodyText)`
  font-size: 11px;

  @media (max-width: ${consentConfig.media.mobile}) {
    font-size: 10px;
  }
`;

export const SectionTitle = styled(BodyText)`
  font-size: 14px;
  font-weight: 600;

  @media (max-width: ${consentConfig.media.mobile}) {
    font-size: 12px;
  }
`;

export const Section = styled(Box)`
  margin-bottom: 16px;

  @media (max-width: ${consentConfig.media.mobile}) {
    margin-bottom: 8px;
  }
`;
