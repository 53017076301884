import {
  UpdateUserCustomerMutation,
  UserCardsQuery,
  UserCurrentQuery,
  UserLoginOtpInitOnPhoneByEmailMutation,
  UserLoginOtpSubmitOnPhoneMutation,
  UserSignupByAppleMutation,
  UserSignupByEmailMutation,
  UserSignupByGoogleMutation,
  GetToken,
} from '@water-web/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserServiceConstructor = (config: { baseUrl: string; getToken: GetToken }) => ({
  current: new UserCurrentQuery(config),
  cards: new UserCardsQuery(config),
  loginOtpInitOnPhoneByEmail: new UserLoginOtpInitOnPhoneByEmailMutation(config.baseUrl),
  signup: new UserSignupByEmailMutation(config.baseUrl),
  signupWithApple: new UserSignupByAppleMutation(config.baseUrl),
  signupWithGoogle: new UserSignupByGoogleMutation(config.baseUrl),
  loginOtpSubmitOnPhone: new UserLoginOtpSubmitOnPhoneMutation(config.baseUrl),
  updateUserCustomer: new UpdateUserCustomerMutation(config),
});
