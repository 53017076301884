import { createApi, Http } from '@water-web/api';
import { HttpPlatform } from '@water-web/types';
import { createRepository } from '@water-web/repository';
import { getApiTrackingParams, Logger } from '@water-web/view';

import { config } from '@app/config';
import { getPersistedLocale } from '@app/i18n';

const isGoogleBooking = new URLSearchParams(window.location.search).has('rwg_token');
const mobilePlatform: HttpPlatform = config.mode.iframe ? 'Widget' : 'Web';
const platform: HttpPlatform = isGoogleBooking ? 'Google' : mobilePlatform;
const trackingParams = getApiTrackingParams();

Http.setPlatform(platform);
Http.setLogger(Logger);
Http.setTrackingParams(trackingParams);

export const api = createApi({
  baseUrl: config.apiUrl,
  platform,
  logger: Logger,
  // In Incognito we don't have localStorage, so we're reaching out to Window object.
  getPreferredLanguage: () => getPersistedLocale() ?? 'en',
  trackingParams,
});

export const repository = createRepository(api);

export const {
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  barberRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  brandRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  cartRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  productRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  saleOrderRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  scheduleRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  searchRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  serviceRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  shopRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  smsRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  waitingListRepository,
  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  customerRepository,
} = repository;
