import { AnimatePresence } from 'framer-motion';
import { PropsWithChildren, useContext, Children } from 'react';
import { createPortal } from 'react-dom';

import { StackName } from './types';
import { PopupContext } from './PopupManager';
import { PopupRenderWrapper } from './PopupRenderWrapper';

/**
 * Will render child in a corresponding `PopupStack`.
 *
 * You may show/hide popup by hiding/showing `PopupRender` itself, or it's children.
 * Order of popups within stack is defined by:
 * - the order of elements within `PopupRender`;
 * - the order of `PopupRender`s appear in time.
 */
export const PopupRender = (props: PropsWithChildren<{ stackName: StackName }>) => {
  const { stacks } = useContext(PopupContext);

  const stack = stacks.find((item) => item.name === props.stackName);
  if (!stack?.ref.current) {
    // waiting for ref to get set
    return null;
  }

  const wrappedChildren = Children.map(props.children, (child) => {
    return (
      <AnimatePresence mode="wait" key={props.stackName}>
        {!!child && <PopupRenderWrapper stackName={props.stackName}>{child}</PopupRenderWrapper>}
      </AnimatePresence>
    );
  });

  return createPortal(wrappedChildren, stack.ref.current);
};
