import { ReactElement } from 'react';
import styled from 'styled-components';
import { style } from 'styled-system';

type LoadingValue = 'lazy' | 'eager' | undefined;
type LayoutValue = 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined;
type NextImageProps = Omit<JSX.IntrinsicElements['img'], 'src' | 'srcSet' | 'ref' | 'width' | 'height' | 'loading'> & {
  src: string;
  quality?: number | string;
  priority?: boolean;
  loading?: LoadingValue;
  unoptimized?: boolean;
} & (
    | {
        width?: never;
        height?: never;
        layout: 'fill';
      }
    | {
        width: number | string;
        height: number | string;
        layout?: Exclude<LayoutValue, 'fill'>;
      }
  );

const objectFitStyle = style({
  prop: 'objectFit',
  cssProperty: 'objectFit',
});

interface CustomImageProps {
  objectFit?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'contain'
    | 'cover'
    | 'fill'
    | 'none'
    | 'scale-down';
  alt: string;
  fallback?: ReactElement;
}

export type ImageProps = Omit<NextImageProps, 'alt'> & CustomImageProps;

export const Image = styled.img<ImageProps>`
  ${objectFitStyle};
`;
