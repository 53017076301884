import { createGlobalStyle } from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { appLayers } from '@app/appLayers';

import { ToastIcon } from './ToastIcon';

const ToastStyles = createGlobalStyle`
  :root {
    --toastify-color-success: #07bc0c;
    --toastify-color-error: #e74c3c;
    --toastify-color-info: #616163;

    --toastify-toast-width: 100%;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 72px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: ${appLayers.toast};

    --toastify-text-color-success: #fff;
    --toastify-text-color-error: #fff;
    --toastify-text-color-info: #fff;
  }

  .Toastify__toast-container {
    top: 0;
    padding: 8px;
  }

  .Toastify__toast {
    border-radius: 12px;
    padding: 16px;
  }

  .Toastify__toast-body {
    padding: 0;

    font-family: SF Pro Text;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  .Toastify__toast-icon {
    width: unset;
    margin-right: 16px;
  }
`;
export const Toaster = () => (
  <>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      closeButton={false}
      hideProgressBar
      pauseOnHover
      pauseOnFocusLoss
      closeOnClick
      draggable
      theme="colored"
    />
    <ToastStyles />
  </>
);

const defaultOptions = { icon: ToastIcon };
export const toaster: Pick<typeof toast, 'error' | 'success' | 'info'> = {
  error: (content, options) => toast.error(content, { ...defaultOptions, ...options }),
  success: (content, options) => toast.success(content, { ...defaultOptions, ...options }),
  info: (content, options) => toast.info(content, { ...defaultOptions, ...options }),
};
