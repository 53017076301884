import { InterpolationFunction } from 'styled-components';
import { zIndex as zIndexOriginal, ZIndexProps as ZIndexPropsOriginal } from 'styled-system';

interface ZIndexProps {
  $zIndex?: ZIndexPropsOriginal['zIndex'];
}

/**
 * A workaround to prevent `zIndex` props leaking into HTML by using `$zIndex` property
 */
export const zIndex: InterpolationFunction<ZIndexProps> = (props) => {
  return zIndexOriginal({ zIndex: props.$zIndex });
};
