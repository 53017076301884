import { useMemo, useEffect, createContext, PropsWithChildren } from 'react';

import { useFeatureFlags } from '../../../contexts/FeatureFlags';
import { ensureApiLoaded } from './utils';

interface BotProtectionContextValue {
  enabled: boolean;
  siteKey: string | null;
}

const defaultValue: BotProtectionContextValue = {
  enabled: false,
  siteKey: null,
};

export const BotProtectionContext = createContext<BotProtectionContextValue>(defaultValue);

interface ProviderProps {
  siteKey: string;
}

export const BotProtectionContextProvider = (props: PropsWithChildren<ProviderProps>) => {
  const { checkIfHuman: enabled } = useFeatureFlags();

  const contextValue = useMemo<BotProtectionContextValue>(() => {
    return {
      enabled: enabled && !!props.siteKey,
      siteKey: props.siteKey,
    };
  }, [props.siteKey, enabled]);

  useEffect(() => {
    if (enabled && !props.siteKey) {
      console.error('Bot protection is on, but site key is missing. Check configuration.');
    }

    if (enabled && props.siteKey) {
      ensureApiLoaded(props.siteKey);
    }
  }, [enabled, props.siteKey]);

  return <BotProtectionContext.Provider value={contextValue}>{props.children}</BotProtectionContext.Provider>;
};
