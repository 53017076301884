import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  AppStateContextProvider,
  BookingUrlBuilderProvider,
  CartContextProvider,
  CartStateContextProvider,
  RepositoryContext,
  UserAgentContextProvider,
  BotProtectionContextProvider,
  FeatureFlagsProvider,
  PopupManager,
  CookieConsentProvider,
  StripeLoader,
  BankCardContextProvider,
  DeeplinkPromoContextProvider,
  AppSettingsContextProvider,
} from '@water-web/view';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { config } from '@app/config';
import { repository } from '@app/repository';
import { getToken } from '@app/auth';
import GlobalStyles from '@app/styles/global';
import { PopupContext, WidgetContainerContextProvider, SignInOpenContextProvider } from '@app/components';
import {
  LocaleProvider,
  BrandedThemeProvider,
  BrandedThemeListener,
  GoogleApiContextProvider,
  BookingStateContextProvider,
  ViewModeContextProvider,
} from '@app/contexts';
import { CartControllerProvider } from '@app/contexts/CartController';
import { CheckoutFlowContextProvider } from '@app/contexts/CheckoutFlow';
import { usePopupController } from '@app/components/molecules/Popup/usePopupController';

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  // TODO: uncomment and use when WTRW-199 is imepemented
  const initialData = null; // window._squireInitialData;
  const popupController = usePopupController();

  return (
    <GoogleOAuthProvider clientId={config.googleAuthClientId}>
      <AppSettingsContextProvider config={{ apiUrl: config.apiUrl }}>
        <RepositoryContext.Provider value={repository}>
          <FeatureFlagsProvider
            clientSideId={config.launchDarkly.clientSideId}
            deploymentEnv={config.deploymentEnv}
            platform={config.mode.iframe ? 'widget' : 'mobile'}
          >
            <BotProtectionContextProvider siteKey={config.botProtection.siteKey}>
              <BrandedThemeProvider>
                <GlobalStyles />
                <CookieConsentProvider strategy={config.mode.iframe ? 'parent' : 'local'}>
                  <PopupManager>
                    <SignInOpenContextProvider>
                      <BrowserRouter basename={config.basePath}>
                        <LocaleProvider>
                          <GoogleApiContextProvider>
                            <AppStateContextProvider>
                              <UserAgentContextProvider>
                                <DeeplinkPromoContextProvider
                                  platform={config.mode.iframe ? 'Widget' : 'Web'}
                                  getToken={getToken}
                                >
                                  <CartContextProvider initialData={initialData}>
                                    <BrandedThemeListener />
                                    <BookingUrlBuilderProvider>
                                      <BookingStateContextProvider>
                                        <CartStateContextProvider>
                                          <WidgetContainerContextProvider>
                                            <PopupContext.Provider value={{ popupController }}>
                                              <ViewModeContextProvider>
                                                <CartControllerProvider>
                                                  <StripeLoader stripeKey={config.stripeKey}>
                                                    <BankCardContextProvider>
                                                      <CheckoutFlowContextProvider>
                                                        {children}
                                                      </CheckoutFlowContextProvider>
                                                    </BankCardContextProvider>
                                                  </StripeLoader>
                                                </CartControllerProvider>
                                              </ViewModeContextProvider>
                                            </PopupContext.Provider>
                                          </WidgetContainerContextProvider>
                                        </CartStateContextProvider>
                                      </BookingStateContextProvider>
                                    </BookingUrlBuilderProvider>
                                  </CartContextProvider>
                                </DeeplinkPromoContextProvider>
                              </UserAgentContextProvider>
                            </AppStateContextProvider>
                          </GoogleApiContextProvider>
                        </LocaleProvider>
                      </BrowserRouter>
                    </SignInOpenContextProvider>
                  </PopupManager>
                </CookieConsentProvider>
              </BrandedThemeProvider>
            </BotProtectionContextProvider>
          </FeatureFlagsProvider>
        </RepositoryContext.Provider>
      </AppSettingsContextProvider>
    </GoogleOAuthProvider>
  );
};
