import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import basketIcon from './basket.svg';

export const Basket = styled(JustIcon).attrs((props) => ({
  alt: 'basket',
  icon: basketIcon,
  width: props.width || '12px',
  height: props.height || '12px',
}))`` as JustIconStyledComponent<IconStaticProps>;
