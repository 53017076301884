import { QueryClient } from 'react-query';
import { WaitingListAppointment } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  id: string;
  slotId: string;
}

export class WaitingListAcceptSlotMutation extends EndpointMutation<WaitingListAppointment, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<WaitingListAppointment> {
    return this.http.post(
      '/v1/book-appointment-time-slot',
      {
        waitingListCustomerId: payload.id,
        waitingListSlotId: payload.slotId,
        platform: 'Web',
      },
      {
        signal,
        headers: this.buildHeaders(),
        resourceUrl: '/book-appointment-time-slot',
      },
    );
  }

  async afterMutationError(queryClient: QueryClient): Promise<void> {
    await queryClient.invalidateQueries(queryKeys.timeSlots());
  }
}
