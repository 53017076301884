import { useContext, Suspense, useEffect } from 'react';
import { matchPath, Switch, useLocation, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useTimeouts } from '@water-web/view';

import { useWindowTitleUpdate } from '@app/hooks';
import { WidgetContainerContext } from '@app/components';

import { DelayedRenderTitleContainer } from './components/molecules/WidgetContainer';
import { routes } from './routeDefinitions';
import { TestErrorPage } from './pages/testError';
import { lazyWithPreload } from './utils/lazyWithPreload';

const loadBrandPage = () => import('@app/pages/brand');
const loadShopPage = () => import('@app/pages/shop');
const loadServicesPage = () => import('@app/pages/services');
const loadSchedulePage = () => import('@app/pages/schedule');
const loadGiftCardPage = () => import('@app/pages/giftCard');
const loadReservationPage = () => import('@app/pages/reservation');
const loadGiftCardInfoPage = () => import('@app/pages/giftCardInfo');
const loadWaitingListPage = () => import('@app/pages/waitingList');

const Brand = lazyWithPreload(() => loadBrandPage().then((module) => ({ default: module.Brand })));
const BrandTitle = lazyWithPreload(() => loadBrandPage().then((module) => ({ default: module.BrandTitle })));
const Shop = lazyWithPreload(() => loadShopPage().then((module) => ({ default: module.Shop })));
const ShopTitle = lazyWithPreload(() => loadShopPage().then((module) => ({ default: module.ShopTitle })));
const Services = lazyWithPreload(() => loadServicesPage().then((module) => ({ default: module.Services })));
const ServicesTitle = lazyWithPreload(() => loadServicesPage().then((module) => ({ default: module.ServicesTitle })));
const Schedule = lazyWithPreload(() => loadSchedulePage().then((module) => ({ default: module.Schedule })));
const ScheduleTitle = lazyWithPreload(() => loadSchedulePage().then((module) => ({ default: module.ScheduleTitle })));
const GiftCard = lazyWithPreload(() => loadGiftCardPage().then((module) => ({ default: module.GiftCard })));
const GiftCardTitle = lazyWithPreload(() => loadGiftCardPage().then((module) => ({ default: module.GiftCardTitle })));
const Reservation = lazyWithPreload(() => loadReservationPage().then((module) => ({ default: module.Reservation })));
const ReservationTitle = lazyWithPreload(() =>
  loadReservationPage().then((module) => ({ default: module.ReservationTitle })),
);
const GiftCardInfo = lazyWithPreload(() => loadGiftCardInfoPage().then((module) => ({ default: module.GiftCardInfo })));
const GiftCardInfoTitle = lazyWithPreload(() =>
  loadGiftCardInfoPage().then((module) => ({ default: module.GiftCardInfoTitle })),
);
const WaitingList = lazyWithPreload(() => loadWaitingListPage().then((module) => ({ default: module.WaitingList })));
const WaitingListTitle = lazyWithPreload(() =>
  loadWaitingListPage().then((module) => ({ default: module.WaitingListTitle })),
);

const neighborComponentsByRoute = {
  [routes.brand]: [Shop, ShopTitle],
  [routes.shop]: [Brand, BrandTitle, Services, ServicesTitle, GiftCard, GiftCardTitle],
  [routes.services]: [Shop, ShopTitle, Schedule, ScheduleTitle, GiftCard, GiftCardTitle],
  [routes.schedule]: [Services, ServicesTitle, Reservation, ReservationTitle],
  [routes.giftCard]: [Shop, ShopTitle, Services, ServicesTitle, GiftCardInfo, GiftCardInfoTitle],
};

const preloadNeighbors = (pathname: string) => {
  const currentRoute = Object.values(routes).find((route) => matchPath(pathname, { exact: true, path: route }));
  const neighbors = neighborComponentsByRoute[currentRoute];
  neighbors?.forEach((component) => component.preload());
};

export const Routes = () => {
  const location = useLocation();
  const { animationExitMediator } = useContext(WidgetContainerContext);
  const timeouts = useTimeouts();

  useWindowTitleUpdate();

  const onExitComplete = () => {
    /**
     * There seems to be a `framer-motion` bug, where `AnimatePresence.onExitComplete`
     * gets called ~20ms before the child gets unmounted. So we'll wait 100ms more for now.
     * @see https://github.com/framer/motion/issues/1457
     */
    timeouts.add(() => animationExitMediator.dispatchEvent(new Event('exit')), 100);
  };

  useEffect(() => {
    // no special reason for the timeout, just want to give the active page some time to load
    // and then preload its neighbors
    setTimeout(() => preloadNeighbors(location.pathname), 1000);
  }, [location]);

  return (
    <Suspense fallback={null}>
      <AnimatePresence initial={false} onExitComplete={onExitComplete}>
        <Switch location={location} key={location.key}>
          <Route path={routes.brand} component={Brand} />
          <Route path={routes.services} component={Services} />
          <Route path={routes.schedule} component={Schedule} />
          <Route path={routes.shop} component={Shop} />
          <Route path={routes.giftCard} component={GiftCard} />
          <Route path={routes.reservation} component={Reservation} />
          <Route path={routes.giftCardInfo} component={GiftCardInfo} />
          <Route path={routes.waitingList} component={WaitingList} />
          <Route path={routes.testErrorRoute} component={TestErrorPage} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
};

export const TitleRoutes = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const titleVisible = (route: string): boolean => {
    return !!matchPath(currentPath, { exact: true, path: route });
  };

  // But why not use <Swith> here? Well, Framer has big trouble understanding
  // what got changed if we don't actually change the component, but only its children.
  // It gets all confused and doesn't animate properly, even when its a direct parent
  // of all <Route> components. So we have to use <AnimatePresence> with actual unmounting
  // and mounting of components - instead of having a Route render null in case its not active.
  return (
    <Suspense fallback={null}>
      <AnimatePresence initial={false} mode="wait">
        <DelayedRenderTitleContainer key={location.key}>
          {titleVisible(routes.brand) && <BrandTitle key={routes.brand} />}
          {titleVisible(routes.services) && <ServicesTitle key={routes.services} />}
          {titleVisible(routes.schedule) && <ScheduleTitle key={routes.schedule} />}
          {titleVisible(routes.shop) && <ShopTitle key={routes.shop} />}
          {titleVisible(routes.giftCard) && <GiftCardTitle key={routes.giftCard} />}
          {titleVisible(routes.reservation) && <ReservationTitle key={routes.reservation} />}
          {titleVisible(routes.giftCardInfo) && <GiftCardInfoTitle key={routes.giftCardInfo} />}
          {titleVisible(routes.waitingList) && <WaitingListTitle key={routes.waitingList} />}
        </DelayedRenderTitleContainer>
      </AnimatePresence>
    </Suspense>
  );
};
