import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '@app/components/atoms';

import toastInfoIcon from './toastInfo.svg';

export const ToastInfo = styled(JustIcon).attrs({
  alt: 'toastInfo',
  icon: toastInfoIcon,
  width: '32px',
  height: '32px',
})`` as JustIconStyledComponent<IconStaticProps>;
