import { QueryClient } from 'react-query';
import { WaitingList } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

interface Payload {
  id: string;
  tipAmount: number;
}

export class WaitingListTipMutation extends EndpointMutation<WaitingList, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<WaitingList> {
    return this.http.put(
      `/v1/customer/waiting-list/${payload.id}`,
      { tipAmount: payload.tipAmount },
      {
        signal,
        headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
        resourceUrl: '/customer/waiting-list/:id/tip',
      },
    );
  }

  async afterMutationSuccess(queryClient: QueryClient, data: WaitingList, payload: Payload): Promise<void> {
    return queryClient.invalidateQueries(queryKeys.waitingList({ id: payload.id }));
  }
}
