import { useEffect } from 'react';
import {
  trackIncomingVisitors,
  useFeatureFlags,
  ReserveWithGoogleTracker,
  BrandGtmTracker,
  ConsoleTracker,
  BrandMetaTracker,
} from '@water-web/view';

import { config } from '@app/config';
import { useInitializedCart } from '@app/hooks';
import { Analytics, StylzTracker, GtmTracker, BarberCoTracker, UpprMgmtTracker } from '@app/analytics';

import { CustomAnalytics } from './components';
import { usePageviewAnalytics } from './hooks';

export const AnalyticsScripts = () => {
  const cart = useInitializedCart();
  const { wowTrackRwgTokens } = useFeatureFlags();

  useEffect(() => {
    const analytics = Analytics.getInstance();
    analytics.registerTracker(new GtmTracker());
    analytics.registerTracker(new BrandGtmTracker());
    analytics.registerTracker(new BrandMetaTracker());

    if (config.deploymentEnv === 'production') {
      analytics.registerTracker(new StylzTracker());
      analytics.registerTracker(new BarberCoTracker());
      analytics.registerTracker(new UpprMgmtTracker());
    } else {
      analytics.registerTracker(new ConsoleTracker());
    }

    trackIncomingVisitors(analytics);
  }, []);

  useEffect(() => {
    const analytics = Analytics.getInstance();
    if (wowTrackRwgTokens) {
      analytics.registerTracker(new ReserveWithGoogleTracker(config.deploymentEnv));
    }
  }, [wowTrackRwgTokens]);

  useEffect(() => {
    const acquisitionChannel = Analytics.getInstance().getAcquisitionChannel();
    if (!!cart && !!acquisitionChannel) {
      cart.setAcquisitionChannel(acquisitionChannel);
    }
  }, [cart]);

  usePageviewAnalytics();

  return <CustomAnalytics />;
};
