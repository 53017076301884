import { useTranslation } from 'react-i18next';
import { Brand } from '@water-web/types';
import { getHasCustomAppsFromBrand, getExtensionFromBrand } from '@water-web/repository';

interface Links {
  ios: string;
  android: string;
  brandName: string;
  isCustom: boolean;
}

const flagshipIos = 'https://apps.apple.com/gb/app/squire/id957644357';
const flagshipAndroid = 'https://play.google.com/store/apps/details?id=com.getsquire.flagship';

// eslint-disable-next-line complexity
export const useMobileAppLinks = (brand?: Brand): Links => {
  const { t } = useTranslation();
  const extension = brand && getExtensionFromBrand(brand);
  const isCustom = getHasCustomAppsFromBrand(brand);

  return {
    ios: isCustom && extension?.iTunesLink ? extension.iTunesLink : flagshipIos,
    android: isCustom && extension?.playStoreLink ? extension.playStoreLink : flagshipAndroid,
    brandName: isCustom && brand.name ? brand.name : t('common.squireName'),
    isCustom,
  };
};
