import { ExternalConfiguration } from '@water-web/view';
import { useQuery, UseQueryResult } from 'react-query';
import { BrandModel, RepositoryResponse } from '@water-web/repository';

type UseBrandResult = UseQueryResult<RepositoryResponse<BrandModel>> & {
  brandModel: BrandModel;
};

export const useBrand = (brandIdOrRoute?: string): UseBrandResult => {
  const { brandRepository } = ExternalConfiguration.getRepository();
  const query = useQuery(['brands', brandIdOrRoute], () => brandRepository.getByIdOrRoute(brandIdOrRoute as string), {
    enabled: !!brandIdOrRoute,
  });

  return {
    ...query,
    brandModel: query.data?.data,
  };
};
