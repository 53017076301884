import { SquireApi, ApiFactory, SquireApiConfig } from '@water-web/types';

import { Http } from './http';
import {
  BarberApi,
  BrandApi,
  ShopApi,
  ServiceApi,
  SearchApi,
  ProductApi,
  CartApi,
  SaleOrderApi,
  SMSApi,
  WaitingListApi,
  CustomerApi,
} from './apis';

export * from './types_public';
export * from './endpoints';
export * from './request';
export * from './transport';
export * from './hooks';

export const createApi: ApiFactory = (config: SquireApiConfig): SquireApi => {
  const http = new Http({
    ...config,
    baseUrl: config.baseUrl.concat('/v1'),
    trackingParams: config.trackingParams,
  });
  const httpV2 = new Http({
    ...config,
    baseUrl: config.baseUrl.concat('/v2'),
    trackingParams: config.trackingParams,
  });

  const brand = new BrandApi(http, httpV2);
  const shop = new ShopApi(http, httpV2);
  const barber = new BarberApi(http, httpV2);
  const service = new ServiceApi(http, httpV2);
  const search = new SearchApi(http, httpV2);
  const product = new ProductApi(http, httpV2);
  const cart = new CartApi(http, httpV2);
  const saleOrder = new SaleOrderApi(http, httpV2);
  const sms = new SMSApi(http, httpV2);
  const waitingList = new WaitingListApi(http, httpV2);
  const customer = new CustomerApi(http, httpV2);

  return {
    brand,
    shop,
    barber,
    service,
    search,
    product,
    cart,
    saleOrder,
    sms,
    waitingList,
    customer,
  };
};
