import {
  AnalyticsEvent,
  Tracker,
  CommonPayload,
  isPaymentEvent,
  GtmBookAppointmentEventPayload,
} from '@water-web/view';

import { config } from '@app/config';

export class UpprMgmtTracker implements Tracker {
  brandId = '773c28e8-8f34-4dd2-9b16-ab78c2501726';
  gclidMixin: Pick<GtmBookAppointmentEventPayload, 'gclid'> = { gclid: '' };

  constructor() {
    if (typeof window !== 'undefined') {
      const gclid = new URLSearchParams(window.location.search).get('gclid');
      if (gclid) {
        this.gclidMixin = { gclid };
      }
    }
  }

  getName(): string {
    return 'UpprMgmtTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    const exit = config.deploymentEnv !== 'production' || payload?.brandId !== this.brandId;

    if (exit || !isPaymentEvent(analyticsEvent, payload)) {
      return;
    }

    const totalWithDecimals = (payload.totalWithTips / 100).toFixed(2);

    window.upprMgmtDataLayer?.push(<GtmBookAppointmentEventPayload>{
      event: 'bookingCompleted',
      value: totalWithDecimals,
      currency: payload.currency,
      transaction_id: payload.appointmentId,
      shop_name: payload.shop.name,
      shop_route: payload.shop.route,
      ...this.gclidMixin,
    });
  }
}
