import { parseJSON } from 'date-fns';
import { Service, Addon, ValidatedCartSubItem, Tax, ServiceAdjustment } from '@water-web/types';

import { Quantity } from './quantity';
import {
  AddonModel,
  BarberModel,
  ServiceModel,
  ProductModel,
  SaleOrderReservationModel,
  SaleOrderModel,
  ValidatedCartModel,
  ShopModel,
  CustomerModel,
  GiftCardModel,
} from '../models';

export class SaleOrderModelPopulator {
  static populateModelFromApiCart(saleOrder: SaleOrderModel, validatedCart: ValidatedCartModel): void {
    const factory = new SaleOrderModelPopulator(saleOrder, validatedCart);
    return factory.populateFromApiSaleOrder();
  }

  constructor(saleOrder: SaleOrderModel, validatedCart: ValidatedCartModel) {
    this.saleOrder = saleOrder;
    this.validatedCart = validatedCart;
  }

  private saleOrder: SaleOrderModel = null;
  private validatedCart: ValidatedCartModel = null;

  populateFromApiSaleOrder(): void {
    this.populateReservations();
    this.populateCartProducts();
    this.populateShop();
    this.populateCustomer();
    this.populateSaleGiftCard();
    this.populateId();
    this.populateValidatedCart();
  }

  private populateReservations(): void {
    if (this.validatedCart) {
      const reservations = this.validatedCart.getReservations().map((item) => {
        const reservation = new SaleOrderReservationModel(item);

        const validatedService = this.validatedCart.getServiceForReservation(item.id);
        const serviceForModel = SaleOrderModelPopulator.cartServiceToServiceModel(validatedService);
        const service = new ServiceModel(serviceForModel);
        reservation.setService(service);

        const validatedAddons = this.validatedCart.getAddonsForReservation(item.id);
        const addons = validatedAddons.map((validatedAddon) => {
          const dataForModel = SaleOrderModelPopulator.cartServiceToServiceModel(validatedAddon);
          return new AddonModel(dataForModel as unknown as Addon);
        });

        reservation.setAddons(addons);

        const barber = new BarberModel(item.barber);
        reservation.setBarber(barber);

        const validatedCustomer = this.validatedCart.getCustomerForReservation(item.id);
        const customer = reservation.getCustomer();
        customer.setFirstName(validatedCustomer?.firstName || '');
        customer.setLastName(validatedCustomer?.lastName || '');

        if (item.appointment.dateTime) {
          // parsing `null` will return `Invalid Date` created by `new Date(NaN)`
          reservation.setDateTime(parseJSON(item.appointment.dateTime));
        }

        return reservation;
      });

      this.saleOrder.setReservations(reservations);
    }
  }

  private populateCartProducts(): void {
    if (this.validatedCart) {
      const validatedProducts = this.validatedCart.getProducts();
      const products = validatedProducts.map((item) => {
        const productForModel = { ...item.product, itemId: item.id };
        const product = new ProductModel(productForModel);
        return new Quantity(item.quantity, product);
      });

      this.saleOrder.setProducts(products);
    }
  }

  private populateShop(): void {
    const shop = new ShopModel(this.validatedCart.getShop());
    this.saleOrder.setShop(shop);
    this.saleOrder.getReservations().forEach((reservation) => reservation.setShop(shop));
  }

  private populateCustomer(): void {
    const customer = new CustomerModel(this.validatedCart.getPayingCustomer());
    this.saleOrder.setCustomer(customer);
  }

  private populateSaleGiftCard(): void {
    const giftCards = this.validatedCart.getGiftCardsForSale().map(GiftCardModel.fromValidatedCartItem);
    this.saleOrder.setSaleGiftCards(giftCards);
  }

  private populateId(): void {
    this.saleOrder.setId(this.validatedCart.getId());
  }

  private populateValidatedCart(): void {
    this.saleOrder.setValidatedCart(this.validatedCart);
  }

  private static cartAdjustmentToTax(adjustment: ServiceAdjustment): Tax {
    return {
      id: adjustment.referenceId,
      addToPrice: adjustment.addToPrice,
      enabled: !adjustment.exclude,
      name: adjustment.name,
      percentage: adjustment.rate,
    } as Tax;
  }

  /**
   * product/addon/service id is stored in the referenceId in the validated cart's items or subItems
   * so we need to properly replace it when creating models
   */
  private static cartServiceToServiceModel(item: ValidatedCartSubItem): Service {
    return {
      ...item, // just in case :)
      id: item.referenceId,
      taxes: item.adjustments?.map(SaleOrderModelPopulator.cartAdjustmentToTax),
      costWithTaxes: item.priceWithTaxes,
      costWithoutTaxes: item.priceWithoutTaxes,
      costWithoutTaxesRange: item.totalWithoutTaxesRange ?? {
        min: item.priceWithoutTaxes,
        max: item.priceWithoutTaxes,
      },
      costWithTaxesRange: item.totalRange ?? {
        min: item.priceWithTaxes,
        max: item.priceWithTaxes,
      },
      duration: 0,
      durationRange: {
        min: 0,
        max: 0,
      },
    } as unknown as Service;
  }
}
