import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { cartIdQuery } from '@water-web/view';

import { useHistoryTyped } from '../useHistoryTyped';

interface UseCartIdResponse {
  getCartId: () => string | null;
  removeCartId: () => void;
}

/**
 * Provides methods to get/remove cartId from URL.
 * Getter is to init app with previously populated cart (Choose Time page refresh).
 * Remover is to clean-up URL (pages before the Choose Time page).
 */
export const useCartId = (): UseCartIdResponse => {
  const { search, pathname } = useLocation();
  const history = useHistoryTyped();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const removeCartId = useCallback(() => {
    if (query.get(cartIdQuery)) {
      query.delete(cartIdQuery);
      history.replace({ pathname, search: query.toString() });
    }
  }, [query, history, pathname]);

  const getCartId = useCallback((): string | null => {
    return (query.get(cartIdQuery) as string) ?? null;
  }, [query]);

  return useMemo(() => ({ getCartId, removeCartId }), [getCartId, removeCartId]);
};
