export const routes = {
  brand: '/brands/:brandIdOrRoute',
  services: '/book/:shopIdOrRoute/barber/:barberIdOrRoute/services',
  schedule: '/book/:shopIdOrRoute/barber/:barberIdOrRoute/schedule',
  shop: '/book/:shopIdOrRoute',
  giftCard: '/gift-card/:shopIdOrRoute/barber/:barberIdOrRoute',
  reservation: '/view-reservation/:saleOrderId',
  giftCardInfo: '/view-gift-card/:saleOrderId',
  waitingList: '/waiting-list/:waitingListApplicationId/:attemptId?',
  testErrorRoute: '/vnmcvnmmfjjfjfhfhfhfhjurur7474774hdbdbdbnbn', // This route is intentionally invalid so user will not accidentally navigate to it
};
