import { useState, createContext, PropsWithChildren, useCallback, useMemo, useEffect } from 'react';

import { CookieConsentState, SelectedCookieSections, CookieSection } from '../../types';
import { getUpdatedCookieConsentState } from './utils';
import { CookieConsentStorage } from './storage';

interface CookieConsentValue {
  pendingResponse: boolean;
  initialized?: boolean;
  selectCookieSections: (selectedSections: SelectedCookieSections) => void;
  isSectionAccepted: (section: CookieSection) => boolean;
}

const initialValue: CookieConsentValue = {
  pendingResponse: false,
  initialized: false,
  selectCookieSections: () => null,
  isSectionAccepted: () => false,
};

export const CookieConsentContext = createContext<CookieConsentValue>(initialValue);

interface Props {
  strategy: 'local' | 'parent';
}

const ClientCookieConsentProvider = (props: PropsWithChildren<Props>) => {
  const storage = useMemo<CookieConsentStorage>(() => {
    return new CookieConsentStorage(props.strategy);
  }, [props.strategy]);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [consentState, setConsentState] = useState<CookieConsentState | null>(null);

  useEffect(() => {
    (async () => {
      const initialState = await storage.read();
      if (initialState) {
        setConsentState(initialState);
      }

      setInitialized(true);
    })();
  }, [storage]);

  const selectCookieSections = useCallback(
    (selectedSections: SelectedCookieSections) => {
      const newConsentState = getUpdatedCookieConsentState(selectedSections);
      setConsentState(newConsentState);
      storage.write(newConsentState);
    },
    [storage],
  );

  const isSectionAccepted = useCallback(
    (section: CookieSection): boolean => {
      if (consentState) {
        return !!consentState[section];
      }
      return false;
    },
    [consentState],
  );

  const value = useMemo<CookieConsentValue>(() => {
    return {
      selectCookieSections,
      isSectionAccepted,
      pendingResponse: consentState === null,
      initialized,
    };
  }, [consentState, initialized]);

  return <CookieConsentContext.Provider value={value}>{props.children}</CookieConsentContext.Provider>;
};

const ServerCookieConsentProvider = (props: PropsWithChildren) => {
  return <CookieConsentContext.Provider value={initialValue}>{props.children}</CookieConsentContext.Provider>;
};

export const CookieConsentProvider = (props: PropsWithChildren<Props>) => {
  return typeof window !== 'undefined' ? (
    <ClientCookieConsentProvider {...props} />
  ) : (
    <ServerCookieConsentProvider {...props} />
  );
};
