/**
 * App layers in the order of appearance in DOM, with their `z-index` values.
 */
export const appLayers = {
  toast: 9999,
  tooltip: 9999999,
  title: 2,
  content: 1,
  cart: 2,
  popupCartStack: 5, // render above `popup`
  viewOrderButton: 1,
  groupApptPromotion: 100,
  stackSignIn: 100,
  popupRateExperience: 100,
  popup: 2,
  processingOverlay: 10,
};
