import { getProcessingPhaseName } from './utils';
import { PhaseConfig, PhaseHookArgs } from '../types';

export const paymentInfo: PhaseConfig = {
  name: 'paymentInfo',
  init: (params: PhaseHookArgs) => {
    params.setFallbackCheckoutPhase('paymentInfo');
    params.popupController.handlers.cardForm().open();
  },
  next: async (params: PhaseHookArgs) => {
    params.loadingHandlers.turnOn();
    params.inputBlockHandlers.turnOn();

    const payment = await params.collectPayment();

    params.loadingHandlers.turnOff();
    params.inputBlockHandlers.turnOff();

    if (payment.error || !payment.token) {
      params.setCheckoutPhase('error');
      return;
    }

    params.cartModel.setPaymentToken(payment.token.id);
    params.setCheckoutPhase(getProcessingPhaseName(params));
  },
  cancel: (params: PhaseHookArgs) => {
    // transition to userInfo
    params.setCheckoutPhase('userInfo');
    params.popupController.handlers.cardForm().close();
  },
};
