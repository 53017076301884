import { AnimatePresence, AnimatePresenceProps, motion } from 'framer-motion';
import styled, { StyledProps } from 'styled-components';
import { ComponentType, Children, PropsWithChildren } from 'react';

import { Box, BoxProps } from '../../atoms';

export interface PresenceHeightProps extends BoxProps, Pick<AnimatePresenceProps, 'mode' | 'onExitComplete'> {
  as?: string | ComponentType;
  $animateOpacity?: boolean;
  $overflow?: string;
  $duration?: number;

  [key: string]: unknown;
}

export const getAnimationFromProps = (props: StyledProps<PresenceHeightProps>) => ({
  initial: {
    height: 0,
    opacity: props.$animateOpacity ? 0 : 1,
    overflow: props.$overflow ?? 'hidden',
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transitionEnd: {
      overflow: 'unset',
    },
  },
  exit: {
    height: 0,
    opacity: props.$animateOpacity ? 0 : 1,
    overflow: props.$overflow ?? 'hidden',
  },
  transition: {
    times: [0, 0.99, 1],
    duration: props.$duration ?? props.theme.animations.booking.interaction,
  },
});

const AnimationContainer = styled(Box).attrs<PresenceHeightProps>(({ as, ...restProps }) => ({
  as: typeof as === 'string' ? motion[as] : motion(as, { forwardMotionProps: true }),
  ...getAnimationFromProps(restProps),
}))<PresenceHeightProps>``;

export const PresenceHeightAnimation = ({
  mode,
  onExitComplete,
  children,
  ...props
}: PropsWithChildren<PresenceHeightProps>) => {
  return (
    <AnimatePresence {...{ mode, onExitComplete }}>
      {Children.map(children, (child) => child && <AnimationContainer {...props}>{child}</AnimationContainer>)}
    </AnimatePresence>
  );
};

PresenceHeightAnimation.defaultProps = {
  as: 'div',
  $animateOpacity: false,
};
