import { useMemo, useCallback, ComponentType, PropsWithChildren } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { PlainChildrenProps } from 'react-intersection-observer';

import { consentConfig } from '../config';

export interface UseAnimatedToggleSectionResult {
  toggleAnimatedSection: () => void;
  AnimatedToggleSection: ComponentType<PropsWithChildren>;
}

export interface UseAnimatedToggleSectionProps {
  isExpanded: boolean;
  toggleExpanded: () => void;
}

export const useAnimatedToggleSection = (props: UseAnimatedToggleSectionProps): UseAnimatedToggleSectionResult => {
  const controls = useAnimation();

  const variants = useMemo(
    () => ({
      expanded: {
        height: 'auto',
        transition: {
          duration: consentConfig.animationDurationSec,
        },
      },
      shrunken: {
        height: '0',
        overflow: 'hidden',
        transition: {
          duration: consentConfig.animationDurationSec,
        },
      },
    }),
    [],
  );

  const toggleAnimatedSection = useCallback(() => {
    controls.start(props.isExpanded ? variants.shrunken : variants.expanded).then(() => {
      props.toggleExpanded();
    });
  }, []);

  const AnimatedToggleSection = (sectionProps: PlainChildrenProps) => (
    <motion.div
      animate={controls}
      variants={variants}
      // eslint-disable-next-line react/prop-types
      initial={props.isExpanded ? 'expanded' : 'shrunken'}
    >
      {sectionProps.children}
    </motion.div>
  );

  return {
    toggleAnimatedSection,
    AnimatedToggleSection,
  };
};
