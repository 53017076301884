import { Token, PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { UserCards } from '@water-web/types';

export type PaymentMethodPayInPerson = { type: 'payInPerson'; id: 'payInPerson' };
export type PaymentMethodApplePay = { type: 'applePay'; id: 'applePay' };
export type PaymentMethodGooglePay = { type: 'googlePay'; id: 'googlePay' };
export type PaymentMethodCardGeneric = { type: 'card'; id: 'card' };

type CardDetails = Pick<Token['card'], 'brand' | 'last4'>;

// A new card which details were just provided by the user
export type PaymentMethodCardNew = { type: 'card'; id: string; token: Token['id']; card: CardDetails };

// A card previously saved to the user profile
export type PaymentMethodCardSaved = { type: 'card'; id: string; card: UserCards[0] };

// for things like Stripe Link, Klarna, etc, which don't expose card details
export type PaymentMethodCardLess = {
  type: 'cardless';
  id: 'link' | string; // payment methods may be enabled from the API side, thus this vague string type
  token: StripePaymentMethod['id'];
};

/**
 * Instance of this type should have enough data to display it or apply to the cart.
 */
export type PaymentMethod =
  | PaymentMethodPayInPerson
  | PaymentMethodApplePay
  | PaymentMethodGooglePay
  | PaymentMethodCardGeneric
  | PaymentMethodCardNew
  | PaymentMethodCardSaved
  | PaymentMethodCardLess;

export const PAYMENT_METHOD_CARD_GENERIC = {
  type: 'card',
  id: 'card',
} as Readonly<PaymentMethodCardGeneric>;

export const PAYMENT_METHOD_APPLE_PAY = {
  type: 'applePay',
  id: 'applePay',
} as Readonly<PaymentMethodApplePay>;

export const PAYMENT_METHOD_GOOGLE_PAY = {
  type: 'googlePay',
  id: 'googlePay',
} as Readonly<PaymentMethodGooglePay>;

export const PAYMENT_METHOD_PAY_IN_PERSON = {
  type: 'payInPerson',
  id: 'payInPerson',
} as Readonly<PaymentMethodPayInPerson>;

export const isPaymentMethodCardGeneric = (method: PaymentMethod | undefined): method is PaymentMethodCardGeneric => {
  return method?.type === PAYMENT_METHOD_CARD_GENERIC.type && method?.id === PAYMENT_METHOD_CARD_GENERIC.id;
};

export const isPaymentMethodCardNew = (method: PaymentMethod | undefined): method is PaymentMethodCardNew => {
  return method?.type === 'card' && !!(method as PaymentMethodCardNew).token;
};

export const isPaymentMethodCardSaved = (method: PaymentMethod | undefined): method is PaymentMethodCardSaved => {
  return method?.type === 'card' && !!(method as PaymentMethodCardSaved).card?.id;
};

export const isPaymentMethodCardLess = (method: PaymentMethod | undefined): method is PaymentMethodCardLess => {
  return method?.type === 'cardless';
};

export const isPaymentMethodSameNewCard = (a: PaymentMethod | undefined, b: PaymentMethod | undefined): boolean => {
  return a && b && isPaymentMethodCardNew(a) && isPaymentMethodCardNew(b) && a.id === b.id;
};

export const isPaymentMethodDigitalWallet = (method: PaymentMethod | undefined): boolean => {
  return method?.type === 'applePay' || method?.type === 'googlePay';
};
