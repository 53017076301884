import { createContext, useState, useEffect, useCallback, useContext, PropsWithChildren } from 'react';

import { WidgetContainerContext } from '@app/components/molecules/WidgetContainer/WidgetContainerContext';

import { cartModes } from './modes';
import { CartMode, CartState, CartController, CartModeName } from './types';

export const CartControllerContext = createContext<CartController>({
  cartState: null,
  setCartState: () => null,
  cartMode: null,
  setCartMode: () => null,
  cartModeConfig: null,
  openNextCartState: () => null,
});

export const CartControllerProvider = ({ children }: PropsWithChildren) => {
  const { scrollableAreaElement } = useContext(WidgetContainerContext);
  const [cartModeName, setCartMode] = useState<CartModeName>('hidden');
  const cartModeConfig: CartMode = cartModes[cartModeName];
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>(null);

  const [cartState, _setCartState] = useState<CartState>(cartModeConfig.defaultState);

  const setCartState = useCallback(
    (nextState: CartState) => {
      const isStateAllowed = cartModeConfig.allowedStates.includes(nextState);
      const isSameState = nextState === cartState;

      if (!isStateAllowed || isSameState) {
        return;
      }

      _setCartState(nextState);
    },
    [cartState, cartModeConfig],
  );

  const openNextCartState = () => {
    const nextState = cartModeConfig.stateTransitions[cartState];
    setCartState(nextState);
  };

  useEffect(() => {
    const mode = cartModes[cartModeName];
    if (mode) {
      setCartState(mode.defaultState);
    }
  }, [cartModeName]);

  useEffect(() => {
    if (scrollableAreaElement) {
      let lastScrollTop = 0;
      const listener = (e) => {
        const { scrollTop, scrollHeight, parentElement } = e.target;
        const isOverScroll = scrollHeight - parentElement.scrollHeight - scrollTop <= 0;

        if (isOverScroll) {
          return;
        }

        if (scrollTop > lastScrollTop) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }

        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
      };

      scrollableAreaElement.addEventListener('scroll', listener, false);

      return () => {
        scrollableAreaElement.removeEventListener('scroll', listener, false);
      };
    }

    return () => null;
  }, [scrollableAreaElement]);

  useEffect(() => {
    setScrollDirection(null);
  }, [cartModeConfig]);

  useEffect(() => {
    if (cartModeConfig.scroll.react) {
      if (scrollDirection === 'up') {
        setCartState(cartModeConfig.scroll.topScrolledState);
      }

      if (scrollDirection === 'down') {
        setCartState(cartModeConfig.scroll.bottomScrolledState);
      }
    }
  }, [scrollDirection]);

  const value = {
    cartState,
    setCartState,
    cartModeConfig,
    setCartMode,
    cartMode: cartModeName,
    openNextCartState,
  };

  return <CartControllerContext.Provider value={value}>{children}</CartControllerContext.Provider>;
};
