import { QueryKey } from 'react-query';

export const queryKeys = {
  all: ['publicBarbers'],
  byBarber: (payload: { shopIdOrRoute: string; idOrRoute: string }): QueryKey => [
    ...queryKeys.all,
    payload.shopIdOrRoute,
    payload.idOrRoute,
  ],
};
