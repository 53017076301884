import { QueryKey } from 'react-query';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';
import { NextAvailableTimePayload } from './payloads';

export class NextAvailableTimeQuery extends EndpointQuery<{ time: string }, NextAvailableTimePayload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: NextAvailableTimePayload): QueryKey {
    return queryKeys.nextAvailableTime(payload);
  }

  async request(payload: NextAvailableTimePayload, signal?: AbortSignal): Promise<{ time: string }> {
    const barberUrl = ['/v1/barber', payload.barberId, 'next-available-time', payload.duration, payload.fromDate]
      .filter(Boolean)
      .join('/');

    const shopUrl = ['/v1/shop', payload.shopId, 'next-available-time', payload.duration, payload.fromDate]
      .filter(Boolean)
      .join('/');

    const url = payload.barberId ? barberUrl : shopUrl;

    return this.http.get(url, {
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      signal,
      resourceUrl: payload.barberId
        ? '/barber/:id/next-available-time/:duration/:fromDate'
        : '/shop/:id/next-available-time/:duration/:fromDate',
    });
  }
}
