import styled, { StyledComponent } from 'styled-components';
import { ButtonBase, VariantProps, ButtonBaseProps, aliasesFromVariants } from '@water-web/view';

import { withAliases } from '@app/components/utils';

import { variants } from './variants';
import { InfoCircled } from '../Icon/InfoCircled';
import { Checkmark } from '../Icon/Checkmark';
import { Chevron } from '../Icon/Chevron';
import { Close } from '../Icon/Close';
import { Pencil } from '../Icon/Pencil';
import { Basket } from '../Icon/Basket';
import { WidgetMenu } from '../Icon/WidgetMenu';
import { WidgetClose } from '../Icon/WidgetClose';

export type ButtonProps = VariantProps<keyof (typeof variants)['variants']>;

const StyledButton = styled(ButtonBase).attrs({
  $styledVariants: variants,
})<ButtonProps>`` as StyledComponent<'button', any, Omit<ButtonBaseProps, 'variants'> & ButtonProps>;

StyledButton.defaultProps = {
  variant: 'primary',
};

export const Button = withAliases(StyledButton, aliasesFromVariants(StyledButton, variants.variants));

Button.Info.defaultProps = {
  children: <InfoCircled />,
};

Button.Close.defaultProps = {
  children: <Close />,
};

Button.Back.defaultProps = {
  children: <Chevron />,
};

Button.Confirm.defaultProps = {
  children: <Checkmark />,
  $testId: 'btn:confirm',
};

Button.Edit.defaultProps = {
  children: <Pencil />,
};

Button.Remove.defaultProps = {
  children: <Basket />,
};

Button.WidgetMenu.defaultProps = {
  children: <WidgetMenu />,
};

Button.WidgetClose.defaultProps = {
  children: <WidgetClose />,
};
