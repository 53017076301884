import { QueryKey } from 'react-query';
import { Shop } from '@water-web/types';

import { queryKeys } from './queryKeys';
import { EndpointQuery } from '../../request';
import { hashKey } from '../../utils/haskey';

export interface PublicShopPayload {
  idOrRoute: string;
}

const staticSearch = {
  include: ['photos', 'address', 'schedules', 'waitingLists', 'logo', 'staticMap', 'instagramImages'],
  nonSquireEcosystem: 'true',
};

export class PublicShopQuery extends EndpointQuery<Shop, PublicShopPayload> {
  constructor(apiUrl: string) {
    super();

    this.baseUrl = apiUrl;
    this.meta = {
      cacheTime: 60 * 60 * 1000,
    };
  }

  getKey(payload: PublicShopPayload): QueryKey {
    return queryKeys.byId(payload).concat(hashKey(staticSearch));
  }

  request(payload: PublicShopPayload): Promise<Shop> {
    return this.http.get(`/v1/shop/${payload.idOrRoute}`, {
      headers: this.buildHeaders(),
      search: staticSearch,
      resourceUrl: '/shop/:id',
    });
  }
}
