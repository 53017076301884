import { PropsWithChildren, useMemo } from 'react';
import { loadStripe } from 'retry-stripe-loader';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { SetupIntentQuery, useApiQuery } from '@water-web/api';

import { ExternalConfiguration } from '../../config';

export interface StripeLoaderProps {
  stripeKey: string;
}

export const StripeLoader = (props: PropsWithChildren<StripeLoaderProps>) => {
  if (!props.stripeKey) {
    throw new Error('Stripe key is required');
  }

  const endpoint = useMemo(() => {
    return new SetupIntentQuery(ExternalConfiguration.getConfig().apiUrl);
  }, []);

  const setupIntentQuery = useApiQuery({ endpoint });

  const stripePromise = useMemo(() => {
    return loadStripe(props.stripeKey, {
      onFailedAttempt: (error) => {
        console.error(`Loading stripeJS in app context provider. Attempt ${error.attemptNumber}.`);
      },
      retries: 3,
    });
  }, []);

  const clientSecret = setupIntentQuery.data?.client_secret;

  if (!clientSecret) {
    return null;
  }

  const options: StripeElementsOptions = {
    clientSecret,
    paymentMethodCreation: 'manual',
  };

  return (
    <Elements stripe={stripePromise} options={clientSecret ? options : null}>
      {props.children}
    </Elements>
  );
};
