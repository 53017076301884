import { ValidatedCartItem, ValidatedCartSubItem, isSubItemAService } from '@water-web/types';

import { Price } from '../types';
import { reducePrices, numberToPrice } from '../utils';
import { getPriceDraftFromCartService, getPriceExactFromCartService } from './cartService';

/**
 * Will return precise services price for detailed breakdown with taxes in a separate line
 */
export const getServicesPriceExactFromCartReservation = (reservation: ValidatedCartItem): Price => {
  return reducePrices(reservation?.subItems?.map(getPriceExactFromCartService));
};

/**
 * Will return human-friendly services price for draft breakdowns and collapsed views, where taxes and fees not visible
 */
export const getServicesPriceDraftFromCartReservation = (reservation: ValidatedCartItem): Price => {
  return reducePrices(reservation?.subItems?.map(getPriceDraftFromCartService));
};

export const getServiceFromReservation = (reservation: ValidatedCartItem): ValidatedCartSubItem | null => {
  return reservation.subItems[0] ?? null;
};

export const getAddonsFromReservation = (reservation: ValidatedCartItem): ValidatedCartSubItem[] => {
  return reservation.subItems.slice(1);
};

export const getSubtotalDraftFromReservation = (reservation: ValidatedCartItem): Price => {
  return reducePrices([
    getServicesPriceDraftFromCartReservation(reservation),
    numberToPrice(reservation.tipAmount ?? 0),
  ]);
};

export const isReadyForTipsFromReservation = (reservation: ValidatedCartItem): boolean => {
  return !!(
    reservation.barber?.earlyTipping &&
    reservation.barberId &&
    reservation.services?.length > 0 &&
    reservation.dateTime
  );
};

export const getDurationFromReservation = (reservation: ValidatedCartItem): number => {
  return reservation.subItems.filter(isSubItemAService).reduce((acc, item) => acc + item.duration, 0);
};
