import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { BoxProps, useBoolean } from '@water-web/view';
import { ComponentPropsWithoutRef, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import { isTouchDevice } from '@app/components/utils';
import { WidgetContainerContext } from '@app/components/molecules/WidgetContainer/WidgetContainerContext';

import { Container, Initiator, Item, ItemProps, ItemContainer } from './styles';

export interface TooltipProps extends ItemProps {
  tooltip?: JSX.Element;
  borderRadius?: BoxProps['borderRadius'];
  bindToRoot?: boolean;
  fadeOnly?: boolean;
}

export const Tooltip = (props: PropsWithChildren<TooltipProps & ComponentPropsWithoutRef<typeof Item>>) => {
  const { isScrolling } = useContext(WidgetContainerContext);
  const mountPointRef = useRef<HTMLDivElement>(null);
  const [isHovered, hoverHandlers] = useBoolean(false);
  const [mountPoint, setMountPoint] = useState(null);

  useEffect(() => {
    // use native mouseleave event listener because react's onMouseLeave is not 100% reliable
    // https://github.com/facebook/react/issues/4251
    // also, no need to clean up because event listeners would be disposed once tooltip element is removed
    mountPointRef.current?.addEventListener('mouseleave', hoverHandlers.turnOff);
  }, [mountPointRef.current]);

  useEffect(() => {
    setMountPoint(document.getElementById('tooltips-mount-point'));
  }, []);

  let mountRect = null;
  let bodyRect = null;
  let spaceToRightSide = null;

  const shouldShowTooltip = props.selected || (isHovered && !props.noShowOnHover && !isScrolling);
  const isReadyToMount = shouldShowTooltip && typeof window !== 'undefined' && mountPointRef.current;

  // calculate bounding rects only if we're on client, have a ref to a target element
  // and have an active trigger(hover, programmatic selection, etc)
  // this help to avoid unnecessary getBoundingClientRect calculations
  if (isReadyToMount) {
    mountRect = mountPointRef.current.getBoundingClientRect();
    bodyRect = global.document.body.getBoundingClientRect();
    spaceToRightSide = bodyRect.width - mountRect.left;
  }

  const { children, tooltip, bindToRoot, ...itemProps } = props;

  if (!tooltip) {
    return <>{children}</>;
  }

  const tooltipBody = (
    <AnimatePresence>
      {bodyRect && mountRect && (
        <ItemContainer
          key={props.tooltip?.key || `${mountRect.top - bodyRect.top}px_${mountRect.left}px`}
          width={`${mountRect.width}px`}
          height={`${mountRect.height}px`}
          top={bindToRoot ? `${mountRect.top - bodyRect.top}px` : 0}
          left={bindToRoot ? `${mountRect.left}px` : undefined}
          fadeOnly={props.fadeOnly}
        >
          <Item spaceToRightSide={spaceToRightSide} {...itemProps}>
            {tooltip}
          </Item>
        </ItemContainer>
      )}
    </AnimatePresence>
  );

  return (
    <Container>
      <Initiator
        ref={mountPointRef}
        onMouseEnter={isTouchDevice() ? undefined : hoverHandlers.turnOn}
        onClick={isTouchDevice() ? hoverHandlers.turnOn : undefined}
      >
        {children}
      </Initiator>
      {bindToRoot && mountPoint ? createPortal(tooltipBody, mountPoint) : tooltipBody}
    </Container>
  );
};

Tooltip.defaultProps = {
  bindToRoot: true,
};
