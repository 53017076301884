import { formatInTimeZone, format } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';

export const formatDate = (
  dateTime: string | number | Date,
  formatTemplate: string,
  timeZone?: string,
  locale?: Locale,
): string => {
  const options = { locale: locale ?? enUS };

  return timeZone
    ? formatInTimeZone(dateTime, timeZone, formatTemplate, options)
    : format(dateTime, formatTemplate, options);
};
