import { ReservationModel, ServiceModel } from '@water-web/repository';

interface Result {
  completed: boolean;
}

/**
 * This function serves as a bridge between city pages where users can click and services and WoW where they must have clicked services pre-selected.
 */
export const preselectServiceFromUrlParam = (services: ServiceModel[], reservation: ReservationModel): Result => {
  const urlParams = new URLSearchParams(window.location.search);
  const serviceId = urlParams.get('preselect');

  const shouldExit = !serviceId || !!reservation.getService();
  if (shouldExit) {
    return { completed: false };
  }

  const service = services.find((s) => s.getId() === serviceId);

  if (!service) {
    return { completed: false };
  }

  reservation.setService(service);
  return { completed: true };
};
