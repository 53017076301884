export const variants = {
  variants: {
    title1: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.75px',
      fontFamily: 'SF Pro Display',
    },
    p1Bold: {
      fontSize: '17px',
      fontWeight: '600',
      lineHeight: '22px',
      letterSpacing: '-0.408px',
      fontFamily: 'SF Pro Text',
    },
    p1Regular: {
      fontSize: '17px',
      lineHeight: '22px',
      letterSpacing: '-0.408px',
      fontFamily: 'SF Pro Text',
    },
    p2Bold: {
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '-0.24px',
      fontFamily: 'SF Pro Text',
    },
    p2Regular: {
      fontSize: '15px',
      lineHeight: '18px',
      letterSpacing: '-0.24px',
      fontFamily: 'SF Pro Text',
    },
    p3Bold: {
      fontSize: '13px',
      fontWeight: '600',
      lineHeight: '18px',
      letterSpacing: '-0.078px',
      fontFamily: 'SF Pro Text',
    },
    p3Regular: {
      fontSize: '13px',
      lineHeight: '18px',
      letterSpacing: '-0.078px',
      fontFamily: 'SF Pro Text',
    },
    p4Bold: {
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '16px',
      fontFamily: 'SF Pro Text',
    },
    p4Regular: {
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily: 'SF Pro Text',
    },
    p5Bold: {
      fontSize: '9px',
      fontWeight: '700',
      lineHeight: '13px',
      fontFamily: 'SF Pro Text',
    },
  },
};
