import { useContext, useEffect } from 'react';
import { CartContext, populateInitialCartData } from '@water-web/view';
import { CartModel } from '@water-web/repository';

import { usePageInitialProps } from '../usePageInitialProps';
import { useCartId } from '../useCartId';

// @Yuriy this is just a principle, we need to make this a bit more gracious
let populatedCart = null;

export const useInitializedCart = (): CartModel => {
  const { cart } = useContext(CartContext);
  const pageInitialProps = usePageInitialProps();
  const { removeCartId } = useCartId();

  useEffect(() => {
    const isDataReady = pageInitialProps.completed && !pageInitialProps.failed;
    if (isDataReady && populatedCart !== cart) {
      populateInitialCartData(cart, removeCartId, pageInitialProps);
      populatedCart = cart;
    }
  }, [pageInitialProps.completed, cart]);

  return populatedCart ? cart : null;
};
