import { useCallback } from 'react';
import { CartModel } from '@water-web/repository';

type UseAnyBarberPickerResult = () => void;

/**
 * Returns a callback that picks `anyBarberCandidate` as a barber for every "any barber" appointment in a cart.
 */
export const useAnyBarberPicker = (cart: CartModel): UseAnyBarberPickerResult => {
  return useCallback(() => {
    cart
      .getReservations()
      .filter((r) => r.getIsAnyBarber())
      .forEach((reservation) => {
        reservation.setBarber(reservation.getAnyBarberCandidate(), true);
      });
  }, [cart]);
};
