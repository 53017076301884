import { AnimatePresence, AnimatePresenceProps, motion } from 'framer-motion';
import styled, { StyledProps } from 'styled-components';
import { ComponentType, Children, PropsWithChildren } from 'react';

import { Box, BoxProps } from '../../atoms';

export interface CalendarFadeProps extends BoxProps, Pick<AnimatePresenceProps, 'mode' | 'onExitComplete'> {
  as?: string | ComponentType;
  $duration?: number;

  [key: string]: unknown;
}

const offset = -50;

// Abandon all hope when you enter here.
// Framer motion doesn't want to animate Y in animate - the 'transform' property in rendered CSS
// is always 'none'. However, it works on exit animation. Thats why we have to use position relative,
// even though thats less performant.
// We use keyframes to adjust the timing of animation - so we can expand space first (layout: true) is
// important to do that; then, when space is expanded, we can fade in and move the calendar.
// Exit is the same, but in opposite direction.
// Protip: x10 the animation duration so you can see whats going on better;
export const getCalendarFadeProps = (props: StyledProps<CalendarFadeProps>) => ({
  layout: true,
  initial: {
    y: offset,
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    position: 'relative',
    top: offset,
  },
  animate: {
    y: 0,
    height: null,
    opacity: [0, 0, 1],
    overflow: 'visible',
    position: 'relative',
    top: [offset, offset, 0],
  },
  exit: {
    y: offset,
    height: 0,
    opacity: [1, 0, 0],
    overflow: 'visible',
    top: 0,
  },
  transition: {
    duration: props.$duration ?? props.theme.animations.booking.interaction * 2,
    ease: 'easeInOut',
  },
});

const AnimationContainer = styled(Box).attrs((props: StyledProps<CalendarFadeProps>) => ({
  as: typeof props.as === 'string' ? motion[props.as] : motion(props.as, { forwardMotionProps: true }),
  ...getCalendarFadeProps(props),
}))``;

export const CalendarFadeAnimation = ({
  mode,
  onExitComplete,
  children,
  ...props
}: PropsWithChildren<CalendarFadeProps>) => {
  return (
    <AnimatePresence {...{ mode, onExitComplete }}>
      {Children.map(children, (child) => child && <AnimationContainer {...props}>{child}</AnimationContainer>)}
    </AnimatePresence>
  );
};

CalendarFadeAnimation.defaultProps = {
  as: 'div',
};
