import { Shop } from '@water-web/types';

import { AnalyticsEvent } from '../events';
import { CommonPayload, isPaymentEvent, Tracker } from '../types';
import { GtmBookAppointmentEventPayload } from './types';

declare global {
  interface Window {
    squireCustomBrandGaScript: any[];
    squireCustomBrandGaGtag: (...args: any[]) => void;
  }
}

export class BrandGtmTracker implements Tracker {
  bookingEvents = new Set<AnalyticsEvent>([
    AnalyticsEvent.PAYMENT_SUCCESS,
    AnalyticsEvent.RESERVATION_SUCCESS,
    AnalyticsEvent.WAITLIST_SUCCESS,
    AnalyticsEvent.GC_PURCHASE_SUCCESS,
    AnalyticsEvent.DEBUG_WALLETS_IS_SUPPORTED,
    AnalyticsEvent.DEBUG_WALLETS_HAS_CARD,
    AnalyticsEvent.DEBUG_WALLETS_SELECTED,
    AnalyticsEvent.DEBUG_WALLETS_PAYMENT_INITIATED,
    AnalyticsEvent.DEBUG_WALLETS_PAYMENT_CANCELED,
    AnalyticsEvent.DEBUG_WALLETS_PAYMENT_SUCCESS,
    AnalyticsEvent.DEBUG_WALLETS_PAYMENT_TOO_LONG_MAYBE_TIMEOUT,
    AnalyticsEvent.DEBUG_WALLETS_PAYMENT_FAILURE,
    AnalyticsEvent.VISITOR_FROM_CITY_PAGES,
  ]);

  gclidMixin: Pick<GtmBookAppointmentEventPayload, 'gclid'> = { gclid: '' };

  constructor() {
    if (typeof window !== 'undefined') {
      const gclid = new URLSearchParams(window.location.search).get('gclid');
      if (gclid) {
        this.gclidMixin = { gclid };
      }
    }
  }

  getName(): string {
    return 'BrandGtmTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    const exit = !window.squireCustomBrandGaScript || !this.bookingEvents.has(analyticsEvent);

    if (exit) {
      return;
    }

    if (isPaymentEvent(analyticsEvent, payload)) {
      const p = payload as typeof payload & { shop: Shop }; // FIXME: need to put `addCommonPayloadProp('shop', shop);` call and `CommonPayload` extension into `view` package
      if (!p.shop) {
        console.error('Missing shop in analytics payload. This should not happen');
      }

      const totalWithDecimals = (payload.totalWithTips / 100).toFixed(2);
      const eventPayload: GtmBookAppointmentEventPayload = {
        value: totalWithDecimals,
        currency: payload.currency,
        transaction_id: payload.appointmentId,
        shop_name: p.shop.name,
        shop_route: p.shop.route,
        ...this.gclidMixin,
      };

      window.squireCustomBrandGaGtag?.('event', 'book_appointment', eventPayload);
    }
  }
}
