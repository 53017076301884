import { addDays, formatISO } from 'date-fns';

interface TTLContainer<T> {
  /**
   * ISO 8601 date: `2021-01-23`
   */
  ttl: string;
  data: T;
}

type ExtractionResult<T> = { data: T; expiredOrMalformed: false } | { data: undefined; expiredOrMalformed: true };

/**
 * Let `pack` data into an object with a limited lifespan in days.
 * Attempt to `extract` an expired container will return no data, and an `expired` flag.
 */
export class TTLGuard<T = any> {
  protected ttl: number;

  constructor(/* Lifespan in days */ ttl = 30) {
    this.ttl = ttl;
  }

  extract(container: any): ExtractionResult<T> {
    if (
      typeof container !== 'object' ||
      !Object.hasOwn(container, 'ttl') ||
      !Object.hasOwn(container, 'data') ||
      !(this.getDateString(new Date()) < container.ttl)
    ) {
      return { data: undefined, expiredOrMalformed: true };
    }

    return { data: container.data, expiredOrMalformed: false };
  }

  pack(data: T): TTLContainer<T> {
    return { data, ttl: this.getDateString(addDays(new Date(), this.ttl)) };
  }

  protected getDateString(date: Date): string {
    return formatISO(date, { representation: 'date' });
  }
}
