import { useMemo, useRef } from 'react';
import { ReservationModel } from '@water-web/repository';

interface UseSelectedServicesAddons {
  (reservation: ReservationModel): {
    addonCallbacks: Record<string, () => void>;
    selectedAddons: Set<string>;
  };
}

export const useSelectedServicesAddons: UseSelectedServicesAddons = (reservation) => {
  const selectedAddonsRef = useRef(new Set<string>());
  const selectedAddons = useMemo(() => {
    const addonIds = reservation?.getAddons().map((addon) => addon.getId());

    selectedAddonsRef.current = new Set(addonIds);
    return selectedAddonsRef.current;
  }, [reservation?.getAddons()]);

  const addonCallbacks = useMemo(() => {
    if (!reservation?.getService()) {
      return {};
    }

    return reservation
      ?.getService()
      .getAddons()
      .reduce(
        (memo, addon) => {
          const toggle = () => {
            const isTogglingOn = !selectedAddonsRef.current.has(addon.getId());
            if (isTogglingOn) {
              reservation.addAddon(addon);
            } else {
              reservation.removeAddon(addon);
            }
          };

          return {
            ...memo,
            [addon.getId()]: toggle,
          };
        },
        {} as Record<string, () => void>,
      );
  }, [reservation, reservation?.getService()]);

  return {
    addonCallbacks,
    selectedAddons,
  };
};
