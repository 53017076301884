import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrandModel } from '@water-web/repository';
import { CartContext, pipe } from '@water-web/view';

import { getBrandedColorsOverrides } from '@app/styles/themes/branded';
import DefaultTheme from '@app/styles/themes/default';
import { lighten, darken, isCloseToWhite } from '@app/utils';
import { config } from '@app/config';

const pickColorsFromBrand = (brand: BrandModel) => {
  const brandData = brand.getExtension().toJson();
  const pickedColors = [
    'primaryColor',
    'primaryColorDark',
    'primaryColorDarker',
    'primaryColorLight',
    'primaryFontColor',
    'secondaryColor',
    'linkColor',
  ].filter((key) => brandData[key]);

  return pickedColors.reduce((acc, key) => {
    acc[key] = (brandData[key] as string).replace('0x', '#');
    return acc;
  }, {});
};

const setLegendColorsFromBrand = (pickedColorsFromBrand: { [key: string]: string }) => {
  const primaryColor = pickedColorsFromBrand?.primaryColor;
  if (!primaryColor) {
    return pickedColorsFromBrand;
  }

  const colorTransformer = isCloseToWhite(primaryColor) ? darken : lighten;

  const availability = {
    0.25: colorTransformer(primaryColor, 3),
    0.5: colorTransformer(primaryColor, 2),
    0.75: colorTransformer(primaryColor, 1),
    1: primaryColor,
  };

  return {
    ...pickedColorsFromBrand,
    availability,
  };
};

type BrandedThemeContextValue = {
  setBrand: (brand: BrandModel) => void;
};

export const BrandedThemeContext = createContext<BrandedThemeContextValue>({
  setBrand: () => null,
});

export const BrandedThemeProvider = ({ children }: PropsWithChildren) => {
  const [brandedTheme, setBrandedTheme] = useState(null);

  const setBrand = useCallback((brand: BrandModel) => {
    if (!config.mode.iframe && process.env.PUBLIC_BRANDING_ENABLED !== 'true') {
      return;
    }

    if (brand?.getExtension().toJson().hasPremiumBranding) {
      const brandedColors = pipe(pickColorsFromBrand, setLegendColorsFromBrand)(brand);

      setBrandedTheme({
        hasPremiumBranding: true,
        colors: getBrandedColorsOverrides({
          ...DefaultTheme.colors,
          ...brandedColors,
        }),
      });
    }
  }, []);

  const theme = useMemo(
    () => ({
      ...DefaultTheme,
      ...{
        colors: {
          ...DefaultTheme.colors,
          ...brandedTheme?.colors,
        },
      },
      hasPremiumBranding: brandedTheme?.hasPremiumBranding ?? DefaultTheme.hasPremiumBranding,
    }),
    [brandedTheme],
  );

  return (
    <BrandedThemeContext.Provider value={{ setBrand }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </BrandedThemeContext.Provider>
  );
};

export const BrandedThemeListener = () => {
  const brand = useContext(CartContext)?.cart?.getBrand();
  const { setBrand } = useContext(BrandedThemeContext);

  useEffect(() => {
    setBrand(brand);
  }, [brand?.getId()]);

  return null;
};
