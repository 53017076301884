import { Price, PRICE_ZERO } from '../types';

export const reducePrices = (prices: Price[]): Price => {
  const hasPercentage = prices.some((price) => price.isPercent);
  if (hasPercentage) {
    throw new Error("Can't sum percentages");
  }

  if (prices.length === 1) {
    return prices[0];
  }

  return prices.reduce((memo, price) => {
    return {
      min: memo.min + price.min,
      max: memo.max + price.max,
      fallback: memo.fallback + price.fallback,
    };
  }, PRICE_ZERO);
};
