import { Box, Flex } from '@water-web/view';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { appLayers } from '@app/appLayers';
import { Button, Text } from '@app/components/atoms';

import { SuccessCheckmark } from './SuccessCheckmark';
import { ProcessingPending } from './icons/ProcessingPending';

export const OverlayContainer = styled(Box).attrs((props) => ({
  as: motion.div,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: props.theme.animations.booking.interaction,
}))`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.chrome};
  backdrop-filter: blur(4px);
  z-index: ${appLayers.processingOverlay};
`;

export const IconContainer = styled(Box)`
  width: 62px;
  height: 62px;
  position: relative;
`;

export const ErrorIconContainer = styled(Box).attrs((props) => ({
  as: motion.div,
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: props.theme.animations.booking.interaction,
}))`
  width: 72px;
  height: 72px;
  position: relative;
`;

const RotatingPendingIcon = styled(ProcessingPending)`
  animation: spin 600ms linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ErrorButton = styled(Button.Outlined)`
  color: ${({ theme }) => theme.colors.primaryLabel};
  background: transparent;
  text-align: center;
  font-size: 15px;
  line-height: 28px;
  height: auto;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.systemGray4};
  font-weight: 600;
  padding: 8px 16px;
  width: 135px;
  margin-top: 40px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryLabel};
  }
`;

export const RotatingPendingIconCollapsible = styled(Box).attrs((props) => ({
  as: motion.div,
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  style: { originX: 0.5, originY: 0.5 },
  transition: props.theme.animations.booking.interaction,
  children: <RotatingPendingIcon />,
}))`
  position: absolute;
`;

export const SuccessCircle = styled(Flex).attrs((props) => ({
  as: motion.div,
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: props.theme.animations.booking.interaction,
  children: <SuccessCheckmark />,
}))`
  position: absolute;
  width: 62px;
  height: 62px;
  background-color: #2caf00;
  border-radius: 50%;
  color: #fff;
  justify-content: center;
  align-items: center;
`;

export const AnimatedTitle = styled(Text.Title1).attrs((props) => ({
  as: motion.p,
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: props.theme.animations.booking.interaction,
}))``;
