import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import pencilIcon from './pencil.svg';

export const Pencil = styled(JustIcon).attrs({
  alt: 'pencil',
  icon: pencilIcon,
  width: '14px',
  height: '14px',
})`` as JustIconStyledComponent<IconStaticProps>;
