import { css } from 'styled-components';
import { variant, VariantArgs } from 'styled-system';

export interface VariantProps<V> {
  variant?: V;
}

export interface WithVariantProps {
  $styledVariants?: VariantArgs;
}

/**
 * Use this style mixin to handle variants in the base styled component
 */
export const withVariantProp = (props: WithVariantProps): ReturnType<typeof css> => {
  return (
    !!props.$styledVariants &&
    css`
      ${variant(props.$styledVariants)}
    `
  );
};
