import { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

export type CartLoaderState = 'pending' | 'success' | 'error';

export interface CartStateContextValue {
  areCustomerNamesValid: boolean;
  setAreCustomerNamesValid: (value: boolean) => void;
  isCustomerPrepayOnly: boolean;
  setCustomerPrepayOnly: (value: boolean) => void;
  loader: CartLoaderState;
  loaderMessage: string | null;
  setLoader: (state: CartLoaderState) => void;
  setLoaderMessage: (message: string | null) => void;
  resetLoader: () => void;
  fullHeight: boolean;
  setFullHeight: (value: boolean) => void;
}

export const CartStateContext = createContext<CartStateContextValue>(null);

export const CartStateContextProvider = (props: PropsWithChildren) => {
  const [areCustomerNamesValid, setAreCustomerNamesValid] = useState(true);
  const [isCustomerPrepayOnly, setCustomerPrepayOnly] = useState(false);
  const [fullHeight, setFullHeight] = useState(false);

  const [loader, setLoader] = useState<CartLoaderState>(null);
  const [loaderMessage, setLoaderMessage] = useState<string>(null);
  const resetLoader = useCallback(() => {
    setLoader(null);
    setLoaderMessage(null);
  }, []);
  const setLoaderMessageHandler = useCallback((message: string | null) => {
    if (!message) {
      return setLoaderMessage(message);
    }

    let messageString = message;

    try {
      // `messages` may have a nested JSON. Try to parse it to display prettier
      messageString = (JSON.parse(message) as { message: string }[]).map((obj) => obj.message).join('\n');
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return setLoaderMessage(messageString.split('. ').join('.\n'));
  }, []);

  const value = useMemo(
    () => ({
      areCustomerNamesValid,
      setAreCustomerNamesValid,
      isCustomerPrepayOnly,
      setCustomerPrepayOnly,
      loader,
      loaderMessage,
      setLoader,
      setLoaderMessage: setLoaderMessageHandler,
      resetLoader,
      fullHeight,
      setFullHeight,
    }),
    [areCustomerNamesValid, isCustomerPrepayOnly, loader, loaderMessage, fullHeight],
  );

  return <CartStateContext.Provider value={value}>{props.children}</CartStateContext.Provider>;
};
