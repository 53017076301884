import { ComponentType } from 'react';

/**
 * "RemainingProps" is a mapped type that picks from `Props` only those properties whose keys are not in `InitialProps`.
 */
type RemainingProps<Props, InitialProps> = Pick<Props, Exclude<keyof Props, keyof InitialProps>>;

type BindPropsFn = <Props, InitialProps extends Partial<Props>>(
  component: ComponentType<Props>,
  initialProps: InitialProps,
) => ComponentType<RemainingProps<Props, InitialProps>>;

/**
 * Binds props to a component, returning a new component, requiring only the remaining props.
 */
export const bindProps: BindPropsFn = (component, initialProps) => {
  return (props: any) => {
    const BoundComponent = component;
    return <BoundComponent {...initialProps} {...props} />;
  };
};
