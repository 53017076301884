import { motion } from 'framer-motion';
import { Box, Flex, zIndex } from '@water-web/view';
import styled, { css, Interpolation, keyframes } from 'styled-components';

import { fromPublic } from '@app/utils';
import { Button, Image } from '@app/components/atoms';

import { StyledWidgetTitle } from '../WidgetContainer/styles';
import { PopupPreset } from './types';

type ZIndexProps = Parameters<typeof zIndex>[0];

export const PopupBase = styled(Box)`
  position: relative;
  height: auto;
  width: 100%;
  padding: 16px;
  overflow: auto;
  box-sizing: border-box;
`;

interface CloseButtonProps {
  preset: PopupPreset;
}

const closeButtonStylesByPreset: Record<PopupPreset, Interpolation<CloseButtonProps>> = {
  top: css`
    top: 10px;
  `,
  bottom: css`
    bottom: 10px;
  `,
  bottomFullHeight: css`
    bottom: 10px;
  `,
  phoneCode: css`
    bottom: 10px;
  `,
  reschedule: css`
    bottom: 10px;
  `,
};

export const CloseButton = styled(Button.Close)<CloseButtonProps>`
  position: absolute;
  top: 14px;
  right: 6px;

  ${({ preset }) => closeButtonStylesByPreset[preset]}
`;

export const Backdrop = styled(Box).attrs<ZIndexProps>((props) => ({
  as: motion.div,
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 0.25,
    transition: {
      duration: props.theme.animations.booking.interaction,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: props.theme.animations.booking.interaction,
    },
  },
}))<ZIndexProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0);
  mix-blend-mode: normal;
  ${zIndex};
`;

interface PopupContainerProps extends ZIndexProps {
  $preset: PopupPreset;
}

const popupContainerYByPreset: Record<PopupPreset, string> = {
  top: '-100%',
  bottom: '100%',
  bottomFullHeight: '100%',
  phoneCode: '100%',
  reschedule: '100%',
};

const popupContainerStylesByPreset: Record<PopupPreset, Interpolation<PopupContainerProps>> = {
  top: css`
    top: 0;
  `,
  bottom: css`
    bottom: 0;
    max-height: calc(100% - 36px);
  `,
  bottomFullHeight: css`
    bottom: 0;
    height: calc(100% - 36px);
  `,
  phoneCode: css`
    bottom: 0;
    height: calc(100% - 36px);
  `,
  reschedule: css`
    top: 44px;
    bottom: 0;
  `,
};

export const PopupContainer = styled(Flex).attrs<PopupContainerProps>((props) => ({
  as: motion.div,
  initial: {
    y: popupContainerYByPreset[props.$preset],
  },
  animate: {
    y: '0',
    transition: {
      duration: props.theme.animations.booking.interaction,
    },
  },
  exit: {
    y: popupContainerYByPreset[props.$preset],
    transition: {
      duration: props.theme.animations.booking.interaction,
    },
  },
}))<PopupContainerProps>`
  position: absolute;
  right: 0;
  left: 0;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.chrome};
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  height: auto;
  max-height: 100%;
  ${zIndex};
  backdrop-filter: blur(0); // fix blurring outside the rounded corners bug

  ${({ $preset }) => popupContainerStylesByPreset[$preset]};
`;

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(Image).attrs({
  src: fromPublic('/img/cartSpinner.png'),
  width: '16px',
  height: '16px',
  layout: 'fixed',
})`
  position: absolute;
  top: 28px;
  right: 20px;
  animation: ${loadingAnimation} 1s linear infinite;
`;

export const StickyPopupTitle = styled(StyledWidgetTitle)`
  margin: -16px;
  padding: 16px;
  top: -16px;
  background: rgb(247 247 247 / 88%);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
`;
