import { CartModel } from '@water-web/repository';
import { useCallback, useContext, useEffect } from 'react';
import { AppStateContext, BookingUrlBuilderContext, CartStateContext } from '@water-web/view';

import { cartRepository } from '@app/repository';
import { convertUrlToRoute } from '@app/utils';
import { useHistoryTyped } from '@app/hooks';

type ChooseTimeFn = () => void;

export const useChooseTimeCallback = (cartModel: CartModel): ChooseTimeFn => {
  const history = useHistoryTyped();
  const urlBuilder = useContext(BookingUrlBuilderContext);
  const appState = useContext(AppStateContext);
  const { setAreCustomerNamesValid } = useContext(CartStateContext);

  useEffect(() => {
    return () => {
      appState.app.setInteractive();
    };
  }, []);

  return useCallback(async () => {
    setAreCustomerNamesValid(true);
    appState.app.setNotInteractive();

    const { error } = await cartRepository.createOrUpdateV2(cartModel);
    if (error) {
      appState.cart.error.set(error);
      appState.app.setInteractive();
      return;
    }

    appState.cart.error.reset();

    cartModel.setDefaultTip(true);
    const cart = cartModel.getValidatedCart().getDataValues();

    const route = urlBuilder.getScheduleUrl(cartModel.getActiveReservation(), cart.id);
    history.replace(convertUrlToRoute(route));
  }, [cartModel, urlBuilder, history, appState, setAreCustomerNamesValid]);
};
