import { QueryKey } from 'react-query';

export const queryKeys = {
  all: ['barber'],
  byShopId: (payload: { shopIdOrRoute: string }): QueryKey => [
    ...queryKeys.all,
    'list',
    'byShopIdOrRoute',
    payload.shopIdOrRoute,
  ],
};
