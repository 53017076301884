import { Brand, Extension } from '@water-web/types';

export const getExtensionFromBrand = (brand?: Brand): Extension | undefined => {
  return brand?.extension ?? brand?.extesnion;
};

export const getHasCustomAppsFromBrand = (brand: Brand): boolean => {
  const extension = getExtensionFromBrand(brand);

  return !!(extension?.hasPremiumBranding && (extension.iTunesLink || extension.playStoreLink));
};

export const getDisplayReservationAdsFromBrand = (brand: Brand): boolean => {
  return brand?.settings?.displayReservationAds ?? false;
};

export const getLogoFromBrand = (brand?: Brand): string | undefined => {
  return getExtensionFromBrand(brand)?.logoImage;
};
