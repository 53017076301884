import {
  AcquisitionChannelPayload,
  AnalyticsEvent,
  CommonPayload,
  isPaymentEvent,
  isTipEvent,
  Tracker,
  GtmBookAppointmentEventPayload,
} from '@water-web/view';

import { config } from '@app/config';

export class GtmTracker implements Tracker {
  bookingEvents = new Set<AnalyticsEvent>([
    AnalyticsEvent.SELECT_PROFESSIONAL,
    AnalyticsEvent.SELECT_ANY_PROFESSIONAL,
    AnalyticsEvent.SELECT_SERVICES,
    AnalyticsEvent.GUEST_ADD,
    AnalyticsEvent.SELECT_DATE_AND_TIME,
    AnalyticsEvent.TIP_ADD,
    AnalyticsEvent.PAYMENT_OPEN,
    AnalyticsEvent.PAYMENT_SUCCESS,
    AnalyticsEvent.RESERVATION_SUCCESS,
    AnalyticsEvent.WAITLIST_SUCCESS,
    AnalyticsEvent.GC_PURCHASE_SUCCESS,
    AnalyticsEvent.VISITOR_FROM_CITY_PAGES,
  ]);

  signupEvents = new Set<AnalyticsEvent>([
    AnalyticsEvent.BURGER_CLICK,
    AnalyticsEvent.CONFIRM_BOOKING_SIGNIN_CLICK,
    AnalyticsEvent.SIGNUP_CLICK,
    AnalyticsEvent.SIGNIN_CLICK,
    AnalyticsEvent.SIGNOUT_CLICK,
    AnalyticsEvent.APPLE_SIGNIN_CLICK,
    AnalyticsEvent.GOOGLE_SIGNIN_CLICK,
    AnalyticsEvent.USER_MODAL_CLOSE_CLICK,
    AnalyticsEvent.KNOWN_USER_MODAL_CLOSE_CLICK,
    AnalyticsEvent.EMAIL_SIGNIN_SUCCESS,
    AnalyticsEvent.APPLE_SIGNIN_SUCCESS,
    AnalyticsEvent.GOOGLE_SIGNIN_SUCCESS,
    AnalyticsEvent.SIGNUP_SUCCESS,
  ]);

  gclidMixin: Pick<GtmBookAppointmentEventPayload, 'gclid'> = { gclid: '' };

  constructor() {
    if (typeof window !== 'undefined') {
      const gclid = new URLSearchParams(window.location.search).get('gclid');
      if (gclid) {
        this.gclidMixin = { gclid };
      }
    }
  }

  getName(): string {
    return 'GtmTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    const exit =
      !config.analytics.gtm.id ||
      !window.dataLayer ||
      (!this.bookingEvents.has(analyticsEvent) && !this.signupEvents.has(analyticsEvent));

    if (exit) {
      return;
    }

    if (isPaymentEvent(analyticsEvent, payload)) {
      window.dataLayer.push(<GtmBookAppointmentEventPayload>{
        event: 'book_appointment',
        value: (payload.totalWithTips / 100).toFixed(2),
        currency: payload.currency,
        transaction_id: payload.appointmentId,
        shop_name: payload.shop.name,
        shop_route: payload.shop.route,
        ...this.gclidMixin,
      });
      return;
    }

    if (isTipEvent(analyticsEvent, payload)) {
      window.dataLayer.push({
        event: analyticsEvent,
        amount: (payload.tip.amount / 100).toFixed(2),
        percentage: payload.tip.percentage,
        currency: payload.currency,
      });
      return;
    }

    switch (analyticsEvent) {
      case AnalyticsEvent.WAITLIST_SUCCESS: {
        window.dataLayer.push({
          event: 'book_waitlist',
        });
        break;
      }
      case AnalyticsEvent.GC_PURCHASE_SUCCESS: {
        window.dataLayer.push({
          event: 'gift_card_purchase',
        });
        break;
      }
      case AnalyticsEvent.VISITOR_FROM_CITY_PAGES: {
        window.dataLayer.push({
          event: 'visitor_from_city_pages',
        });

        if ((payload as AcquisitionChannelPayload)?.fromRolesPage) {
          window.dataLayer.push({
            event: 'visitor_from_city_pages_through_roles_page',
          });
        }

        break;
      }
      case AnalyticsEvent.SELECT_PROFESSIONAL:
      case AnalyticsEvent.SELECT_ANY_PROFESSIONAL:
      case AnalyticsEvent.SELECT_SERVICES:
      case AnalyticsEvent.SELECT_DATE_AND_TIME:
      case AnalyticsEvent.GUEST_ADD:
      case AnalyticsEvent.PAYMENT_OPEN: {
        window.dataLayer.push({
          event: analyticsEvent,
        });
        break;
      }
      default:
        if (this.signupEvents.has(analyticsEvent)) {
          window.dataLayer.push({
            event: analyticsEvent,
          });
        }
        break;
    }
  }
}
