export enum AnalyticsEvent {
  SELECT_LOCATION = 'Location Chosen',
  SELECT_PROFESSIONAL = 'Professional Selected',
  SELECT_SERVICES = 'Service Selected',
  SELECT_DATE_AND_TIME = 'Date and Time Selected',
  CONFIRM_PAYMENT = 'Reserve and Pay Confirmed',
  CONFIRM_RESERVATION = 'Reserve Confirmed',
  PAYMENT_SUCCESS = 'Payment Success',
  PAYMENT_FAILURE = 'Payment Failure',

  SELECT_WAITLIST = 'Waitlist Selected',
  CONFIRM_WAITLIST = 'Wailist Confirmed',
  WAITLIST_SUCCESS = 'Waitlist Success',
  WAITLIST_FAILURE = 'Waitlist Failure',
  PAGE_VIEW = 'Pageview',

  GC_PURCHASE_SUCCESS = 'GC Purchase Success',

  SELECT_ANY_PROFESSIONAL = 'Any Professional Selected',
  RESERVATION_SUCCESS = 'Reserve Success',
  RESERVATION_FAILURE = 'Reserve Failure',

  GUEST_ADD = 'Guest Added',
  TIP_ADD = 'Tip Added',
  PAYMENT_OPEN = 'Payment Open',

  GOOGLE_CALENDAR_SYNC_CLICK = 'Calendar Icon Clicked',
  GOOGLE_CALENDAR_OAUTH_CLICK = 'Google OAuth Button Clicked',
  GOOGLE_CALENDAR_OAUTH_RESULT = 'Google OAuth Result',
  SELECT_ALTERNATIVE_DATE_AND_TIME = 'Alternative Date and Time Selected',
  SELECT_IGNORE_CONFLICT = 'Ignore Conflict Button Clicked',

  DEBUG_WALLETS_IS_SUPPORTED = 'Debug: Wallet is Supported',
  DEBUG_WALLETS_HAS_CARD = 'Debug: Wallet has Card',
  DEBUG_WALLETS_SELECTED = 'Debug: Wallet payment Selected',
  DEBUG_WALLETS_PAYMENT_INITIATED = 'Debug: Wallet payment Started',
  DEBUG_WALLETS_PAYMENT_CANCELED = 'Debug: Wallet payment Canceled',
  DEBUG_WALLETS_PAYMENT_SUCCESS = 'Debug: Wallet payment Success',
  DEBUG_WALLETS_PAYMENT_TOO_LONG_MAYBE_TIMEOUT = 'Debug: Wallet payment Too Long Maybe Timeout',
  DEBUG_WALLETS_PAYMENT_FAILURE = 'Debug: Wallet payment Failure',

  VISITOR_FROM_CITY_PAGES = 'Visitor from City Pages',

  BURGER_CLICK = 'login_menu',
  CONFIRM_BOOKING_SIGNIN_CLICK = 'login_menu_confirm_booking',
  SIGNUP_CLICK = 'login_sign_up',
  SIGNIN_CLICK = 'login_email',
  SIGNOUT_CLICK = 'login_sign_out',
  APPLE_SIGNIN_CLICK = 'login_apple',
  GOOGLE_SIGNIN_CLICK = 'login_google',
  USER_MODAL_CLOSE_CLICK = 'login_menu_close',
  KNOWN_USER_MODAL_CLOSE_CLICK = 'login_menu_close_logged',
  EMAIL_SIGNIN_SUCCESS = 'login_email_success',
  APPLE_SIGNIN_SUCCESS = 'login_apple_success',
  GOOGLE_SIGNIN_SUCCESS = 'login_google_success',
  SIGNUP_SUCCESS = 'login_sign_up_success',

  REVIEW_SET_RATING = 'review_rating',
  REVIEW_CLICK_SUBMIT_RATING = 'review_rating_continue',
  REVIEW_FOCUS_COMMENT = 'review_text',
  REVIEW_CLICK_SUBMIT_COMMENT = 'review_text_submit',
  REVIEW_CLICK_SKIP = 'review_text_skip',
  REVIEW_CLICK_CLOSE = 'review_close',
}
