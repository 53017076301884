import { parseJSON } from 'date-fns';
import { Addon } from '@water-web/types';

import { Quantity } from './quantity';
import { CartModel, AddonModel, BarberModel, ProductModel, ReservationModel, CartModelPopulationData } from '../models';

export class CartModelPopulator {
  static populateModelFromApiCart(cart: CartModel, dataForPopulation: CartModelPopulationData): void {
    const factory = new CartModelPopulator(cart, dataForPopulation);
    return factory.populateFromApiCart();
  }

  constructor(cart: CartModel, dataForPopulation: CartModelPopulationData) {
    this.cart = cart;
    this.dataForPopulation = dataForPopulation;
  }

  private cart: CartModel = null;
  private dataForPopulation: CartModelPopulationData = null;

  populateFromApiCart(): void {
    this.populateBrand();
    this.populateReservations();
    this.populateCartProducts();
    this.populateValidatedCart();
  }

  populateBrand(): void {
    const { brand } = this.dataForPopulation;
    if (brand) {
      this.cart.setBrand(brand);
    }
  }

  private populateReservations(): void {
    const { shop, barbers, cart: validatedCart } = this.dataForPopulation;

    if (shop && barbers) {
      shop.setBarbers(barbers);
    }

    if (validatedCart) {
      // eslint-disable-next-line complexity
      const reservations = validatedCart.getReservations().map((item) => {
        const reservation = new ReservationModel();

        if (shop) {
          reservation.setShop(shop);
          if (this.dataForPopulation.shopServices) {
            shop.setServices(this.dataForPopulation.shopServices);
          }
        }

        if (item.barberId) {
          const barber =
            this.dataForPopulation.barber?.getId() === item.barberId
              ? this.dataForPopulation.barber
              : shop.getBarbers().find((shopBarber) => shopBarber.getId() === item.barberId);
          reservation.setBarber(barber);
          reservation.setValidatedPasscode(item.barberPasscode || null);

          const barberServices = this.dataForPopulation.servicesByBarberId[barber.getId()];
          if (barberServices) {
            barber.setServices(barberServices);
          }
        } else {
          reservation.setBarber(BarberModel.createAnyBarber());
        }

        const services = reservation.getIsAnyBarber()
          ? reservation.getShop().getServices()
          : reservation.getBarber().getServices();

        const validatedService = validatedCart.getServiceForReservation(item.id);
        const service = services.find((s) => s.getId() === validatedService.referenceId);
        reservation.setService(service);

        const validatedAddons = validatedCart.getAddonsForReservation(item.id);
        const addons = validatedAddons.map((validatedAddon) => {
          /**
           * @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
           * We'll need to get rid of searching addons in services once we have addons implemented
           * Because addons will live in the service's addonServices field
           */
          const serviceForAddon = services.find((s) => s.getId() === validatedAddon.referenceId);
          return new AddonModel(serviceForAddon.toJson() as unknown as Addon);
        });

        reservation.setAddons(addons);

        const validatedCustomer = validatedCart.getCustomerForReservation(item.id);
        const customer = reservation.getCustomer();
        customer.setFirstName(validatedCustomer?.firstName || '');
        customer.setLastName(validatedCustomer?.lastName || '');

        // parsing `null` will return `Invalid Date` created by `new Date(NaN)`
        if (item.dateTime) {
          reservation.setDateTime(parseJSON(item.dateTime));
        }

        if (reservation.isEarlyTippingEnabled()) {
          reservation.setTipFromCart(item.tipAmount);
        }

        return reservation;
      });

      this.cart.setReservations(reservations);
    }
  }

  private populateCartProducts(): void {
    const { cart: validatedCart } = this.dataForPopulation;
    if (validatedCart) {
      const validatedProducts = validatedCart.getProducts();
      const products = validatedProducts.map((item) => {
        const product = new ProductModel(item.product);
        return new Quantity(item.quantity, product);
      });

      this.cart.setProducts(products);
    }
  }

  private populateValidatedCart(): void {
    const { cart: validatedCart } = this.dataForPopulation;
    if (validatedCart) {
      this.cart.setValidatedCart(validatedCart);
    }
  }
}
