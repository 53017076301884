import { add, formatDuration } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

import { SaleOrderReservationModel } from '../models';

export type CalendarType = 'ical' | 'google' | 'yahoo';

export class CalendarLinkBuilder {
  static buildFromReservation(reservation: SaleOrderReservationModel, calendar: CalendarType): string {
    const builder = new CalendarLinkBuilder(reservation, calendar);
    return builder.getCalendarLink();
  }

  constructor(reservation: SaleOrderReservationModel, calendar: CalendarType) {
    this.reservation = reservation;
    this.calendar = calendar;
  }

  private reservation: SaleOrderReservationModel;
  private calendar: CalendarType;
  private baseUrl = 'https://addtocalendar.com/atc';
  private dateFormat = 'yyyy-MM-dd HH:mm:ss';

  private getCalendarPathname() {
    return `${this.baseUrl}/${this.calendar}`;
  }

  getEventDescription() {
    const shop = this.reservation.getShop();
    const service = this.reservation.getService();
    const customer = this.reservation.getCustomer();
    const barber = this.reservation.getBarber();

    const zonedStartDate = utcToZonedTime(this.reservation.getDateTime(), shop.getTimezone());
    const zonedEndDate = add(zonedStartDate, { minutes: this.reservation.getDuration() });

    const address = shop.getAddress();
    const location = [
      [address.street, address.street2, address.city, address.state].filter(Boolean),
      [address.zip].filter(Boolean),
    ]
      .map((arr) => arr.join(', '))
      .filter(Boolean)
      .join(' ');

    return {
      startTime: format(zonedStartDate, this.dateFormat),
      finishTime: format(zonedEndDate, this.dateFormat),
      timezone: shop.getTimezone(),
      title: service.getName(),
      organizer: customer.getFullName(),
      organizerEmail: customer.getEmail(),
      location,
      description: `
        Confirmation Code: ${this.reservation.getConfirmationCode()}
        Service: ${service.getName()}
        Time: ${format(zonedStartDate, 'h:mma')}
        Duration: ${formatDuration({ minutes: this.reservation.getDuration() })}
        Client: ${customer.getFullName()}
        Barber: ${barber.getShortName()}
        Location: ${shop.getName()}, ${location}
      `,
    };
  }

  getCalendarLink(): string {
    const description = this.getEventDescription();
    return this.getCalendarPathname()
      .concat('?f=m&')
      .concat(
        [
          ['e[0][date_start]', description.startTime],
          ['e[0][date_end]', description.finishTime],
          ['e[0][timezone]', description.timezone],
          ['e[0][title]', description.title],
          ['e[0][organizer]', description.organizer],
          ['e[0][organizer_email]', description.organizerEmail],
          ['e[0][location]', description.location],
          ['e[0][description]', description.description],
          ['e[0][privacy]', 'public'],
        ]
          .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
          .join('&'),
      );
  }
}
