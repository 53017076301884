import { isEqual, isBefore, subMinutes } from 'date-fns';
import { ValidatedCartItem, ValidatedCartAvailableTip } from '@water-web/types';

import { getIsInitialFromSaleOrderItem } from '../getters';
import { getAvailableTips, CalendarLinkBuilder, CalendarType } from '../utils';
import { ReservationModel } from './reservation';

export class SaleOrderReservationModel extends ReservationModel {
  protected dataValues: Readonly<ValidatedCartItem>;

  constructor(validatedCartItem: ValidatedCartItem) {
    super();

    this.dataValues = { ...validatedCartItem };
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  getTotal(): number {
    return this.dataValues.priceWithoutTaxes;
  }

  /**
   * Time of service with addons in minutes
   */
  getDuration(): number {
    return this.dataValues.appointment.duration;
  }

  getDateTime(): Date {
    return this.dateTime || new Date(this.dataValues.appointment.dateTime);
  }

  hasUpdatedDateTime(): boolean {
    return this.dateTime && !isEqual(this.dateTime, new Date(this.dataValues.appointment.dateTime));
  }

  /**
   * Only true, when the model’s value is different from what was initially
   */
  hasUpdatedBarber(): boolean {
    return this.barber && this.barber.getId() !== this.dataValues.barber.id;
  }

  getConfirmationCode(): string {
    return this.dataValues.appointment.confirmationCode;
  }

  getCalendarLink(calendar: CalendarType): string {
    return CalendarLinkBuilder.buildFromReservation(this, calendar);
  }

  isBeforeCancelTimelimit(now: Date): boolean {
    return isBefore(now, subMinutes(this.getDateTime(), this.barber.getDataValues().advanceCancelMins));
  }

  isCancellable(now: Date): boolean {
    return (
      getIsInitialFromSaleOrderItem(this.dataValues) &&
      this.barber.getDataValues().canCustomerCancel &&
      this.isBeforeCancelTimelimit(now)
    );
  }

  updateAppointmentData(appointmentDataValues: Partial<ValidatedCartItem['appointment']>): SaleOrderReservationModel {
    this.dataValues = {
      ...this.dataValues,
      appointment: {
        ...this.dataValues.appointment,
        ...appointmentDataValues,
      },
    };

    return this;
  }

  isLateTippingEnabled(): boolean {
    return this.barber.getDataValues().lateTipping;
  }

  getAvailableTips(): ValidatedCartAvailableTip[] {
    if (!this.isLateTippingEnabled()) {
      return [];
    }

    return getAvailableTips(
      this.shop.getTipPercentages(),
      this.getTotalForTips(),
      this.shop.getDataValues().roundUpTips,
    );
  }

  setTipFromPreset(preset: ValidatedCartAvailableTip): ReservationModel {
    if (!this.isLateTippingEnabled()) {
      console.error('Tipping is not allowed for reservation');
      return this;
    }

    this.tip = {
      type: 'percentage',
      amount: preset.amount,
      percentage: preset.percentage,
    };

    return this;
  }

  setCustomTip(amount: number): ReservationModel {
    if (!this.isLateTippingEnabled()) {
      console.error('Tipping is not allowed for reservation');
      return this;
    }

    this.tip = {
      amount,
      type: 'amount',
      percentage: null,
    };

    return this;
  }

  setDefaultTipFromValidatedReservation(validatedReservation?: ValidatedCartItem): ReservationModel {
    if (!this.isLateTippingEnabled()) {
      return this;
    }

    if (validatedReservation?.defaultTip) {
      this.setTipFromPreset(validatedReservation.defaultTip);
      return this;
    }

    this.setCustomTip(0);
    return this;
  }

  getDataValues(): Readonly<ValidatedCartItem> {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    throw new Error('Not implemented');
  }
}
