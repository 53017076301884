import {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  cloneElement,
  ReactElement,
  EventHandler,
  PropsWithChildren,
} from 'react';
import { createPortal } from 'react-dom';

import { useTimeouts } from '../../../hooks';
import { BotProtectionContext } from './BotProtectionContext';
import {
  buildContainerId,
  getApiInstance,
  getPopupPosition,
  PopupAlignment,
  PopupPlacement,
  readBotProtectionToken,
} from './utils';
import { BotProtectedActionPopup, BotProtectedActionPopupArrow } from './styled';
import { inMemoryKVStore } from '../../../persistence';

declare global {
  interface Window {
    turnstile: Record<string, any>;
    _turnstileLoadingPromise: Promise<void>;
  }
}

interface BotProtectedActionProps {
  children: ReactElement;
  placement?: PopupPlacement;
  alignment?: PopupAlignment;
  action: string; // required for forming a container ID and for analytics
}

/**
 * BotProtectedAction component that wraps exactly one child element.
 * When the child element is clicked, it shows a Cloudflare Turnstile challenge.
 * If the user successfully passes the challenge, the original click event handler of the child is executed.
 * The child must forward its ref all the way down to an HTML element that is supposed to be clicked.
 *
 * @example
 * // Wrap a button with the BotProtectedAction component
 * <BotProtectedAction placement="left" action="action-name">
 *   <Button onClick={handleClick}>Click me</Button>
 * </BotProtectedAction>
 */
export const BotProtectedAction = (props: PropsWithChildren<BotProtectedActionProps>) => {
  // useState instead of useRef because BotProtectedActionPopup is conditionally rendered
  const [popupRef, setPopupRef] = useState<HTMLDivElement>();
  const childHtmlElementRef = useRef<HTMLElement>();
  const originalClickRef = useRef<EventHandler<any>>();
  const timeouts = useTimeouts();

  const [isCheckVisible, setCheckVisibility] = useState(false);
  const { siteKey, enabled: botProtectionEnabled } = useContext(BotProtectionContext);

  const containerId = buildContainerId(props.action);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const availableToken = readBotProtectionToken();
      if (availableToken) {
        childHtmlElementRef.current.onclick = originalClickRef.current;
        childHtmlElementRef.current.click();
        childHtmlElementRef.current.onclick = handleClick;
        return;
      }

      // give the popup container time to render
      timeouts.add(() => setCheckVisibility(true), 10);
    },
    [childHtmlElementRef.current],
  );

  useEffect(() => {
    const el = childHtmlElementRef.current;

    if (el && botProtectionEnabled) {
      originalClickRef.current = el?.onclick;
      el.onclick = handleClick;
      return () => {
        el.onclick = originalClickRef.current;
      };
    }

    return () => null;
  }, [childHtmlElementRef.current, botProtectionEnabled]);

  useEffect(() => {
    if (isCheckVisible) {
      (async () => {
        const turnstile = await getApiInstance();
        turnstile.render(`#${containerId}`, {
          sitekey: siteKey,
          appearance: 'always',
          action: props.action,
          callback: (token: string) => {
            inMemoryKVStore.setItem('cf-turnstile-response', token);

            // wait till turnstile animates
            timeouts.add(() => {
              childHtmlElementRef.current.onclick = originalClickRef.current;
              setCheckVisibility(false);

              // give the popup time to un-animate
              timeouts.add(() => {
                childHtmlElementRef.current.click();
                childHtmlElementRef.current.onclick = handleClick;
              }, 10);
            }, 1500);
          },
          'error-callback': () => {
            // wait till turnstile animates
            timeouts.add(() => {
              setCheckVisibility(false);
            }, 1500);
          },
        });
      })();
    }
  }, [isCheckVisible]);

  if (!botProtectionEnabled) {
    return props.children;
  }

  const child = props.children; // only one child is allowed

  const clonedChild = cloneElement(child, {
    disabled: !!child.props.disabled || isCheckVisible,
    ref: childHtmlElementRef,
  });

  const popupPosition = getPopupPosition({
    trigger: childHtmlElementRef.current,
    popup: popupRef,
    placement: props.placement,
    alignment: props.alignment,
  });

  return (
    <>
      {clonedChild}
      {isCheckVisible &&
        createPortal(
          <>
            <BotProtectedActionPopup ref={(node) => setPopupRef(node)} id={containerId} {...popupPosition} />
            <BotProtectedActionPopupArrow {...popupPosition} />
          </>,
          document.getElementById('tooltips-mount-point'),
        )}
    </>
  );
};

BotProtectedAction.defaultProps = {
  placement: 'top',
  alignment: 'center',
};
