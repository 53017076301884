export const config = {
  codeEnv: process.env.NODE_ENV,
  deploymentEnv: process.env.PUBLIC_DEPLOYMENT_ENV,
  deploymentUrl: process.env.PUBLIC_DEPLOYMENT_URL,
  staticAssetsUrl: process.env.PUBLIC_STATIC_ASSETS_URL,
  apiUrl: process.env.PUBLIC_API_URL,
  stripeKey: process.env.PUBLIC_STRIPE_KEY,
  googleApiClientId: process.env.PUBLIC_GOOGLE_API_CLIENT_ID,
  googleAuthClientId: process.env.PUBLIC_GOOGLE_AUTH_CLIENT_ID,
  googleApiKey: process.env.PUBLIC_GOOGLE_API_KEY,
  googleMapsApiKey: process.env.PUBLIC_GMAPS_API_KEY,
  sentry: {
    dsn: process.env.PUBLIC_SENTRY_DSN,
    release: process.env.PUBLIC_SENTRY_RELEASE,
  },
  dates: {
    availabilityApiDateFormat: 'yyyy-MM-dd', // 2021-01-23,
  },
  links: {
    termsOfService: 'https://getsquire.com/terms-of-service',
    privacyPolicy: 'https://getsquire.com/privacy-policy',
  },
  mode: {
    standalone: window.self === window.parent,
    iframe: window.self !== window.parent,
  },
  basePath: process.env.PUBLIC_BASE_PATH || null,
  botProtection: {
    siteKey: process.env.PUBLIC_TURNSTILE_SITE_KEY,
  },
  brandIds: {
    gme: 'c1e4e77f-1868-41f0-a79f-b071edf9e603',
  },
  launchDarkly: {
    clientSideId: process.env.PUBLIC_LD_CLIENT_SIDE_ID,
  },
  analytics: {
    gtm: {
      id: process.env.PUBLIC_GTM_ID,
    },
    fullstory: {
      orgId: process.env.PUBLIC_FULLSTORY_ORG_ID,
    },
  },
  rokt: {
    accountId: '3172976204686052439',
    sandbox: process.env.PUBLIC_DEPLOYMENT_ENV !== 'production',
  },
};
