import { Translate } from '../../atoms';
import { BodyText, TabContent } from './styles';

export const ConsentTab = () => {
  return (
    <TabContent>
      <BodyText>
        <Translate i18nKey="components.cookieConsent.consentTabInfo" />
      </BodyText>
    </TabContent>
  );
};
