// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UniqFn<T> = (item: T) => any;

type FilterFn<T> = (item: T) => boolean;

export const uniqBy = <T>(uniqFn: UniqFn<T>): FilterFn<T> => {
  const memo = {};
  return (item: T) => {
    const id = uniqFn(item);

    if (!memo[id]) {
      memo[id] = true;
      return true;
    }

    return false;
  };
};
