import { NotificationsConsentUpdate, CustomerMatchSearch, HttpResponse, NotificationsConsent } from '@water-web/types';

import { Repository } from './base';

export class CustomerRepository extends Repository {
  /**
   * @deprecated Use `NotificationsConsentByMatchQuery` instead
   */
  getNotificationsConsentByMatch(
    shopId: string,
    search: CustomerMatchSearch,
  ): Promise<HttpResponse<NotificationsConsent>> {
    return this.api.customer.getNotificationsConsentByMatch(shopId, search);
  }

  /**
   * @deprecated Use `NotificationsConsentUpdateMutation` instead
   */
  updateNotificationsConsent(
    id: string,
    shopId: string,
    data: NotificationsConsentUpdate,
  ): Promise<HttpResponse<NotificationsConsent>> {
    return this.api.customer.updateNotificationsConsent(id, shopId, data);
  }
}
