import { useCallback, useState, ChangeEvent } from 'react';

const getDigitsOnly = (value: string): string => {
  return value.slice(0).replace(/[^\d]/g, '');
};

export interface CurrencyInputValue {
  viewValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Value in cents
   */
  modelValue: number;
  setModelValue: (cents: number) => void;
}

/**
 * Let input integer number, without cents, with leading currency sign
 * @param currencySign
 * @param maxModelValue - maximum value in cents, defaults to 199999 ($1999.99) due to Stripe limitations
 */
export const useCurrencyInput = (currencySign: string, maxModelValue = 199999): CurrencyInputValue => {
  const [viewValue, setViewValue] = useState(currencySign);

  const setModelValue = useCallback<CurrencyInputValue['setModelValue']>(
    (cents) => {
      if (!cents) {
        return setViewValue(currencySign);
      }
      return setViewValue(`${currencySign}${cents / 100}`);
    },
    [currencySign],
  );

  const onChange = useCallback<CurrencyInputValue['onChange']>(
    (e) => {
      const inputString = e.target.value;

      if (!inputString || inputString === currencySign) {
        setViewValue(currencySign);
        return;
      }

      const intNumber = Number(getDigitsOnly(inputString));
      if (!intNumber) {
        // zero
        setViewValue(currencySign);
        return;
      }

      const maxNumber = Math.floor(maxModelValue / 100); // must remain int: 1999.99 -> 1999
      if (intNumber > maxNumber) {
        setViewValue(`${currencySign}${maxNumber}`);
        return;
      }

      setViewValue(`${currencySign}${intNumber}`);
    },
    [currencySign, maxModelValue],
  );

  return {
    onChange,
    viewValue,
    modelValue: Number(viewValue.replace(/\D/g, '')) * 100, // cents
    setModelValue,
  };
};
