import {
  Barber,
  Professional,
  HttpResponse,
  BarberApiSearch,
  BarberApi as IBarberApi,
  BarberScheduleTimeRangeDay,
  BarberApiTimeSearch,
  RelatedDatesAndBarbers,
  BarberScheduleTimeRangeDayWithConflicts,
} from '@water-web/types';

import { SERVICE_ID_V2_HEADER_MIXIN } from '../utils';
import { Namespace } from './namespace';

export class BarberApi extends Namespace implements IBarberApi {
  getByIdOrRoute(shopIdOrRoute: string, idOrRoute: string, search?: BarberApiSearch): Promise<HttpResponse<Barber>> {
    return this.http.get<Barber>(`/shop/${shopIdOrRoute}/barber/${idOrRoute}`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:shopId/barber/:barberId',
    });
  }

  getAllByShopIdOrRoute(shopIdOrRoute: string, search?: BarberApiSearch): Promise<HttpResponse<Professional[]>> {
    return this.http.get<Professional[]>(`/shop/${shopIdOrRoute}/professional`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:id/professional',
    });
  }

  /**
   * Will return 6 weeks from given start date at max
   */
  getScheduleTimeRange(
    barberIdOrRoute: string,
    start: string,
    end: string,
    duration?: number,
    search?: BarberApiTimeSearch,
  ): Promise<HttpResponse<BarberScheduleTimeRangeDay[] | BarberScheduleTimeRangeDayWithConflicts[]>> {
    const path = `/barber/${barberIdOrRoute}/schedule-time-range/${start}/${end}/${duration || ''}`;
    return this.http.get<BarberScheduleTimeRangeDay[] | BarberScheduleTimeRangeDayWithConflicts[]>(path, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/barber/:id/schedule-time-range/:start/:end/:duration',
    });
  }

  /**
   * Get dates, when barber can provide a given service on a given time
   * And other barbers, that can provide a given service on a given day and time
   */
  getRelatedDatesAndBarbers(
    barberIdOrRoute: string,
    dateTime: string,
    services: string[],
  ): Promise<HttpResponse<RelatedDatesAndBarbers>> {
    const path = `/barber/${barberIdOrRoute}/related-dates-barbers/${dateTime}/${services.join(';')}`;
    return this.http.get(path, {
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/barber/:id/related-dates-barbers/:dateTime/:services',
    });
  }
}
