import { useEffect } from 'react';
import { ApiStatusQuery, isWaitingRoomInfo, WaitingRoomInfo, useApiQuery } from '@water-web/api';
import { Flex, Box } from '@water-web/view';

import { config } from '@app/config';
import { fromPublic } from '@app/utils';
import { Text } from '@app/components/atoms/Text';
import { Image } from '@app/components/atoms/Image';

import { WaitingRoomActionButton, CloseButton } from './styles';

const endpoint = new ApiStatusQuery(config.apiUrl);

export const WaitingRoom = () => {
  const query = useApiQuery({ endpoint });

  useEffect(() => {
    // tell parent window to use default language because settings are not available
    const message = { type: 'squire_data_refresh', payload: { default: true } };
    window.parent?.postMessage?.(JSON.stringify(message), '*');
  }, []);

  useEffect(() => {
    const isApiOk = !isWaitingRoomInfo(query.data) && query.data?.ok === true;
    if (isApiOk) {
      window.location.reload();
    } else {
      const statusCheckInterval = (query.data as WaitingRoomInfo)?.cfWaitingRoom?.refreshIntervalSeconds || 30;
      setTimeout(() => {
        query.remove();
        query.refetch();
      }, statusCheckInterval * 1000);
    }
  }, [query.data, query.error]);

  const showCloseButton = config.mode.iframe;

  const closeWidget = () => {
    window.parent.postMessage?.('close_squire_widget', '*');
  };

  return (
    <>
      {showCloseButton && <CloseButton onClick={closeWidget} />}
      <Flex justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="100%">
        <Image width="80" height="80" src={fromPublic('/img/waiting_room_image.png')} alt="waiting room" />
        <Flex maxWidth="370px" flexDirection="column" alignItems="center" mt={8}>
          <Text.Title1 textAlign="center" letterSpacing="0.7px">
            SQUIRE is currently unavailable.
          </Text.Title1>
          <Box mt={2} mb={10}>
            <Text.P2Regular textAlign="center" fontWeight="400" fontSize="14px" letterSpacing="-0.3px">
              Once it becomes available, we&#39;ll let you in automatically...
            </Text.P2Regular>
          </Box>
          <WaitingRoomActionButton as="a" href="mailto:support@getsquire.com">
            Contact support
          </WaitingRoomActionButton>
        </Flex>
      </Flex>
    </>
  );
};
