import { convertUrlToRoute } from '@app/utils';
import { cartRepository, saleOrderRepository } from '@app/repository';

import { updateMarketingConsent } from './utils';
import { PhaseConfig, PhaseHookArgs } from '../types';

export const processingGiftCard: PhaseConfig = {
  name: 'processingGiftCard',
  init: async (params: PhaseHookArgs) => {
    params.cartState.setLoader('pending');

    const giftCardCheckoutResult = await cartRepository.checkoutGiftCardSale(params.cartModel);
    if (giftCardCheckoutResult.error) {
      params.setCheckoutPhase('error');
      return;
    }

    params.userActionsAnalytics.trackGcPurchaseSuccess();

    const saleOrderResult = await saleOrderRepository.getById(giftCardCheckoutResult.data.getSaleOrderId());
    if (saleOrderResult.data) {
      const customerId = saleOrderResult.data.getPayingCustomerId();
      await updateMarketingConsent(params, customerId);
    }

    params.setCheckoutPhase('success');
    params.popupController.closeAll();

    const route = params.urlBuilder.getGiftCardInfoUrl(giftCardCheckoutResult.data);
    params.history.replace(convertUrlToRoute(route));
  },
  next: () => {
    // not available
  },
  cancel: () => {
    // not available
  },
};
