import { Photo, Product, ProductBrand } from '@water-web/types';

import { BaseModel } from './base';

export class ProductModel extends BaseModel<ProductModel> {
  constructor(product: Product) {
    super();

    Object.keys(product).forEach((key) => {
      this.dataValues[key] = product[key];
    });
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  // only for sale order products
  getItemId(): string {
    return this.dataValues.itemId;
  }

  getName(): string {
    return this.dataValues.name as string;
  }

  getDescription(): null | string {
    return this.dataValues.desc as string;
  }

  getCost(): number {
    return this.dataValues.priceWithoutTaxes as number;
  }

  getPhoto(): null | Photo {
    return (this.dataValues.photos || [])[0] as Photo;
  }

  getBrandName(): null | string {
    const brand = this.dataValues.productBrand as ProductBrand;
    return brand?.name || null;
  }

  getAvailableQuantity(): number {
    return this.dataValues.inventory as number;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
