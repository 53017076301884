import { Shop, Barber, ScheduleAvailability } from '@water-web/types';
import { getIsAnyFromBarber, getHasActiveWaitingListFromShop, getIsDayEnabledFromBarber } from '@water-web/repository';

/**
 * @param shop
 * @param barber
 * @param dayAvailability
 * @param day - ISO 8601 date: `2021-01-23`
 */
export const isWaitlistAvailableForDay = (
  shop: Shop,
  barber: Barber,
  dayAvailability: ScheduleAvailability,
  day: string,
): boolean => {
  if (dayAvailability) {
    return false;
  }

  /**
   * We don't support Waitlist for Any Barber
   * @see https://squire.atlassian.net/browse/WTRW-474
   */
  if (getIsAnyFromBarber(barber)) {
    return false;
  }

  if (!getHasActiveWaitingListFromShop(shop)) {
    return false;
  }

  return getIsDayEnabledFromBarber(barber, day);
};
