import { WaitingList, Service, Addon, WaitingListItem, WaitingListItemPersonal } from '@water-web/types';

export const getServiceFromWaitingList = (waitingList: WaitingList): Service => {
  return waitingList.services[0];
};

export const getAddonsFromWaitingList = (waitingList: WaitingList): Addon[] => {
  return waitingList.services.slice(1) as unknown as Addon[];
};

export const getTotalForTipsFromWaitingList = (waitingList: WaitingList): number => {
  return (
    0 +
    getServiceFromWaitingList(waitingList).cost +
    getAddonsFromWaitingList(waitingList).reduce((sum, item) => sum + item.cost ?? 0, 0)
  );
};

export const getTotalFromWaitingList = (waitingList: WaitingList): number => {
  return getTotalForTipsFromWaitingList(waitingList) + waitingList.tipAmount;
};

const isPersonalItem = (item: WaitingListItem): item is WaitingListItemPersonal => {
  return !!(item as unknown as WaitingListItemPersonal).customer;
};

export const getPersonalItemFromWaitingList = (waitingList: WaitingList): WaitingListItemPersonal | undefined => {
  return waitingList.list.find((item) => isPersonalItem(item)) as WaitingListItemPersonal;
};
