import {
  Brand,
  HttpResponse,
  BrandApiSearch,
  BrandApi as IBrandApi,
  Extension,
  AppleDeepLinksConfig,
  GoogleDeepLinksConfig,
} from '@water-web/types';

import { SERVICE_ID_V2_HEADER_MIXIN } from '../utils';
import { Namespace } from './namespace';

export class BrandApi extends Namespace implements IBrandApi {
  async getByIdOrRoute(idOrRoute: string, search?: BrandApiSearch): Promise<HttpResponse<Brand>> {
    const response = await this.http.get<{ brand: Brand }>(`/home/${idOrRoute}`, {
      search: {
        ...(search || null),
        nonSquireEcosystem: 'true',
        ignoreShowOnCustomApp: 'true',
      },
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/home/:id',
    });
    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: response.data.brand,
      error: null,
    };
  }

  getByIdOrRouteNearby(idOrRoute: string, search?: BrandApiSearch): Promise<HttpResponse<{ brand: Brand }>> {
    return this.http.get<{ brand: Brand }>(`/home/${idOrRoute}`, {
      search: {
        ...(search || null),
        nonSquireEcosystem: 'true',
        ignoreShowOnCustomApp: 'true',
      },
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/home/:id',
    });
  }

  getExtensionByRoute(route: string): Promise<HttpResponse<Extension>> {
    return this.http.get<Extension>(`/extension/${route}`, {
      resourceUrl: '/extension/:route',
    });
  }

  getAppleDeepLinksConfig(): Promise<HttpResponse<AppleDeepLinksConfig>> {
    return this.http.get<AppleDeepLinksConfig>('/extension/apple-deep-linking-config', {
      resourceUrl: '/extension/apple-deep-linking-config',
    });
  }

  getGoogleDeepLinksConfig(): Promise<HttpResponse<GoogleDeepLinksConfig[]>> {
    return this.http.get<GoogleDeepLinksConfig[]>('/extension/google-deep-linking-config', {
      resourceUrl: '/extension/google-deep-linking-config',
    });
  }
}
