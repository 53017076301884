import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { CartLoaderState, CartStateContext, useTimeouts } from '@water-web/view';

import { useCheckoutFlow } from '@app/contexts/CheckoutFlow';

import { ErrorMessage } from './ErrorMessage';
import { ProcessingMessage } from './ProcessingMessage';
import { OverlayContainer } from './styles';
import { SuccessMessage } from './SuccessMessage';

interface ProcessingOverlayProps {
  onClick?: () => void;
  message?: string;
  loader: CartLoaderState;
}

export const JustProcessingOverlay = (props: ProcessingOverlayProps) => {
  const { loader } = props;

  return (
    <OverlayContainer>
      {loader === 'error' && <ErrorMessage message={props.message} onClick={props.onClick} />}
      {loader === 'success' && <SuccessMessage />}
      {loader === 'pending' && <ProcessingMessage />}
    </OverlayContainer>
  );
};

export const ProcessingOverlay = () => {
  const checkoutFlow = useCheckoutFlow();
  const { loader, loaderMessage, resetLoader } = useContext(CartStateContext);
  const [showOverlay, setShowOverlay] = useState(false);
  const theme = useTheme();
  const timeouts = useTimeouts();

  useEffect(() => {
    if (loader) {
      setShowOverlay(true);
    } else {
      timeouts.add(() => setShowOverlay(false), theme.animations.booking.interaction * 1000);
    }
  }, [loader, theme]);

  const closeError = () => {
    resetLoader();
    checkoutFlow.cancel();
  };

  return (
    <AnimatePresence mode="wait">
      {showOverlay && <JustProcessingOverlay loader={loader} message={loaderMessage} onClick={closeError} />}
    </AnimatePresence>
  );
};
