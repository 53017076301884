import { MutationKey, QueryClient } from 'react-query';

import { EndpointMutation, GetToken } from '../../request';
import { queryKeys } from './queryKeys';

type Payload = {
  id: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
};

export class UpdateUserCustomerMutation extends EndpointMutation<void, Payload> {
  constructor(config: { baseUrl: string; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  getKey(): MutationKey {
    return queryKeys.signup();
  }

  request({ id, ...payload }: Payload, signal?: AbortSignal): Promise<void> {
    const headers = this.buildHeaders();

    return this.http.put(`/v1/customer/${id}`, payload, {
      signal,
      headers,
      resourceUrl: '/customer/:id',
    });
  }

  async afterMutationSuccess(queryClient: QueryClient): Promise<void> {
    await queryClient.invalidateQueries(queryKeys.current());
  }
}
