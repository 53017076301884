import {
  WaitingListQuery,
  WaitingListTipMutation,
  WaitingListTimeSlotsQuery,
  WaitingListAcceptSlotMutation,
  WaitingListAcceptRescheduleMutation,
} from '@water-web/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WaitingListServiceConstructor = (apiUrl: string) => ({
  getById: new WaitingListQuery(apiUrl),
  getTimeSlots: new WaitingListTimeSlotsQuery(apiUrl),
  updateTip: new WaitingListTipMutation(apiUrl),
  acceptSlot: new WaitingListAcceptSlotMutation(apiUrl),
  acceptReschedule: new WaitingListAcceptRescheduleMutation(apiUrl),
});
