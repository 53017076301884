/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MutationKey, MutationOptions, QueryClient } from 'react-query';
import { Nullable } from '@water-web/types';

import { HttpError } from '../transport/types';
import { Endpoint } from './Endpoint';

export abstract class EndpointMutation<ClientModel, RequestParams = void, EContext = unknown> extends Endpoint<
  ClientModel,
  RequestParams
> {
  protected meta: MutationOptions<ClientModel, HttpError, RequestParams> = {};

  getMeta(): MutationOptions<ClientModel, HttpError, RequestParams> {
    return this.meta;
  }

  getKey(): MutationKey | undefined {
    return undefined;
  }

  afterMutationStart(
    queryClient: QueryClient,
    payload: RequestParams,
  ): Promise<EContext | undefined> | EContext | undefined {
    return;
  }

  afterMutationSuccess(
    queryClient: QueryClient,
    data: ClientModel,
    payload: RequestParams,
    context: EContext,
  ): Promise<unknown> | void {
    return;
  }

  afterMutationError(
    queryClient: QueryClient,
    error: HttpError,
    payload: RequestParams,
    context: EContext,
  ): Promise<unknown> | void {
    return;
  }

  afterMutationSettled(
    queryClient: QueryClient,
    data: ClientModel | undefined,
    error: Nullable<HttpError>,
    payload: RequestParams,
    context: EContext,
  ): Promise<unknown> | void {
    return;
  }
}
