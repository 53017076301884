import { useEffect, useState } from 'react';
import { useApiQuery } from '@water-web/api';
import { useFlagsContextSwitcher } from '@water-web/view';
import { Brand } from '@water-web/types';

import { useFastRouteParams } from '@app/hooks';
import { BrandHomeService, PublicShopService } from '@app/apiServices';

interface PageRouteParams {
  brandIdOrRoute?: string;
  shopIdOrRoute?: string;
}

export const FeatureFlagSwitcher = () => {
  const routeParams = useFastRouteParams<PageRouteParams>();
  const switchFlagsContext = useFlagsContextSwitcher();
  const [brandIdOrRoute, setBrandIdOrRoute] = useState(routeParams.brandIdOrRoute);

  const brandQuery = useApiQuery({
    endpoint: BrandHomeService.byId,
    payload: {
      idOrRoute: brandIdOrRoute,
    },
    options: {
      enabled: !!brandIdOrRoute,
      select: (data) => {
        const { brand } = data;
        return brand as Brand;
      },
    },
  });

  const shopQuery = useApiQuery({
    endpoint: PublicShopService.byId,
    payload: {
      idOrRoute: routeParams.shopIdOrRoute,
    },
    options: {
      enabled: !!routeParams.shopIdOrRoute,
    },
  });

  useEffect(() => {
    if (shopQuery.data && !brandIdOrRoute) {
      setBrandIdOrRoute(shopQuery.data.brandId);
    }
  }, [brandIdOrRoute, shopQuery.data]);

  useEffect(() => {
    if (brandQuery?.data?.id) {
      switchFlagsContext({ brandId: brandQuery?.data?.id });
    }
  }, [brandQuery?.data?.id]);

  return null;
};
