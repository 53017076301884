import { LocationModel, RepositoryResponse, ShopModel } from '@water-web/repository';

import { ExternalConfiguration } from '../../../config';

/**
 * @description Since GME doesn't allow loading all locations at once, we can't just
 * use the searchRepository.getLocations() method. Instead, we have to make a separate
 * request for each shopId returned from the shop service.
 * This is a hacky and slow solution, but it works.
 */
export const specialGMESearch = async (shopIds: Set<string>): Promise<LocationModel[]> => {
  const { shopRepository } = ExternalConfiguration.getRepository();
  const matchingLocations: LocationModel[] = [];

  // Get all shops from shopIds, loading them in parallel but mostly 10 at a time
  // Firstm, split shopIds into chunks of 5 (to not overload browser / WAF with too many requests)
  const chunks = [];
  const chunkSize = 5;
  for (let i = 0; i < shopIds.size; i += chunkSize) {
    chunks.push(Array.from(shopIds).slice(i, i + chunkSize));
  }

  // For each chunks, make N (five here) parallel requests to the shop service to get the actual shop data
  // and then transform them so that they look like we loaded them from /home endpoint
  for (let j = 0; j < chunks.length; j += 1) {
    const chunk = chunks[j];
    type Promises = Promise<RepositoryResponse<ShopModel>>[];

    // Because of ShopApi.getByIdOrRoute hack, where IG images are missing in the /shop/:idOrRoute endpoint
    // we would make two requests for each shop: one for shop, and one for brand /home with shopId from where
    // we'd get the instagram images. By including ignoreInsta = true here we skip that request, since
    // GME doesn't care for insta
    const promises: Promises = chunk.map((shopId) => shopRepository.getByIdOrRoute(shopId, true));

    // eslint-disable-next-line no-await-in-loop
    const newShops = await Promise.all(promises);
    const newShopsData = newShops.map((shop) => shop.data.getDataValues());
    const locationModels = newShopsData.map((shop) => new LocationModel(shop));
    matchingLocations.push(...locationModels);
  }

  return matchingLocations;
};
