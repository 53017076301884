import { createContext, PropsWithChildren, useEffect, useState } from 'react';

export type UserAgentContextValue = {
  isSafari: boolean;
  isIos: boolean;
  isMobileSafari: boolean;
  isChrome: boolean;
  isAndroid: boolean;
  isMobileChrome: boolean;
};

export const UserAgentContext = createContext<UserAgentContextValue>(null);

declare const window: Window &
  typeof globalThis & {
    MSStream: any;
  };

export const UserAgentContextProvider = (props: PropsWithChildren) => {
  const [value, setValue] = useState<UserAgentContextValue>(null);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    const isMobileSafari = isSafari && isIos;
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isAndroid = /android/i.test(navigator.userAgent.toLowerCase());
    const isMobileChrome = isChrome && isAndroid;

    setValue({
      isSafari,
      isIos,
      isMobileSafari,
      isChrome,
      isAndroid,
      isMobileChrome,
    });
  }, []);

  return <UserAgentContext.Provider value={value}>{props.children}</UserAgentContext.Provider>;
};
