import { useEffect } from 'react';
import { getBrandPixelId } from '@water-web/view';

import { config } from '@app/config';
import { useAnalyticsShopInfo } from '@app/hooks';

import { getTrackingScript } from './utils';

const noop = () => undefined;

const makeScriptNode = (params: { script: string; id?: string }) => {
  const node = document.createElement('script');
  node.type = 'text/javascript';
  if (params.id) {
    node.id = params.id;
  }
  node.innerHTML = params.script;
  return node;
};

export const CustomAnalytics = () => {
  const { brandId, shop, brandGaProperty } = useAnalyticsShopInfo();

  const brandPixelId = getBrandPixelId(brandId, config.deploymentEnv !== 'production');

  useEffect(() => {
    if (brandGaProperty) {
      const scriptFile = document.createElement('script');
      scriptFile.src = `https://www.googletagmanager.com/gtag/js?id=${brandGaProperty}&l=squireCustomBrandGaScript`;
      scriptFile.async = true;

      document.head.appendChild(scriptFile);

      const scriptText = `
          window.squireCustomBrandGaScript = window.squireCustomBrandGaScript || [];
          
          function squireCustomBrandGaGtag(){
            squireCustomBrandGaScript.push(arguments);
          }
          
          squireCustomBrandGaGtag('js', new Date());
          squireCustomBrandGaGtag('config', '${brandGaProperty}');
      `;

      const node = makeScriptNode({
        script: scriptText,
        id: 'squire-ga-brand-specific',
      });

      document.head.appendChild(node);
    }

    return () => {
      window.squireCustomBrandGaScript = [];
    };
  }, [brandGaProperty]);

  useEffect(() => {
    if (brandPixelId) {
      const noScriptElement = document.createElement('noscript');
      const imgTag = document.createElement('img');
      imgTag.height = 1;
      imgTag.width = 1;
      imgTag.style.display = 'none';
      imgTag.src = `https://www.facebook.com/tr?id=${brandPixelId}&ev=PageView&noscript=1`;
      imgTag.alt = '';
      noScriptElement.appendChild(imgTag);
      document.head.appendChild(noScriptElement);

      const scriptText = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${brandPixelId}');
        fbq('track', 'PageView');
      `;

      const node = makeScriptNode({
        script: scriptText,
        id: 'squire-fb-brand-specific',
      });
      document.head.appendChild(node);
    }

    return () => {
      window.fbq = null;
      window._fbq = null; // eslint-disable-line no-underscore-dangle
    };
  }, [brandPixelId]);

  const brandScript = getTrackingScript('brand', brandId);
  useEffect(() => {
    if (brandScript) {
      const node = makeScriptNode({
        script: brandScript.code,
        id: 'squire-outer-brand-analytics',
      });
      document.head.appendChild(node);
    }

    return brandScript?.dispose ?? noop;
  }, [brandId, brandScript]);

  const shopScript = getTrackingScript('shop', shop?.id);
  useEffect(() => {
    if (shopScript) {
      const node = makeScriptNode({
        script: shopScript.code,
        id: 'squire-outer-shop-analytics',
      });
      document.head.appendChild(node);
    }

    return shopScript?.dispose ?? noop;
  }, [brandId, shopScript]);

  return null;
};
