import { useEffect, useMemo } from 'react';
import { useApiQuery } from '@water-web/api';
import { BarberModel, ShopModel } from '@water-web/repository';

import { BarberServiceConstructor } from '../../../apiServices';
import { useAppSettingsContext } from '../../../contexts';

interface UseBarbersResponse {
  loading: boolean;
  barbers: BarberModel[];
}

export const useShopBarbersList = (shop: ShopModel, shopIdOrRoute: string): UseBarbersResponse => {
  const appSettingsConfig = useAppSettingsContext();
  const barberService = useMemo(() => BarberServiceConstructor(appSettingsConfig.apiUrl), [appSettingsConfig]);

  const { data: barbers, isLoading } = useApiQuery({
    endpoint: barberService.getByShopId,
    payload: { shopIdOrRoute },
  });

  // this is the backward compatibility part
  // to make sure that we don't break anything in the places where we consume barbers from the shop model
  useEffect(() => {
    if (!shop) {
      return;
    }

    const areBarbersLoaded = !!barbers?.length;
    const wasShopConfigured = !!shop.getBarbers()?.length;

    if (areBarbersLoaded && !wasShopConfigured) {
      const shopBarbers = barbers.map((professional) => BarberModel.fromJson(professional));
      shop.setBarbers(shopBarbers);
    }
  }, [barbers, shopIdOrRoute, shop]);

  return useMemo(() => {
    if (!shop) {
      return { barbers: [], loading: true };
    }

    const result = (shop.isAnyBarberAvailable() ? [BarberModel.createAnyBarber()] : []).concat(
      (barbers ?? []).map((professional) => BarberModel.fromJson(professional)),
    );

    const haveSomeData = !!barbers;

    return {
      barbers: haveSomeData ? result : [],
      loading: !haveSomeData && isLoading,
    };
  }, [isLoading, barbers, shop]);
};
