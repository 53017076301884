import { QueryKey } from 'react-query';
import { CustomerPayload } from '@water-web/types';

export const queryKeys = {
  all: ['customer'],
  prepayOnlyByMatch: (payload: {
    shopId: string;
    barberId: string;
    customer: Omit<CustomerPayload, 'preferredLanguage'>;
  }): QueryKey => [...queryKeys.all, payload.shopId, payload.barberId, payload.customer],
};
