import { createContext, PropsWithChildren, useState, useMemo } from 'react';

import { isPaymentMethodCardLess, PaymentMethodCardNew, PaymentMethodCardLess } from '../types';

interface BankCardContextValue {
  cardInfo: PaymentMethodCardNew | undefined;
  cardlessInfo: PaymentMethodCardLess | undefined;
  setPaymentInfo: (card: PaymentMethodCardNew | PaymentMethodCardLess | undefined) => void;
}

export const BankCardContext = createContext<BankCardContextValue>({
  cardInfo: undefined,
  cardlessInfo: undefined,
  setPaymentInfo: () => undefined,
});

export const BankCardContextProvider = (props: PropsWithChildren) => {
  const [paymentMethodInfo, setPaymentMethodInfo] = useState<PaymentMethodCardNew | PaymentMethodCardLess>(undefined);
  const value: BankCardContextValue = useMemo(
    () => ({
      cardInfo: !isPaymentMethodCardLess(paymentMethodInfo) ? paymentMethodInfo : undefined,
      cardlessInfo: isPaymentMethodCardLess(paymentMethodInfo) ? paymentMethodInfo : undefined,
      setPaymentInfo: setPaymentMethodInfo,
    }),
    [paymentMethodInfo],
  );

  return <BankCardContext.Provider value={value}>{props.children}</BankCardContext.Provider>;
};
