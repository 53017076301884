import { CSSProperties } from 'react';

export const block: CSSProperties = { display: 'block' };

export const sizerWrapperStyles: CSSProperties = {
  ...block,
  position: 'absolute',
  left: '-20000px',
  width: '100%',
};

export const spreaderStyles: CSSProperties = {
  ...block,
  overflow: 'hidden',
  height: '0px',
  width: '100%',
};

export const wrapperStyles: CSSProperties = {
  ...block,
  position: 'relative',
  overflow: 'hidden',
};

export const childrenStyles: CSSProperties = {
  ...block,
  width: '100%',
};

const tagWhitelist = ['div', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'a'];

export const setTag = (tagName: string): string => {
  if (!tagName) {
    return 'div';
  }

  const foundTag = tagWhitelist.find((tag) => tagName === tag);
  return foundTag || 'div';
};
