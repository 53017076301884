import { PhaseConfig, PhaseHookArgs } from '../types';

export const success: PhaseConfig = {
  name: 'success',
  init: (params: PhaseHookArgs) => {
    params.resetNewCard();
    params.resetDeeplinkPromo();
    params.cartState.setLoader('success');
    setTimeout(() => {
      params.cartState.resetLoader();
    }, 3000);
  },
  next: () => {
    // not available
  },
  cancel: () => {
    // not available
  },
};
