import { BarberModel, CartCheckoutType } from '@water-web/repository';

import { shouldShowPolicies } from './utils';
import { PhaseConfig, PhaseHookArgs } from '../types';

export const initial: PhaseConfig = {
  name: 'initial',
  init: (params: PhaseHookArgs) => {
    const reservation = params.cartModel.getActiveReservation();
    if (reservation.getIsAnyBarber()) {
      reservation.setBarber(BarberModel.createAnyBarber());
    }

    params.setFallbackCheckoutPhase('initial');
    params.popupController.closeAll();

    params.cartModel.setCustomer(null);
    params.cartModel.setPaymentToken(null);
    params.cartModel.setPaymentMethod(null);
    params.cartModel.setPromoCode(null);
    params.cartModel.setGiftCardCode(null);
    params.cartModel.setCheckoutType(CartCheckoutType.bookAndPay);
  },
  next: (params: PhaseHookArgs) => {
    params.popupController.handlers.checkoutForm().preload();
    params.popupController.handlers.checkoutFormV2().preload();
    params.popupController.handlers.policies().preload();
    params.popupController.handlers.cardForm().preload();

    if (shouldShowPolicies(params)) {
      params.setCheckoutPhase('policies');
    } else {
      params.setCheckoutPhase('userInfo');
    }
  },
  cancel: () => {
    // do nothing
  },
};
