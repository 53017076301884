import {
  SaleOrderQuery,
  SaleOrderAppointmentRefundMutation,
  SaleOrderAppointmentRescheduleMutation,
  SaleOrderTipsMutation,
} from '@water-web/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SaleOrderServiceConstructor = (apiUrl: string) => ({
  getById: new SaleOrderQuery(apiUrl),
  refundAppointment: new SaleOrderAppointmentRefundMutation(apiUrl),
  rescheduleAppointment: new SaleOrderAppointmentRescheduleMutation(apiUrl),
  setTips: new SaleOrderTipsMutation(apiUrl),
});
