import { QueryKey } from 'react-query';
import { SaleOrder } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { queryKeys } from './queryKeys';

interface QueryPayload {
  id: string;
}

export class SaleOrderQuery extends EndpointQuery<SaleOrder, QueryPayload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: QueryPayload): QueryKey {
    return queryKeys.saleOrder(payload);
  }

  request(payload: QueryPayload, signal?: AbortSignal): Promise<SaleOrder> {
    return this.http.get(`/v1/customer-sale/${payload.id}`, {
      signal,
      resourceUrl: '/customer-sale/:id',
    });
  }
}
