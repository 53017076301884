import { ScheduleTimeRangeDay, Shop, Barber } from '@water-web/types';
import { ANY_BARBER_ID_AND_ROUTE, ANY_BARBER_OBJECT } from '@water-web/repository';
import { useApiQuery } from '@water-web/api';

import { PublicBarberConstructor, PublicShopConstructor } from '../../apiServices';
import { useAppSettingsContext } from '../../contexts';
import { isWaitlistAvailableForDay } from '../../utils';

type UseCanJoinWaitingListResult = { isLoading: boolean; canJoinWaitingList: boolean };

export const useCanJoinWaitingList = (
  shopId: string | null,
  barberId: string | null,
  scheduleDay: Pick<ScheduleTimeRangeDay, 'day' | 'availability'>,
): UseCanJoinWaitingListResult => {
  const appSettingsConfig = useAppSettingsContext();
  const publicShopService = PublicShopConstructor(appSettingsConfig.apiUrl);
  const publicBarberService = PublicBarberConstructor(appSettingsConfig.apiUrl);
  const isAnyBarber = barberId === ANY_BARBER_ID_AND_ROUTE;

  const { data: shop } = useApiQuery({
    endpoint: publicShopService.byId,
    payload: {
      idOrRoute: shopId,
    },
    options: {
      enabled: !!shopId,
    },
  });

  const { data: barberResponse } = useApiQuery({
    endpoint: publicBarberService.byId,
    payload: {
      shopIdOrRoute: shopId,
      idOrRoute: barberId,
    },
    options: {
      enabled: !!barberId && !isAnyBarber,
    },
  });

  const barber = isAnyBarber ? ANY_BARBER_OBJECT : barberResponse;

  if (!shop || !barber) {
    return {
      isLoading: true,
      canJoinWaitingList: false,
    };
  }

  return {
    isLoading: false,
    canJoinWaitingList: isWaitlistAvailableForDay(
      shop as unknown as Shop,
      barber as unknown as Barber,
      scheduleDay.availability,
      scheduleDay.day,
    ),
  };
};
