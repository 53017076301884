import { useMemo } from 'react';
import { format } from 'date-fns';
import { Modifiers, ModifiersUtils } from 'react-day-picker';

export enum Availability {
  unavailable = 0,
  low = 0.25,
  moderate = 0.5,
  high = 0.75,
  full = 1,
}

const AVAILABILITY_KEYS = new Set(Object.keys(Availability).filter((key) => !Number.isNaN(Number(key))));

const getDayKey = (day: Date) => format(day, 'yyyy-MM-dd'); // 2021-01-23

export interface UseAvailabilityMapResponse {
  has: (day: Date) => boolean;
  get: (day: Date) => number | undefined;
}

export const useAvailabilityMap = (modifiers?: Partial<Modifiers>): UseAvailabilityMapResponse => {
  return useMemo(() => {
    const map = new Map<string, number>();

    const getValue = (day: Date): number | undefined => {
      if (!modifiers) {
        return undefined;
      }

      const key = getDayKey(day);
      if (map.has(key)) {
        return map.get(key);
      }

      const dayModifiers = ModifiersUtils.getModifiersForDay(day, modifiers);
      const availability = dayModifiers.find((s) => AVAILABILITY_KEYS.has(s));
      const result = availability === undefined ? undefined : Number(availability);
      map.set(key, result);

      return result;
    };

    return {
      has: (day) => getValue(day) !== undefined,
      get: (day) => getValue(day),
    };
  }, [modifiers]);
};
