export const config = {
  dates: {
    timeFormat: 'h:mma', // 9:30AM
    shortDateFormat: 'LLL do', // Sep 16th
    fullDateFormat: 'iii, LLL do, h:mma', // Sun, Sep 1, 9:30am
  },
  brandIds: {
    gme: 'c1e4e77f-1868-41f0-a79f-b071edf9e603',
  },
  reserveWithGooglePartnerId: '10000116',
};
