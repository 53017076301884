import { useContext } from 'react';
import { useBoolean } from '@app/../../view';

import { Text, WithClickOutside } from '@app/components/atoms';
import { LocaleContext } from '@app/contexts';
import { AppLocale, LanguageLabels } from '@app/i18n';

import { FullTranslationIndicator } from './Indicator';
import { StyledButtonOld, Menu, MenuEntry, Container, StyledButton } from './styled';
import { Globe } from './icons/Globe';

interface Props {
  useOldStyle?: boolean;
}

export const LanguagePicker = (props: Props) => {
  const localeContext = useContext(LocaleContext);
  const [dropdownVisible, handlers] = useBoolean(false);

  const { supportedLanguages } = localeContext;

  const selectLanguage = (language: AppLocale) => () => {
    handlers.turnOff();
    localeContext.setLocale(language);
  };

  // No switcher if only single language supported!
  if (!supportedLanguages || Object.keys(supportedLanguages).length <= 1) {
    return null;
  }

  const commonProps = {
    $testId: 'btn:language-selector',
    onClick: handlers.toggle,
  };
  const localeLabel = localeContext.locale.toUpperCase();

  return (
    <WithClickOutside onClickOutside={handlers.turnOff}>
      <Container>
        {props.useOldStyle ? (
          <StyledButtonOld {...commonProps}>
            <Globe />

            <Text.P4Bold>{localeLabel}</Text.P4Bold>
          </StyledButtonOld>
        ) : (
          <Text.P4Bold as={StyledButton} {...commonProps}>
            <Globe />
            {localeLabel}
          </Text.P4Bold>
        )}

        {dropdownVisible && (
          <Menu $useOldStyle={props.useOldStyle}>
            {Object.entries(supportedLanguages || []).map(([lang, fullSupport]) => (
              <MenuEntry $testId={`lang:${lang}`} onClick={selectLanguage(lang as AppLocale)} key={lang}>
                {LanguageLabels[lang]}
                {!fullSupport && <FullTranslationIndicator locale={lang as AppLocale} />}
              </MenuEntry>
            ))}
          </Menu>
        )}
      </Container>
    </WithClickOutside>
  );
};
