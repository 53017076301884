import { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';

import { appLayers } from '@app/appLayers';

import { PopupContainer, Backdrop } from './styles';
import { PopupController } from './types';
import { ChunkLoadingErrorBoundary } from './ChunkLoadingErrorBoundary';

interface PopupProps {
  controller: PopupController;
}

export const Popup = (props: PopupProps) => {
  const {
    popup: primaryPopup,
    payload: primaryPayload,
    preset: primaryPreset,
    handler: primaryHandler,
    showBackdrop: primaryShowBackdrop,
    component: ActivePrimaryComponent,
  } = props.controller.primary;

  const {
    popup: secondaryPopup,
    payload: secondaryPayload,
    preset: secondaryPreset,
    handler: secondaryHandler,
    showBackdrop: secondaryShowBackdrop,
    component: ActiveSecondaryComponent,
  } = props.controller.secondary;

  const isPrimaryDropdownShown = primaryShowBackdrop && ActivePrimaryComponent && !ActiveSecondaryComponent;
  const isSecondaryDropdownShown = secondaryShowBackdrop && ActiveSecondaryComponent;
  const PrimaryPopupContainer = primaryPayload?.container ?? PopupContainer;
  const SecondaryPopupContainer = secondaryPayload?.container ?? PopupContainer;
  const hasPhoneCodePopup = primaryPopup === 'phoneCode' || secondaryPopup === 'phoneCode';
  const zIndex = hasPhoneCodePopup ? appLayers.stackSignIn + 1 : appLayers.popup;

  return (
    <>
      <ChunkLoadingErrorBoundary onError={() => primaryHandler?.close()}>
        <Suspense fallback={null}>
          <AnimatePresence mode="wait">{isPrimaryDropdownShown && <Backdrop $zIndex={zIndex} />}</AnimatePresence>
          <AnimatePresence mode="wait">
            {ActivePrimaryComponent && (
              <PrimaryPopupContainer key={primaryPopup} $preset={primaryPreset} $zIndex={zIndex}>
                <ActivePrimaryComponent
                  controller={props.controller}
                  onClose={primaryHandler.close}
                  handler={primaryHandler as any}
                  payload={primaryPayload as never}
                />
              </PrimaryPopupContainer>
            )}
          </AnimatePresence>
        </Suspense>
      </ChunkLoadingErrorBoundary>
      <ChunkLoadingErrorBoundary onError={() => secondaryHandler?.close()}>
        <Suspense fallback={null}>
          <AnimatePresence mode="wait">{isSecondaryDropdownShown && <Backdrop $zIndex={zIndex} />}</AnimatePresence>
          <AnimatePresence mode="wait">
            {ActiveSecondaryComponent && (
              <SecondaryPopupContainer key={secondaryPopup} $preset={secondaryPreset} $zIndex={zIndex}>
                <ActiveSecondaryComponent
                  controller={props.controller}
                  onClose={secondaryHandler.close}
                  handler={secondaryHandler as any}
                  payload={secondaryPayload as never}
                />
              </SecondaryPopupContainer>
            )}
          </AnimatePresence>
        </Suspense>
      </ChunkLoadingErrorBoundary>
    </>
  );
};
