import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import closeIcon from './close.svg';

export const Close = styled(JustIcon).attrs((props) => ({
  alt: 'close',
  icon: closeIcon,
  width: props.width || '24px',
  height: props.height || '24px',
}))`` as JustIconStyledComponent<IconStaticProps>;
