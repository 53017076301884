import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Box } from '@water-web/view';

import { Button, Text } from '@app/components/atoms';
import { getSpace } from '@app/components/utils';

export const Container = styled(Box)`
  position: relative;
  width: 57px;
  height: 26px;
`;

export const StyledButtonOld = styled(Box).attrs((props) => ({
  as: motion.div,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: props.theme.animations.booking.interaction,
}))`
  border: 1px solid #dadadc;
  border-radius: 16px;
  cursor: pointer;
  transition: 200ms linear all;
  height: 100%;

  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 999;

  svg {
    margin-right: ${getSpace(1)};
    height: 12px;
    width: 12px;
  }

  &:hover {
    background: #ffffff;
    border-color: #e1e1e1;
    box-shadow: 0px 8px 16px -5px rgba(0, 0, 0, 0.1);
  }
`;

export const StyledButton = styled(Button.Outlined)`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #dedee2;
  border-radius: 16px;
  padding: 0 12px;

  svg {
    height: 12px;
    width: 12px;
  }

  &:hover {
    background: #ffffff;
    border: 0.5px solid #e1e1e1;
    box-shadow: 0 8px 16px -5px rgba(0, 0, 0, 0.1);
  }
`;

export const Menu = styled(Box)<{ $useOldStyle?: boolean }>`
  position: absolute;
  top: calc(100% + ${getSpace(1)});
  ${({ $useOldStyle }) =>
    $useOldStyle
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}

  min-width: 96px;
  padding: ${getSpace(2)} 0;
  overflow: hidden;

  background: white;
  border: 0.5px solid #e1e1e1;
  border-radius: 16px;

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
`;

export const MenuEntry = styled(Text.P2Regular).attrs({ as: Button })`
  background: #ffffff;
  color: black;
  border: none;
  cursor: pointer;

  width: 100%;
  padding: ${getSpace(2)} ${getSpace(4)};
  text-align: left;
  border-radius: 0;

  transition: ${(props) => props.theme.animations.booking.interaction} linear all;
  position: relative;
  top: 0;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  &:hover {
    background: #f7f7f7;
  }
`;

export const IconContainer = styled.span`
  color: #ff3b30;
  padding: 8px;
  margin-right: -12px;
  position: relative;
  top: 3px;

  svg {
    transform: scale(0.75);
    transform-origin: center center;
  }
`;
