import './types';
import { persistentKVStore } from '@water-web/view';

import { AppLocale } from './locales';

export const getPersistedLocale = (): AppLocale => {
  let persistedLocale = AppLocale.en;

  // Using try catch instead of checking for existence of an object,
  // because we may actually have it defined but get a security error
  try {
    persistedLocale = persistentKVStore.getItem('SQUIRE_WIDGET_LOCALE') as AppLocale;
  } catch (e) {
    persistedLocale = (window.SQUIRE_WIDGET_LOCALE as AppLocale) || AppLocale.en;
  }

  return persistedLocale;
};
