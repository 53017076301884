import qs from 'qs';
import { ShopAddress, Shop } from '@water-web/types';

export const getShopAddressLines = (address: ShopAddress): [string, string] => {
  const stateAndZip = [address.state, address.zip].filter(Boolean).join(' ');
  return [[address.street, address.street2].filter(Boolean), [address.city, stateAndZip].filter(Boolean)].map(
    (lineArr) => lineArr.join(', '),
  ) as [string, string];
};

export const getShopDirectionsLink = (shop: Pick<Shop, 'address' | 'name'>, gMapsKey?: string): string => {
  const addressStr = [
    shop.address.street,
    shop.address.street2,
    shop.address.city,
    shop.address.state,
    shop.address.zip,
  ]
    .filter(Boolean)
    .join(', ');

  const query = {
    q: `${shop.name},${addressStr}`,
    key: gMapsKey,
    zoom: 16,
  };

  return `https://www.google.com/maps/place?${qs.stringify(query)}`;
};
