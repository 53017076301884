import { Box } from '@water-web/view';
import { motion } from 'framer-motion';
import styled, { css, StyledProps, Interpolation } from 'styled-components';
import { HeightProps, position, PositionProps, WidthProps } from 'styled-system';

import { SelectableProps, PlacementProps } from '@app/components/types';

export const Container = styled(Box)`
  position: relative;
  width: fit-content;
  height: fit-content;
`;

export const Initiator = styled(Box)`
  cursor: pointer;
`;

type Placement = 'top' | 'bottom' | 'left' | 'right';

type Alignment = 'start' | 'center' | 'end';

export interface ItemProps extends SelectableProps, Partial<PlacementProps<Placement>> {
  getBackgroundColor?: (props: StyledProps<ItemProps>) => string;
  noShowOnHover?: boolean;
  shadow?: boolean;

  /**
   * Alignment of tooltip relative to the hovered element
   */
  tooltipAlignment?: Alignment;
  /**
   * Alignment of arrow relative to the tooltip
   */
  arrowAlignment?: Alignment;

  /**
   * Arrow shift in px against alignment position;
   * Positive value will move arrow right/up,
   * negative will move it left/down
   */
  arrowShift?: number;

  /**
   * Gap in px between tooltip and element (not accounting arrow size)
   */
  tooltipOffset?: number;
  /**
   * Tooltip shift in px against alignment position;
   * Positive value will move tooltip right/up,
   * negative will move it left/down
   */
  tooltipShift?: number;

  /**
   * Maximal amount of pixels left till the right side of container
   */
  spaceToRightSide?: number;
}

const TOOLTIP_OFFSET_PX = 7;
const ARROW_WIDTH_PX = 12;
const ARROW_HEIGHT_PX = 4;

const X_AXIS_PLACEMENT: Placement[] = ['left', 'right'];

const shiftValue = (propsShiftValue: number, placement: Placement) =>
  X_AXIS_PLACEMENT.includes(placement) ? -propsShiftValue : propsShiftValue;
const arrowShiftValue = (props: ItemProps) => shiftValue(props.arrowShift || 0, props.placement);
const tooltipShiftValue = (props: ItemProps) => shiftValue(props.tooltipShift || 0, props.placement);

const mainAxisStart = (props: ItemProps) => {
  switch (props.tooltipAlignment) {
    case 'start':
      return 0;
    case 'end':
      return undefined;
    case 'center':
    default:
      return '50%';
  }
};

const mainAxisEnd = (props: ItemProps) => {
  switch (props.tooltipAlignment) {
    case 'end':
      return 0;
    default:
      return undefined;
  }
};

// eslint-disable-next-line complexity
const mainAxisTranslate = (props: ItemProps) => {
  switch (props.tooltipAlignment) {
    case 'start':
    case 'end':
      return `calc(min(${tooltipShiftValue(props)}px, ${
        props.spaceToRightSide
      }px - max(100%, ${props.spaceToRightSide}px)))`;
    case 'center':
    default:
      return `calc(-50% + ${tooltipShiftValue(props)}px)`;
  }
};

const tooltipOffsetPx = (props: ItemProps) => `${props.tooltipOffset ?? TOOLTIP_OFFSET_PX}px`;

// eslint-disable-next-line complexity
const arrowShiftPx = (props: ItemProps) => {
  switch (props.arrowAlignment) {
    case 'start':
      return `${arrowShiftValue(props)}px`;
    case 'end':
      return `calc(-100% + ${arrowShiftValue(props)}px)`;
    case 'center':
    default:
      return `calc(-50% + ${arrowShiftValue(props)}px)`;
  }
};

const arrowPosition = (props: ItemProps) => {
  switch (props.arrowAlignment) {
    case 'start':
      return `clamp(
        0px,
        max(100%, ${props.spaceToRightSide}px) - ${props.spaceToRightSide}px,
        ${tooltipShiftValue(props)}px + (100% - ${props.spaceToRightSide}px)
      )`;
    case 'end':
      return '100%';
    case 'center':
    default:
      return '50%';
  }
};

const itemYAxisStyles = css<ItemProps>`
  left: ${mainAxisStart};
  right: ${mainAxisEnd};

  &:after {
    left: ${arrowPosition};
    transform: translate3d(${arrowShiftPx}, 0, 0);
  }
`;

const itemTopStyles = css<ItemProps>`
  transform: translate3d(${mainAxisTranslate}, calc(-100% - ${tooltipOffsetPx}), 0);

  &:after {
    bottom: -${ARROW_HEIGHT_PX}px;
    border-width: ${ARROW_HEIGHT_PX}px ${ARROW_WIDTH_PX / 2}px 0;
    border-color: ${(props) => props.getBackgroundColor(props)} transparent transparent;
  }

  ${itemYAxisStyles}
`;

const itemBottomStyles = css<ItemProps>`
  transform: translate3d(${mainAxisTranslate}, ${tooltipOffsetPx}, 0);
  top: 100%;

  &:after {
    top: -${ARROW_HEIGHT_PX}px;
    border-width: 0 ${ARROW_WIDTH_PX / 2}px ${ARROW_HEIGHT_PX}px;
    border-color: transparent transparent ${(props) => props.getBackgroundColor(props)};
  }

  ${itemYAxisStyles}
`;

const itemXAxisStyles = css<ItemProps>`
  top: ${mainAxisStart};
  bottom: ${mainAxisEnd};

  &:after {
    top: ${arrowPosition};
    transform: translate3d(0, ${arrowShiftPx}, 0);
  }
`;

const itemLeftStyles = css<ItemProps>`
  transform: translate3d(calc(-100% - ${tooltipOffsetPx}), ${mainAxisTranslate}, 0);

  &:after {
    right: -${ARROW_HEIGHT_PX}px;
    border-width: ${ARROW_WIDTH_PX / 2}px 0 ${ARROW_WIDTH_PX / 2}px ${ARROW_HEIGHT_PX}px;
    border-color: transparent transparent transparent ${(props) => props.getBackgroundColor(props)};
  }

  ${itemXAxisStyles}
`;

const itemRightStyles = css<ItemProps>`
  transform: translate3d(${tooltipOffsetPx}, ${mainAxisTranslate}, 0);
  left: 100%;

  &:after {
    left: -${ARROW_HEIGHT_PX}px;
    border-width: ${ARROW_WIDTH_PX / 2}px ${ARROW_HEIGHT_PX}px ${ARROW_WIDTH_PX / 2}px 0;
    border-color: transparent ${(props) => props.getBackgroundColor(props)} transparent transparent;
  }

  ${itemXAxisStyles}
`;

const itemStyleByPlacement: Record<Placement, Interpolation<ItemProps>> = {
  top: itemTopStyles,
  bottom: itemBottomStyles,
  left: itemLeftStyles,
  right: itemRightStyles,
};

const itemShadow = css<ItemProps>`
  box-shadow: 0 40px 20px -20px rgba(0, 0, 0, 0.24);
`;

export const Item = styled(Box)<ItemProps>`
  position: absolute;
  min-width: 90px;
  background-color: ${(props) => props.getBackgroundColor(props)};
  z-index: 1;
  color: ${(props) => props.theme.colors.primaryFontOnPrimary};

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  ${(props) => itemStyleByPlacement[props.placement] || ''}

  ${(props) => props.shadow && itemShadow}
`;

Item.defaultProps = {
  placement: 'top',
  getBackgroundColor: (props) => props.theme.colors.primaryFonts[9],
  borderRadius: '8px',
};

export const ItemContainer = styled(Box).attrs(({ theme, ...props }: any) => ({
  as: motion.div,
  initial: {
    opacity: 0,
    y: props.fadeOnly ? 0 : -20,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: props.fadeOnly ? 0 : -20,
    transition: {
      duration: theme.animations.booking.interaction,
    },
  },
  transition: {
    duration: theme.animations.booking.interaction,
  },
}))<PositionProps & WidthProps & HeightProps & { fadeOnly?: boolean }>`
  position: absolute;
  z-index: 2;
  pointer-events: none;
  ${position};
`;
