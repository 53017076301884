import { addMinutes } from 'date-fns';
import { WaitingList, WaitingListTimeSlot, WaitingListStatus } from '@water-web/types';

import { getServiceFromWaitingList, getAddonsFromWaitingList, getTotalForTipsFromWaitingList } from '../getters';
import { BaseModel } from './base';
import { AddonModel } from './addon';
import { ServiceModel } from './service';
import { WaitingListItemModel } from './waiting_list_item';

export type WaitingListExtendedStatus = WaitingListStatus | 'active' | 'available';

export class WaitingListModel extends BaseModel<WaitingListModel> {
  protected dataValues: Readonly<WaitingList>;
  protected list: WaitingListItemModel[];
  protected service: ServiceModel;
  protected addons: AddonModel[];
  protected tipAmount: number;
  protected date: Date;
  protected personalItem: WaitingListItemModel;
  protected priceTotal: number;

  /**
   * this field is not a part of a waiting list
   * we get it from the list of available slots and set to the waiting list model for simplicity
   * @see WaitingListRepository.getTimeSlots
   */
  protected timeSlots: WaitingListTimeSlot[] = [];

  /**
   * this field is not a part of a waiting list
   * we get it from WaitingListAppointment and set to the waiting list model for simplicity
   * @see WaitingListRepository.acceptTimeSlot
   * @see WaitingListAppointment
   */
  protected saleOrderId: string = null;

  // waitingList is optional so it's still possible to create an empty model and populate later
  constructor(waitingList?: WaitingList) {
    super();

    if (waitingList) {
      this.populate(waitingList);
    }
  }

  getId(): string {
    return this.dataValues.id;
  }

  getWaitingListId(): string {
    return this.dataValues.waitingListId;
  }

  getCustomerId(): string {
    return this.dataValues.customerId;
  }

  getShopId(): string {
    return this.dataValues.shopId;
  }

  getBarberId(): string | undefined {
    return this.dataValues.barberId;
  }

  /**
   * @description
   * Returns date of a waiting list entry. Only the day matters since waiting list can't have time.
   */
  getDate(): Date {
    return this.date;
  }

  getDuration(): number {
    return this.dataValues.serviceDuration;
  }

  setTipAmount(tipAmount: number): WaitingListModel {
    this.tipAmount = tipAmount;
    return this;
  }

  getTipAmount(): number {
    return this.tipAmount;
  }

  getNumberInLine(): number {
    return this.dataValues.numberInLine;
  }

  getList(): WaitingListItemModel[] {
    return this.list;
  }

  getService(): ServiceModel {
    return this.service;
  }

  getAddons(): AddonModel[] {
    return this.addons;
  }

  /**
   * @description Returns true if there's a time slot available
   */
  canChooseTime(): boolean {
    return !!this.timeSlots.length;
  }

  setTimeSlots(timeSlots: WaitingListTimeSlot[]): WaitingListModel {
    this.timeSlots = timeSlots;
    return this;
  }

  getTimeSlots(): WaitingListTimeSlot[] {
    return this.timeSlots;
  }

  setSaleOrderId(saleOrderId: string): WaitingListModel {
    this.saleOrderId = saleOrderId;
    return this;
  }

  getSaleOrderId(): string {
    return this.saleOrderId;
  }

  getStatus(): WaitingListExtendedStatus {
    if (!this.dataValues.status) {
      return this.canChooseTime() ? 'available' : 'active';
    }

    return this.dataValues.status;
  }

  getPersonalItem(): WaitingListItemModel {
    return this.personalItem;
  }

  getPriceTotal(): number {
    return this.priceTotal;
  }

  populate(waitingList: WaitingList): WaitingListModel {
    this.dataValues = { ...waitingList };
    this.list = waitingList.list.map((item) => new WaitingListItemModel(item));
    this.service = new ServiceModel(getServiceFromWaitingList(waitingList));
    this.addons = getAddonsFromWaitingList(waitingList).map((addon) => new AddonModel(addon));
    this.tipAmount = waitingList.tipAmount;
    this.date = addMinutes(new Date(waitingList.day), new Date().getTimezoneOffset()); // make UTC day
    this.personalItem = this.getList().find((item) => item.getIsPersonal());
    this.priceTotal = getTotalForTipsFromWaitingList(waitingList) + this.getTipAmount();

    return this;
  }

  toJson(): Record<string, unknown> & WaitingList {
    return { ...this.dataValues };
  }

  getDataValues() {
    return this.dataValues;
  }
}
