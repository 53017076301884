import { persistentKVStore } from '../../persistence';
import { clientSideFlags, ClientSideFlags, DeploymentEnvironment, RemoteFlags } from './flags';

const overridesStorageKey = 'squireFlagOverrides';

export const getClientFlagOverrides = (): Partial<ClientSideFlags> => {
  if (typeof window === 'undefined') {
    return {};
  }

  let jsonString = '{}';

  try {
    const value = persistentKVStore.getItem(overridesStorageKey);
    jsonString = value !== null ? value : jsonString;
  } catch (error) {
    return {};
  }

  try {
    return JSON.parse(jsonString);
  } catch (err) {
    return {};
  }
};

export const setClientFlagOverride = (key: keyof ClientSideFlags, value: boolean): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const overrides = getClientFlagOverrides();
  const newOverrides = {
    ...overrides,
    [key]: value,
  };

  persistentKVStore.setItem(overridesStorageKey, JSON.stringify(newOverrides));
};

export const resetClientFlagOverrides = (): void => {
  if (typeof window === 'undefined') {
    return;
  }

  persistentKVStore.setItem(overridesStorageKey, JSON.stringify({}));
};

export const getRemoteFlagOverrides = (): Partial<RemoteFlags> => {
  if (typeof window === 'undefined') {
    return {};
  }

  try {
    const jsonString = persistentKVStore.getItem(overridesStorageKey) ?? 'false';
    return JSON.parse(jsonString);
  } catch (err) {
    return {};
  }
};

export const setRemoteFlagOverride = (key: keyof RemoteFlags, value: boolean): void => {
  if (typeof window === 'undefined') {
    return;
  }

  const overrides = getRemoteFlagOverrides();
  const newOverrides = {
    ...overrides,
    [key]: value,
  };

  persistentKVStore.setItem(overridesStorageKey, JSON.stringify(newOverrides));
};

export const resetRemoteFlagOverrides = (): void => {
  if (typeof window === 'undefined') {
    return;
  }

  persistentKVStore.setItem(overridesStorageKey, JSON.stringify(false));
};

type Callback = () => void;

export const watchClicksPerMs = (clicksRequired: number, ms: number, callback: Callback): void => {
  if (typeof window === 'undefined') {
    return;
  }

  let clickCount = 0;
  let timerId: NodeJS.Timeout | null = null;

  window.document.addEventListener('click', () => {
    clickCount += 1;

    if (timerId === null) {
      timerId = setTimeout(() => {
        if (clickCount >= clicksRequired) {
          callback();
        }

        clickCount = 0;
        clearTimeout(timerId as NodeJS.Timeout);
        timerId = null;
      }, ms);
    }
  });
};

export const mergeWithOverrides = (
  flags: typeof clientSideFlags,
  overrides: Partial<ClientSideFlags>,
  deploymentEnv: DeploymentEnvironment,
): ClientSideFlags => {
  return Object.keys(flags).reduce((memo, key) => {
    const flag = flags[key];
    let { value } = flag;

    if (flag.only) {
      value = flag.only.includes(deploymentEnv) ? flag.value : !flag.value;
    }

    if (typeof overrides[key] === 'boolean') {
      value = overrides[key];
    }

    return {
      ...memo,
      [key]: value,
    };
  }, {} as ClientSideFlags);
};

export const mergeWithRemoteOverrides = (flags: RemoteFlags, overrides: Partial<RemoteFlags>): RemoteFlags => {
  return Object.keys(flags).reduce((memo, key) => {
    let flagValue = flags[key];

    if (typeof overrides[key] === 'boolean') {
      flagValue = overrides[key];
    }

    return {
      ...memo,
      [key]: flagValue,
    };
  }, {} as RemoteFlags);
};

type Platform = 'desktop' | 'mobile' | 'widget';

export interface FlagsContextOptions {
  brandId?: string;
  platform: Platform;
}

type UserContext = { kind: 'user'; key: 'anonymous'; anonymous: true };
type BrandContext = { kind: 'brand'; key: string };
type PlatformContext = { kind: 'platform'; key: Platform };

type FlagsContext = {
  kind: 'multi';
  platform: PlatformContext;
  brand?: BrandContext;
  user?: UserContext;
};

export const buildContext = (options: FlagsContextOptions): FlagsContext => {
  const platformContext: PlatformContext = {
    kind: 'platform',
    key: options.platform,
  };

  if (options.brandId) {
    const brandContext: BrandContext = {
      kind: 'brand',
      key: options.brandId,
    };

    return {
      kind: 'multi',
      brand: brandContext,
      platform: platformContext,
    };
  }

  return {
    kind: 'multi',
    user: {
      kind: 'user',
      key: 'anonymous',
      anonymous: true,
    },
    platform: platformContext,
  };
};
