import i18next, { i18n } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { config } from '@app/config';

import { AppLocale } from './locales';
// will be bundled and used as a default locale to remove translation glitch
import en from '../../static/locales/en.json';
import fr from '../../static/locales/fr.json';
import { getPersistedLocale } from './getPersistedLocale';

let initExecuted = false;

export const geti18n = (): i18n => {
  if (!initExecuted) {
    const persistedLocale = getPersistedLocale();

    // Find maching locale in the AppLocale enum and use that or default to en
    const lng = Object.values(AppLocale).find((l) => l === persistedLocale) ?? AppLocale.en;

    i18next
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng,
        fallbackLng: AppLocale.en,
        ns: ['app'], // it's always one namespace
        defaultNS: 'app',
        backend: {
          loadPath: `${config.staticAssetsUrl}/locales/{{lng}}.json`,
        },
        react: {
          bindI18n: 'languageChanged',
          bindI18nStore: 'onAdded',
          useSuspense: false,
        },
        interpolation: {
          escapeValue: false,
        },
      });

    i18next.addResourceBundle(AppLocale.en, 'app', en);
    i18next.addResourceBundle(AppLocale.fr, 'app', fr);

    initExecuted = true;
  }

  return i18next;
};
