export const variants = {
  variants: {
    primary: {
      color: 'primaryFontColor',
      bg: 'primaryColor',
      textAlign: 'center',
      fontFamily: 'SF Pro Text',
      fontSize: '12px',
      height: '42px',
      borderRadius: '8px',
      cursor: 'pointer',
      border: 'none',
      fontWeight: '600',

      '&:disabled': {
        color: 'systemGray9',
        bg: 'systemGray2',
      },
    },
    secondary: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'systemGray1',
      bg: 'systemGray5',
      textAlign: 'center',
      fontFamily: 'SF Pro Text',
      fontSize: '12px',
      lineHeight: '16px',
      height: '24px',
      borderRadius: '24px',
      padding: '0 12px',
      cursor: 'pointer',
      border: 'none',
      fontWeight: '400',

      '&:disabled': {
        opacity: '0.6',
      },
    },
    outlined: {
      color: 'secondaryFont',
      bg: 'rgba(255, 255, 255, 0)',
      textAlign: 'center',
      fontFamily: 'SF Pro Text',
      fontSize: '12px',
      lineHeight: '28px',
      height: '30px',
      borderRadius: '15px',
      cursor: 'pointer',
      border: '1px solid',
      borderColor: 'buttonOutlinedBorder',
      fontWeight: '600',
      px: 4,
      py: 0,
      transition: 'background-color 300ms, border-color 300ms, box-shadow 300ms',

      '&:disabled': {
        opacity: '0.6',
      },

      '@media (hover: hover)': {
        '&:hover': {
          bg: 'rgba(255, 255, 255, 1)',
          borderColor: '#DADADC',
          boxShadow: '0px 8px 16px -5px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    empty: {
      bg: 'rgba(255,255,255,0)',
      textAlign: 'center',
      padding: 0,
      cursor: 'pointer',
      border: 'none',

      '&:disabled': {
        opacity: '0.6',
      },
    },
    info: {
      bg: 'rgba(255,255,255,0)',
      textAlign: 'center',
      cursor: 'pointer',
      border: 'none',
      height: '20px',
      padding: '5px',
      color: 'systemGray3',

      '@media (hover: hover)': {
        '&:hover': {
          color: 'linkColor',
        },
      },

      '&:disabled': {
        opacity: '0.6',
      },
    },
    close: {
      color: 'systemGray1',
      background: 'none',
      cursor: 'pointer',
      border: 'none',
      width: '44px',
      height: '44px',

      '&:disabled': {
        color: 'systemGrays1.6',
      },
    },
    back: {
      bg: 'rgba(255,255,255,0)',
      textAlign: 'center',
      padding: 0,
      cursor: 'pointer',
      border: 'none',
      width: '24px',
      height: '24px',
      opacity: 0.3,
      marginRight: '-2px',
      transform: 'translate(-8px, 1px)',

      '&:disabled': {
        opacity: '0.6',
      },
    },
    confirm: {
      color: 'primaryFontOnPrimary',
      bg: 'primaryColor',
      textAlign: 'center',
      fontFamily: 'SF Pro Text',
      fontSize: '12px',
      height: '42px',
      borderRadius: '8px',
      cursor: 'pointer',
      border: 'none',
      fontWeight: '600',

      '&:disabled': {
        opacity: '0.6',
      },
    },
    edit: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'primaryColor',
      cursor: 'pointer',
      border: 'none',
      height: '30px',
      width: '30px',
      color: 'primaryFontColor',
      borderRadius: '50%',

      '&:disabled': {
        bg: 'systemGray2',
        color: 'rgba(198, 198, 200, 1)',
      },
    },
    remove: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'secondaryFont',
      bg: 'unset',
      textAlign: 'center',
      fontFamily: 'SF Pro Text',
      borderRadius: '15px',
      cursor: 'pointer',
      border: '1px solid',
      borderColor: 'buttonOutlinedBorder',
      height: '30px',
      width: '30px',

      '&:disabled': {
        bg: 'systemGray2',
        color: 'rgba(198, 198, 200, 1)',
      },
    },
    widgetMenu: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      minWidth: '32px',
      height: '32px',
      padding: 0,
      background: 'rgba(255, 255, 255, 0)',
      border: '1px solid rgba(222, 222, 226, 1)',
      borderRadius: '16px',
      boxShadow: '0 4px 16px -5px rgba(0, 0, 0, 0)',
      cursor: 'pointer',

      '@media (hover: hover)': {
        '&:hover': {
          background: 'rgba(255, 255, 255, 1)',
          borderColor: 'rgba(225, 225, 225, 1)',
          boxShadow: '0px 4px 16px -5px rgba(0, 0, 0, 0.10)',
        },
      },

      '&:active': {
        backgroundColor: 'rgba(238, 238, 238, 1)',
      },
    },
    widgetClose: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32px',
      height: '32px',
      padding: 0,
      background: 'rgba(255, 255, 255, 0)',
      border: '1px solid rgba(222, 222, 226, 1)',
      borderRadius: '16px',
      cursor: 'pointer',

      '@media (hover: hover)': {
        '&:hover': {
          background: 'rgba(255, 255, 255, 1)',
          borderColor: 'rgba(225, 225, 225, 1)',
          boxShadow: '0px 4px 16px -5px rgba(0, 0, 0, 0.10)',
        },
      },

      '&:active': {
        backgroundColor: 'rgba(238, 238, 238, 1)',
      },
    },
  },
};
