import { ValidatedCartItem, AppointmentStatus, SaleOrderItem, SaleOrderSubItem } from '@water-web/types';

import { Price } from '../types';
import { reducePrices } from '../utils';
import { getPriceDraftFromSaleOrderSubItem } from './saleOrderSubItem';

export const getIsInitialFromSaleOrderItem = (saleOrderItem: ValidatedCartItem): boolean => {
  return saleOrderItem.appointment.status === AppointmentStatus.initial;
};

export const getIsCanceledFromSaleOrderItem = (saleOrderItem: ValidatedCartItem): boolean => {
  return saleOrderItem.appointment.status === AppointmentStatus.canceled;
};

/**
 * Will return human-friendly services price for draft breakdowns and collapsed views, where taxes and fees not visible
 */
export const getServicesPriceDraftFromSaleOrderItem = (saleOrderItem: SaleOrderItem): Price => {
  return reducePrices(saleOrderItem.subItems?.map(getPriceDraftFromSaleOrderSubItem));
};

export const getServiceFromSaleOrderItem = (saleOrderItem: SaleOrderItem): SaleOrderSubItem | null => {
  return saleOrderItem.subItems[0] ?? null;
};

export const getAddonsFromSaleOrderItem = (saleOrderItem: SaleOrderItem): SaleOrderSubItem[] => {
  return saleOrderItem.subItems.slice(1);
};
