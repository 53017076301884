/**
 * Safe with multibyte characters
 * Almost a polyfill for the missing String#[@iterator]
 */
export const getFirstCharacter = (str: string): string => {
  const firstCodeUnit = str[0];

  if (firstCodeUnit < '\uD800' || firstCodeUnit > '\uDFFF') {
    return firstCodeUnit;
  }

  return str.slice(0, 2);
};
