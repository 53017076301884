import { QueryKey } from 'react-query';
import { ClientSecret } from '@water-web/types';

import { EndpointQuery, EndpointQueryMeta } from '../../request';
import { queryKeys } from './queryKeys';

export class SetupIntentQuery extends EndpointQuery<ClientSecret, void> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getMeta(): EndpointQueryMeta<ClientSecret, void> {
    return {
      cacheTime: 0, // we must fetch new client secret every time
    };
  }

  getKey(): QueryKey {
    return queryKeys.setupIntent();
  }

  async request(): Promise<ClientSecret> {
    return this.http.get('/v1/payment/setup-intent', {
      resourceUrl: '/payment/setup-intent',
    });
  }
}
