const getLocale = (currency: string): string => {
  switch (currency.toUpperCase()) {
    case 'CAD':
      return 'en-CA';
    case 'USD':
      return 'en-US';
    case 'GBP':
      return 'en-UK';
    case 'EUR':
      return 'en-EU';
    default:
      return 'en-US';
  }
};

export const formatMoney = (
  cents: number,
  currency: string,
  precision = 2,
  noDecimalsIfInt = true,
  withCurrencySymbol = true,
): string => {
  const amountInCurrencyUnits = cents / 100;
  const locale = getLocale(currency);
  const isIntegerAmount = cents % 100 === 0;

  const format = {
    style: withCurrencySymbol ? 'currency' : 'decimal',
    minimumFractionDigits: noDecimalsIfInt && isIntegerAmount ? 0 : Math.min(2, precision),
    maximumFractionDigits: Math.min(2, precision),
  };

  return amountInCurrencyUnits.toLocaleString(locale, { ...format, currency });
};

interface MoneyBaseProps {
  children?: number | string;
  precision?: number;
  noDecimalsIfInt?: boolean;
}

type MoneyProps = MoneyBaseProps &
  (
    | {
        currency: string;
        withCurrencySymbol?: boolean;
      }
    | {
        currency?: string;
        withCurrencySymbol: false;
      }
  );

const MONEY_TYPES = new Set(['number', 'string']);

export const Money = (props: MoneyProps) => {
  const value = MONEY_TYPES.has(typeof props.children) ? props.children : 0;
  return (
    <>{formatMoney(Number(value), props.currency, props.precision, props.noDecimalsIfInt, props.withCurrencySymbol)}</>
  );
};

Money.defaultProps = {
  precision: 2,
  noDecimalsIfInt: true,
  currency: 'USD',
  withCurrencySymbol: true,
};
