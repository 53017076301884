import { QueryKey } from 'react-query';

import { Endpoint } from './Endpoint';
import { EndpointQueryMeta } from './types';

export abstract class EndpointQuery<ClientModel, RequestParams = void> extends Endpoint<ClientModel, RequestParams> {
  protected meta: EndpointQueryMeta<ClientModel, RequestParams> = {};

  getMeta(): EndpointQueryMeta<ClientModel, RequestParams> {
    return this.meta;
  }

  abstract getKey(payload?: RequestParams): QueryKey;
}
