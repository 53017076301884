import { QueryKey } from 'react-query';
import { Appointment, Shop, Barber } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  id: string;
}

interface Response extends Appointment {
  shop: Shop;
  barber: Barber;
}

export class AppointmentByIdQuery extends EndpointQuery<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.byId(payload);
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    return this.http.get<Response>(`/v1/appointment/${payload.id}`, {
      signal,
      resourceUrl: '/appointment/:id',
      search: { include: ['shop', 'shop.photos', 'shop.logo', 'shop.settings', 'barber', 'barber.photos'] },
    });
  }
}
