import { QueryKey } from 'react-query';

export const queryKeys = {
  all: ['notificationsConsent'],
  byCustomerId: (payload: { customerId: string; shopId: string }): QueryKey => [
    ...queryKeys.all,
    payload.customerId,
    payload.shopId,
  ],
  byMatch: (payload: {
    shopId: string;
    customer: {
      firstName?: string;
      lastName?: string;
      email?: string;
      phone?: string;
    };
  }): QueryKey => [...queryKeys.all, payload.shopId, payload.customer],
};
