import { GoogleDeepLinksConfig } from '@water-web/types';

import { BaseModel } from './base';

export class GoogleDeepLinksConfigModel extends BaseModel<GoogleDeepLinksConfigModel> {
  constructor(config: GoogleDeepLinksConfig[]) {
    super();

    this.dataValues = config;
  }

  toJson(): Record<string, unknown> {
    return this.dataValues;
  }
}
