import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components';
import { width, height, WidthProps, HeightProps } from 'styled-system';

export interface IconStaticProps {
  fill?: string;
  stroke?: string;
  testId?: string;
}

export interface IconDynamicProps extends IconStaticProps, WidthProps, HeightProps {}

interface StyledIconProps extends IconStaticProps {
  width: string;
  height: string;
  alt?: string;
  testId?: string;
}

const StyledIcon = styled.svg<StyledIconProps>`
  ${width}
  ${height}

  ${({ fill }) =>
    fill &&
    css`
      fill: ${fill};

      * {
        fill: ${fill} !important;
      }
    `}

  ${({ stroke }) =>
    stroke &&
    css`
      stroke: ${stroke};

      * {
        stroke: ${stroke} !important;
      }
    `}
`;

interface IconProps extends StyledIconProps {
  alt: string;
  testId?: string;
  icon?: React.SVGProps<SVGSVGElement>;
}

export const JustIcon = (props: IconProps) => {
  const { alt, icon, ...styledProps } = props;
  const { t } = useTranslation();
  const i18nKey = `iconAlt.${alt}` as Parameters<typeof t>[0];

  useEffect(() => {
    if (!icon) {
      // eslint-disable-next-line no-console
      console.warn(`No icon ${alt}`);
    }
  }, [icon]);

  if (!icon) {
    return null;
  }

  return (
    <StyledIcon
      {...styledProps}
      viewBox={icon.viewBox}
      alt={t(i18nKey)?.toString() ?? undefined}
      {...(props.testId && { 'data-testid': props.testId })}
    >
      <use xlinkHref={`#${icon.id}`} />
    </StyledIcon>
  );
};

export type JustIconStyledComponent<O extends object = object> = StyledComponent<'svg', DefaultTheme, O>;
