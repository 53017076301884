import { ValidatedCart, ValidateCartPayload, HttpPlatform, WithHttpPlatform } from '@water-web/types';

import { EndpointMutation, GetToken } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN, getAttachPlatformFn } from '../../utils';

interface Payload {
  shopId: string;
  cart: ValidateCartPayload;
}

export class CartCreateMutation extends EndpointMutation<ValidatedCart, Payload> {
  protected attachPlatform: ReturnType<typeof getAttachPlatformFn>;

  constructor(config: { baseUrl: string; platform: HttpPlatform; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.attachPlatform = getAttachPlatformFn(config.platform);
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<ValidatedCart> {
    return this.http.post<ValidatedCart, WithHttpPlatform<ValidateCartPayload>>(
      `/v2/shop/${payload.shopId}/cart`,
      this.attachPlatform(payload.cart),
      {
        headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
        resourceUrl: '/shop/:id/cart',
        signal,
      },
    );
  }
}
