import { Shop } from '@water-web/types';
import { AddonModel, BarberModel, LocationModel, ServiceModel } from '@water-web/repository';
import { AnalyticsEvent, CartContext } from '@water-web/view';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo } from 'react';

import { config } from '@app/config';
import { Analytics } from '@app/analytics';

import { useAnalyticsShopInfo } from '../useAnalyticsShopInfo';

type FnWrapper = <T extends (...args: any[]) => void>(fn: T) => (...args: Parameters<T>) => void;

const once: FnWrapper = (fn) => {
  let called = false;
  return (...args) => {
    if (!called) {
      called = true;
      fn(...args);
    }
  };
};

// do not allow tracking errors to break any actual functionality
const safeFn: FnWrapper = (fn) => {
  return (...args) => {
    try {
      fn(...args);
    } catch (e) {
      if (config.deploymentEnv === 'local') {
        console.error('Error tracking event', e);
      }
    }
  };
};

declare module '@water-web/view' {
  interface CommonPayload {
    brandId: string;
    shopId?: string;
    shop?: Shop;
  }
}

export const useUserActionsAnalytics = () => {
  const analytics = Analytics.getInstance();
  const { brandId, shop } = useAnalyticsShopInfo();
  const cartContext = useContext(CartContext);

  useEffect(() => {
    analytics.addCommonPayloadProp('brandId', brandId);
  }, [brandId]);

  useEffect(() => {
    analytics.addCommonPayloadProp('shopId', shop?.id ?? undefined);
    analytics.addCommonPayloadProp('shop', shop ?? undefined);
  }, [shop?.id]);

  return useMemo(() => {
    const trackPageview = (pageName: string): void => {
      analytics.page(pageName);
    };

    const trackSelectLocation = (location: LocationModel) => {
      analytics.track(AnalyticsEvent.SELECT_LOCATION, {
        shop: location.getName(),
      });
    };

    const trackSelectProfessional = (barber: BarberModel) => {
      if (barber.isAnyBarber()) {
        analytics.track(AnalyticsEvent.SELECT_ANY_PROFESSIONAL, { shop: shop?.name });
      } else {
        analytics.track(AnalyticsEvent.SELECT_PROFESSIONAL, {
          barber: `${barber.getFirstName()} ${barber.getLastName()}`,
          shop: shop?.name,
        });
      }
    };

    const trackSelectServices = (services: (ServiceModel | AddonModel)[]) => {
      const serviceNames = services.map((service) => service.getName()).join(', ');
      analytics.track(AnalyticsEvent.SELECT_SERVICES, {
        service: serviceNames,
        shop: shop?.name,
      });
    };

    const trackSelectDateAndTime = (dateTime: Date) => {
      analytics.track(AnalyticsEvent.SELECT_DATE_AND_TIME, {
        dateTime: format(dateTime, 'iii, LLL do, h:mma'), // Sun, Sep 1, 9:30am
        shop: shop?.name,
      });
    };

    const trackConfirmPayment = () => {
      analytics.track(AnalyticsEvent.CONFIRM_PAYMENT, { ...cartContext.cart.getCustomer().toJson() });
      analytics.trackCustomerInfo(cartContext.cart.getCustomer());
    };

    const trackPaymentSuccess = ({
      customerId,
      currency,
      totalWithTips,
      appointmentId,
    }: {
      customerId: string;
      currency: string;
      totalWithTips: number;
      appointmentId: string;
    }) => {
      analytics.track(AnalyticsEvent.PAYMENT_SUCCESS, {
        currency,
        totalWithTips,
        appointmentId,
      });
      analytics.identify(customerId);
    };

    const trackPaymentFailure = () => {
      analytics.track(AnalyticsEvent.PAYMENT_FAILURE);
    };

    const trackConfirmReservation = () => {
      analytics.track(AnalyticsEvent.CONFIRM_RESERVATION, { ...cartContext.cart.getCustomer().toJson() });
      analytics.trackCustomerInfo(cartContext.cart.getCustomer());
    };

    const trackReservationSuccess = ({
      customerId,
      currency,
      totalWithTips,
      appointmentId,
    }: {
      customerId: string;
      currency: string;
      totalWithTips: number;
      appointmentId: string;
    }) => {
      analytics.track(AnalyticsEvent.RESERVATION_SUCCESS, {
        currency,
        totalWithTips,
        appointmentId,
      });
      analytics.identify(customerId);
    };

    const trackReservationFailure = () => {
      analytics.track(AnalyticsEvent.RESERVATION_FAILURE);
    };

    const trackSelectWaitlist = () => {
      analytics.track(AnalyticsEvent.SELECT_WAITLIST);
    };

    const trackConfirmWaitlist = () => {
      analytics.track(AnalyticsEvent.CONFIRM_WAITLIST, { ...cartContext.cart.getCustomer().toJson() });
      analytics.trackCustomerInfo(cartContext.cart.getCustomer());
    };

    const trackWaitlistSuccess = (customerId: string) => {
      analytics.track(AnalyticsEvent.WAITLIST_SUCCESS);
      analytics.identify(customerId);
    };

    const trackWaitlistFailure = () => {
      analytics.track(AnalyticsEvent.WAITLIST_FAILURE);
    };

    const trackGcPurchaseSuccess = () => {
      analytics.track(AnalyticsEvent.GC_PURCHASE_SUCCESS);
    };

    const trackGuestAdd = () => {
      analytics.track(AnalyticsEvent.GUEST_ADD);
    };

    const trackTipAdd = (tip: { amount: number; percentage?: number }) => {
      analytics.track(AnalyticsEvent.TIP_ADD, {
        tip,
        currency: shop?.currency,
      });
    };

    const trackPaymentOpen = () => {
      analytics.track(AnalyticsEvent.PAYMENT_OPEN);
    };

    const trackBurgerOpen = () => {
      analytics.track(AnalyticsEvent.BURGER_CLICK);
    };

    const trackConfirmScreenSignInClick = () => {
      analytics.track(AnalyticsEvent.CONFIRM_BOOKING_SIGNIN_CLICK);
    };

    const trackSignupClick = () => {
      analytics.track(AnalyticsEvent.SIGNUP_CLICK);
    };

    const trackSignInClick = (type: 'email' | 'apple' | 'google') => {
      switch (type) {
        case 'google':
          analytics.track(AnalyticsEvent.GOOGLE_SIGNIN_CLICK);
          break;
        case 'apple':
          analytics.track(AnalyticsEvent.APPLE_SIGNIN_CLICK);
          break;
        default:
          analytics.track(AnalyticsEvent.SIGNIN_CLICK);
          break;
      }
    };

    const trackSignInSuccess = (type: 'email' | 'apple' | 'google') => {
      switch (type) {
        case 'google':
          analytics.track(AnalyticsEvent.GOOGLE_SIGNIN_SUCCESS);
          break;
        case 'apple':
          analytics.track(AnalyticsEvent.APPLE_SIGNIN_SUCCESS);
          break;
        default:
          analytics.track(AnalyticsEvent.EMAIL_SIGNIN_SUCCESS);
          break;
      }
    };

    const trackSignUpSuccess = () => {
      analytics.track(AnalyticsEvent.SIGNUP_SUCCESS);
    };

    const trackSignoutClick = () => {
      analytics.track(AnalyticsEvent.SIGNOUT_CLICK);
    };

    const trackCloseUserModalClick = (knownUser: boolean) => {
      if (knownUser) {
        analytics.track(AnalyticsEvent.KNOWN_USER_MODAL_CLOSE_CLICK);

        return;
      }

      analytics.track(AnalyticsEvent.USER_MODAL_CLOSE_CLICK);
    };

    const trackReviewEvent = (
      event:
        | AnalyticsEvent.REVIEW_SET_RATING
        | AnalyticsEvent.REVIEW_CLICK_SUBMIT_RATING
        | AnalyticsEvent.REVIEW_FOCUS_COMMENT
        | AnalyticsEvent.REVIEW_CLICK_SUBMIT_COMMENT
        | AnalyticsEvent.REVIEW_CLICK_SKIP
        | AnalyticsEvent.REVIEW_CLICK_CLOSE,
    ) => {
      analytics.track(event);
    };

    return {
      trackPageview: safeFn(trackPageview),
      trackSelectLocation: safeFn(trackSelectLocation),
      trackSelectProfessional: safeFn(trackSelectProfessional),
      trackSelectServices: safeFn(trackSelectServices),
      trackSelectDateAndTime: safeFn(trackSelectDateAndTime),

      trackConfirmPayment: safeFn(trackConfirmPayment),
      trackPaymentSuccess: once(safeFn(trackPaymentSuccess)), // conversion event
      trackPaymentFailure: safeFn(trackPaymentFailure),

      trackConfirmReservation: safeFn(trackConfirmReservation),
      trackReservationSuccess: once(safeFn(trackReservationSuccess)), // conversion event
      trackReservationFailure: safeFn(trackReservationFailure),

      trackSelectWaitlist: safeFn(trackSelectWaitlist),
      trackConfirmWaitlist: safeFn(trackConfirmWaitlist),
      trackWaitlistSuccess: once(safeFn(trackWaitlistSuccess)), // conversion event
      trackWaitlistFailure: safeFn(trackWaitlistFailure),

      trackGcPurchaseSuccess: once(safeFn(trackGcPurchaseSuccess)), // conversion event
      trackGuestAdd: safeFn(trackGuestAdd),
      trackTipAdd: safeFn(trackTipAdd),
      trackPaymentOpen: safeFn(trackPaymentOpen),

      trackBurgerOpen: safeFn(trackBurgerOpen),
      trackConfirmScreenSignInClick: safeFn(trackConfirmScreenSignInClick),
      trackSignupClick: safeFn(trackSignupClick),
      trackSignInClick: safeFn(trackSignInClick),
      trackSignoutClick: safeFn(trackSignoutClick),
      trackCloseUserModalClick: safeFn(trackCloseUserModalClick),
      trackSignInSuccess: safeFn(trackSignInSuccess),
      trackSignUpSuccess: safeFn(trackSignUpSuccess),

      trackReviewEvent: safeFn(trackReviewEvent),
    };
  }, [analytics, shop?.name, cartContext.cart?.getCustomer()?.toJson()]);
};
