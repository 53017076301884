import { useHistory } from 'react-router-dom';

export interface LocationState {
  isBack?: boolean;
}

/**
 * Returns typed History.
 * This is a workaround due to poor-ish `@types/history` types, prohibiting `LocationState` declaration merging.
 * @see https://www.typescriptlang.org/docs/handbook/declaration-merging.html
 * @see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/41568
 * @see https://github.com/microsoft/TypeScript/issues/4062
 */
export const useHistoryTyped = (): ReturnType<typeof useHistory<LocationState>> => {
  return useHistory<LocationState>();
};
