import styled from 'styled-components';

import { Box } from '../../atoms/Box';
import { PopupPosition } from './utils';

export const BotProtectedActionPopup = styled(Box)<PopupPosition>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  z-index: 99;
  padding: 8px;
  width: 316px;
  height: 81px;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
`;

export const BotProtectedActionPopupArrow = styled(Box)<PopupPosition>`
  position: absolute;
  top: ${(props) => props.arrowTop}px;
  left: ${(props) => props.arrowLeft}px;
  z-index: 99;
  transform: rotate(${(props) => props.arrowAngle}deg);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 6px 8px;
  border-color: transparent transparent #ffffff transparent;
`;

export const StandaloneProtectionContainer = styled(Box)`
  padding: 8px;
  width: 100%;
  height: 81px;
`;
