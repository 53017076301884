import { Shop } from '@water-web/types';

export const getTipPercentagesFromShop = (shop: Shop): number[] => {
  const defaultTipPercentages = [20, 25, 30, 35];

  return [shop.tipTier1, shop.tipTier2, shop.tipTier3, shop.tipTier4].map(
    (tip, idx) => tip || defaultTipPercentages[idx],
  ); // replace `0`s too, just like API does
};

/**
 * @deprecated use `shop.name` and `shop.alias` separately and explicitly: `name` first, `alias as a second line if present
 */
export const getAliasFromShop = (shop: Partial<Pick<Shop, 'alias' | 'name'>>): string | undefined => {
  return shop.alias || shop.name;
};

export const getCoverPhotoUrlFromShop = (shop: Pick<Shop, 'photos'>): string | undefined => {
  const photo = shop.photos?.[0];
  return photo?.thumbnail || photo?.url;
};

export const getHasActiveWaitingListFromShop = (shop: Pick<Shop, 'waitingListEnabled' | 'waiting_lists'>): boolean => {
  return !!shop.waitingListEnabled && !!shop.waiting_lists?.[0]?.enabled;
};
