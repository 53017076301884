import { Barber, BarberApiSearch } from '@water-web/types';

import { Repository } from './base';
import { BarberModel } from '../models';
import { RepositoryResponse } from '../utils';

export class BarberRepository extends Repository {
  getFromJson(json: Barber): BarberModel {
    return new BarberModel(json);
  }

  async getByIdOrRoute(shopIdOrRoute: string, idOrRoute: string): Promise<RepositoryResponse<BarberModel>> {
    const search: BarberApiSearch = {
      include: ['photos', 'days_on', 'days_off', 'reward', 'schedules'],
      sortBy: 'order:asc,createdAt:desc',
    };
    const response = await this.api.barber.getByIdOrRoute(shopIdOrRoute, idOrRoute, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<BarberModel>;
    }

    return {
      data: new BarberModel(response.data),
      error: null,
    } as RepositoryResponse<BarberModel>;
  }

  /**
   * @deprecated Use `ShopBarbersQuery` instead
   */
  async getAllByShopIdOrRoute(shopIdOrRoute: string): Promise<RepositoryResponse<BarberModel[]>> {
    const search: BarberApiSearch = {
      include: ['photos', 'days_on', 'days_off', 'reward', 'schedules'],
      sortBy: 'order:asc,createdAt:desc',
    };
    const response = await this.api.barber.getAllByShopIdOrRoute(shopIdOrRoute, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<BarberModel[]>;
    }

    return {
      data: response.data.map((professional) => BarberModel.fromJson(professional)),
      error: null,
    } as RepositoryResponse<BarberModel[]>;
  }

  createAnyBarber(): BarberModel {
    return BarberModel.createAnyBarber();
  }
}
