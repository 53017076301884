import { useContext } from 'react';

import { PopupContext } from './PopupManager';

/**
 * @returns level of popup in the stack, starts with 0.
 */
export const usePopupLevel = (stackName: string, id: string): number => {
  const { popups } = useContext(PopupContext);

  let level = 0;

  for (let i = 0; i < popups.length; i += 1) {
    if (popups[i].id === id) {
      return level;
    }
    if (popups[i].stackName === stackName) {
      level += 1;
    }
  }

  return level;
};
