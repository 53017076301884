import styled from 'styled-components';

import { Button } from '@app/components/atoms/Button';

export const WaitingRoomActionButton = styled(Button.Outlined)`
  width: 177px;
  height: 42px;
  display: block;
  text-decoration: none;
  line-height: 40px;
  border-radius: 8px;
`;

export const CloseButton = styled(Button.Close)`
  position: absolute;
  top: 14px;
  right: 6px;
  z-index: 2;
`;
