import { useEffect } from 'react';

import { useAnalyticsShopInfo, useUserActionsAnalytics } from '@app/hooks';

export const usePageviewAnalytics = (): void => {
  const { trackPageview } = useUserActionsAnalytics();
  const { brandId } = useAnalyticsShopInfo();

  useEffect(() => {
    // wait for brandId info to get loaded on app open
    if (brandId) {
      trackPageview(window.location.pathname);
    }
  }, [window.location.pathname, brandId]);
};
