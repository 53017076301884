import { css } from 'styled-components';

export interface FullwidthProps {
  fullwidth?: boolean;
}

export const fullwidth = (props: FullwidthProps): ReturnType<typeof css> => {
  return (
    props.fullwidth &&
    css`
      width: 100%;
    `
  );
};
