import styled from 'styled-components';
import {
  flexWrap,
  alignItems,
  flexDirection,
  justifyContent,
  FlexWrapProps,
  AlignItemsProps,
  FlexDirectionProps,
  JustifyContentProps,
  style,
} from 'styled-system';

import { Box, BoxProps } from '../Box';

const gap = style({
  prop: 'gap',
  cssProperty: 'gap',
});

const columnGap = style({
  prop: 'columnGap',
  cssProperty: 'columnGap',
});

const rowGap = style({
  prop: 'rowGap',
  cssProperty: 'rowGap',
});

export interface FlexProps extends BoxProps, FlexWrapProps, AlignItemsProps, FlexDirectionProps, JustifyContentProps {
  gap?: string;
  columnGap?: string;
  rowGap?: string;
}

export const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${alignItems}
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
  ${gap}
  ${columnGap}
  ${rowGap}
`;
