import { isSameDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ValidatedCartCardInfo, SaleOrder } from '@water-web/types';

import { getIsInitialFromSaleOrderItem, getTotalExactFromSaleOrder } from '../getters';
import { BaseModel } from './base';
import { ShopModel } from './shop';
import { ProductModel } from './product';
import { CustomerModel } from './customer';
import { GiftCardModel } from './gift_card';
import { ValidatedCartModel } from './validated_cart';
import { SaleOrderModelPopulator, Quantity } from '../utils';
import { SaleOrderReservationModel } from './sale_order_reservation';

export class SaleOrderModel extends BaseModel<SaleOrderModel> {
  private id: string = null;
  private reservations: SaleOrderReservationModel[] = [];
  private products: Quantity<ProductModel>[] = [];
  private customer: CustomerModel = null;
  private shop: ShopModel = null;
  private validatedCart: ValidatedCartModel = null;
  private saleGiftCards: GiftCardModel[] = [];

  constructor() {
    super();

    this.dataValues = {};
  }

  setId(id: string): SaleOrderModel {
    this.id = id;
    return this;
  }

  getId(): string {
    return this.id;
  }

  getReservations(): SaleOrderReservationModel[] {
    return this.reservations;
  }

  setReservations(reservations: SaleOrderReservationModel[]): SaleOrderModel {
    this.reservations = reservations;
    return this;
  }

  setProducts(products: Quantity<ProductModel>[]): SaleOrderModel {
    this.products = products;
    return this;
  }

  removeProduct(product: ProductModel): SaleOrderModel {
    this.products = this.products.filter((productWithQuantity) => {
      return productWithQuantity.getModel().getId() !== product.getId();
    });
    return this;
  }

  getProducts(): Quantity<ProductModel>[] {
    return this.products;
  }

  setCustomer(customer: CustomerModel): SaleOrderModel {
    this.customer = customer;
    return this;
  }

  getCustomer(): CustomerModel {
    return this.customer;
  }

  setShop(shop: ShopModel): SaleOrderModel {
    this.shop = shop;
    return this;
  }

  getShop(): ShopModel {
    return this.shop;
  }

  /**
   * @description
   * Sets gift cards sold to customer
   */
  setSaleGiftCards(giftCards: GiftCardModel[]): SaleOrderModel {
    this.saleGiftCards = giftCards;
    return this;
  }

  /**
   * @description
   * Returns gift cards sold to customer
   */
  getSaleGiftCards(): GiftCardModel[] {
    return this.saleGiftCards;
  }

  setValidatedCart(validatedCart: ValidatedCartModel): SaleOrderModel {
    this.validatedCart = validatedCart;
    return this;
  }

  getValidatedCart(): ValidatedCartModel {
    return this.validatedCart;
  }

  getCurrency(): string {
    return this.validatedCart.getCurrency();
  }

  getTotal(): number {
    return getTotalExactFromSaleOrder(this.validatedCart.getDataValues() as unknown as SaleOrder);
  }

  isReschedulable(now: Date): boolean {
    return this.reservations
      .filter((r) => getIsInitialFromSaleOrderItem(r.getDataValues()))
      .every((r) => r.isCancellable(now));
  }

  getIsValidReschedule(): boolean {
    const activeReservations = this.getReservations().filter((reservation) =>
      getIsInitialFromSaleOrderItem(reservation.getDataValues()),
    );

    const hasActive = !!activeReservations.length;
    if (!hasActive) {
      return false;
    }

    const hasUpdates = activeReservations.some((reservation) => {
      return reservation.hasUpdatedDateTime() || reservation.hasUpdatedBarber();
    });
    if (!hasUpdates) {
      return false;
    }

    const sampleReservation = activeReservations[0];
    const timezone = sampleReservation.getShop().getTimezone();
    const sampleDate = utcToZonedTime(sampleReservation.getDateTime(), timezone);

    return activeReservations.every((reservation) => {
      return isSameDay(sampleDate, utcToZonedTime(reservation.getDateTime(), timezone));
    });
  }

  getPaymentCardInfo(): ValidatedCartCardInfo | null {
    return this.validatedCart.getPaymentCardInfo();
  }

  getIsReservation(): boolean {
    return this.validatedCart.getIsReservation();
  }

  getIsBookNoPay(): boolean {
    return this.validatedCart.getIsBookNoPay();
  }

  populate(validatedCart: ValidatedCartModel): SaleOrderModel {
    SaleOrderModelPopulator.populateModelFromApiCart(this, validatedCart);
    return this;
  }

  toJson(): Record<string, unknown> {
    throw new Error('Not implemented');
  }
}
