import { parsePhoneNumber } from 'libphonenumber-js';

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const isUserInfoDifferent = (a: UserInfo | undefined, b: UserInfo | undefined): boolean => {
  if (!a || !b) {
    return true;
  }

  return a.firstName !== b.firstName || a.lastName !== b.lastName || a.email !== b.email || a.phone !== b.phone;
};

const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const isValidEmail = (email: string): boolean => {
  return !!EMAIL_REGEXP.test(email);
};

const PHONE_REGEXP = /^\+440\d{9}$/;
// extend this function whenever you need to implement a custom phone format validation
const isAlternativelyValidPhone = (phone: string): boolean => {
  return PHONE_REGEXP.test(phone);
};
const isValidPhone = (phone: string): boolean => {
  if (isAlternativelyValidPhone(phone)) {
    return true;
  }

  try {
    return parsePhoneNumber(phone).isValid();
  } catch (e) {
    return false;
  }
};

export const isUserInfoValid = (user: UserInfo | undefined): boolean => {
  if (!user || !user.email || !user.phone || !user.lastName || !user.firstName) {
    return false;
  }

  return isValidEmail(user.email) && isValidPhone(user.phone) && !!user.lastName && !!user.firstName;
};
