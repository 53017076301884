import { ValidatedCartItem, ValidatedCart, Service, AcquisitionChannel } from '@water-web/types';

import { Price } from '../types';
import { BaseModel } from './base';
import { BrandModel } from './brand';
import { ReservationModel } from './reservation';
import { ValidatedCartModel } from './validated_cart';
import { CartModelPopulator, Quantity, reducePrices } from '../utils';
import { ProductModel } from './product';
import { ShopModel } from './shop';
import { BarberModel } from './barber';
import { CustomerModel } from './customer';
import { ServiceModel } from './service';

export interface CartModelPopulationData {
  brand?: BrandModel;
  cart?: ValidatedCartModel;
  shop?: ShopModel;
  barbers?: BarberModel[];
  barber?: BarberModel;
  shopServices?: ServiceModel[];
  servicesByBarberId?: Record<string, ServiceModel[]>;
}

export enum CartCheckoutType {
  bookAndPay,
  bookNoCard,
  bookProvideCard,
}

export type CartPaymentMethod = 'digitalWallet' | 'cardEntry' | 'noPayment';

export class CartModel extends BaseModel<CartModel> {
  private brand: BrandModel = null;
  private reservations: ReservationModel[] = [];
  private activeReservation: ReservationModel = null;
  private validatedCart: ValidatedCartModel = null;
  // Error-like object to trigger effects even if message inside is the same
  private validationError: { message: string; code?: string } | null = null;
  private products: Quantity<ProductModel>[] = [];
  private validating = false;
  private promoCode: string | null = null;
  private giftCardCode: string | null = null;
  private paymentToken: string = null;
  private paymentCardId: string = null;
  private proceedWithExistingCard = false;
  private customer: CustomerModel = null;
  private checkoutType: CartCheckoutType = CartCheckoutType.bookAndPay;
  private paymentMethod: CartPaymentMethod = null;
  private acquisitionChannel: AcquisitionChannel | null = null;

  constructor() {
    super();

    this.dataValues.id = (Date.now() * Math.random()).toFixed(0);
  }

  clearReservations(): CartModel {
    this.setReservations([]);
    this.setActiveReservation(null);
    this.setValidatedCart(null);
    this.setValidationError(null);
    this.setProducts([]);
    this.setValidating(false);
    this.setPromoCode(null);
    this.setGiftCardCode(null);
    this.setPaymentToken(null);
    this.setPaymentCardId(null);
    this.setProceedWithExistingCard(false);
    this.setCustomer(null);
    this.setCheckoutType(CartCheckoutType.bookAndPay);

    return this;
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  setValidating(value: boolean): CartModel {
    this.validating = value;
    return this;
  }

  isValidating(): boolean {
    return this.validating;
  }

  setValidationError(value: { message: string }): CartModel {
    this.validationError = value;
    return this;
  }

  getValidationError(): { message: string; code?: string } {
    return this.validationError;
  }

  setBrand(brand: BrandModel): CartModel {
    this.brand = brand;
    return this;
  }

  getBrand(): BrandModel {
    return this.brand;
  }

  getReservations(): ReservationModel[] {
    return this.reservations;
  }

  setReservations(reservations: ReservationModel[]): CartModel {
    this.reservations = reservations;
    return this;
  }

  addReservation(): ReservationModel {
    const reservation = new ReservationModel();
    this.reservations.push(reservation);

    return reservation;
  }

  removeReservation(idOrModel: string | ReservationModel): CartModel {
    const id = typeof idOrModel === 'string' ? idOrModel : idOrModel.getId();
    const idx = this.reservations.findIndex((res) => res.getId() === id);

    if (idx !== -1) {
      this.reservations = [...this.reservations.slice(0, idx), ...this.reservations.slice(idx + 1)];
    }

    return this;
  }

  setActiveReservation(idOrModel: null | string | ReservationModel): CartModel {
    if (idOrModel === null) {
      this.activeReservation = null;
      return this;
    }

    const id = typeof idOrModel === 'string' ? idOrModel : idOrModel.getId();
    const reservation = this.reservations.find((res) => res.getId() === id);

    if (reservation) {
      this.activeReservation = reservation;
    }

    return this;
  }

  getActiveReservation(): ReservationModel {
    return this.activeReservation;
  }

  getValidatedCart(): ValidatedCartModel | null {
    return this.validatedCart;
  }

  setValidatedCart(validatedCart: ValidatedCartModel): CartModel {
    this.validatedCart = validatedCart;
    return this;
  }

  /**
   * @deprecated Turns out cart API does already support appointments with null-ish services/date.
   * Create cart earlier instead of using this method
   */
  getValidatedCartMock(): Readonly<ValidatedCart> {
    const shop = this.getActiveReservation()?.getShop()?.getDataValues();
    const zeroRange = {
      min: 0,
      max: 0,
    };

    return {
      id: '',
      createdAt: '',
      updatedAt: '',
      availableTips: {},
      canCheckOut: false,
      currency: shop?.currency || 'usd',
      items: this.reservations.map((res) => {
        const barber = (res.getIsAnyBarber() ? res.getAnyBarberCandidate() : res.getBarber())?.getDataValues() || null;
        const services = [res.getService(), ...res.getAddons()].filter(Boolean).map((item) => item.getDataValues());

        return {
          id: '',
          createdAt: '',
          updatedAt: '',
          barberId: barber?.id,
          barber,
          barberPasscode: res.getValidatedPasscode() || null,
          bookedFromWaitingList: false,
          bookedWithAnyBarber: res.getIsAnyBarber(),
          cost: 0,
          discountAmount: 0,
          name: 'new appointment',
          priceWithTaxes: 0,
          priceWithoutTaxes: 0,
          promoAmount: 0,
          quantity: 0,
          referenceId: '',
          taxAmount: 0,
          taxBeforeAdjustments: 0,
          tipAmount: 0,
          type: 'new_appointment',
          services: services.map(({ id }) => ({ id })),
          subItems: services.map((service) => ({
            type: 'service',
            id: service.id,
            name: service.name,
            duration: service.duration,
            adjustments: service.taxes.map((tax) => ({
              type: 'tax',
              addToPrice: tax.addToPrice,
              exclude: !tax.enabled,
              isFee: false,
              name: tax.name,
              rate: tax.percentage,
              referenceId: tax.id,
              amount: 0,
            })),
            totalWithoutTaxesRange: service.costWithoutTaxesRange,
            priceWithoutTaxes: service.costWithoutTaxes,
            totalRange: service.costWithTaxesRange,
            priceWithTaxes: service.costWithTaxes,
            bookedFromWaitingList: false,
            categoryName: (service as Service).categories?.[0]?.name ?? '',
            cost: 0,
            createdAt: '',
            description: service.desc,
            updatedAt: '',
            quantity: 1,
            order: service.order,
            promoAmount: 0,
            requiresPrepaid: service.requiresPrepaid,
            referenceId: '',
            taxBeforeAdjustments: 0,
            taxAmount: 0,
            tipAmount: 0,
            discountAmount: 0,
            rangeDisplayPrice: service.costRange,
          })),
          totalRange: zeroRange,
          totalWithoutTaxesRange: zeroRange,
        };
      }),
      payments: [],
      refunds: [],
      shopId: shop?.id || '',
      state: 'new',
      taxAmount: 0,
      tips: [],
      total: 0,
      totalBeforeDiscount: 0,
      totalForTip: 0,
      breakdown: {
        taxes: [],
        amountLeftForPayment: 0,
        customerBookingFee: 0,
        waitingListFee: 0,
        totalFee: 0,
        discountAmount: 0,
        promoAmount: 0,
        totalChargeAmount: 0,
        outstandingChargeAmount: 0,
        canBookNoPay: false,
        canReserve: false,
        totalRange: zeroRange,
        totalWithoutTaxesRange: zeroRange,
        breakdownPrice: 0,
        rangeDisplayPrice: zeroRange,
      },
      promoCode: '',
      paymentOutstanding: 0,
      new_appointments: this.reservations.map((res) => ({
        dateTime: res.getDateTime()?.toISOString() || null,
        barberId: (res.getIsAnyBarber() ? res.getAnyBarberCandidate()?.getId() : res.getBarber()?.getId()) || null,
        barberPasscode: res.getValidatedPasscode() || null,
        bookedWithAnyBarber: res.getIsAnyBarber(),
        services: [res.getService(), ...res.getAddons()].filter(Boolean).map((item) => ({ id: item.getId() })),
      })),
      shop,
    };
  }

  isValidatedCartOutdated(): boolean {
    if (!this.validatedCart) {
      return true;
    }

    if (this.reservations.length !== this.validatedCart.getReservations().length) {
      return true;
    }

    return this.reservations.some((res) => {
      const validatedRes = this.getValidatedReservation(res);
      if (!validatedRes) {
        return true;
      }

      const localServiceAddons = [res.getService(), ...res.getAddons()].filter(Boolean);
      if (localServiceAddons.length !== validatedRes.subItems.length) {
        return true;
      }

      return localServiceAddons.some((item, idx) => {
        return localServiceAddons[idx].getId() !== validatedRes.subItems[idx].referenceId;
      });
    });
  }

  addProduct(product: ProductModel): CartModel {
    const productWithQuantity: Quantity<ProductModel> = new Quantity(1, product);
    this.products = [...this.products, productWithQuantity];
    return this;
  }

  setProducts(products: Quantity<ProductModel>[]): CartModel {
    this.products = products;
    return this;
  }

  removeProduct(product: ProductModel): CartModel {
    this.products = this.products.filter((productWithQuantity) => {
      return productWithQuantity.getModel().getId() !== product.getId();
    });
    return this;
  }

  getProducts(): Quantity<ProductModel>[] {
    return this.products;
  }

  getProductsTotal(): number {
    return this.products.reduce((memo, product) => {
      return memo + product.getModel().getCost();
    }, 0);
  }

  resetProducts(): CartModel {
    this.products = [];
    return this;
  }

  incrementProduct(product: ProductModel): CartModel {
    const existingProduct = this.products.find((item) => {
      return item.getModel().getId() === product.getId();
    });

    const productWithQuantity = existingProduct || new Quantity<ProductModel>(0, product);
    if (!existingProduct) {
      this.products = [...this.products, productWithQuantity];
    }

    if (product.getAvailableQuantity() > productWithQuantity.getQuantity()) {
      productWithQuantity.increment();
    }

    this.products = [...this.products];

    return this;
  }

  decrementProduct(product: ProductModel): CartModel {
    const existingProduct = this.products.find((item) => {
      return item.getModel().getId() === product.getId();
    });

    if (!existingProduct) {
      return this;
    }

    existingProduct.decrement();
    if (existingProduct.getQuantity() <= 0) {
      this.products = this.products.filter((item) => item.getModel().getId() !== product.getId());
    }

    this.products = [...this.products];

    return this;
  }

  setPromoCode(code: string | null): CartModel {
    this.promoCode = code;
    return this;
  }

  getPromoCode(): string | null {
    return this.promoCode;
  }

  setGiftCardCode(code: string | null): CartModel {
    this.giftCardCode = code;
    return this;
  }

  getGiftCardCode(): string | null {
    return this.giftCardCode;
  }

  setPaymentCardId(paymentCardId: string): CartModel {
    this.paymentCardId = paymentCardId;

    return this;
  }

  getPaymentCardId(): string {
    return this.paymentCardId;
  }

  setProceedWithExistingCard(proceedToPayment: boolean): CartModel {
    this.proceedWithExistingCard = proceedToPayment;

    return this;
  }

  getProceedWithExistingCard(): boolean {
    return this.proceedWithExistingCard;
  }

  setPaymentToken(token: string): CartModel {
    this.paymentToken = token;
    return this;
  }

  getPaymentToken(): string {
    return this.paymentToken;
  }

  setCustomer(customer: CustomerModel): CartModel {
    this.customer = customer;
    return this;
  }

  getCustomer(): CustomerModel {
    return this.customer;
  }

  setDefaultTip(includeAnyBarberReservations = false): CartModel {
    this.reservations.forEach((reservation) => {
      if (!includeAnyBarberReservations && reservation.getIsAnyBarber()) {
        return;
      }

      reservation.setDefaultTipFromValidatedReservation(this.getValidatedReservation(reservation));
    });

    return this;
  }

  populate(data: CartModelPopulationData): CartModel {
    CartModelPopulator.populateModelFromApiCart(this, data);
    return this;
  }

  getIsValidReschedule(): boolean {
    if (!this.getReservations().length) {
      return false;
    }

    return this.getReservations().every((reservation) => reservation.getDateTime());
  }

  canApplyDiscount(): boolean {
    return (
      !this.getActiveReservation().getWaitingListDate() &&
      !!this.getActiveReservation().getDateTime() &&
      this.checkoutType === CartCheckoutType.bookAndPay
    );
  }

  getCheckoutType(): CartCheckoutType {
    return this.checkoutType;
  }

  setCheckoutType(value: CartCheckoutType): CartModel {
    this.checkoutType = value;
    return this;
  }

  getPaymentMethod(): CartPaymentMethod {
    return this.paymentMethod;
  }

  setPaymentMethod(value: CartPaymentMethod): CartModel {
    this.paymentMethod = value;
    return this;
  }

  /**
   * @description
   * returns false if either final duration or price are in range(happens only in any barber case)
   * true if final price and duration are fixed
   */
  arePriceAndDurationFinal(): boolean {
    return this.reservations.every((reservation) => reservation.arePriceAndDurationFinal());
  }

  getEstimatedTotal(): Price {
    const totals = this.getReservations().map((res) => res.getTotalRange());

    return reducePrices(totals);
  }

  getAcquisitionChannel(): AcquisitionChannel | null {
    return this.acquisitionChannel;
  }

  setAcquisitionChannel(value: AcquisitionChannel | null): CartModel {
    this.acquisitionChannel = value;
    return this;
  }

  toJson(): Record<string, unknown> {
    throw new Error('Not implemented');
  }

  getValidatedReservation(reservation: ReservationModel): ValidatedCartItem | null {
    const idx = this.reservations.findIndex((res) => res.getId() === reservation.getId());
    if (idx < 0) {
      return null;
    }

    return this.getValidatedCart()?.getReservations()[idx] || null;
  }

  getReservationByCartItem(cartItem: ValidatedCartItem): ReservationModel | null {
    const idx =
      this.getValidatedCart()
        ?.getDataValues()
        .items.findIndex((item) => item.id === cartItem.id) ?? -1;
    if (idx < 0) {
      return null;
    }

    return this.getReservations()[idx] ?? null;
  }
}
