import { QueryClient } from 'react-query';
import { ValidatedCartItem } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { buildBotProtectionHeaders } from '../../utils';
import { queryKeys } from './queryKeys';

interface MutationData {
  barberId: string;
  dateTime: string;
}

interface Payload extends MutationData {
  id: string;
  appointmentId: string;
  botProtectionToken?: string;
}

export class SaleOrderAppointmentRescheduleMutation extends EndpointMutation<ValidatedCartItem, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<ValidatedCartItem> {
    return this.http.put<ValidatedCartItem, MutationData>(
      `/v1/reschedule-appointment/${payload.appointmentId}`,
      {
        barberId: payload.barberId,
        dateTime: payload.dateTime,
      },
      {
        signal,
        headers: this.buildHeaders(buildBotProtectionHeaders(payload.botProtectionToken)),
        resourceUrl: '/reschedule-appointment/:id',
      },
    );
  }

  async afterMutationSuccess(queryClient: QueryClient, data: ValidatedCartItem, payload: Payload): Promise<void> {
    await queryClient.invalidateQueries(queryKeys.saleOrder({ id: payload.id }));
  }
}
