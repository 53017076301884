import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import {
  isWithinInterval,
  startOfDay,
  startOfMonth,
  max,
  subMonths,
  isBefore,
  isAfter,
  endOfMonth,
  addMonths,
  startOfWeek as _startOfWeek,
  addDays,
  endOfDay,
  isEqual,
} from 'date-fns';

import { CalendarController } from '../../types';
import { useStateWithRef } from '../useStateWithRef';

export const useCalendarController = (
  reservationInterval: { start: Date; end: Date },
  monthsToDisplay: number,
  weekDaysToDisplay: number,
  startOfWeek = _startOfWeek,
): CalendarController => {
  const reservationIntervalRef = useRef(reservationInterval);
  useEffect(() => {
    reservationIntervalRef.current = reservationInterval;
  }, [reservationInterval]);

  const [day, setDay, dayRef] = useStateWithRef<Date>(reservationInterval.start);
  const [week, setWeek] = useState<Date>(startOfWeek(reservationInterval.start));
  const [month, setMonth, monthRef] = useStateWithRef<Date>(startOfMonth(reservationInterval.start));
  const [direction, setDirection] = useState<CalendarController['direction']>(1);

  const monthMinMaxRef = useRef<{ min: Date; max: Date }>();
  const monthMinMax = useMemo(() => {
    // update on `reservationInterval` or `monthsToDisplay` change
    const minMonth = startOfMonth(reservationInterval.start);
    const maxMonth = max([minMonth, startOfMonth(subMonths(reservationInterval.end, monthsToDisplay - 1))]);
    monthMinMaxRef.current = {
      min: minMonth,
      max: maxMonth,
    };

    return monthMinMaxRef.current;
  }, [reservationInterval, monthsToDisplay]);

  const visibleWeekIntervalRef = useRef<{ start: Date; end: Date }>();
  useEffect(() => {
    visibleWeekIntervalRef.current = {
      start: week,
      end: endOfDay(addDays(week, weekDaysToDisplay - 1)),
    };
  }, [week, weekDaysToDisplay]);

  const visibleMonthsIntervalRef = useRef<{ start: Date; end: Date }>();
  useEffect(() => {
    visibleMonthsIntervalRef.current = {
      start: month,
      end: endOfMonth(addMonths(month, monthsToDisplay - 1)),
    };
  }, [month, monthsToDisplay]);

  // month change doesn't affect the day
  const setDisplayMonthWithinInterval = useCallback((newMonth: Date) => {
    if (monthRef.current === newMonth || isEqual(monthRef.current, newMonth)) {
      return;
    }
    if (isBefore(newMonth, monthMinMaxRef.current.min)) {
      setMonth(monthMinMaxRef.current.min);
      return;
    }
    if (isAfter(newMonth, monthMinMaxRef.current.max)) {
      setMonth(monthMinMaxRef.current.max);
      return;
    }
    setMonth(startOfMonth(newMonth));
  }, []);

  const goPreviousMonth = useCallback(() => {
    setDisplayMonthWithinInterval(subMonths(monthRef.current, 1));
    setDirection(-1);
  }, []);

  const goNextMonth = useCallback(() => {
    setDisplayMonthWithinInterval(addMonths(monthRef.current, 1));
    setDirection(1);
  }, []);

  // day change does affect the month
  const setSelectedDayWithinInterval = useCallback((newDay: Date) => {
    if (!isWithinInterval(newDay, reservationIntervalRef.current)) {
      return;
    }

    setDay(startOfDay(newDay));

    if (!isWithinInterval(newDay, visibleWeekIntervalRef.current)) {
      setWeek(startOfWeek(newDay)); // TODO: make the shop's start of the week
    }

    if (!isWithinInterval(newDay, visibleMonthsIntervalRef.current)) {
      setDisplayMonthWithinInterval(newDay);
    }
  }, []);

  useEffect(() => {
    // reset day on `reservationInterval` change
    if (!isWithinInterval(dayRef.current, reservationInterval)) {
      setDay(reservationInterval.start);
      setWeek(startOfWeek(reservationInterval.start)); // TODO: make the shop's start of the week
    }
  }, [reservationInterval]);

  useEffect(() => {
    // update month on `reservationInterval` or `monthsToDisplay` change
    setDisplayMonthWithinInterval(monthRef.current);
  }, [reservationInterval, monthsToDisplay]);

  return useMemo(
    () => ({
      selectedDay: day,
      setSelectedDay: setSelectedDayWithinInterval,
      displayWeek: week,
      displayMonth: month,
      canGoPreviousMonth: isBefore(monthMinMax.min, month),
      canGoNextMonth: isAfter(monthMinMax.max, month),
      goPreviousMonth,
      goNextMonth,
      direction,
    }),
    [day, week, month, monthMinMax, direction],
  );
};
