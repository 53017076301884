import { FC } from 'react';

export const omitProps = (keys: string[], Component: FC) => {
  return (props) => {
    const filteredProps = Object.keys(props).reduce((acc, key) => {
      if (!keys.includes(key)) {
        acc[key] = props[key];
      }
      return acc;
    }, {});

    return <Component {...filteredProps} />;
  };
};
