import Fonts from './fonts';
import DocumentStyles from './document';

const ComposedStyles = () => {
  return (
    <>
      <Fonts />
      <DocumentStyles />
    </>
  );
};

export default ComposedStyles;
