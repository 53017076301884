import styled from 'styled-components';
import {
  flex,
  color,
  order,
  space,
  width,
  height,
  display,
  borders,
  minWidth,
  maxWidth,
  overflow,
  fontSize,
  minHeight,
  textAlign,
  maxHeight,
  alignSelf,
  lineHeight,
  FlexProps,
  ColorProps,
  OrderProps,
  SpaceProps,
  WidthProps,
  HeightProps,
  DisplayProps,
  BordersProps,
  MinWidthProps,
  MaxWidthProps,
  OverflowProps,
  FontSizeProps,
  MinHeightProps,
  MaxHeightProps,
  AlignSelfProps,
  LineHeightProps,
  TextAlignProps,
} from 'styled-system';

import { TestIdProps, withTestIdProp } from '../../../utils';

export interface BoxProps
  extends TestIdProps,
    FlexProps,
    ColorProps,
    OrderProps,
    SpaceProps,
    WidthProps,
    HeightProps,
    DisplayProps,
    BordersProps,
    FontSizeProps,
    AlignSelfProps,
    LineHeightProps,
    MinHeightProps,
    MaxHeightProps,
    MinWidthProps,
    MaxWidthProps,
    Pick<OverflowProps, 'overflow'>,
    TextAlignProps {}

export const Box = styled('div').attrs(withTestIdProp)<BoxProps>(
  display,
  flex,
  color,
  order,
  space,
  width,
  height,
  borders,
  minWidth,
  maxWidth,
  fontSize,
  minHeight,
  maxHeight,
  overflow,
  alignSelf,
  lineHeight,
  textAlign,
);
