import { User } from '@water-web/types';
import { MutationKey } from 'react-query';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';
import { buildBotProtectionHeaders } from '../../utils';

type Payload = {
  user: {
    username: string;
    password: string;
  };
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  botProtectionToken?: string;
};

type Response = {
  token: string;
  user: User;
};

export class UserSignupByEmailMutation extends EndpointMutation<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(): MutationKey {
    return queryKeys.signup();
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    const { botProtectionToken, ...rest } = payload;
    return this.http.post('/v1/signup?include=customer', rest, {
      signal,
      headers: this.buildHeaders(buildBotProtectionHeaders(botProtectionToken)),
      resourceUrl: '/signup',
    });
  }
}
