import { FC, useRef, useEffect, useContext } from 'react';

import { PopupWrapperProps } from './typesExternal';
import { StackName } from './types';
import { PopupContext } from './PopupManager';
import { PopupWrapperDefault, PopupFadeDefault } from './PopupDefaultStyles';

/**
 * Used as a mount point for popups.
 * Will add its child `div` ref to the `PopupManager`.
 */
export const PopupStack = (props: {
  name: StackName;
  /**
   * A component, implementing fade in between popups. See `PopupFadeDefault` for example.
   */
  Fade?: FC;
  /**
   * A component, providing offset in a stack of popups. See `PopupWrapperDefault` for example.
   */
  Wrapper?: FC<PopupWrapperProps>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { stackRegisterEffect } = useContext(PopupContext);

  useEffect(
    () =>
      stackRegisterEffect({
        Wrapper: PopupWrapperDefault,
        Fade: PopupFadeDefault,
        ref,
        ...props,
      }),
    [props.name, props.Wrapper, props.Fade],
  );

  return <div ref={ref} />;
};
