import { QueryKey } from 'react-query';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';
import { AnyBarberSchedule } from './types';
import { getScheduleFetchIntervals } from './utils';
import { AnyBarberApptSchedulePayload } from './payloads';

const MAX_WEEKS_IN_REQUEST = 4;

export class AnyBarberApptScheduleQuery extends EndpointQuery<AnyBarberSchedule[], AnyBarberApptSchedulePayload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: AnyBarberApptSchedulePayload): QueryKey {
    return queryKeys.anyBarberApptSchedule(payload);
  }

  async request(payload: AnyBarberApptSchedulePayload, signal?: AbortSignal): Promise<AnyBarberSchedule[]> {
    const intervals = getScheduleFetchIntervals(payload.fromDate, payload.toDate, MAX_WEEKS_IN_REQUEST);

    const urlsToRequest = intervals.map(({ start, end }) =>
      ['/v1/shop', payload.shopId, 'schedule-time-range', start, end, payload.serviceIds.join(';')].join('/'),
    );

    const requests = urlsToRequest.map<Promise<AnyBarberSchedule[]>>((url) =>
      this.http.get(url, {
        headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
        signal,
        resourceUrl: '/shop/:shopId/schedule-time-range/:start/:end/:serviceIds',
      }),
    );

    const responses = await Promise.all(requests);

    return responses.reduce((acc, val) => acc.concat(val), []);
  }
}
