import { useEffect, useState } from 'react';
import { AvailableDigitalWallets, PaymentProcessor } from '@water-web/payments';

import { useBoolean } from '../useBoolean';

interface UseAvailableDigitalWalletsResult {
  initialized: boolean;
  digitalWallets: AvailableDigitalWallets | null;
}

export const useAvailableDigitalWallets = (processor?: PaymentProcessor): UseAvailableDigitalWalletsResult => {
  const [digitalWallets, setDigitalWallets] = useState(null);
  const [isInitialized, initHandlers] = useBoolean();

  useEffect(() => {
    if (processor) {
      const run = async () => {
        const availableDigitalWallets = await processor.getAvailableDigitalWallets();
        setDigitalWallets(availableDigitalWallets);
        initHandlers.turnOn();
      };

      run();
    }
  }, [processor]);

  return {
    digitalWallets,
    initialized: isInitialized,
  };
};
