export const countryCodeByCurrency = new Map([
  ['usd', 'US'],
  ['cad', 'CA'],
  ['gbp', 'GB'],
  ['aud', 'AU'],
  ['gip', 'GI'],
  ['eur', 'IE'],
]);

export const countryCodeByName = new Map([
  ['United States', 'US'],
  ['Canada', 'CA'],
  ['United Kingdom', 'GB'],
  ['Gibraltar', 'GI'],
  ['Australia', 'AU'],
  ['Ireland', 'IE'],
]);

export const getCountryCodeByName = (name: string): string => {
  return countryCodeByName.get(name) ?? countryCodeByName.get('United States');
};

export const currencySigns = new Map([
  ['usd', '$'],
  ['cad', '$'],
  ['gbp', '£'],
  ['aud', '$'],
  ['gip', '£'],
  ['eur', '€'],
]);

/**
 * @description Adds separators to any amount
 * @example prettifyAmount(10000); // outputs 10,000
 */
export const prettifyAmount = (amount: number, separator = ','): string => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};
