import { StripeDigitalWalletPaymentResult } from '@water-web/payments';

import { customerRepository } from '@app/repository';

import { PhaseHookArgs, CheckoutPhase } from '../types';

export const getProcessingPhaseName = (params: PhaseHookArgs): CheckoutPhase => {
  const reservation = params.cartModel.getActiveReservation();

  const isWaitingList = !!reservation.getWaitingListDate();
  if (isWaitingList) {
    return 'processingWaitingList';
  }

  const isGiftCard = !!reservation.getGiftCardSaleAmount();
  if (isGiftCard) {
    return 'processingGiftCard';
  }

  return 'processingBooking';
};

export const collectPaymentOrShowCardInput = async (params: PhaseHookArgs): Promise<void> => {
  if (params.cartModel.getPaymentMethod() === 'noPayment') {
    params.loadingHandlers.turnOff();
    params.inputBlockHandlers.turnOff();
    params.setCheckoutPhase(getProcessingPhaseName(params));
    return;
  }

  if (params.cartModel.getPaymentMethod() === 'digitalWallet') {
    const payment = (await params.collectPayment()) as StripeDigitalWalletPaymentResult; // reveal `cancelled` property

    params.loadingHandlers.turnOff();
    params.inputBlockHandlers.turnOff();

    if (payment.cancelled) {
      params.setCheckoutPhase(params.fallbackCheckoutPhase);
      return;
    }

    if (payment.error || !payment.token) {
      params.setCheckoutPhase('error');
      return;
    }

    params.cartModel.setPaymentToken(payment.token.id);
    params.setCheckoutPhase(getProcessingPhaseName(params));

    return;
  }

  if (params.newCard) {
    params.loadingHandlers.turnOff();
    params.inputBlockHandlers.turnOff();
    params.cartModel.setPaymentToken(params.newCard.token);
    params.setCheckoutPhase(getProcessingPhaseName(params));

    return;
  }

  if (params.cartModel.getPaymentCardId()) {
    params.loadingHandlers.turnOff();
    params.inputBlockHandlers.turnOff();
    params.cartModel.setProceedWithExistingCard(true);
    params.setCheckoutPhase(getProcessingPhaseName(params));

    return;
  }

  params.loadingHandlers.turnOff();
  params.inputBlockHandlers.turnOff();

  params.setCheckoutPhase('paymentInfo');
};

export const updateMarketingConsent = async (params: PhaseHookArgs, customerId: string): Promise<void> => {
  const shopId = params.cartModel.getActiveReservation()?.getShop().getId();
  const customer = params.cartModel.getCustomer();

  const update: Record<string, boolean> = {};
  if (customer.getSmsPromotionConsentAccepted() !== null) {
    update.phone = customer.getSmsPromotionConsentAccepted();
  }

  if (customer.getEmailPromotionConsentAccepted() !== null) {
    update.email = customer.getEmailPromotionConsentAccepted();
  }

  const didConsentsChange = Object.keys(update).length > 0;
  if (didConsentsChange) {
    const consentUpdate = await customerRepository.updateNotificationsConsent(customerId, shopId, {
      promotional: update,
    });

    if (consentUpdate.data) {
      customer.setNotificationConsents(consentUpdate.data);
    }
  }
};

export const shouldShowPolicies = (params: PhaseHookArgs): boolean => {
  const isGiftCardFlow = !!params.cartModel.getActiveReservation()?.getGiftCardSaleAmount();
  return !isGiftCardFlow;
};
