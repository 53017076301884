export function pipe<T1, R1>(fn1: (a: T1) => R1): (a: T1) => R1;
export function pipe<T1, R1, R2>(fn1: (a: T1) => R1, fn2: (a: R1) => R2): (a: T1) => R2;
export function pipe<T1, R1, R2, R3>(fn1: (a: T1) => R1, fn2: (a: R1) => R2, fn3: (a: R2) => R3): (a: T1) => R3;
export function pipe<T1, R1, R2, R3, R4>(
  fn1: (a: T1) => R1,
  fn2: (a: R1) => R2,
  fn3: (a: R2) => R3,
  fn4: (a: R3) => R4,
): (a: T1) => R4;
export function pipe<T1, R1, R2, R3, R4, R5>(
  fn1: (a: T1) => R1,
  fn2: (a: R1) => R2,
  fn3: (a: R2) => R3,
  fn4: (a: R3) => R4,
  fn5: (a: R4) => R5,
): (a: T1) => R5;

export function pipe(...fns: Array<(arg: unknown) => unknown>) {
  return <T>(input: T): unknown => fns.reduce((acc, fn) => fn(acc), input);
}
