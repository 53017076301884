import { useEffect } from 'react';
import { SnippetOptions } from '@fullstory/browser';

import { useFeatureFlags } from '../../contexts';

const initFullstory = async (options: SnippetOptions) => {
  const { init } = await import('@fullstory/browser');
  init(options);
};

interface Props {
  options: SnippetOptions;
}

/**
 * Will init Fullstory if `options.orgId` is provided, and `fullstorySampling` flag on LaunchDarkly is enabled.
 * Depends on `FeatureFlagsProvider`
 */
export const MountFullstory = (props: Props) => {
  const { fullstorySampling } = useFeatureFlags();
  const shouldInit = fullstorySampling && !!props.options.orgId;

  useEffect(() => {
    if (shouldInit) {
      initFullstory(props.options);
    }
  }, [props.options, shouldInit]);

  return null;
};
