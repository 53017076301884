import { AcquisitionChannel } from '@water-web/types';
import { CustomerModel } from '@water-web/repository';

import { AnalyticsEvent } from './events';

export interface AcquisitionChannelPayload {
  fromRolesPage?: boolean;
}

export interface Analytics {
  track(event: AnalyticsEvent, payload?: Record<string, unknown>): void;
  setAcquisitionChannel(channel: AcquisitionChannel, payload: AcquisitionChannelPayload): void;
}

/**
 * User-extendable interface
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CommonPayload {}

export interface Tracker {
  track?: <T extends CommonPayload>(analyticsEvent: AnalyticsEvent, payload: T) => void;
  trackCustomerInfo?: (customer: CustomerModel) => void;
  page?: (location: string, commonPayload: CommonPayload) => void;
  identify?: (customerId: string) => void;
  getName: () => string;
}

interface PaymentPayload {
  currency: string;
  totalWithTips: number;
  appointmentId: string;
}

export const isPaymentEvent = (event: AnalyticsEvent, payload: any): payload is CommonPayload & PaymentPayload =>
  event === AnalyticsEvent.PAYMENT_SUCCESS || event === AnalyticsEvent.RESERVATION_SUCCESS;

interface TipPayload {
  tip: { amount: number; percentage?: number };
  currency: string;
}

export const isTipEvent = (event: AnalyticsEvent, payload: any): payload is CommonPayload & TipPayload =>
  event === AnalyticsEvent.TIP_ADD;

interface WalletFailurePayload {
  error?: string;
}

export const isWalletFailureEvent = (
  event: AnalyticsEvent,
  payload: any,
): payload is CommonPayload & WalletFailurePayload => event === AnalyticsEvent.DEBUG_WALLETS_PAYMENT_FAILURE;
