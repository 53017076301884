import { useMemo, useContext } from 'react';
import { useApiQuery } from '@water-web/api';
import { ValidatedCart, SaleOrder, Shop, Brand } from '@water-web/types';
import { BrandModel, SaleOrderModel, ValidatedCartModel, ShopModel } from '@water-web/repository';
import { RepositoryContext } from '@water-web/view';

import { BrandHomeService, PublicShopService, SaleOrderService } from '@app/apiServices';

import { useFastRouteParams } from '../useFastRouteParams';
import { useSetBrandThemeEffect } from '../useSetBrandThemeEffect';

interface UseSaleOrderResult {
  isLoading: boolean;
  saleOrder?: SaleOrder;
  saleOrderModel?: SaleOrderModel;
  shop?: Shop;
  shopModel?: ShopModel;
  brand?: Brand;
  brandModel?: BrandModel;
}

export const useReservationModel = (): UseSaleOrderResult => {
  const { brandRepository } = useContext(RepositoryContext);
  const routeParams = useFastRouteParams<{ saleOrderId?: string }>();

  const { data: saleOrder, isLoading: isSaleOrderLoading } = useApiQuery({
    endpoint: SaleOrderService.getById,
    payload: { id: routeParams?.saleOrderId },
    options: { enabled: !!routeParams?.saleOrderId },
  });
  const { data: shop, isLoading: isShopLoading } = useApiQuery({
    endpoint: PublicShopService.byId,
    payload: {
      idOrRoute: saleOrder?.shopId,
    },
    options: {
      enabled: !!saleOrder?.shopId,
      select: (data) => data as unknown as Shop,
    },
  });
  const shopModel = useMemo(() => {
    return !shop ? null : new ShopModel(shop);
  }, [shop]);

  const { isLoading: isBrandLoading, data: brand } = useApiQuery({
    endpoint: BrandHomeService.byId,
    payload: {
      idOrRoute: saleOrder?.shop.brandId,
    },
    options: {
      enabled: !!saleOrder?.shop.brandId,
    },
  });
  const brandModel = useMemo(() => {
    return !brand ? null : new BrandModel(brand.brand, { brandRepository });
  }, [brand]);

  useSetBrandThemeEffect(saleOrder?.shop.brandId);

  const saleOrderModel = useMemo(() => {
    if (!saleOrder || !shopModel) {
      return null;
    }

    const model = new SaleOrderModel();
    // FIXME: SaleOrder is not a Cart
    model.populate(new ValidatedCartModel(saleOrder as unknown as ValidatedCart));
    model.setShop(shopModel);
    return model;
  }, [saleOrder, shopModel]);

  return {
    saleOrder,
    saleOrderModel,
    shop,
    shopModel,
    brand: brand?.brand,
    brandModel,
    isLoading: isSaleOrderLoading || isShopLoading || isBrandLoading,
  };
};
