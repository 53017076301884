import { css } from 'styled-components';

export interface MaxLinesProps {
  maxLines?: number;
}

export const maxLines = (props: MaxLinesProps): ReturnType<typeof css> =>
  Number.isSafeInteger(props.maxLines) &&
  css`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props.maxLines};
    -webkit-box-orient: vertical;
  `;
