import { persistentKVStore } from '../../persistence';
import { CookieConsentState, isValidConsentState } from '../../types';

export const COOKIE_CONSENT_KEY = 'squire_cookie_consent';

interface Strategy {
  read: () => Promise<string | null>;
  write: (newConsentState: CookieConsentState) => Promise<void>;
}

const localStrategy: Strategy = {
  read: async () => persistentKVStore.getItem(COOKIE_CONSENT_KEY),
  write: async (newConsentState: CookieConsentState) => {
    persistentKVStore.setItem(COOKIE_CONSENT_KEY, JSON.stringify(newConsentState));
  },
};

const parentStrategy: Strategy = {
  read: async (): Promise<string | null> => {
    // in case there's no response from parent, we'll resolve with null
    return Promise.race([
      new Promise<string | null>((resolve) => {
        const getCookieConsentListener = (event) => {
          if (event.data?.call === 'squire_pass_cookie_consent_to_iframe') {
            window.removeEventListener('message', getCookieConsentListener);
            resolve(event.data.value);
          }
        };

        window.addEventListener('message', getCookieConsentListener);
        window.parent?.postMessage({ call: 'squire_request_cookie_consent_from_parent' }, '*');
      }),

      new Promise<string | null>((resolve) => {
        setTimeout(() => resolve(null), 1000);
      }),
    ]) as Promise<string | null>;
  },
  write: async (newConsentState: CookieConsentState): Promise<void> => {
    window.parent?.postMessage(
      {
        call: 'squire_set_cookie_consent_to_parent',
        value: newConsentState,
      },
      '*',
    );
  },
};

const strategies = {
  local: localStrategy,
  parent: parentStrategy,
};

export class CookieConsentStorage {
  private strategy: Strategy;
  private activeVersion = 1;
  private validForDays = 30;

  constructor(strategy: 'local' | 'parent') {
    this.strategy = strategies[strategy];
  }

  private parseJsonString = (jsonString?: string) => {
    if (!jsonString) {
      return null;
    }

    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return null;
    }
  };

  private validate = (consent: CookieConsentState | null): boolean => {
    if (!consent) {
      return false;
    }

    if (!isValidConsentState(consent)) {
      return false;
    }

    if (consent.version !== this.activeVersion) {
      return false;
    }

    const consentDate = new Date(consent.date);
    const now = new Date();
    const diff = now.getTime() - consentDate.getTime();
    const diffInDays = diff / (1000 * 3600 * 24);

    if (diffInDays > this.validForDays) {
      return false;
    }

    return true;
  };

  public read = async (): Promise<CookieConsentState | null> => {
    const jsonString = await this.strategy.read();
    const json = this.parseJsonString(jsonString);

    if (this.validate(json)) {
      return json;
    }

    return null;
  };

  public write = async (newConsentState: CookieConsentState): Promise<void> => {
    return this.strategy.write(newConsentState);
  };
}
