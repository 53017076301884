import styled, { css } from 'styled-components';

import { Box, Flex, TextBase } from '../../atoms';
import { consentConfig } from './config';
import { CookieConsentProps } from './types';

export const Container = styled(Box)`
  position: relative;
  width: 456px;
  padding: 0;
  background-color: ${consentConfig.bgColor};
  box-shadow: 0px 24px 49px -16px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
  transition-property: height;
  transition-duration: ${consentConfig.animationDurationSec}s;

  @media (max-width: ${consentConfig.media.mobile}) {
    width: auto;
  }
`;

export const PositionedContainer = styled(Box)<CookieConsentProps>`
  position: fixed;
  z-index: 100;
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  ${({ right }) =>
    !!right &&
    css`
      right: ${right};
    `};
`;

export const Tabs = styled(Flex)`
  border-bottom: 1px solid ${consentConfig.borderColor};
  gap: 32px;
  padding: 0 16px;
`;

export const Tab = styled.button<{ active: boolean }>`
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  border: none;
  flex: 1;
  padding: 22px 24px 16px 24px;
  color: ${consentConfig.primaryTextColor};
  font-family: 'SF Pro Text';
  font-size: 14px;
  position: relative;
  bottom: -1px;
  z-index: 1;

  ${({ active }) =>
    active &&
    css`
      border-bottom: 1px solid rgb(0, 107, 178);
      font-weight: 600;
    `};

  @media (max-width: ${consentConfig.media.mobile}) {
    height: 42px;
    text-align: center;
    line-height: 42px;
    padding: 0;
    font-size: 11px;
    flex: none;
  }
`;

export const ConsentButton = styled.button`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border: 1px solid ${consentConfig.borderColor};
  transition-property: border-color, background-color, color;
  transition-duration: ${consentConfig.animationDurationSec}s;
  background-color: ${consentConfig.bgColor};
  color: ${consentConfig.primaryTextColor};
  cursor: pointer;
  user-select: none;
  border-radius: 8px;
  font-family: 'SF Pro Text';
  font-weight: 600;
  font-size: 12px;

  &:hover,
  &:active {
    color: ${consentConfig.invertedPrimaryTextColor};
    border-color: rgb(230, 230, 230);
    background-color: ${consentConfig.invertedBgColor};
  }

  @media (max-width: ${consentConfig.media.mobile}) {
    height: 32px;
    font-size: 10px;
    border-radius: 4px;
  }
`;

export const BodyText = styled(TextBase)`
  color: ${consentConfig.primaryTextColor};
  font-family: 'SF Pro Display';
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${consentConfig.media.mobile}) {
    font-size: 10px;
    line-height: 13px;
    font-weight: 400;
    font-family: 'SF Pro Text';
  }
`;

export const ActionsContainer = styled(Flex)`
  padding: 0 24px 32px 24px;
  gap: 16px;

  @media (max-width: ${consentConfig.media.mobile}) {
    padding: 0 16px 16px 16px;
    gap: 8px;
  }
`;

export const TabContent = styled(Flex)`
  padding: 32px 24px;

  @media (max-width: ${consentConfig.media.mobile}) {
    padding: 16px;
  }
`;

export const AboutTabContent = styled(TabContent)`
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${consentConfig.media.mobile}) {
    gap: 8px;
  }
`;
