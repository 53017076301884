import {
  HttpResponse,
  WaitingListApi as IWaitingListApi,
  WaitingList,
  WaitingListApplicationSearch,
  WaitingListSlot,
  WaitingListAppointment,
} from '@water-web/types';

import { SERVICE_ID_V2_HEADER_MIXIN } from '../utils';
import { Namespace } from './namespace';

export class WaitingListApi extends Namespace implements IWaitingListApi {
  getById(id: string, search?: WaitingListApplicationSearch): Promise<HttpResponse<WaitingList>> {
    return this.http.get(`/customer/waiting-list/${id}`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/customer/waiting-list/:id',
    });
  }

  removeById(id: string): Promise<HttpResponse<{ ok: boolean }>> {
    return this.http.delete(`/customer/waiting-list/${id}`, {
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/customer/waiting-list/:id',
    });
  }

  changeTip(id: string, tipAmount: number): Promise<HttpResponse<WaitingList>> {
    return this.http.put(
      `/customer/waiting-list/${id}`,
      { tipAmount },
      {
        headers: SERVICE_ID_V2_HEADER_MIXIN,
        resourceUrl: '/customer/waiting-list/:id',
      },
    );
  }

  getTimeSlots(id: string, slotId: string): Promise<HttpResponse<WaitingListSlot>> {
    return this.http.get(`/customer/waiting-list-slot-times/${id}/${slotId}`, {
      resourceUrl: '/customer/waiting-list-slot-times/:id/:slotId',
    });
  }

  acceptTimeSlot(id: string, timeSlotId: string): Promise<HttpResponse<WaitingListAppointment>> {
    const search = {
      waitingListCustomerId: id,
      waitingListSlotId: timeSlotId,
      platform: 'Web',
    };
    return this.http.post('/book-appointment-time-slot', search, {
      resourceUrl: '/book-appointment-time-slot',
    });
  }

  async reschedule(id: string, dateTime: string, barberId: string): Promise<HttpResponse<WaitingListAppointment>> {
    const search = { dateTime, barberId };
    return this.http.post(`/book-from-waiting-list/${id}`, search, {
      resourceUrl: '/book-from-waiting-list/:id',
    });
  }
}
