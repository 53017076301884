import { createContext } from 'react';
import enUS from 'date-fns/locale/en-US';

import { AppLocale } from '@app/i18n';
import { LocaleSupport } from '@app/types';

import { AppTFunction } from './types';
import { getDateFormats } from './getDateFormats';
import { getTimeFormat } from './getTimeFormat';

export interface LocaleContextValue {
  readonly locale: AppLocale;
  readonly setLocale: (locale: AppLocale) => void;
  readonly t: AppTFunction;
  readonly dateFnsLocale: Locale;
  readonly supportedLanguages: LocaleSupport;
  readonly setSupportedLanguages: (langs: LocaleSupport) => void;
  readonly dateFormats: ReturnType<typeof getDateFormats>;
  readonly timeFormat: ReturnType<typeof getTimeFormat>;
}

export const LocaleContext = createContext<LocaleContextValue>({
  locale: AppLocale.en,
  dateFnsLocale: enUS,
  setLocale: () => null,
  supportedLanguages: {},
  setSupportedLanguages: () => null,
  t: () => null,
  dateFormats: getDateFormats((k) => k), // just make the right shape
  timeFormat: 'h:mma',
});
