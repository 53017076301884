import { ValidatedPasscode } from '@water-web/types';

import { EndpointMutation } from '../../request';

export interface ValidatePasscodePayload {
  barberId: string;
  passcode: string;
}

export class ValidatePasscodeMutation extends EndpointMutation<ValidatedPasscode, ValidatePasscodePayload> {
  constructor(baseUrl: string) {
    super();

    this.baseUrl = baseUrl;
  }

  request(payload: ValidatePasscodePayload, signal?: AbortSignal): Promise<ValidatedPasscode> {
    return this.http.post(
      `/v1/barber/validate/${payload.barberId}`,
      {
        passcode: payload.passcode,
      },
      { signal, resourceUrl: '/barber/validate/:id' },
    );
  }
}
