import { RequestLifecyclePhase, RequestLifecycleInfo, RequestLifecycleEvent } from './types';

export const HttpTransportEvent = {
  start: 'squire-http-request-start',
  success: 'squire-http-request-success',
  error: 'squire-http-request-error',
} as const;

const dispatchEvent = (phase: RequestLifecyclePhase, info: RequestLifecycleInfo): void => {
  const eventName: RequestLifecycleEvent = HttpTransportEvent[phase];
  try {
    // Using trycatch instead of is-defined check, because in IE it is defined but doesn't work.
    // CustomEvent nor window don't exist in nextjs SSR context
    const event = new CustomEvent(eventName, { detail: info });
    window.dispatchEvent(event);
  } catch (e) {
    // Do nothing, just don't dispatch event
  }
};

export const notifyRequestStart = (info: RequestLifecycleInfo): void => {
  dispatchEvent('start', info);
};

export const notifyRequestSuccess = (info: RequestLifecycleInfo): void => {
  dispatchEvent('success', info);
};

export const notifyRequestError = (info: RequestLifecycleInfo): void => {
  dispatchEvent('error', info);
};

export const downloadFromBlob = (blob: Blob, fileName: string): void => {
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
