import { Addon } from '@water-web/types';

import { Price } from '../types';
import {
  getCostRangeFromService,
  getDurationRangeFromService,
  getPriceExactFromService,
  getPriceDraftFromService,
} from '../getters';
import { BaseModel } from './base';

export class AddonModel extends BaseModel<AddonModel> {
  /**
   @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
   Figure out the cost without tax, once we have addons implemented
   */
  protected dataValues: Readonly<Addon>;

  constructor(addon: Addon) {
    super();

    this.dataValues = { ...addon };
  }

  static fromJson(addon: Addon): AddonModel {
    return new AddonModel(addon);
  }

  getId(): string {
    return this.dataValues.id;
  }

  getName(locale?: string): string {
    return this.dataValues.localizedName?.[locale] || this.dataValues.name;
  }

  getDuration(): number {
    return this.dataValues.duration;
  }

  /**
   * @description returns a minimal service duration or default cost if range is not present
   */
  getMinDuration(): number {
    return getDurationRangeFromService(this.dataValues).min;
  }

  /**
   * @description returns a maximal service duration or default cost if range is not present
   */
  getMaxDuration(): number {
    return getDurationRangeFromService(this.dataValues).max;
  }

  /**
   * Will return precise service price for detailed breakdown with taxes in a separate line
   */
  getPriceExact(): Price {
    return getPriceExactFromService(this.dataValues);
  }

  /**
   * Will return human-friendly service price for draft breakdowns and collapsed views, where taxes and fees not visible
   */
  // eslint-disable-next-line complexity
  getPriceDraft(): Price {
    return getPriceDraftFromService(this.dataValues);
  }

  getCost(): number {
    /**
     @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
     Figure out the cost, once we have addons implemented
     */
    return this.dataValues.cost;
  }

  /**
   * @description returns a minimal service cost or default cost if range is not present
   */
  getMinCost(): number {
    return getCostRangeFromService(this.dataValues).min;
  }

  /**
   * @description returns a maximal service cost or default cost if range is not present
   */
  getMaxCost(): number {
    return getCostRangeFromService(this.dataValues).max;
  }

  /**
   @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
   Figure out the cost with tax, once we have addons implemented
   */
  getCostForTips(): number {
    return this.dataValues.costWithTaxes;
  }

  getDescription(locale?: string): null | string {
    return this.dataValues.localizedDesc?.[locale] || this.dataValues.desc;
  }

  isPrepaidOnly(): boolean {
    return this.dataValues.requiresPrepaid;
  }

  /**
   * @description
   * returns false if service may have different price and duration based on selected barber
   * true if price and duration will not change regardless a barber
   */
  arePriceAndDurationFinal(): boolean {
    return this.getMinDuration() === this.getMaxDuration() && this.getMinCost() === this.getMaxCost();
  }

  getDataValues() {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
