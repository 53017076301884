import { camelCaseKeys } from 'launchdarkly-react-client-sdk';

type HyphenToCamelCase<S extends string> = S extends `${infer A}-${infer B}`
  ? B extends `${infer C}-${infer D}`
    ? `${A}${Capitalize<HyphenToCamelCase<B>>}`
    : `${A}${Capitalize<B>}`
  : S;

type CamelCaseKeys<T> = {
  [K in keyof T as HyphenToCamelCase<K extends string ? K : never>]: T[K];
};

export type DeploymentEnvironment = 'local' | 'development' | 'qa' | 'demo' | 'staging' | 'production';

interface ClientSideFlag {
  value: boolean;
  only?: DeploymentEnvironment[];
}

// validators purely cater TypeScript and do no actual work.
// instead of defining types with flag keys and objects that implement them,
// validator functions type objects with a generic type(FlagType) while keeping a limited set of allowed keys.
const createFlagValidator =
  <FlagType>() =>
  <T>(v: { [K in keyof T]: FlagType }) =>
    v;

export const clientSideFlags = createFlagValidator<ClientSideFlag>()({
  wowUseBranding: {
    value: false,
    only: ['qa', 'staging', 'demo', 'production'],
  },
});

export const remoteFlags = createFlagValidator<boolean>()({
  'check-if-human': false,
  'checkout-v2': false,
  'hide-apple-pay': false,
  'announce-group-appointments': false,
  'fullstory-sampling': false,
  'web-login': false,
  'cookie-consent': false,
  'flexible-tipping': false,
  'client-reviews': false,
  'mobile-checkout-v-2': false,
  'wow-track-rwg-tokens': false,
  'client-reviews-v2': false,
});

export type RemoteFlags = CamelCaseKeys<{ [K in keyof typeof remoteFlags]: boolean }>;

export const remoteCamelCasedFlags = camelCaseKeys(remoteFlags) as RemoteFlags;

export type ClientSideFlags = {
  [K in keyof typeof clientSideFlags]: boolean;
};

export type FlagSet = ClientSideFlags & RemoteFlags;
