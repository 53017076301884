import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../../../../atoms/Icon/Icon';
import signInAvatarDefaultIcon from './signInAvatarDefault.svg';

export const SignInAvatarDefault = styled(JustIcon).attrs({
  alt: 'signInAvatarDefault',
  icon: signInAvatarDefaultIcon,
  width: '20px',
  height: '20px',
})`` as JustIconStyledComponent<IconStaticProps>;
