import styled, { css } from 'styled-components';
import {
  width,
  space,
  height,
  textAlign,
  WidthProps,
  SpaceProps,
  HeightProps,
  TextAlignProps,
  MinWidthProps,
  minWidth,
} from 'styled-system';

import { TestIdProps, withTestIdProp, VariantProps, WithVariantProps, withVariantProp } from '../../../utils';
import { FullwidthProps, fullwidth } from './fullwidth';

export interface ButtonBaseProps
  extends TestIdProps,
    WidthProps,
    MinWidthProps,
    SpaceProps,
    HeightProps,
    TextAlignProps,
    VariantProps<unknown>,
    WithVariantProps,
    FullwidthProps {}

const disabled = (props: Pick<JSX.IntrinsicElements['button'], 'disabled'>) =>
  props.disabled &&
  css`
    cursor: not-allowed;
  `;

const widthAuto = () => css`
  width: auto;
`;

// will compile static variants definitions into finite amount of CSS classes
const ButtonBaseStatic = styled('button').attrs(withTestIdProp)<ButtonBaseProps>(widthAuto, withVariantProp);

export const ButtonBase = styled(ButtonBaseStatic)(
  // will compile dynamic props into infinite amount of CSS classes
  height,
  width,
  minWidth,
  space,
  textAlign,
  disabled,
  fullwidth,
);
