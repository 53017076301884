import { UserCards } from '@water-web/types';
import { QueryKey } from 'react-query';

import { EndpointQuery, GetToken } from '../../request';
import { queryKeys } from './queryKeys';

type Response = UserCards;
type Request = { customerId: string };

export class UserCardsQuery extends EndpointQuery<Response, Request> {
  meta: EndpointQuery<Response, void>['meta'] = {};

  constructor(config: { baseUrl: string; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  getKey(): QueryKey {
    return queryKeys.cards();
  }

  async request(payload?: Request, signal?: AbortSignal): Promise<Response> {
    const headers = this.buildHeaders();

    return this.http.get(`/v1/customer/${payload.customerId}/payment-card`, {
      signal,
      headers,
      resourceUrl: '/customer/:id/payment-card',
    });
  }
}
