import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { persistentKVStore } from '../../persistence';
import { ClientSideFlags, DeploymentEnvironment, clientSideFlags, RemoteFlags, remoteCamelCasedFlags } from './flags';
import { Box } from '../../components/atoms/Box';
import { Flex } from '../../components/atoms/Flex';
import {
  watchClicksPerMs,
  mergeWithOverrides,
  setClientFlagOverride,
  getClientFlagOverrides,
  resetClientFlagOverrides,
  mergeWithRemoteOverrides,
  getRemoteFlagOverrides,
  setRemoteFlagOverride,
  resetRemoteFlagOverrides,
} from './utils';
import { FlagsContainer, FlagLabel, CheckboxInput, CloseButton } from './styled';

declare global {
  interface Window {
    flagOverrides: {
      getAll: typeof getClientFlagOverrides;
      set: typeof setClientFlagOverride;
      reset: typeof resetClientFlagOverrides;
    };
  }
}

export const OverridesContext = createContext<{
  overrides: Partial<ClientSideFlags>;
  remoteOverrides: Partial<RemoteFlags>;
  setOverride: typeof setClientFlagOverride;
}>(null);

export const OverridesProvider = (props: PropsWithChildren<{ deploymentEnv: DeploymentEnvironment }>) => {
  const [isFlagViewOn, setFlagViewState] = useState(false);
  const [overrides, setOverrides] = useState(getClientFlagOverrides());
  const [remoteOverrides, setRemoteOverrides] = useState(getRemoteFlagOverrides());

  const setOverride: typeof setClientFlagOverride = useCallback((key, value) => {
    setClientFlagOverride(key, value);
    setOverrides(getClientFlagOverrides());
  }, []);

  const setRemoteOverride: typeof setRemoteFlagOverride = useCallback((key, value) => {
    setRemoteFlagOverride(key, value);
    setRemoteOverrides(getRemoteFlagOverrides());
  }, []);

  const resetOverrides = useCallback(() => {
    resetClientFlagOverrides();
    resetRemoteFlagOverrides();
    setOverrides(getClientFlagOverrides());
    setRemoteOverrides(getRemoteFlagOverrides());
  }, []);

  const throwError = useCallback(() => {
    throw new Error('Test error thrown');
  }, []);

  const contextValue = useMemo(() => {
    return {
      overrides,
      remoteOverrides,
      setOverride,
    };
  }, [overrides, setOverride]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.flagOverrides = {
        getAll: getClientFlagOverrides,
        set: setOverride,
        reset: resetOverrides,
      };

      const isFlagMenuForceDisabled = !!persistentKVStore.getItem('disable_feature_flags_menu');
      if (props.deploymentEnv !== 'production' && !isFlagMenuForceDisabled) {
        watchClicksPerMs(10, 2000, () => {
          setFlagViewState(true);
        });
      }
    }
  }, []);

  const clientValues: ClientSideFlags = useMemo(
    () => mergeWithOverrides(clientSideFlags, overrides, props.deploymentEnv),
    [props.deploymentEnv, overrides],
  );

  const remoteValues: RemoteFlags = useMemo(
    () => mergeWithRemoteOverrides(remoteCamelCasedFlags, remoteOverrides),
    [remoteOverrides],
  );

  return (
    <OverridesContext.Provider value={contextValue}>
      {isFlagViewOn && (
        <FlagsContainer>
          <Box m="auto" width="360px">
            <Flex justifyContent="space-between" alignItems="center">
              <h3>Feature flags</h3>
              <CloseButton type="button" onClick={() => setFlagViewState(false)}>
                ✕
              </CloseButton>
            </Flex>
            {Object.keys(clientValues).map((key: keyof typeof clientValues) => (
              <Box key={key} my="8px">
                <FlagLabel>
                  {key}
                  <CheckboxInput
                    type="checkbox"
                    checked={clientValues[key]}
                    onChange={() => setOverride(key, !clientValues[key])}
                  />
                </FlagLabel>
              </Box>
            ))}
            {Object.keys(remoteValues).map((key: keyof typeof remoteValues) => (
              <Box key={key} my="8px">
                <FlagLabel>
                  {key}
                  <CheckboxInput
                    type="checkbox"
                    checked={remoteValues[key]}
                    onChange={() => setRemoteOverride(key, !remoteValues[key])}
                  />
                </FlagLabel>
              </Box>
            ))}
            <Box as="button" type="button" mt="16px" onClick={resetOverrides}>
              Reset to defaults
            </Box>
            <Box as="button" type="button" mt="16px" onClick={throwError}>
              Throw test error
            </Box>
          </Box>
        </FlagsContainer>
      )}
      {props.children}
    </OverridesContext.Provider>
  );
};
