import { QueryKey } from 'react-query';
import { NotificationsConsent } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

interface Customer {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

interface Payload {
  shopId: string;
  customer: Customer;
}

type Response = NotificationsConsent;

export class NotificationsConsentByMatchQuery extends EndpointQuery<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.byMatch(payload);
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    return this.http.post<Response, Customer>(`/v1/shop/${payload.shopId}/notifications-consent`, payload.customer, {
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      signal,
      resourceUrl: '/shop/:id/notifications-consent',
    });
  }
}
