import { FC, PropsWithChildren, useRef, useState, RefObject, createContext } from 'react';

import { PopupWrapperProps } from './typesExternal';

type StackItem = {
  name: string;
  ref: RefObject<HTMLDivElement>;
  Wrapper: FC<PopupWrapperProps>;
  Fade: FC;
};

type PopupItem = {
  stackName: string;
  id: string;
};

interface ContextValue {
  /**
   * Currently available stacks, used to render popups into them
   */
  stacks: StackItem[];
  /**
   * Register a new stack in the manager, to let popups render into it.
   * @return a cleanup function to remove stack from the manager.
   */
  stackRegisterEffect: (stackItem: StackItem) => () => void;
  /**
   * Currently rendered popup ids, used to calculate popup level in stack
   */
  popups: PopupItem[];
  /**
   * Register a new popup in the manager, to let it calculate its level in stack.
   * @return a cleanup function to remove popup from the manager.
   */
  popupRegisterEffect: (popupItem: PopupItem) => () => void;
}

/**
 * Context to share state between `PopupManager` components.
 */
export const PopupContext = createContext<Readonly<ContextValue>>({
  stacks: [],
  stackRegisterEffect: () => () => undefined,
  popups: [],
  popupRegisterEffect: () => () => undefined,
});

type ContextMethods = Omit<ContextValue, 'stacks' | 'popups'>;

/**
 * Provides context to share data between `PopupManager` components.
 * Place it once in the root of the app.
 *
 * Implementation details:
 * - `PopupManager` - is placed in the root of the app to provide `PopupContext` and share data between components.
 * - `PopupStack` - used to provide a named mount point for the popups. Will share its ref via `PopupContext`.
 * - `PopupRender` - used to render children as popups inside the corresponding `PopupStack` by using `createPortal`.
 * - `PopupRenderWrapper` - wraps every `PopupRender`'s child to stack popups and display underlying fade.
 * - `PopupBase` - every popup must be wrapped with such component to let popup float above the fade.
 */
export const PopupManager = ({ children }: PropsWithChildren) => {
  const [stacks, setStacks] = useState<ContextValue['stacks']>([]);
  const [popups, setPopups] = useState<ContextValue['popups']>([]);

  const methods = useRef<ContextMethods>({
    stackRegisterEffect: (stackItem) => {
      setStacks((prev) => {
        if (prev.some((item) => item.name === stackItem.name)) {
          throw new Error(`Stack with name \`${stackItem.name}\` already exists`);
        }

        return [...prev, stackItem];
      });

      return () => {
        setStacks((prev) => prev.filter((item) => item.name !== stackItem.name));
      };
    },
    popupRegisterEffect: (popupItem) => {
      setPopups((prev) => [...prev, popupItem]);

      return () => {
        setPopups((prev) => prev.filter((item) => item.id !== popupItem.id));
      };
    },
  });

  const value = {
    ...methods.current,
    stacks,
    popups,
  };

  return <PopupContext.Provider value={value}>{children}</PopupContext.Provider>;
};
