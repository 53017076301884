import { useEffect, useMemo, useState } from 'react';
import { ServiceModel } from '@water-web/repository';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../components';

interface SelectedCategoryResponse {
  selectedCategory: SelectOption<string>;
  categoryOptions: SelectOption<string>[];
  changeCategory: (option: SelectOption<string>) => void;
}

export const useSelectedServicesCategory = (services: ServiceModel[]): SelectedCategoryResponse => {
  const { t } = useTranslation();
  const allServicesOption: SelectOption<string> = {
    label: t('pages.services.allCategories') as string,
    value: 'all',
    'data-testId': 'pages.services.categories',
  };

  const uncategorizedOption: SelectOption<string> = {
    label: t('pages.services.uncategorized') as string,
    value: 'uncategorized',
    'data-testId': 'pages.services.uncategorized',
  };

  const [selected, setSelected] = useState(allServicesOption);

  const options = useMemo<SelectOption<string>[]>(() => {
    const categories: SelectOption<string>[] = services
      .map((service) => service.getCategory())
      .filter((category, index, self) => category && self.findIndex((c) => c?.id === category.id) === index)
      .map((category) => ({
        value: category.id,
        label: category.name,
        'data-testId': category.name,
      }));

    if (!categories.length) {
      return [];
    }

    return [allServicesOption, ...categories, uncategorizedOption];
  }, [services, t]);

  useEffect(() => {
    // Refresh the selected object when the language changes,
    // so that pointer to label changes and React knows to re-render
    if (selected.value === allServicesOption.value) {
      setSelected(allServicesOption);
    } else if (selected.value === uncategorizedOption.value) {
      setSelected(uncategorizedOption);
    }
  }, [t]);

  return {
    categoryOptions: options,
    selectedCategory: selected,
    changeCategory: setSelected,
  };
};
