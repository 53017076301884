import { createRepository } from '@water-web/repository';

export class ExternalConfiguration {
  static repository: ReturnType<typeof createRepository> = null;
  static config: { apiUrl: string } = null;

  /**
   * @deprecated Use `<RepositoryContext.Provider value={repository}>` instead.
   */
  static setRepository(repository: ReturnType<typeof createRepository>) {
    this.repository = repository;
  }

  /**
   * @deprecated Use `useContext(RepositoryContext)` instead.
   */
  static getRepository() {
    if (!this.repository) {
      throw new Error('You should initialize repository first');
    }

    return this.repository;
  }

  static setConfig(config: typeof this.config) {
    this.config = config;
  }

  static getConfig() {
    if (!this.config) {
      throw new Error('You should initialize config first');
    }

    return this.config;
  }
}
