export interface TestIdProps {
  /**
   * This property is used to attach `data-testid` attribute to elements for testing purposes.
   *
   * Use it to make easily-selectable elements like:
   * - container — a groups of elements like list of timeslots or services;
   * - button or label — a key element, something that has/might get i18n, like `Book Now` or `Custom Tip` button
   *
   * @example
   * <Box $testId="timeslots" >...</Box>
   * <Button $testId="book-now" >...</Button>
   */
  $testId?: string;
}

/**
 * Use this function to handle `testId` prop, to attach `data-testid` attribute to elements for testing purposes.
 */
export const withTestIdProp = (props: TestIdProps): { 'data-testid': string } | undefined => {
  const testId = props.$testId || props['data-testid'];
  return testId ? { 'data-testid': testId } : undefined;
};
