import styled, { css, StyledProps } from 'styled-components';
import { Box } from '@water-web/view';

export const CARD_SHADOW_RADIUS_UNITS = 4;
export const CARD_SHADOW_OFFSET_Y_UNITS = 4;
export const CARD_SHADOW_LENGTH_X_UNITS = CARD_SHADOW_RADIUS_UNITS;
export const CARD_SHADOW_LENGTH_Y_UNITS = CARD_SHADOW_RADIUS_UNITS + CARD_SHADOW_OFFSET_Y_UNITS;

interface BaseCardProps {
  $selected?: boolean;
  $shadowOffsetY?: string | number;
}

const baseCardSelectedStyles = ({ theme }: StyledProps<BaseCardProps>) => {
  return css`
    --card-background: ${theme.colors.primaryColor};
    border-color: ${theme.colors.primaryColor};
    color: ${theme.colors.primaryFontColor};
  `;
};

export const getBaseCardHoverStyles = ({ theme }: StyledProps<BaseCardProps>) => {
  return css`
    --card-background: ${theme.colors.cardBackgroundFocused};
    border-color: ${theme.colors.cardBorderFocused};
    box-shadow: 0 8px 16px -5px ${theme.colors.cardShadowFocused};
  `;
};

export const BaseCard = styled(Box)<BaseCardProps>`
  ${css`
    // separate scope to prevent syntax highlighting break
    @property --card-background {
      syntax: '<color>';
      inherits: true;
      initial-value: transparent;
    }
  `}

  --card-background: ${({ theme }) => theme.colors.chrome};

  position: relative;
  padding: 24px;
  border: 1px solid ${(props) => props.theme.colors.cardBorder};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.systemGray1};
  background-color: var(--card-background);
  transition-property: border-color, background-color, box-shadow;
  transition-duration: ${(props) => props.theme.animations.booking.interaction}s;
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  cursor: pointer;

  ${(props) =>
    props.$selected
      ? baseCardSelectedStyles
      : css`
          /* 
      Add hover state only if we actually support hover - like mouse, 
      but not simulate it like on mobile, with one tap to hover, another to click.
      This selector looks weird, but it excludes devices that don't support hover.
    */
          @media not all and (hover: none) {
            &:hover {
              ${getBaseCardHoverStyles};
            }
          }
        `}
`;

BaseCard.defaultProps = {
  $shadowOffsetY: CARD_SHADOW_OFFSET_Y_UNITS,
};
