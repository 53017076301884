import {
  HttpConfig,
  HttpError,
  HttpResponse,
  HttpSearchParams,
  HttpGetOptions,
  HttpPostOptions,
} from '@water-web/types';
import * as Sentry from '@sentry/browser';

import { buildTrackingHeaders, getAttachPlatformFn } from '../utils';

const IS_SSR = typeof window === 'undefined';

export class Http {
  static buildResponse<T>(data?: T, error?: HttpError): HttpResponse<T> {
    return { data, error };
  }

  protected baseUrl: string;

  protected logger: HttpConfig['logger'];

  protected platform: HttpConfig['platform'];

  protected staticHeaders = {};

  protected getPreferredLanguage = (): string => 'en';

  attachPlatform: ReturnType<typeof getAttachPlatformFn>;

  constructor(config: HttpConfig) {
    this.baseUrl = config.baseUrl;
    this.logger = config.logger;
    this.platform = config.platform;
    this.attachPlatform = getAttachPlatformFn(config.platform);

    if (config.getPreferredLanguage) {
      this.getPreferredLanguage = config.getPreferredLanguage;
    }

    if (IS_SSR) {
      if (config.clientIp) {
        this.staticHeaders = {
          ...this.staticHeaders,
          'X-Forwarded-For': config.clientIp,
          'X-Real-IP': config.clientIp,
        };
      }

      if (config.userAgent) {
        this.staticHeaders = {
          ...this.staticHeaders,
          'User-Agent': config.userAgent,
        };
      }
    }

    if (config.trackingParams) {
      this.staticHeaders = {
        ...this.staticHeaders,
        ...buildTrackingHeaders(config.trackingParams),
      };
    }
  }

  private getUrl(path: string, search?: HttpSearchParams | string): string {
    if (!search) {
      return this.baseUrl.concat(path);
    }

    if (typeof search === 'string') {
      return this.baseUrl.concat(path).concat('?').concat(search);
    }

    const query = Object.keys(search)
      .map((key) => {
        const value = search[key];
        if (Array.isArray(value)) {
          return `${key}=${(search[key] as string[]).join(',')}`;
        }
        return `${key}=${search[key]}`;
      })
      .join('&');

    return this.baseUrl.concat(path).concat('?').concat(query);
  }

  private getDDSessionId(): string | undefined {
    let sessionId: string | undefined;

    if (!IS_SSR) {
      const ddCookie = document.cookie.split('; ').find((row) => row.startsWith('_dd_s='));

      if (ddCookie) {
        const cookieParams = new URLSearchParams(ddCookie.split('=')[1]);
        sessionId = cookieParams.get('id') || undefined;
      }
    }

    if (!sessionId && !IS_SSR) {
      // @ts-expect-error DD_RUM is added to window by the Datadog RUM SDK
      sessionId = window.DD_RUM?.getInternalContext()?.session_id;
    }
    return sessionId;
  }

  private getHeaders(headers = {} as Record<string, string>): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      // TODO: can we relay language from the client?
      'preferred-language': IS_SSR ? 'en' : this.getPreferredLanguage() || 'en',
      ...headers,
      ...this.staticHeaders,
      'x-datadog-session-id': this.getDDSessionId(),
    };
  }

  async get<T>(path: string, options: HttpGetOptions): Promise<HttpResponse<T>> {
    const url = this.getUrl(path, options.search);
    const optionsForRequest = {
      ...options,
      headers: this.getHeaders(options.headers),
    };

    let dataOrError: { message: string; code?: string };
    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      this.logger.error('Failed to execute request', {
        url,
        platform: this.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });
      return Http.buildResponse(null, error);
    }

    try {
      dataOrError = await response.json();
    } catch (jsonError) {
      const error: HttpError = {
        status: response.status,
        code: 'invalid_response_format',
        message: 'Failed to parse the server response as JSON',
      };
      return Http.buildResponse(null, error);
    }

    if (response.ok) {
      return Http.buildResponse(dataOrError as T, null);
    }

    const error: HttpError = {
      status: response.status,
      code: dataOrError.code,
      message: dataOrError.message,
    };

    Sentry.addBreadcrumb({
      category: 'http',
      message: `GET request failed: ${url}`,
      level: 'error',
      data: {
        method: 'GET',
        url,
        status: response.status,
        errorMessage: error.message,
      },
    });

    return Http.buildResponse(null, error);
  }

  async post<Response, Payload>(
    path: string,
    data: Payload | undefined = undefined,
    options: HttpPostOptions,
  ): Promise<HttpResponse<Response>> {
    const url = this.getUrl(path);
    const optionsForRequest = {
      ...options,
      method: 'POST',
      body: JSON.stringify(data),
      headers: this.getHeaders(options.headers),
    };

    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      this.logger.error('Failed to execute request', {
        url,
        platform: this.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });
      return Http.buildResponse(null, error);
    }

    const dataOrError = await response.json();

    if (response.ok) {
      return Http.buildResponse(dataOrError as Response, null);
    }

    const error: HttpError = {
      status: response.status,
      code: dataOrError.code,
      message: dataOrError.message,
    };

    Sentry.addBreadcrumb({
      category: 'http',
      message: `POST request failed: ${url}`,
      level: 'error',
      data: {
        method: 'POST',
        url,
        status: response.status,
        errorMessage: error.message,
      },
    });

    return Http.buildResponse(null, error);
  }

  async put<Response, Payload>(
    path: string,
    data: Payload | undefined = undefined,
    options: HttpPostOptions,
  ): Promise<HttpResponse<Response>> {
    const url = this.getUrl(path);
    const optionsForRequest = {
      ...options,
      method: 'PUT',
      body: JSON.stringify(data),
      headers: this.getHeaders(options.headers),
    };

    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      this.logger.error('Failed to execute request', {
        url,
        platform: this.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });
      return Http.buildResponse(null, error);
    }

    const dataOrError = await response.json();

    if (response.ok) {
      return Http.buildResponse(dataOrError as Response, null);
    }

    const error: HttpError = {
      status: response.status,
      code: dataOrError.code,
      message: dataOrError.message,
    };

    Sentry.addBreadcrumb({
      category: 'http',
      message: `PUT request failed: ${url}`,
      level: 'error',
      data: {
        method: 'PUT',
        url,
        status: response.status,
        errorMessage: error.message,
      },
    });

    return Http.buildResponse(null, error);
  }

  async delete<T>(path: string, options = {} as HttpPostOptions): Promise<HttpResponse<T>> {
    const url = this.getUrl(path);
    const optionsForRequest = {
      ...options,
      method: 'DELETE',
      headers: this.getHeaders(options.headers),
    };

    let response: globalThis.Response;

    try {
      response = await fetch(url, optionsForRequest);
    } catch (responseError) {
      const error: HttpError = {
        status: 0,
        code: 'network_error',
        message: 'Failed to execute request',
      };

      this.logger.error('Failed to execute request', {
        url,
        platform: this.platform,
        ssr: IS_SSR,
        resource_url: options.resourceUrl,
      });
      return Http.buildResponse(null, error);
    }

    const dataOrError = await response.json();

    if (response.ok) {
      return Http.buildResponse(dataOrError as T, null);
    }

    const error: HttpError = {
      status: response.status,
      code: dataOrError.code,
      message: dataOrError.message,
    };

    Sentry.addBreadcrumb({
      category: 'http',
      message: `DELETE request failed: ${url}`,
      level: 'error',
      data: {
        method: 'DELETE',
        url,
        status: response.status,
        errorMessage: error.message,
      },
    });

    return Http.buildResponse(null, error);
  }
}
