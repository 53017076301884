import { QueryClient } from 'react-query';
import { RequestLifecycleInfo, RequestLifecycleEvent, HttpError } from '@water-web/api';
import { queryKeys as userQueryKeys } from '@water-web/api/src/endpoints/user/queryKeys';

import { TokenStorage } from './TokenStorage';

const removeTokenOnAuthError = (queryClient: QueryClient, info: RequestLifecycleInfo) => {
  if ((info.error as HttpError).status !== 401) {
    return;
  }

  if (!TokenStorage.get()) {
    return; // 3rd-party API call, or bad Endpoint implementation
  }

  TokenStorage.remove();
  queryClient.resetQueries(userQueryKeys.all);
};

export const initAuthErrorHandler = (queryClient: QueryClient): void => {
  const eventName: RequestLifecycleEvent = 'squire-http-request-error';
  window.addEventListener(eventName, (event: CustomEvent<RequestLifecycleInfo>) => {
    removeTokenOnAuthError(queryClient, event.detail);
  });
};
