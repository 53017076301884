import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useElements } from '@stripe/react-stripe-js';
import { CartModel } from '@water-web/repository';
import { PaymentProcessor } from '@water-web/payments';

import { usePaymentInfoCollector } from '../usePaymentInfoCollector';

export type UsePaymentInfoHandlerResult = () =>
  | ReturnType<PaymentProcessor['payWithCard']>
  | ReturnType<PaymentProcessor['payWithDigitalWallet']>;

export const usePaymentInfoHandler = (
  paymentProcessor: PaymentProcessor,
  cart: CartModel,
): UsePaymentInfoHandlerResult => {
  const { t } = useTranslation();
  const stripeElements = useElements();
  const paymentCollectors = usePaymentInfoCollector({
    processor: paymentProcessor,
    stripeElements,
    label: t('common.walletPayee'),
    amount: cart.getValidatedCart()?.getTotalLeftForPayment(),
  });

  return useCallback(() => {
    if (cart.getPaymentMethod() === 'digitalWallet') {
      return paymentCollectors.collectDigitalWalletPaymentInfo();
    }

    return paymentCollectors.collectCardEntryPaymentInfo();
  }, [
    cart.getPaymentMethod(),
    paymentCollectors.collectCardEntryPaymentInfo,
    paymentCollectors.collectDigitalWalletPaymentInfo,
  ]);
};
