import { useContext, useEffect, useState, PropsWithChildren } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ApiInternalStatus, ApiStatusQuery, useApiQuery } from '@water-web/api';

import { config } from '@app/config';
import { WaitingRoom } from '@app/components/organisms/WaitingRoom';
import { Popup, PopupContext } from '@app/components/molecules/Popup';
import { ProcessingOverlay } from '@app/components/molecules/ProcessingOverlay';

import { StyledWidgetContainer, TooltipsMountingPoint } from './styles';

const endpoint = new ApiStatusQuery(config.apiUrl);

export const WidgetContainer = ({ children }: PropsWithChildren) => {
  const query = useApiQuery({ endpoint });
  const [isApiChecked, setApiCheckStatus] = useState(false);

  useEffect(() => {
    if (query.data || query.error) {
      setApiCheckStatus(true);
    }
  }, [query.data, query.error]);

  const { popupController } = useContext(PopupContext);

  useEffect(() => {
    if (isApiChecked) {
      window.parent.postMessage?.('squire_widget_loaded', '*');
    }
  }, [isApiChecked]);

  if (!isApiChecked) {
    return null;
  }

  const isInWaitingRoom = (query.data as ApiInternalStatus)?.ok !== true;
  if (isInWaitingRoom) {
    return <WaitingRoom />;
  }

  return (
    <Switch>
      {/* Wrapping everything in a route so all components can acess useLocation and useParams */}
      <Route
        path="/"
        render={() => (
          <>
            <TooltipsMountingPoint id="tooltips-mount-point" />
            <StyledWidgetContainer>
              {children}
              <Popup controller={popupController} />
              <ProcessingOverlay />
            </StyledWidgetContainer>
          </>
        )}
      />
    </Switch>
  );
};
