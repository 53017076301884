import { useMemo, FC, useContext, PropsWithChildren, useState, useEffect } from 'react';
import { useFlags, useLDClient, ProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LDContext, { ReactSdkContext } from 'launchdarkly-react-client-sdk/lib/context';

import { remoteFlags, clientSideFlags, FlagSet, RemoteFlags, ClientSideFlags, DeploymentEnvironment } from './flags';
import { OverridesContext, OverridesProvider } from './OverridesContext';
import { buildContext, FlagsContextOptions, mergeWithOverrides, mergeWithRemoteOverrides } from './utils';

interface FeatureFlagsProviderProps {
  clientSideId: string;
  deploymentEnv: string;
  brandId?: string;
  platform: FlagsContextOptions['platform'];
}

// this value is not supposed to change in runtime so a plain variable is enough for sharing
let deploymentEnv: DeploymentEnvironment;

export const FeatureFlagsProvider = (props: PropsWithChildren<FeatureFlagsProviderProps>) => {
  const [LDProvider, setLDProvider] = useState<FC<PropsWithChildren>>(null);

  useEffect(() => {
    if (props.clientSideId) {
      asyncWithLDProvider({
        clientSideID: props.clientSideId,
        // https://github.com/launchdarkly/js-client-sdk/issues/147
        options: {
          sendEvents: false,
        },
        flags: remoteFlags,
        context: buildContext({ brandId: props.brandId, platform: props.platform }),
      }).then((provider) => {
        setLDProvider(() => provider);
      });
    }
  }, []);

  const ldConfig = useMemo<ProviderConfig>(() => {
    return {
      clientSideID: props.clientSideId,
      flags: remoteFlags,
      options: {
        bootstrap: remoteFlags,
      },
      context: buildContext({ brandId: props.brandId, platform: props.platform }),
    };
  }, []);

  if (deploymentEnv !== props.deploymentEnv) {
    deploymentEnv = props.deploymentEnv as DeploymentEnvironment;
  }

  if (!props.clientSideId) {
    return (
      <OverridesProvider deploymentEnv={props.deploymentEnv as DeploymentEnvironment}>
        <LDContext.Provider value={ldConfig as unknown as ReactSdkContext}>{props.children}</LDContext.Provider>
      </OverridesProvider>
    );
  }

  if (!LDProvider) {
    return null;
  }

  return (
    <OverridesProvider deploymentEnv={props.deploymentEnv as DeploymentEnvironment}>
      <LDProvider>{props.children}</LDProvider>
    </OverridesProvider>
  );
};

export const useFeatureFlags = (): FlagSet => {
  const { overrides, remoteOverrides } = useContext(OverridesContext);
  const clientValues: ClientSideFlags = useMemo(
    () => mergeWithOverrides(clientSideFlags, overrides, deploymentEnv),
    [deploymentEnv, overrides],
  );
  const remoteFlagValues = useFlags<RemoteFlags>();

  const remoteValues = useMemo(
    () => mergeWithRemoteOverrides(remoteFlagValues, remoteOverrides),
    [remoteOverrides, remoteFlagValues],
  );

  return {
    ...clientValues,
    ...remoteValues,
  };
};

export const useFlagsContextSwitcher = () => {
  const ldClient = useLDClient();

  // https://support.launchdarkly.com/hc/en-us/articles/12998125691419-Error-LaunchDarklyFlagFetchError-network-error
  ldClient.on('error', () => {
    // do nothing
  });

  return (options?: Omit<FlagsContextOptions, 'platform'>): void => {
    const prevContext = ldClient.getContext() as ReturnType<typeof buildContext>;
    const { platform } = prevContext;

    if (prevContext.brand?.key !== options.brandId) {
      const newContext = buildContext({
        ...(options ?? {}),
        platform: platform.key,
      });

      ldClient?.identify(newContext);
    }
  };
};
