import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { routes } from '../../routeDefinitions';
import { useInitializedCart } from '../useInitializedCart';

export const useBackButtonHandler = (): (() => void) => {
  const location = useLocation();
  const cart = useInitializedCart();
  const reservations = cart?.getReservations();
  const moreThanOneAppointment = reservations?.length > 1;
  const isRentalShop = cart?.getActiveReservation()?.getShop()?.isRentalShop();
  const isShopPage = matchPath(location.pathname, { exact: true, path: routes.shop });
  const isServicesPage = matchPath(location.pathname, { exact: true, path: routes.services });

  return useCallback(() => {
    const shouldRemoveReservation = isShopPage || (isServicesPage && isRentalShop);

    if (!moreThanOneAppointment || !shouldRemoveReservation) {
      return;
    }

    reservations.pop();

    const prevReservation = reservations[reservations.length - 1];
    cart.setActiveReservation(prevReservation);
  }, [reservations, moreThanOneAppointment, isRentalShop, isServicesPage, isShopPage]);
};
