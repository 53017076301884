import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { ReservationModel, ServiceModel } from '@water-web/repository';

interface UseSelectedServiceReturnType {
  areServicesVisible: boolean;
  selectedService?: ServiceModel;
  serviceCallbacks: {
    [key: string]: () => void;
  };
  resetSelectedService: () => void;
}

interface UseSelectedService {
  (
    reservation: ReservationModel,
    services: ServiceModel[],
    isGcSelected: boolean,
    afterSelectionIdleMs?: number,
  ): UseSelectedServiceReturnType;
}

export const useSelectedService: UseSelectedService = (
  reservation,
  services,
  isGcSelected,
  afterSelectionIdleMs = 100,
) => {
  const selectedService = reservation?.getService();

  const [areServicesVisible, setAreServicesVisible] = useState(!(selectedService && reservation?.canAddAddons()));

  const serviceCallbacks = useMemo(() => {
    return services.reduce<UseSelectedServiceReturnType['serviceCallbacks']>((memo, service) => {
      return {
        ...memo,
        [service.getId()]: () => {
          const currentService = reservation?.getService();
          reservation?.setService(currentService?.getId() === service.getId() ? null : service);
        },
      };
    }, {});
  }, [reservation, services]);

  const resetSelectedService = useCallback(() => {
    setAreServicesVisible(true);
    reservation?.setService(null);
    reservation?.clearAddons();
  }, [reservation]);

  const timeoutRef = useRef<number>();
  const doEffectLogics = (trueCondition) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    if (trueCondition) {
      timeoutRef.current = setTimeout(() => {
        setAreServicesVisible(false);
        timeoutRef.current = undefined;
      }, afterSelectionIdleMs) as unknown as number;
    }
  };

  useEffect(() => {
    // resets animation if unselected service too fast
    if (!selectedService) {
      resetSelectedService();
    }
    doEffectLogics(selectedService && reservation.canAddAddons());
  }, [selectedService]);

  useEffect(() => {
    doEffectLogics(isGcSelected);
  }, [isGcSelected]);

  return useMemo(
    () => ({
      selectedService,
      serviceCallbacks,
      areServicesVisible,
      resetSelectedService,
    }),
    [selectedService, serviceCallbacks, areServicesVisible, resetSelectedService],
  );
};
