import { parsePhoneNumber } from 'libphonenumber-js';

import { RoktAttributesInput, RoktAttributes } from './types';
import { countryCodeByName } from '../../../utils';

export const formatRoktAttributes = (attrs: RoktAttributesInput): RoktAttributes => {
  const omittedFields = ['phone'];
  const formattedAttrs = Object.entries(attrs).reduce((acc, [key, value]) => {
    if (!omittedFields.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {}) as RoktAttributes;

  if (formattedAttrs.country) {
    formattedAttrs.country = countryCodeByName.get(formattedAttrs.country) || formattedAttrs.country;
  }

  if (formattedAttrs.currency) {
    formattedAttrs.currency = formattedAttrs.currency.toUpperCase();
  }

  if (attrs.phone) {
    const parsed = parsePhoneNumber(attrs.phone);

    // this might get a little tricky with the UK phone numbers since in certain cases the area code is 4-5 digits long
    // since rokt isn't operating outside of the US, we can assume that the area code is 3 digits long
    formattedAttrs.areaCode = parsed.nationalNumber.slice(0, 3);
  }

  return formattedAttrs;
};
