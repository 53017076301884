import { RefObject, useCallback, useMemo, useRef, useState, SyntheticEvent } from 'react';

export interface SelectedEntityResponse<T> {
  selectedEntity?: T;
  selectedEntityRef: RefObject<T>;
  selectEntity: (entity: null | T) => void;
  resetSelectedEntity: () => void;
  entityCallbacks: {
    [key: string]: (event?: SyntheticEvent) => void;
  };
}

interface WithGettableId {
  getId: () => string | number;
}

export interface SelectedEntityConfig<T> {
  stopPropagation?: boolean;
  initialValue?: T;
  preserveSelected?: boolean;
}

const defaultConfig = {
  stopPropagation: false,
  preserveSelected: false,
};

export const useSelectedEntity = <T extends WithGettableId>(
  items: T[],
  config?: SelectedEntityConfig<T>,
): SelectedEntityResponse<T> => {
  const {
    initialValue = null,
    preserveSelected,
    stopPropagation,
  } = {
    ...defaultConfig,
    ...(config ?? {}),
  };

  const [selectedEntity, setSelectedEntity] = useState<T>(initialValue);
  const selectedEntityRef = useRef<T>(initialValue);

  const resetSelectedEntity = useCallback(() => {
    setSelectedEntity(null);
    selectedEntityRef.current = null;
  }, []);

  const selectEntity = useCallback((value: T) => {
    setSelectedEntity(value);
    selectedEntityRef.current = value;
  }, []);

  const entityCallbacks = useMemo(() => {
    return items.reduce((memo, item) => {
      const toggle = (e: Event) => {
        if (stopPropagation) {
          e.stopPropagation();
        }

        if (!preserveSelected && selectedEntityRef.current?.getId() === item.getId()) {
          setSelectedEntity(null);
          selectedEntityRef.current = null;
          return;
        }

        setSelectedEntity(item);
        selectedEntityRef.current = item;
      };

      return {
        ...memo,
        [item.getId()]: toggle,
      };
    }, {});
  }, [items]);

  return {
    selectedEntity,
    entityCallbacks,
    selectedEntityRef,
    resetSelectedEntity,
    selectEntity,
  };
};
