import { createContext, useState, useMemo, useCallback, PropsWithChildren } from 'react';

import { useBoolean, BooleanHandlers } from '../../hooks/useBoolean';
import { ErrorHandlers, useErrorHandlers } from './useErrorHandlers';

interface AppSegmentState {
  readonly interactive: boolean;
  interactivity: BooleanHandlers;
  error: ErrorHandlers;
}

type UpdateLoaderFunc = () => void;

interface AppStateContextValue {
  readonly loading: boolean;
  addAppLoader: UpdateLoaderFunc;
  removeAppLoader: UpdateLoaderFunc;
  cart: AppSegmentState;
  list: AppSegmentState;
  app: {
    setInteractive: () => void;
    setNotInteractive: () => void;
  };
}

export const AppStateContext = createContext<AppStateContextValue>({
  loading: false,
  addAppLoader: () => null,
  removeAppLoader: () => null,
  cart: null,
  list: null,
  app: null,
});

export const AppStateContextProvider = (props: PropsWithChildren) => {
  const [loadingCounter, setLoadingCounter] = useState(0);

  const addAppLoader = useCallback(() => setLoadingCounter((v) => v + 1), []);

  const removeAppLoader = useCallback(() => setLoadingCounter((v) => (v <= 1 ? 0 : v - 1)), []);

  const cartError = useErrorHandlers();
  const [cartInteractivity, cartInteractivityHandlers] = useBoolean(true);

  const listError = useErrorHandlers();
  const [listInteractivity, listInteractivityHandlers] = useBoolean(true);

  const setAppInteractive = useCallback(() => {
    cartInteractivityHandlers.turnOn();
    listInteractivityHandlers.turnOn();
  }, []);

  const setAppNotInteractive = useCallback(() => {
    cartInteractivityHandlers.turnOff();
    listInteractivityHandlers.turnOff();
  }, []);

  const value = useMemo<AppStateContextValue>(() => {
    return {
      addAppLoader,
      removeAppLoader,
      loading: !!loadingCounter,
      cart: {
        interactive: cartInteractivity,
        interactivity: cartInteractivityHandlers,
        error: cartError,
      },
      list: {
        interactive: listInteractivity,
        interactivity: listInteractivityHandlers,
        error: listError,
      },
      app: {
        setInteractive: setAppInteractive,
        setNotInteractive: setAppNotInteractive,
      },
    };
  }, [loadingCounter, cartInteractivity, listInteractivity, cartError, listError]);

  return <AppStateContext.Provider value={value}>{props.children}</AppStateContext.Provider>;
};
