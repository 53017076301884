import { StringMap, ResourceKey } from 'i18next';
import { Namespace, useTranslation, UseTranslationOptions } from 'react-i18next';

interface TranslateProps extends UseTranslationOptions, StringMap {
  readonly i18nKey: string;
  readonly ns?: Namespace; // eslint-disable-line react/require-default-props
}

export const Translate = ({ i18nKey, ns, ...options }: TranslateProps) => {
  const { t } = useTranslation(ns, options);
  return <>{t(i18nKey as ResourceKey, options)}</>;
};
