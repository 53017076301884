import { SquireApi } from '@water-web/types';

import {
  ShopRepository,
  CartRepository,
  BrandRepository,
  BarberRepository,
  ServiceRepository,
  SearchRepository,
  ProductRepository,
  ScheduleRepository,
  SaleOrderRepository,
  SMSRepository,
  WaitingListRepository,
  CustomerRepository,
} from './repositories';

export class Repository {
  public brandRepository: BrandRepository;
  cartRepository: CartRepository;
  shopRepository: ShopRepository;
  barberRepository: BarberRepository;
  serviceRepository: ServiceRepository;
  searchRepository: SearchRepository;
  productRepository: ProductRepository;
  scheduleRepository: ScheduleRepository;
  saleOrderRepository: SaleOrderRepository;
  smsRepository: SMSRepository;
  waitingListRepository: WaitingListRepository;
  customerRepository: CustomerRepository;

  constructor(api: SquireApi) {
    this.brandRepository = new BrandRepository();
    this.brandRepository.configure(api);

    this.cartRepository = new CartRepository();
    this.cartRepository.configure(api);

    this.serviceRepository = new ServiceRepository();
    this.serviceRepository.configure(api);

    this.shopRepository = new ShopRepository();
    this.shopRepository.configure(api);

    this.barberRepository = new BarberRepository();
    this.barberRepository.configure(api);

    this.searchRepository = new SearchRepository();
    this.searchRepository.configure(api);

    this.productRepository = new ProductRepository();
    this.productRepository.configure(api);

    this.scheduleRepository = new ScheduleRepository();
    this.scheduleRepository.configure(api);

    this.saleOrderRepository = new SaleOrderRepository();
    this.saleOrderRepository.configure(api);

    this.smsRepository = new SMSRepository();
    this.smsRepository.configure(api);

    this.waitingListRepository = new WaitingListRepository();
    this.waitingListRepository.configure(api);

    this.customerRepository = new CustomerRepository();
    this.customerRepository.configure(api);
  }
}

export const createRepository = (api: SquireApi): Repository => {
  return new Repository(api);
};

export * from './types';
export * from './models';
export * from './utils';
export * from './getters';
