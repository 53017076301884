/* eslint-disable dot-notation */
import { AnalyticsScript } from './types';

declare global {
  interface Window {
    TiktokAnalyticsObject?: string;
    ttq?: unknown;
    stylzGtag?: (...args: any[]) => void;
    barberCoDataLayer?: any[];
    upprMgmtDataLayer?: any[];
    dataLayer?: any[];
  }
}

const scriptByBrandId: Map<string, AnalyticsScript> = new Map([
  [
    'cce706a3-c15a-4728-9d81-f2f8d8e57172',
    {
      code: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1139426346947943');
        fbq('track', 'PageView');
        
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','stylzDataLayer','GTM-WB6MQKBP');

        !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

        ttq.load('CD3BDQJC77UBGRDK0GR0');
        ttq.page();
        }(window, document, 'ttq');
      `,
      dispose: () => {
        window['fbq'] = undefined;
        window['_fbq'] = undefined;
        ((n) => n && document.head.removeChild(n))(document.head.querySelector('script[src$="fbevents.js"]'));

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window['stylzGtag'] = function stylzGtag() {};
        window['stylzDataLayer'] = [];
        ((n) => n && document.head.removeChild(n))(document.head.querySelector('script[src*="10926152046"]'));

        delete window['TiktokAnalyticsObject'];
        delete window['ttq'];
        ((n) => n && document.head.removeChild(n))(document.head.querySelector('script[src*="CD3BDQJC77UBGRDK0GR0"]'));
      },
    },
  ],
  [
    '3a9badf3-3d9d-407d-abd0-209959b511ab',
    {
      code: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','barberCoDataLayer','GTM-WDX36HMR');
      `,
      dispose: () => {
        window['barberCoDataLayer'] = [];
      },
    },
  ],
  [
    '773c28e8-8f34-4dd2-9b16-ab78c2501726',
    {
      code: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','upprMgmtDataLayer','GTM-NG6XGB9C');
      `,
      dispose: () => {
        window['upprMgmtDataLayer'] = [];
      },
    },
  ],
]);

const scriptByShopId: Map<string, AnalyticsScript> = new Map([]);

export const getTrackingScript = (ownerType: 'brand' | 'shop', ownerId: string): AnalyticsScript | undefined => {
  const source = ownerType === 'brand' ? scriptByBrandId : scriptByShopId;
  return source.get(ownerId) || null;
};
