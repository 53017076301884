import { QueryKey } from 'react-query';

import {
  NextAvailableTimePayload,
  GroupApptSchedulePayload,
  AnyBarberApptSchedulePayload,
  SingleApptSchedulePayload,
} from './payloads';

export const queryKeys = {
  all: ['schedule'] as const,
  nextAvailableTime: (payload: NextAvailableTimePayload): QueryKey => [
    ...queryKeys.all,
    'next-available-time',
    payload.duration,
    { shop: payload.shopId, barber: payload.barberId, fromDate: payload.fromDate },
  ],
  groupApptSchedule: (payload: GroupApptSchedulePayload): QueryKey => [
    ...queryKeys.all,
    'shop',
    payload.shopId,
    'group',
    payload.fromDate,
    payload.toDate,
    payload.appointments,
  ],
  anyBarberApptSchedule: (payload: AnyBarberApptSchedulePayload): QueryKey => [
    ...queryKeys.all,
    'shop',
    payload.shopId,
    'anyBarber',
    { services: payload.serviceIds.join(',') },
    payload.fromDate,
    payload.toDate,
  ],
  singleApptSchedule: (payload: SingleApptSchedulePayload): QueryKey => [
    ...queryKeys.all,
    'barber',
    payload.barberId,
    payload.fromDate,
    payload.toDate,
    payload.duration,
  ],
};
