/**
 * Returns a debounced version of the given callback.
 * The destroy method should be called to prevent the callback from being called ever again.
 *
 * @param cb - The callback to debounce
 * @param delay - The delay in milliseconds
 */
export const debounce = <T extends (...args: any[]) => void>(cb: T, delay = 300): { cb: T; destroy: () => void } => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  let isDestroyed = false;

  const debounced: any = (...args: Parameters<T>) => {
    if (!isDestroyed) {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => cb(...args), delay);
    }
  };

  const destroy = () => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = null;
    isDestroyed = true;
  };

  return {
    cb: debounced,
    destroy,
  };
};
