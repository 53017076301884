import { AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useState, useMemo } from 'react';

import { useBoolean } from '../../../hooks';
import { CookieConsentContext } from '../../../contexts';
import { Box, Translate } from '../../atoms';
import { PresenceHeightAnimation } from '../../animations';
import { useSelectedCookieSections, useExpandedCookieSections } from './hooks';
import { Container, PositionedContainer, Tabs, Tab, ConsentButton, ActionsContainer } from './styles';
import { ConsentTab } from './ConsentTab';
import { DetailsTab } from './DetailsTab';
import { AboutTab } from './AboutTab';
import { CookieConsentProps } from './types';

interface CookieConsentBaseProps {
  className?: string;
}

export const CookieConsentBase = (props: CookieConsentBaseProps) => {
  const consent = useContext(CookieConsentContext);
  const [activeTab, setActiveTab] = useState(0);

  const { selectedSections, agreeAll, denyAll, toggleSectionHandlers } = useSelectedCookieSections();

  const { expandedSections, toggleSectionExpandedHandlers } = useExpandedCookieSections();

  const onDenyAll = () => {
    const newSelectedSections = denyAll();
    consent.selectCookieSections(newSelectedSections);
  };

  const onAgreeAll = () => {
    const newSelectedSections = agreeAll();
    consent.selectCookieSections(newSelectedSections);
  };

  const onAgreeSelection = () => {
    consent.selectCookieSections(selectedSections);
  };

  const tabs = useMemo(
    () => [
      {
        nameKey: 'components.cookieConsent.consentTab',
        component: ConsentTab,
      },
      {
        nameKey: 'components.cookieConsent.detailsTab',
        component: () => (
          <DetailsTab
            selectedSections={selectedSections}
            toggleSectionHandlers={toggleSectionHandlers}
            expandedSections={expandedSections}
            toggleSectionExpandedHandlers={toggleSectionExpandedHandlers}
          />
        ),
      },
      {
        nameKey: 'components.cookieConsent.aboutTab',
        component: AboutTab,
      },
    ],
    [selectedSections, toggleSectionHandlers],
  );

  return (
    <Container className={props.className} $testId="container:cookie">
      <Tabs>
        {tabs.map((tab, idx) => (
          <Tab key={tab.nameKey} active={idx === activeTab} onClick={() => setActiveTab(idx)}>
            <Translate i18nKey={tab.nameKey} />
          </Tab>
        ))}
      </Tabs>
      {tabs.map((tab, idx) => {
        const TabComponent = tab.component;
        return (
          <PresenceHeightAnimation $animateOpacity key={`content-${tab.nameKey}`}>
            {idx === activeTab && <TabComponent />}
          </PresenceHeightAnimation>
        );
      })}
      <ActionsContainer>
        <Box as={ConsentButton} flex="1" onClick={onDenyAll} $testId="btn:cookie:deny">
          <Translate i18nKey="components.cookieConsent.deny" />
        </Box>
        {activeTab === 1 ? (
          <Box as={ConsentButton} flex="1" onClick={onAgreeSelection} $testId="btn:cookie:allowSelection">
            <Translate i18nKey="components.cookieConsent.allowSelection" />
          </Box>
        ) : (
          <Box as={ConsentButton} flex="1" onClick={() => setActiveTab(1)} $testId="btn:cookie:customize">
            <Translate i18nKey="components.cookieConsent.customize" />
          </Box>
        )}
        <Box as={ConsentButton} flex="1" onClick={onAgreeAll} $testId="btn:cookie:allowAll">
          <Translate i18nKey="components.cookieConsent.allowAll" />
        </Box>
      </ActionsContainer>
    </Container>
  );
};

export const CookieConsent = (props: CookieConsentProps) => {
  const consent = useContext(CookieConsentContext);
  const [renderClientSideComponent, clientSideRenderHandlers] = useBoolean(false);

  // see https://blog.hao.dev/render-client-side-only-component-in-next-js
  // for the description of the problem. using 'solution 2' here to fix
  useEffect(() => {
    clientSideRenderHandlers.turnOn();
  }, []);

  const shouldRenderConsent = consent.initialized && consent.pendingResponse && renderClientSideComponent;

  return (
    <AnimatePresence>
      {shouldRenderConsent && <PositionedContainer {...props} as={CookieConsentBase} />}
    </AnimatePresence>
  );
};
