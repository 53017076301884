import { formatISO } from 'date-fns';
import {
  AcquisitionChannel,
  CustomerPayload,
  ValidateCartItem,
  ValidateCartNewAppointment,
  ValidateCartPayload,
  ValidateCartPayment,
  ValidateCartPaymentCommon,
  ValidatedCartTip,
} from '@water-web/types';

import { CartModel, ReservationModel, CartCheckoutType } from '../models';

export class ValidateCartDtoFactory {
  static getDataForValidation(cart: CartModel): ValidateCartPayload {
    const factory = new ValidateCartDtoFactory(cart);
    return factory.toValidateCartPayload();
  }

  constructor(cart: CartModel) {
    this.cart = cart;
  }

  private cart: CartModel = null;

  toValidateCartPayload(): ValidateCartPayload {
    const customer = this.getCustomer();

    return {
      ...(customer ? { customer } : {}),
      tips: this.getTips(),
      payments: this.getPayments(),
      discounts: this.getDiscounts(),
      items: [...this.getProducts()],
      new_appointments: this.getNewAppts(),
      promoCode: this.getPromoCode(),
      giftCardCode: this.getGiftCardCode(),
      isBookNoPay: this.cart.getCheckoutType() === CartCheckoutType.bookNoCard,
      isReservation: this.cart.getCheckoutType() === CartCheckoutType.bookProvideCard,
    };
  }

  private getProducts(): ValidateCartItem[] {
    return this.cart.getProducts().map((productWithQuantity) => {
      const product = productWithQuantity.getModel();
      return {
        id: product.getId(),
        quantity: productWithQuantity.getQuantity(),
        type: 'product',
      };
    });
  }

  private getPromoCode(): string {
    return this.cart.getPromoCode();
  }

  private getGiftCardCode(): string {
    return this.cart.getGiftCardCode();
  }

  private getPayments(): ValidateCartPayment[] {
    const cartModel = this.cart;
    const cart = cartModel.getValidatedCart()?.getDataValues();

    if (!cart) {
      return [];
    }

    const commonInfo: ValidateCartPaymentCommon = {
      type: 'card',
      amount: cart.breakdown.amountLeftForPayment,
    };

    const paymentToken = cartModel.getPaymentToken();
    if (paymentToken) {
      return [{ ...commonInfo, paymentToken }];
    }

    const paymentCardId = cartModel.getPaymentCardId();
    if (paymentCardId && cartModel.getProceedWithExistingCard()) {
      return [{ ...commonInfo, paymentCardId }];
    }

    return [];
  }

  private getDiscounts(): [] {
    return [];
  }

  private getTips(): Omit<ValidatedCartTip, 'id'>[] {
    return this.cart
      .getReservations()
      .filter((reservation) => reservation.getTip())
      .map((reservation) => {
        return {
          amount: reservation.getTip().amount,
          barberId: ValidateCartDtoFactory.getReservationBarberId(reservation),
          tipPercentage: reservation.getTip().percentage ?? null,
        };
      });
  }

  private getNewAppts(): ValidateCartNewAppointment[] {
    const reservationToPayload = (reservation: ReservationModel): ValidateCartNewAppointment => {
      const barberId = ValidateCartDtoFactory.getReservationBarberId(reservation);
      const services = [reservation.getService(), ...reservation.getAddons()];
      const customer = reservation.getCustomer()?.toJson() as unknown as CustomerPayload;
      const dateTime = reservation.getDateTime() ? formatISO(reservation.getDateTime()) : null;

      const newAppt: ValidateCartNewAppointment = {
        dateTime,
        customer: customer?.firstName ? customer : undefined,
        barberId,
        barberPasscode: reservation.getValidatedPasscode(),
        bookedWithAnyBarber: reservation.getIsAnyBarber() || reservation.getBookedWithAnyBarber(),
        tipPercentage: reservation.getTip()?.percentage,
        services: services.filter(Boolean).map((service) => {
          return {
            id: service?.getId() || null,
          };
        }),
      };

      // the name must match what we have on the API side, so we must control what we pass down
      const allowedChannels: AcquisitionChannel[] = ['SQUIRE', 'Google', 'Google Ads', 'Meta Ads'];
      if (allowedChannels.includes(this.cart.getAcquisitionChannel())) {
        newAppt.channel = {
          name: this.cart.getAcquisitionChannel(),
          shopId: reservation.getShop().getId(),
        };
      }

      return newAppt;
    };

    return this.cart.getReservations().map((rsrv) => reservationToPayload(rsrv));
  }

  private getCustomer(): CustomerPayload | null {
    const customer = this.cart.getCustomer();
    return customer ? (customer.toJson() as unknown as CustomerPayload) : null;
  }

  static getReservationBarberId(reservation: ReservationModel): string | undefined {
    const canApplyCandidate = reservation.getIsAnyBarber() && !!reservation.getAnyBarberCandidate();
    if (canApplyCandidate) {
      return reservation.getAnyBarberCandidate().getId();
    }

    const canApplyRealBarber = !reservation.getIsAnyBarber() && reservation.getBarber();
    if (canApplyRealBarber) {
      return reservation.getBarber().getId();
    }

    return undefined;
  }
}
