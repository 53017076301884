import { AnimatePresence, AnimatePresenceProps, motion } from 'framer-motion';
import styled from 'styled-components';
import { ComponentType, Children, PropsWithChildren } from 'react';

import { Box, BoxProps } from '../../atoms';

interface PresenceHeightProps extends BoxProps, Pick<AnimatePresenceProps, 'mode' | 'onExitComplete'> {
  as?: string | ComponentType;
  $duration?: number;

  [key: string]: unknown;
}

const offset = 0;

const AnimationContainer = styled(Box).attrs<PresenceHeightProps>((props) => ({
  as: typeof props.as === 'string' ? motion[props.as] : motion(props.as, { forwardMotionProps: true }),
  layout: true,
  initial: {
    y: offset,
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    position: 'relative',
    top: offset,
  },
  animate: {
    y: 0,
    height: null,
    opacity: [0, 0, 1],
    overflow: 'visible',
    position: 'relative',
    top: [offset, offset, 0],
  },
  exit: {
    y: offset,
    height: 0,
    opacity: [1, 0, 0],
    overflow: 'visible',
    top: 0,
  },
  transition: {
    duration: props.$duration ?? props.theme.animations.booking.pageTransition.duration * 2,
    ease: 'easeInOut',
  },
}))<PresenceHeightProps>``;

export const PresenceHeightWithFade = ({
  mode,
  onExitComplete,
  children,
  ...props
}: PropsWithChildren<PresenceHeightProps>) => {
  return (
    <AnimatePresence {...{ mode, onExitComplete }}>
      {Children.map(children, (child) => child && <AnimationContainer {...props}>{child}</AnimationContainer>)}
    </AnimatePresence>
  );
};

PresenceHeightWithFade.defaultProps = {
  as: 'div',
};
