import { parsePhoneNumber } from 'libphonenumber-js';
import { Customer, CustomerPayload, NotificationsConsent } from '@water-web/types';

import { BaseModel } from './base';

const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// extend this function whenever you need to implement a custom phone format validation
const isAlternativelyValidPhone = (phone: string): boolean => {
  return /^\+440\d{9}$/.test(phone);
};

export class CustomerModel extends BaseModel<CustomerModel> {
  protected dataValues: Customer;

  private notificationConsents: NotificationsConsent = null;
  private isSmsPromotionConsentAccepted: boolean = null;
  private isEmailPromotionConsentAccepted: boolean = null;

  constructor(customer: Customer | CustomerPayload) {
    super();

    this.dataValues = {
      id: `${Date.now()}`,
      ...(customer || {}),
      ...(customer?.phone && !customer.phone.startsWith('+') ? { phone: `+${customer.phone}` } : {}),
    } as Customer;
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  setFirstName(name: string | null): CustomerModel {
    this.dataValues.firstName = name;
    this.resetNotificationConsents();
    return this;
  }

  getFirstName(): string {
    return this.dataValues.firstName as string;
  }

  setLastName(name: string | null): CustomerModel {
    this.dataValues.lastName = name;
    this.resetNotificationConsents();
    return this;
  }

  getLastName(): string {
    return this.dataValues.lastName as string;
  }

  getFullName(): string {
    return [this.getFirstName(), this.getLastName()].filter(Boolean).join(' ').trim();
  }

  getEmail(): string {
    return this.dataValues.email as string;
  }

  getPhone(): string {
    return this.dataValues.phone as string;
  }

  getPreferredLanguage(): string {
    return (this.dataValues.preferredLanguage as string) || 'en';
  }

  setPreferredLanguage(lang: string): CustomerModel {
    this.dataValues.preferredLanguage = lang;
    return this;
  }

  isValidEmail(): boolean {
    const email = this.getEmail();
    return !!email && EMAIL_REGEXP.test(email);
  }

  isValidPhone(): boolean {
    const phone = this.getPhone();

    if (!phone) {
      return false;
    }

    if (isAlternativelyValidPhone(phone)) {
      return true;
    }

    try {
      return parsePhoneNumber(phone).isValid();
    } catch (_e) {
      return false;
    }
  }

  /**
   * @deprecated Use `isUserInfoValid` from `view` package
   */
  isValid(): boolean {
    return !!(this.isValidEmail() && this.isValidPhone() && this.getLastName() && this.getFirstName());
  }

  setNotificationConsents(consents: NotificationsConsent | null): CustomerModel {
    this.notificationConsents = consents;
    return this;
  }

  setSmsPromotionConsentAccepted(value: boolean): CustomerModel {
    this.isSmsPromotionConsentAccepted = value;
    return this;
  }

  getSmsPromotionConsentAccepted(): boolean {
    return this.isSmsPromotionConsentAccepted;
  }

  setEmailPromotionConsentAccepted(value: boolean): CustomerModel {
    this.isEmailPromotionConsentAccepted = value;
    return this;
  }

  getEmailPromotionConsentAccepted(): boolean {
    return this.isEmailPromotionConsentAccepted;
  }

  getShouldShowSmsPromotionConsent(): boolean {
    // return true as a fallback because it better to show if not sure
    return this.notificationConsents?.showPrompt?.promotional?.phone ?? true;
  }

  getShouldShowEmailPromotionConsent(): boolean {
    // return true as a fallback because it better to show if not sure
    return this.notificationConsents?.showPrompt?.promotional?.email ?? true;
  }

  resetNotificationConsents(): CustomerModel {
    this.notificationConsents = null;
    this.isSmsPromotionConsentAccepted = null;
    this.isEmailPromotionConsentAccepted = null;

    return this;
  }

  toJson(): Record<string, unknown> {
    return {
      firstName: this.getFirstName() || '',
      lastName: this.getLastName() || '',
      phone: this.getPhone() || '',
      email: this.getEmail() || '',
      preferredLanguage: this.getPreferredLanguage() || '',
    };
  }

  getDataValues(): Readonly<Customer> {
    return this.dataValues;
  }
}
