export interface EventListener {
  (event: Event): void;
}

export interface EventMap {
  [type: string]: EventListener[];
}

export interface PolyfillEventTarget {
  addEventListener(type: string, listener: EventListener): void;
  removeEventListener(type: string, listener: EventListener): void;
  dispatchEvent(event: Event): void;
}

export const createEventTarget = (): EventTarget | PolyfillEventTarget => {
  if (
    typeof EventTarget === 'function' &&
    Object.getOwnPropertyDescriptor(EventTarget.prototype, 'constructor')?.writable
  ) {
    return new EventTarget();
  }

  const events: EventMap = {};

  return {
    addEventListener(type: string, listener: EventListener) {
      if (!events[type]) {
        events[type] = [];
      }
      events[type].push(listener);
    },
    removeEventListener(type: string, listener: EventListener) {
      if (!events[type]) return;
      events[type] = events[type].filter((l) => l !== listener);
    },
    dispatchEvent(event: Event) {
      if (!events[event.type]) return;
      events[event.type].forEach((listener) => listener(event));
    },
  };
};
