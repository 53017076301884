import { formatISO } from 'date-fns';
import { WaitingListTimeSlot } from '@water-web/types';

import { Repository } from './base';
import { RepositoryResponse } from '../utils';
import { CartModel, WaitingListModel } from '../models';

export class WaitingListRepository extends Repository {
  /**
   * @deprecated Use `WaitingListQuery` instead
   */
  async getById(id: string): Promise<RepositoryResponse<WaitingListModel>> {
    const response = await this.api.waitingList.getById(id, { include: ['customer', 'services'] });

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      error: null,
      data: new WaitingListModel(response.data),
    };
  }

  /**
   * @description Removes customer from a waiting list
   */
  async removeById(waitingListApplication: WaitingListModel): Promise<RepositoryResponse<{ ok: boolean }>> {
    return this.api.waitingList.removeById(waitingListApplication.getId());
  }

  /**
   * @description Updates tip amount of an existing waiting list
   * @deprecated Use `WaitingListTipMutation` instead
   */
  async changeTip(waitingList: WaitingListModel, tipAmount: number): Promise<RepositoryResponse<WaitingListModel>> {
    const response = await this.api.waitingList.changeTip(waitingList.getId(), tipAmount);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    waitingList.setTipAmount(tipAmount);

    return {
      error: null,
      data: waitingList,
    };
  }

  /**
   * @description Accept a time slot from the list of waiting list available slots
   * @deprecated Use `WaitingListAcceptSlotMutation` instead
   */
  async acceptTimeSlot(
    waitingList: WaitingListModel,
    timeSlot: WaitingListTimeSlot,
  ): Promise<RepositoryResponse<WaitingListModel>> {
    const response = await this.api.waitingList.acceptTimeSlot(waitingList.getId(), timeSlot.waitingListSlotId);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    waitingList.setSaleOrderId(response.data.saleOrderId);

    return {
      error: null,
      data: waitingList,
    };
  }

  /**
   * @description Create an appointment from a waiting list for an available time
   * @deprecated Use `WaitingListAcceptRescheduleMutation` instead
   */
  async reschedule(waitingList: WaitingListModel, cart: CartModel): Promise<RepositoryResponse<WaitingListModel>> {
    const reservation = cart.getActiveReservation();
    const response = await this.api.waitingList.reschedule(
      waitingList.getId(),
      formatISO(reservation.getDateTime()),
      reservation.getBarber().getId(),
    );

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    waitingList.setSaleOrderId(response.data.saleOrderId);

    return {
      error: null,
      data: waitingList,
    };
  }

  /**
   * @description Updates timeslots of an existing waiting list
   * @deprecated Use `WaitingListTimeSlotsQuery` instead
   */
  async getTimeSlots(waitingList: WaitingListModel, slotId: string): Promise<RepositoryResponse<WaitingListModel>> {
    const response = await this.api.waitingList.getTimeSlots(waitingList.getId(), slotId);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    waitingList.setTimeSlots(response.data.times);

    return {
      error: null,
      data: waitingList,
    };
  }
}
