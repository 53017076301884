import { createContext, PropsWithChildren, useState, useRef, useMemo } from 'react';

type ContextValue = {
  /**
   * Whether the sign-in flow is currently active
   */
  isOpen: boolean;
  /**
   * Will open the first of the sign-in flow popups
   */
  open: () => void;
  /**
   * Will close currently active popup and reset the sign-in flow.
   */
  close: () => void;
};

export const SignInOpenContext = createContext<ContextValue>({
  isOpen: false,
  open: () => undefined,
  close: () => undefined,
});

export const SignInOpenContextProvider = (props: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const methodsRef = useRef<Omit<ContextValue, 'isOpen'>>({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  });

  const value = useMemo(
    () => ({
      isOpen,
      ...methodsRef.current,
    }),
    [isOpen],
  );

  return <SignInOpenContext.Provider value={value}>{props.children}</SignInOpenContext.Provider>;
};
