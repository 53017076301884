import { QueryKey } from 'react-query';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';
import { Schedule } from './types';
import { getScheduleFetchIntervals } from './utils';
import { SingleApptSchedulePayload } from './payloads';

const MAX_WEEKS_IN_REQUEST = 4;

export class SingleApptScheduleQuery extends EndpointQuery<Schedule[], SingleApptSchedulePayload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: SingleApptSchedulePayload): QueryKey {
    return queryKeys.singleApptSchedule(payload);
  }

  async request(payload: SingleApptSchedulePayload, signal?: AbortSignal): Promise<Schedule[]> {
    const intervals = getScheduleFetchIntervals(payload.fromDate, payload.toDate, MAX_WEEKS_IN_REQUEST);

    const urlsToRequest = intervals.map(({ start, end }) =>
      ['/v1/barber', payload.barberId, 'schedule-time-range', start, end, payload.duration].filter(Boolean).join('/'),
    );

    const requests = urlsToRequest.map<Promise<Schedule[]>>((url) =>
      this.http.get(url, {
        headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
        signal,
        resourceUrl: '/barber/:id/schedule-time-range/:start/:end/:duration',
      }),
    );

    const responses = await Promise.all(requests);

    return responses.reduce((acc, val) => acc.concat(val), []);
  }
}
