import { QueryKey } from 'react-query';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';
import { GroupAppointmentSchedule } from './types';
import { getScheduleFetchIntervals } from './utils';
import { GroupApptSchedulePayload } from './payloads';

const MAX_WEEKS_IN_REQUEST = 2;

export class GroupApptsScheduleQuery extends EndpointQuery<GroupAppointmentSchedule[], GroupApptSchedulePayload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: GroupApptSchedulePayload): QueryKey {
    return queryKeys.groupApptSchedule(payload);
  }

  async request(payload: GroupApptSchedulePayload, signal?: AbortSignal): Promise<GroupAppointmentSchedule[]> {
    const intervals = getScheduleFetchIntervals(payload.fromDate, payload.toDate, MAX_WEEKS_IN_REQUEST);

    const urlsToRequest = intervals.map(({ start, end }) =>
      ['/v2/shop', payload.shopId, 'schedule-time-range', start, end].join('/'),
    );

    const requests = urlsToRequest.map<Promise<GroupAppointmentSchedule[]>>((url) =>
      this.http.post(
        url,
        { appointments: payload.appointments },
        {
          headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
          signal,
          resourceUrl: '/shop/:id/schedule-time-range/:start/:end',
        },
      ),
    );

    const responses = await Promise.all(requests);

    return responses.reduce((acc, val) => acc.concat(val), []);
  }
}
