import { DefaultTheme, InterpolationFunction, ThemedStyledProps } from 'styled-components';
import { get } from 'styled-system';

const LIGHTEN_INTERVAL = 40;

interface RGB {
  r: number;
  g: number;
  b: number;
}

const lightenComponent = (rgbComponent: number, lightenSteps: number): number =>
  Math.min(255, Math.max(0, rgbComponent + LIGHTEN_INTERVAL * lightenSteps));

const hexToRgb = (hex: string): RGB => {
  let fullHex = hex;

  if (hex.length === 4) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    fullHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  }

  const parsedHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  return parsedHex
    ? {
        r: parseInt(parsedHex[1], 16),
        g: parseInt(parsedHex[2], 16),
        b: parseInt(parsedHex[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const stringToRgb = (color: string): RGB => {
  if (!color.startsWith('rgb')) {
    return hexToRgb(color);
  }

  const parsedRgb = /^rgba?\((\d+),\s?(\d+),\s?(\d+)/i.exec(color);
  return parsedRgb
    ? {
        r: parseInt(parsedRgb[1], 10),
        g: parseInt(parsedRgb[2], 10),
        b: parseInt(parsedRgb[3], 10),
      }
    : { r: 0, g: 0, b: 0 };
};

const rgbToHex = (rgb: RGB): string =>
  // eslint-disable-next-line no-bitwise
  `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;

export const lighten = (hexColor: string, lightenSteps: number): string => {
  const rgb = stringToRgb(hexColor);
  rgb.r = lightenComponent(rgb.r, lightenSteps);
  rgb.g = lightenComponent(rgb.g, lightenSteps);
  rgb.b = lightenComponent(rgb.b, lightenSteps);
  return rgbToHex(rgb);
};

export const darken = (hexColor: string, darkenSteps: number): string => lighten(hexColor, -darkenSteps);

const getLuminance = (hexColor: string): number => {
  const rgb = stringToRgb(hexColor);
  return (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114) / 256;
};

export const isCloseToWhite = (hexColor: string): boolean => getLuminance(hexColor) > 0.9;

export const isCloseToBlack = (hexColor: string): boolean => getLuminance(hexColor) < 0.1;

type ColorName = keyof DefaultTheme['colors'] | string;
export const defaultAndBrandedColors = (
  defaultColor: ColorName,
  brandedColor: ColorName,
): InterpolationFunction<ThemedStyledProps<unknown, DefaultTheme>> => {
  return (props) => {
    const colorName = props.theme.hasPremiumBranding ? brandedColor : defaultColor;

    return get(props.theme.colors, colorName, colorName);
  };
};
