import { useTranslation, Trans } from 'react-i18next';

import { Translate } from '../../atoms';
import { AboutTabContent, BodyText } from './styles';

export const AboutTab = () => {
  const { t } = useTranslation();

  return (
    <AboutTabContent flexDirection="column" gap="16px">
      <BodyText>
        <Translate i18nKey="components.cookieConsent.aboutTabInfo.cookieDef" />
      </BodyText>
      <BodyText>
        <Translate i18nKey="components.cookieConsent.aboutTabInfo.law" />
      </BodyText>
      <BodyText>
        <Translate i18nKey="components.cookieConsent.aboutTabInfo.thisSiteCookies" />
      </BodyText>
      <BodyText>
        <Trans t={t} i18nKey="components.cookieConsent.aboutTabInfo.cookiesPolicy">
          Check out our
          <BodyText as="a" target="_blank" href="https://getsquire.com/cookies-policy">
            cookies policy
          </BodyText>
          .
        </Trans>
      </BodyText>
      <BodyText>
        <Trans t={t} i18nKey="components.cookieConsent.aboutTabInfo.privacyPolicy">
          Check out our
          <BodyText as="a" target="_blank" href="https://getsquire.com/privacy-policy">
            privacy policy
          </BodyText>
          .
        </Trans>
      </BodyText>
    </AboutTabContent>
  );
};
