import { Barber } from '@water-web/types';

import { getIsDayEnabledFromSchedules } from './schedules';

export const ANY_BARBER_ID_AND_ROUTE = 'any';

export const ANY_BARBER_OBJECT = {
  id: ANY_BARBER_ID_AND_ROUTE,
  route: ANY_BARBER_ID_AND_ROUTE,
  canCustomerCancel: false,
  advanceCancelMins: 0,
  days_on: [],
  days_off: [],
  earlyTipping: true,
  lateTipping: true,
} as Readonly<Barber>;

export const getIsAnyFromBarber = (barber: Pick<Barber, 'id'>): boolean => {
  return barber.id === ANY_BARBER_ID_AND_ROUTE;
};

export const getAvatarUrlFromBarber = (barber: Barber): string | undefined => {
  const photo = barber.photos?.[0];
  return photo?.thumbnail || photo?.url;
};

/**
 * @param barber
 * @param day - ISO 8601 date: `2021-01-23`
 */
export const getIsDayEnabledFromBarber = (
  barber: Pick<Barber, 'id' | 'days_on' | 'days_off' | 'schedules'>,
  day: string,
): boolean => {
  if (getIsAnyFromBarber(barber)) {
    throw new Error("Can't check Any Barber's schedule. Please use `getIsDayEnabledFromShop()` with `Shop` schedule");
  }

  const isDayOff = barber.days_off.some((dayOff) => dayOff.wholeDay && dayOff.day.startsWith(day));
  if (isDayOff) {
    return false;
  }

  const isDayOn = barber.days_on.some((dayOn) => dayOn.day.startsWith(day));
  if (isDayOn) {
    return true;
  }

  return getIsDayEnabledFromSchedules(barber.schedules, day);
};
