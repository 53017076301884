import { CookiesSectionDef } from './types';
import { CloudflareIcon, FacebookIcon, GoogleIcon, StripeIcon } from './icons';

export const cookieSections: CookiesSectionDef[] = [
  {
    key: 'necessary',
    name: 'Necessary',
    isRequired: true,
    description:
      'We use necessary cookies to help make our website the most user-friendly, enabling the navigation of the booking experience, scheduling services, and making payments.',
    subsections: [
      {
        name: 'Stripe',
        description:
          'We use Stripe to process card payments. Stripe uses a cookie to remember who you are and to enable us to process payments without storing any card information on our servers.',
        Icon: StripeIcon,
        cookies: [
          {
            name: '__stripe_sid',
            description:
              'Set for fraud prevention purposes and helps Stripe assess the risk associated with an attempted transaction.',
            expiry: '30 minutes',
            type: 'HTTP',
          },
          {
            name: '__stripe_mid',
            description:
              'Set for fraud prevention purposes and helps Stripe assess the risk associated with an attempted transaction.',
            expiry: '1 year',
            type: 'HTTP',
          },
          {
            name: 'm',
            description:
              'Set for fraud prevention purposes and helps Stripe assess the risk associated with an attempted transaction.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: 'cookie-perms',
            description:
              'Set to keep Stripe services working correctly, like showing you the right information for your selected location.',
            expiry: '6 months',
            type: 'HTTP',
          },
        ],
      },
      {
        name: 'Google',
        description:
          'We use Google for various services such as Google Pay and Google Calendar and store its cookies to ensure proper functioning.',
        Icon: GoogleIcon,
        cookies: [
          {
            name: 'G_ENABLED_IDPS',
            description: 'Set by Google to enable Single Sign On.',
            expiry: '∞',
            type: 'HTTP',
          },
          {
            name: 'NID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '6 months',
            type: 'HTTP',
          },
          {
            name: '1P_JAR',
            description: 'Set for the proper functioning of Google services.',
            expiry: '1 month',
            type: 'HTTP',
          },
          {
            name: 'SID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: 'SSID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: 'HSID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: 'SIDCC',
            description: 'Set for the proper functioning of Google services.',
            expiry: '1 year',
            type: 'HTTP',
          },
          {
            name: 'APISID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-1PSID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-1PAPISID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-3PAPISID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-3PSID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-3PSIDCC',
            description: 'Set for the proper functioning of Google services.',
            expiry: '1 year',
            type: 'HTTP',
          },
          {
            name: '__Secure-APISID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-HSID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '__Secure-SSID',
            description: 'Set for the proper functioning of Google services.',
            expiry: '2 years',
            type: 'HTTP',
          },
        ],
      },
      {
        name: 'Cloudflare',
        description:
          'We use Cloudflare to ensure a pleasant user experience and store various cookies to maximize network resources, manage traffic, and protect our sites from malicious traffic.',
        Icon: CloudflareIcon,
        cookies: [
          {
            name: '__cf_bm',
            description: 'Set for the proper functioning of Bot Management or Bot Fight functionality.',
            expiry: '30 minutes',
            type: 'HTTP',
          },
        ],
      },
    ],
  },
  {
    key: 'statistics',
    name: 'Statistical',
    isRequired: false,
    description:
      'We use statistical cookies to help us understand how you interact with our website by collecting and reporting information anonymously.',
    subsections: [
      {
        name: 'Google Analytics',
        description:
          'We use Google Analytics and its cookies to collect information about how users, like yourself, use our website.',
        Icon: GoogleIcon,
        cookies: [
          {
            name: 'DV',
            description: 'Used by Google Analytics to provide an aggregate analysis of Website visitors.',
            expiry: '6 hours',
            type: 'HTTP',
          },
          {
            name: 'OTZ',
            description: 'Used by Google Analytics to provide an aggregate analysis of Website visitors.',
            expiry: '2 years',
            type: 'HTTP',
          },
          {
            name: '_gat',
            description: 'Used by Google Analytics to throttle request rate.',
            expiry: '1 munute',
            type: 'HTTP',
          },
          {
            name: '_gid',
            description: 'Used by Google Analytics to distinguish users.',
            expiry: '24 hours',
            type: 'HTTP',
          },
        ],
      },
      {
        name: 'Facebook Pixel',
        description:
          'We use Facebook and its cookies to collect information about how users, like yourself, use our website.',
        Icon: FacebookIcon,
        cookies: [
          {
            name: '_fbp',
            description: 'Used by Facebook to distinguish users.',
            expiry: '15 months',
            type: 'HTTP',
          },
          {
            name: 'fr',
            description: 'Used by Facebook to deliver, measure and improve the relevancy of ads.',
            expiry: '3 months',
            type: 'HTTP',
          },
          {
            name: 'datr',
            description: 'Used by Facebook to provide an aggregate analysis of Website visitors.',
            expiry: '2 years',
            type: 'HTTP',
          },
        ],
      },
    ],
  },
];
