import { AppleDeepLinksConfig } from '@water-web/types';

import { BaseModel } from './base';

export class AppleDeepLinksConfigModel extends BaseModel<AppleDeepLinksConfigModel> {
  constructor(config: AppleDeepLinksConfig) {
    super();

    this.dataValues.applinks = config.applinks;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
