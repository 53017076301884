import { Nullable } from '@water-web/types';
import { createContext, FC } from 'react';

export interface AppSettings {
  apiUrl: string;
}

export interface AppSettingsProviderProps {
  config: AppSettings;
  children: React.ReactNode;
}

export const AppSettingsContext = createContext<Nullable<AppSettings>>(null);

export const AppSettingsContextProvider: FC<AppSettingsProviderProps> = (props) => {
  const { config, children } = props;
  return <AppSettingsContext.Provider value={config}>{children}</AppSettingsContext.Provider>;
};
