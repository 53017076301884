import { Box } from '@water-web/view';
import { PropsWithChildren, useEffect, useRef } from 'react';

interface WithClickOutsideProps {
  onClickOutside: () => void;
}

export const WithClickOutside = (props: PropsWithChildren<WithClickOutsideProps>) => {
  const ref = useRef();

  // Handle clicks that happen outside the component boundaries, so we can hide gallery then
  useEffect(() => {
    const handleClickInOrOutside = (event) => {
      const withinBoundaries = event.composedPath().includes(ref.current);

      if (!withinBoundaries) {
        props.onClickOutside();
      }
    };

    document.addEventListener('click', handleClickInOrOutside);
    return () => document.removeEventListener('click', handleClickInOrOutside);
  }, [props.onClickOutside]);

  return <Box ref={ref}>{props.children}</Box>;
};
