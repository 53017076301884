import { persistentKVStore } from '../persistence';

const pixelIdsByBrandId = {
  'bceeb0f6-2f05-4932-82b3-89cc03fd11a7': '990485269615954', // LifeLine
};

// once we store the pixel ids in the database, we can deprecate this function
export const getBrandPixelId = (brandId: string, debug = false): string | null => {
  if (debug && typeof window !== 'undefined') {
    const storedDebugPixelId = persistentKVStore.getItem('debug_facebook_pixel_id');
    if (storedDebugPixelId) {
      return storedDebugPixelId;
    }
  }

  return pixelIdsByBrandId[brandId] || null;
};
