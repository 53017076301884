import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import widgetMenuIcon from './widgetMenu.svg';

export const WidgetMenu = styled(JustIcon).attrs({
  alt: 'widgetMenu',
  icon: widgetMenuIcon,
  width: '10px',
  height: '10px',
})`` as JustIconStyledComponent<IconStaticProps>;
