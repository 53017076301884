import { ComponentType } from 'react';

import { PreloadableComponent, lazyWithPreload } from '@app/utils/lazyWithPreload';

import { PopupAlias } from './types';

export const LocationInfoPopup = lazyWithPreload(() =>
  import('./popups/LocationInfoPopup').then((module) => ({ default: module.LocationInfoPopup })),
);
export const BarberInfoPopup = lazyWithPreload(() =>
  import('./popups/BarberInfoPopup').then((module) => ({ default: module.BarberInfoPopup })),
);
export const LocationRequestPopup = lazyWithPreload(() =>
  import('./popups/LocationRequestPopup').then((module) => ({ default: module.LocationRequestPopup })),
);
export const LocationDeniedPopup = lazyWithPreload(() =>
  import('./popups/LocationDeniedPopup').then((module) => ({ default: module.LocationDeniedPopup })),
);
export const PoliciesPopup = lazyWithPreload(() =>
  import('./popups/PoliciesPopup').then((module) => ({ default: module.PoliciesPopup })),
);
export const CheckoutFormPopup = lazyWithPreload(() =>
  import('./popups/CheckoutFormPopup').then((module) => ({ default: module.CheckoutFormPopup })),
);
export const CardFormPopup = lazyWithPreload(() =>
  import('./popups/CardFormPopup').then((module) => ({ default: module.CardFormPopup })),
);
export const AddCodePopup = lazyWithPreload(() =>
  import('./popups/AddCodePopup').then((module) => ({ default: module.AddCodePopup })),
);
export const PhoneCodePopup = lazyWithPreload(() =>
  import('./popups/PhoneCodePopup').then((module) => ({ default: module.PhoneCodePopup })),
);
export const AppLinkPopup = lazyWithPreload(() =>
  import('./popups/AppLinkPopup').then((module) => ({ default: module.AppLinkPopup })),
);
export const PasscodePopup = lazyWithPreload(() =>
  import('./popups/PasscodePopup').then((module) => ({ default: module.PasscodePopup })),
);
export const WaitingListTimeSlotsPopup = lazyWithPreload(() =>
  import('./popups/WaitingListTimeSlotsPopup').then((module) => ({ default: module.WaitingListTimeSlotsPopup })),
);
export const WaitingListReschedulePopup = lazyWithPreload(() =>
  import('./popups/WaitingListReschedulePopup').then((module) => ({ default: module.WaitingListReschedulePopup })),
);
export const ReschedulePopup = lazyWithPreload(() =>
  import('./popups/ReschedulePopup').then((module) => ({ default: module.ReschedulePopup })),
);
export const CancelAppointmentPopup = lazyWithPreload(() =>
  import('./popups/CancelAppoitmentPopup').then((module) => ({ default: module.CancelAppointmentPopup })),
);

const componentsByAlias: Record<PopupAlias, PreloadableComponent<ComponentType>> = {
  locationInfo: LocationInfoPopup,
  barberInfo: BarberInfoPopup,
  locationRequest: LocationRequestPopup,
  locationDenied: LocationDeniedPopup,
  policies: PoliciesPopup,
  checkoutForm: CheckoutFormPopup,
  checkoutFormV2: CheckoutFormPopup,
  phoneCode: PhoneCodePopup,
  cardForm: CardFormPopup,
  addCode: AddCodePopup,
  appLink: AppLinkPopup,
  barberPasscode: PasscodePopup,
  waitingListTimeSlots: WaitingListTimeSlotsPopup,
  waitingListReschedule: WaitingListReschedulePopup,
  reschedule: ReschedulePopup,
  cancelAppointment: CancelAppointmentPopup,
};

export const preloadPopup = (popup: PopupAlias): void => {
  componentsByAlias[popup].preload();
};
