import { DefaultTheme } from 'styled-components';

import colors from './colors';
import { ThemeAnimations, ThemeSpacing } from '../../types';

const unit = 4;
const space = new Array(101).fill(null).map((_, idx) => unit * idx) as ThemeSpacing;
space.unit = unit;

const breakpoints = ['375px', '1024px', '1440px'];

const fontSizes = [12, 13, 15, 17, 18, 20, 22, 28, 34, 48];

const animations: ThemeAnimations = {
  booking: {
    pageTransition: {
      duration: 0.3,
      delay: 0.3,
    },
    interaction: 0.15,
  },
};

const defaultTheme: DefaultTheme = {
  colors,
  space,
  fontSizes,
  breakpoints,
  animations,
  hasPremiumBranding: false,
};

export default defaultTheme;
