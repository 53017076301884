import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { ILogger } from '../logger';
import { DatadogLogger } from './logger';

interface DatadogConfig {
  clientToken: string;
  service: string;
  environment: string;
  appId: string;
  version: string;
  sessionSampleRate?: string;
  traceSampleRate?: string;
}

export class DatadogService {
  private static logger: ILogger | null = null;

  static init(config: DatadogConfig) {
    if (['development', 'local', 'test'].includes(config.environment)) {
      return;
    }
    const defaultSessionSampleRate = 100;
    const defaultTraceSampleRate = 100;
    const configSessionSampleRate = Number(config.sessionSampleRate);
    const configTraceSampleRate = Number(config.traceSampleRate);

    const sessionSampleRate = Number.isNaN(configSessionSampleRate)
      ? defaultSessionSampleRate
      : configSessionSampleRate;
    const traceSampleRate = Number.isNaN(configTraceSampleRate) ? defaultTraceSampleRate : configTraceSampleRate;

    const mutualOptions = {
      clientToken: config.clientToken,
      service: config.service,
      env: config.environment,
      site: 'datadoghq.com' as const,
      version: config.version,
      sessionSampleRate,
      traceSampleRate,
    };

    datadogLogs.init({
      ...mutualOptions,
      silentMultipleInit: true,
    });

    datadogRum.init({
      ...mutualOptions,
      applicationId: config.appId,
      sessionReplaySampleRate: 0,
      allowedTracingUrls: [/https:\/\/.*\.api.*\.getsquire\.com/, /https:\/\/.*\.getsquire\.com/],
      trackResources: true,
      trackLongTasks: true,
    });

    DatadogService.logger = new DatadogLogger();
  }

  static getLogger(): ILogger | null {
    return DatadogService.logger;
  }
}
