import { formatISO } from 'date-fns';
import { HttpResponse, RescheduledAppointment } from '@water-web/types';

import { RepositoryResponse } from '../utils';
import { getIsInitialFromSaleOrderItem } from '../getters';
import { SaleOrderModel, ValidatedCartModel } from '../models';
import { Repository } from './base';

export class SaleOrderRepository extends Repository {
  /**
   * @deprecated Use `SaleOrderQuery` instead
   */
  async getById(id: string): Promise<RepositoryResponse<ValidatedCartModel>> {
    const response = await this.api.saleOrder.getById(id);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: new ValidatedCartModel(response.data),
      error: null,
    };
  }

  /**
   * @deprecated Use `SaleOrderAppointmentRefundMutation` instead
   */
  async refundItem(itemId: string, saleOrder: SaleOrderModel): Promise<RepositoryResponse<SaleOrderModel>> {
    const response = await this.api.saleOrder.refundItem(itemId, saleOrder.getId());

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    const validatedCart = new ValidatedCartModel(response.data);
    saleOrder.populate(validatedCart);

    return {
      data: saleOrder,
      error: null,
    };
  }

  /**
   * @deprecated Use `SaleOrderAppointmentRescheduleMutation` instead
   */
  async reschedule(
    saleOrder: SaleOrderModel,
    botProtectionToken?: string,
  ): Promise<RepositoryResponse<SaleOrderModel>> {
    const rescheduleItems = saleOrder.getReservations().filter((reservation) => {
      return (
        reservation.getAppointmentId() &&
        getIsInitialFromSaleOrderItem(reservation.getDataValues()) &&
        reservation.hasUpdatedDateTime()
      );
    });

    const promises = rescheduleItems.map<Promise<HttpResponse<RescheduledAppointment>>>((reservation) => {
      return this.api.saleOrder.rescheduleItem(
        reservation.getAppointmentId(),
        {
          barberId: reservation.getBarber().getId(),
          dateTime: formatISO(reservation.getDateTime()),
        },
        // FIXME: token is valid only once, so this is not going to work when we support multiple reservations
        botProtectionToken,
      );
    });

    const responses = await Promise.all(promises);

    const responseWithError = responses.find((response) => response.error);
    if (responseWithError) {
      return {
        data: null,
        error: responseWithError.error,
      };
    }

    const dataByAppointmentId = responses.reduce((map, response) => {
      map.set(response.data.id, response.data);
      return map;
    }, new Map<string, RescheduledAppointment>());

    const updatedReservations = saleOrder.getReservations().map((reservation) => {
      const appointmentId = reservation.getAppointmentId();
      if (!dataByAppointmentId.has(appointmentId)) {
        return reservation;
      }

      return reservation.updateAppointmentData(dataByAppointmentId.get(appointmentId));
    });

    return {
      error: null,
      data: saleOrder.setReservations(updatedReservations),
    };
  }
}
