import { Brand } from '@water-web/types';

import { BaseModel } from './base';
import { LocationModel } from './location';
import { ExtensionModel } from './extension';
import { BrandRepository } from '../repositories';
import { RepositoryResponse } from '../utils';
import { getExtensionFromBrand } from '../getters';

interface BrandModelInjections {
  brandRepository: BrandRepository;
}

export class BrandModel extends BaseModel<BrandModel> {
  protected dataValues: Readonly<Brand>;
  private brandRepository: BrandRepository = null;

  private readonly locations?: LocationModel[] = null;

  private locationsNearby?: LocationModel[] = null;

  private readonly extension?: ExtensionModel = null;

  constructor(brand: Brand, injections: BrandModelInjections) {
    super();

    this.brandRepository = injections.brandRepository;

    this.dataValues = {
      ...brand,
    };

    if (brand.shops) {
      this.locations = brand.shops.map((location) => new LocationModel(location));
    }

    const extension = getExtensionFromBrand(brand);
    if (extension) {
      this.extension = new ExtensionModel(extension);
    }
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  getRoute(): string {
    return this.getExtension()?.getBrandRoute();
  }

  getName(): string {
    return this.dataValues.name as string;
  }

  getDescription(): string {
    return this.dataValues.description as string;
  }

  getLocations(): LocationModel[] {
    return this.locations;
  }

  getSupportedLanguages(): string[] {
    return this.dataValues.settings?.supportedLanguages || ['en'];
  }

  getPreferredLanguage(): string {
    return this.dataValues.settings?.defaultLanguage || 'en';
  }

  async getLocationsNearby(lat: number, lng: number): Promise<RepositoryResponse<LocationModel[]>> {
    if (this.locationsNearby) {
      return {
        data: this.locationsNearby,
        error: null,
      } as RepositoryResponse<LocationModel[]>;
    }

    const response = await this.brandRepository.getByIdOrRouteWithLocationsNearby(
      this.dataValues.id as string,
      lat,
      lng,
    );
    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<LocationModel[]>;
    }

    this.locationsNearby = response.data.getLocations();
    return {
      data: this.locationsNearby,
      error: null,
    } as RepositoryResponse<LocationModel[]>;
  }

  getExtension(): ExtensionModel {
    return this.extension;
  }

  getDataValues(): Readonly<Brand> {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    const json: Record<string, unknown> = { ...this.dataValues };

    if (this.extension) {
      json.extesnion = this.extension.toJson();
    }

    if (this.locations) {
      json.shops = this.locations.map((location) => location.toJson());
    }

    return json;
  }
}
