import uuid from 'is-uuid';
import { Brand, BrandApiSearch } from '@water-web/types';

import { Repository } from './base';
import { BrandModel, AppleDeepLinksConfigModel, GoogleDeepLinksConfigModel } from '../models';
import { RepositoryResponse } from '../utils';

export class BrandRepository extends Repository {
  getFromJson(json: Brand): BrandModel {
    return new BrandModel(json, { brandRepository: this });
  }

  async getByIdOrRoute(idOrRoute: string): Promise<RepositoryResponse<BrandModel>> {
    let brandId = idOrRoute;

    if (!uuid.anyNonNil(idOrRoute)) {
      const response = await this.api.brand.getExtensionByRoute(idOrRoute);

      if (response.error) {
        return {
          data: null,
          error: response.error,
        } as RepositoryResponse<BrandModel>;
      }

      brandId = response.data.brandId;
    }

    const search: BrandApiSearch = {
      include: ['shops', 'shops.staticMap', 'shops.address', 'shops.photos', 'shops.instagramImages', 'extensions'],
    };
    const response = await this.api.brand.getByIdOrRoute(brandId, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<BrandModel>;
    }

    return {
      data: new BrandModel(response.data, { brandRepository: this }),
      error: null,
    } as RepositoryResponse<BrandModel>;
  }

  async getByIdOrRouteWithLocationsNearby(
    idOrRoute: string,
    lat: number,
    lng: number,
  ): Promise<RepositoryResponse<BrandModel>> {
    const search: BrandApiSearch = {
      lat,
      lng,
      include: ['shops', 'shops.staticMap', 'shops.address', 'shops.photos', 'shops.instagramImages', 'extensions'],
    };
    const response = await this.api.brand.getByIdOrRouteNearby(idOrRoute, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<BrandModel>;
    }

    return {
      data: new BrandModel(response.data.brand, { brandRepository: this }),
      error: null,
    } as RepositoryResponse<BrandModel>;
  }

  /**
   * @description
   * Returns a universal(deep) links config to feed apple. Should not be used in any presentation logic.
   */
  async getAppleDeepLinksConfig(): Promise<RepositoryResponse<AppleDeepLinksConfigModel>> {
    const response = await this.api.brand.getAppleDeepLinksConfig();

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: new AppleDeepLinksConfigModel(response.data),
      error: null,
    };
  }

  /**
   * @description
   * Returns a universal(deep) links config to feed google. Should not be used in any presentation logic.
   */
  async getGoogleDeepLinksConfig(): Promise<RepositoryResponse<GoogleDeepLinksConfigModel>> {
    const response = await this.api.brand.getGoogleDeepLinksConfig();

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: new GoogleDeepLinksConfigModel(response.data),
      error: null,
    };
  }
}
