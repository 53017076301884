import { AcquisitionChannel, ALLOWED_TRACKING_PARAMS, TrackingParams } from '@water-web/types';

import { Analytics } from './types';
import { sessionKVStore } from '../persistence';

const getAcquisitionChannel = (
  utmSource?: string,
  gclid?: string,
  fbclid?: string,
  rwgToken?: string,
): AcquisitionChannel | null => {
  if (!utmSource && !gclid && !fbclid && !rwgToken) {
    return null;
  }

  if (rwgToken) {
    return 'Google';
  }

  if (utmSource === 'city-pages') {
    return 'SQUIRE';
  }

  // Facebook does not rewrite gclid from links, so this would be tracked by GA anyway
  if (gclid) {
    return 'Google Ads';
  }

  if (utmSource?.toLocaleLowerCase() === 'facebook') {
    return 'Meta Ads';
  }

  return null;
};

export const trackIncomingVisitors = (analytics: Analytics): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');
  const gclid = urlParams.get('gclid');
  const fbclid = urlParams.get('fbclid');
  const rwgToken = urlParams.get('rwg_token');

  const urlAcqChannel = getAcquisitionChannel(utmSource, gclid, fbclid, rwgToken);
  const storedAcqChannel = sessionKVStore.getItem('acquisition-channel') as AcquisitionChannel;
  const acqChannel = urlAcqChannel || storedAcqChannel;

  if (acqChannel) {
    const utmMedium = urlParams.get('utm_medium');
    const isSquire = acqChannel === 'SQUIRE';
    const squireAcqChannelParams = {
      fromRolesPage: utmMedium === 'roles-page',
    };

    analytics.setAcquisitionChannel(acqChannel, isSquire ? squireAcqChannelParams : {});

    sessionKVStore.setItem('acquisition-channel', acqChannel);
  }
};

export const getApiTrackingParams = (): TrackingParams => {
  if (typeof window === 'undefined') {
    return {};
  }

  const trackingParams: TrackingParams = {};

  let urlParams = new URLSearchParams(window.location.search || '');

  const hasEnoughtUtmParams = (params: URLSearchParams) =>
    params.has('utm_source') || params.has('utm_medium') || params.has('utm_campaign');

  if (hasEnoughtUtmParams(urlParams)) {
    sessionKVStore.setItem('squire_search_string', urlParams.toString());
  } else {
    const paramsFromSessionStorage = sessionKVStore.getItem('squire_search_string') || '';
    urlParams = new URLSearchParams(paramsFromSessionStorage);
  }

  urlParams.forEach((value, key) => {
    if (ALLOWED_TRACKING_PARAMS.indexOf(key) !== -1) {
      trackingParams[key] = value;
    }
  });

  const timezone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (timezone) {
    trackingParams.timezone = timezone;
  }

  return trackingParams;
};
