import { useCallback, useMemo, useState } from 'react';
import { BarberModel, CartModel } from '@water-web/repository';

import { ExternalConfiguration } from '../../../config';

interface UseBarberReservation {
  (
    cart: CartModel,
    selectedBarber: BarberModel,
    isGcSelected: boolean,
  ): {
    areBarbersVisible: boolean;
    makeReservation: () => void;
  };
}

export const useBarberReservation: UseBarberReservation = (cart, selectedBarber, isGcSelected) => {
  const [areBarbersVisible, setAreBarbersVisible] = useState(true);
  const { barberRepository } = ExternalConfiguration.getRepository();

  const makeReservation = useCallback(() => {
    if (!selectedBarber && !isGcSelected) {
      return;
    }

    const reservation = cart.getActiveReservation();
    reservation.setBarber(selectedBarber ?? barberRepository.createAnyBarber());
    setAreBarbersVisible(false);
  }, [cart, selectedBarber, isGcSelected]);

  return useMemo(() => ({ makeReservation, areBarbersVisible }), [makeReservation, areBarbersVisible]);
};
