/* eslint-disable @typescript-eslint/no-unused-vars */
import { parseISO, formatISO } from 'date-fns';
import {
  ScheduleTimeRangeDayBase,
  ScheduleTimeRangeDayWithConflicts,
  ScheduleTimeItemWithConflicts,
  TimeItemConflict,
} from '@water-web/types';

import { BaseModel } from './base';
import { uniqBy } from '../utils';

export interface ScheduleConflict extends Omit<TimeItemConflict, 'start' | 'end'> {
  utcStart: Date;
  utcEnd: Date;
}

export interface ScheduleTime extends Omit<ScheduleTimeItemWithConflicts, 'conflicts'> {
  utcTime: Date;
  conflicts: ScheduleConflict[];
}

export type ScheduleDay = ScheduleTimeRangeDayBase<ScheduleTime>;

export class ScheduleTimeRangeModel extends BaseModel<ScheduleTimeRangeModel> {
  private days: Map<string, ScheduleDay> = null;

  constructor(daysOfRange: ScheduleTimeRangeDayWithConflicts[]) {
    super();

    this.days = new Map();
    const data = this.sortByAndUniqByDay(daysOfRange);
    this.dataValues = { data };

    let daysMissingNextAvailableTime: ScheduleDay[] = [];
    data.forEach((day) => {
      const dayId = day.day;
      const dayData = ScheduleTimeRangeModel.dayOfSegmentsToTimeSegmentsDay(day);
      this.days.set(dayId, dayData);

      if (!day.availability) {
        daysMissingNextAvailableTime.push(dayData);
      } else {
        daysMissingNextAvailableTime.forEach((dayMissingNext) => {
          // eslint-disable-next-line no-param-reassign
          dayMissingNext.nextAvailableDay = dayId;
        });

        daysMissingNextAvailableTime = [];
      }
    });
  }

  hasAvailableTimeslotsPastDate(start: Date, minimumTimeslots = 1): boolean {
    const startDateString = formatISO(start, { representation: 'date' });
    const entriesIterator = this.days.entries();

    let availableSlots = 0;
    let entry = entriesIterator.next();
    while (!entry.done && availableSlots < minimumTimeslots) {
      const [date, scheduleDay] = entry.value as [string, ScheduleDay];

      if (date >= startDateString && scheduleDay.availability) {
        availableSlots += Object.keys(scheduleDay.times)
          .map((key) => this.filterAvailableTime(scheduleDay.times[key]).length)
          .reduce((sum, dayPartAvailableSlots) => sum + dayPartAvailableSlots, 0); // single day available slots
      }

      entry = entriesIterator.next();
    }

    return availableSlots >= minimumTimeslots;
  }

  export(): [string, ScheduleDay][] {
    return Array.from(this.days.entries());
  }

  join(timeSegments: ScheduleTimeRangeModel): ScheduleTimeRangeModel {
    const combinedData = [...this.dataValues.data, ...timeSegments.dataValues.data];
    return new ScheduleTimeRangeModel(combinedData);
  }

  getDay(day: string): ScheduleDay {
    return this.days.get(day) || this.getDefaultDay(day);
  }

  getDefaultDay(day: string): ScheduleDay {
    return {
      day,
      times: {
        morning: [],
        afternoon: [],
        evening: [],
      },
      availability: 0,
      nextAvailableDay: day,
    };
  }

  /**
   *
   * @param day Shop timezoned date string. Format: yyyy-MM-dd (2021-01-23)
   * @param dateTime UTC timezoned reservation date object
   */
  getBarberIdsForTime(day: string, dateTime: Date): string[] | null {
    const timeSegments = this.getDay(day);
    if (!timeSegments) {
      return null;
    }

    const dateTimeIso = dateTime.toISOString();

    return (
      [...timeSegments.times.morning, ...timeSegments.times.afternoon, ...timeSegments.times.evening].find(
        (timeslot) => {
          return new Date(timeslot.utcTime).toISOString() === dateTimeIso;
        },
      )?.barberIds ?? []
    );
  }

  toJson(): Record<string, unknown> {
    throw new Error('Not implemented');
  }

  private static parseConflictDates(conflict: TimeItemConflict): ScheduleConflict {
    return {
      ...ScheduleTimeRangeModel.omitStartEnd(conflict),
      utcStart: parseISO(conflict.start),
      utcEnd: parseISO(conflict.end),
    };
  }

  private static parseDayTimeString(item: ScheduleTimeItemWithConflicts): ScheduleTime {
    return {
      ...item,
      utcTime: parseISO(item.time),
      conflicts: item.conflicts.map(ScheduleTimeRangeModel.parseConflictDates),
    };
  }

  private static dayOfSegmentsToTimeSegmentsDay(day: ScheduleTimeRangeDayWithConflicts): ScheduleDay {
    return {
      day: day.day,
      availability: day.availability,
      times: {
        morning: day.times.morning.map(ScheduleTimeRangeModel.parseDayTimeString),
        afternoon: day.times.afternoon.map(ScheduleTimeRangeModel.parseDayTimeString),
        evening: day.times.evening.map(ScheduleTimeRangeModel.parseDayTimeString),
      },
      nextAvailableDay: day.day,
    };
  }

  private filterAvailableTime(times: ScheduleTime[]): ScheduleTime[] {
    return times.filter((time) => time.available);
  }

  private sortByAndUniqByDay<T extends Record<'day', unknown>>(list: T[]): T[] {
    const sorted = list.sort((a, b) => (a.day > b.day ? 1 : -1));
    return sorted.filter(uniqBy((item) => item.day));
  }

  private static omitStartEnd = (conflict: TimeItemConflict): Omit<TimeItemConflict, 'start' | 'end'> => {
    const { start: _start, end: _end, ...rest } = conflict;
    return rest;
  };
}
