import {
  NotificationsConsentByIdQuery,
  NotificationsConsentByMatchQuery,
  NotificationsConsentUpdateMutation,
} from '@water-web/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const NotificationsConsentConstructor = (apiUrl: string) => ({
  byId: new NotificationsConsentByIdQuery(apiUrl),
  byMatch: new NotificationsConsentByMatchQuery(apiUrl),
  update: new NotificationsConsentUpdateMutation(apiUrl),
});
