export const consentConfig = {
  animationDurationSec: 0.15,
  bgColor: 'rgb(22, 22, 22)',
  invertedBgColor: '#ffffff',
  primaryTextColor: '#ffffff',
  invertedPrimaryTextColor: '#000000',
  secondaryTextColor: 'rgba(235, 235, 245, 0.6)',
  borderColor: '#3A3A3C',
  media: {
    mobile: '480px',
  },
};
