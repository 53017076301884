/* eslint-disable no-param-reassign */
import { useState } from 'react';

import { cookieSections } from '../cookies';
import { SelectedCookieSections } from '../../../../types';

const allDenied = cookieSections.reduce((result, section) => {
  result[section.key] = section.isRequired;
  return result;
}, {});

const allAgreed = cookieSections.reduce((result, section) => {
  result[section.key] = true;
  return result;
}, {});

export interface UseSelectedCookieSectionsResult {
  selectedSections: SelectedCookieSections;
  denyAll: () => SelectedCookieSections;
  agreeAll: () => SelectedCookieSections;
  toggleSectionHandlers: (sectionKey: string) => () => SelectedCookieSections;
}

export const useSelectedCookieSections = (): UseSelectedCookieSectionsResult => {
  const [selectedSections, setSelectedSections] = useState(allDenied);

  const denyAll = (): SelectedCookieSections => {
    setSelectedSections(allDenied);
    return allDenied;
  };

  const agreeAll = (): SelectedCookieSections => {
    setSelectedSections(allAgreed);
    return allAgreed;
  };

  const toggleSectionHandlers = (sectionKey: string) => (): SelectedCookieSections => {
    const newSelectedSections = {
      ...selectedSections,
      [sectionKey]:
        cookieSections.find((section) => section.key === sectionKey).isRequired || !selectedSections[sectionKey],
    };
    setSelectedSections(newSelectedSections);
    return newSelectedSections;
  };

  return {
    selectedSections,
    denyAll,
    agreeAll,
    toggleSectionHandlers,
  };
};
