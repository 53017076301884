import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { CartContext } from '../Cart';
import { UrlBuilder } from './UrlBuilder';

export const BookingUrlBuilderContext = createContext<UrlBuilder>(null);

export const BookingUrlBuilderProvider = (props: PropsWithChildren) => {
  const cartContext = useContext(CartContext);

  const urlBuilder = useMemo(() => {
    return new UrlBuilder(cartContext?.cart);
  }, [cartContext?.cart]);

  return <BookingUrlBuilderContext.Provider value={urlBuilder}>{props.children}</BookingUrlBuilderContext.Provider>;
};
