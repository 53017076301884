import { CSSProperties } from 'react';

import { PopupWrapperProps, PopupBaseProps } from './typesExternal';

const FADE_STYLES: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

export const PopupFadeDefault = () => <div style={FADE_STYLES} />;

const WRAPPER_STYLES: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
};

export const PopupWrapperDefault = (props: PopupWrapperProps) => {
  const style = {
    ...WRAPPER_STYLES,
    paddingTop: ((props.level ?? 0) + 1) * 50,
  };

  return <div style={style}>{props.children}</div>;
};

const BASE_STYLES: CSSProperties = {
  position: 'relative',
};

/**
 * Put popup contents inside this component to make it float above fade.
 * You may style it as you need: border, rounded corners, background, padding, etc.
 * Add `flex-grow: 1` style to this component to make popup take all available vertical space.
 */
export const PopupBaseDefault = (props: PopupBaseProps) => <div style={BASE_STYLES}>{props.children}</div>;
