import { createContext, PropsWithChildren, useEffect, useMemo, useContext, useCallback, useState } from 'react';
import {
  HttpPlatform,
  ValidateCartPayload,
  ValidatedCart,
  HttpError,
  ValidateCartNewAppointment,
} from '@water-web/types';
import { CartCreateMutation, UserCurrentQuery, useApiMutation, useApiQuery, GetToken } from '@water-web/api';

import { AppSettingsContext } from './AppSettingsContext';

const IS_SSR = typeof window === 'undefined';
const EMPTY_CART_PAYLOAD: ValidateCartPayload = {
  new_appointments: [],
  discounts: [],
  items: [],
  payments: [],
  tips: [],
};
const makeNewAppointments = (customerId?: string): ValidateCartNewAppointment[] =>
  customerId
    ? [
        {
          customerId,
          barberId: null,
          dateTime: null,
          barberPasscode: null,
          bookedWithAnyBarber: false,
          services: [],
        },
      ]
    : [];

interface ContextValue {
  promo: ValidatedCart['promo'] | undefined;
  isLoading: boolean;
  error?: HttpError;
  /**
   * Will clear memoized code details.
   * Warning: it doesn't remove `?promoId=` from the URL, which may cause code memoized again
   */
  clear: () => void;
}

export const DeeplinkPromoContext = createContext<ContextValue>({
  promo: undefined,
  isLoading: false,
  error: undefined,
  clear: () => undefined,
});

interface ProviderProps {
  platform: HttpPlatform;
  getToken: GetToken;
}

export const DeeplinkPromoContextProvider = (props: PropsWithChildren<ProviderProps>) => {
  const { apiUrl } = useContext(AppSettingsContext);
  const [shopId, setShopId] = useState('');
  const [promoId, setPromoId] = useState('');

  const href = IS_SSR ? '' : window.location.href;
  useEffect(() => {
    const { searchParams } = new URL(href);
    if (searchParams.get('promoId')) {
      setPromoId(searchParams.get('promoId'));
    }
    if (searchParams.get('shopId')) {
      setShopId(searchParams.get('shopId'));
    }
  }, [href]);

  const userCurrentEndpoint = useMemo(
    () => new UserCurrentQuery({ baseUrl: apiUrl, getToken: props.getToken }),
    [props.getToken],
  );
  const user = useApiQuery({ endpoint: userCurrentEndpoint });

  const cartCreateEndpoint = useMemo(
    () => new CartCreateMutation({ baseUrl: apiUrl, platform: props.platform, getToken: props.getToken }),
    [props.platform, props.getToken],
  );
  const cartMutation = useApiMutation({ endpoint: cartCreateEndpoint });
  useEffect(() => {
    if (!shopId || !promoId || user.isLoading) {
      return;
    }

    cartMutation.mutate({
      shopId,
      cart: {
        ...EMPTY_CART_PAYLOAD,
        new_appointments: makeNewAppointments(user.data?.customer.id),
        promoId,
      },
    });
  }, [shopId, promoId, user.data, user.isLoading]);

  const clear = useCallback(() => {
    setShopId('');
    setPromoId('');
    cartMutation.reset();
  }, [cartMutation]);

  const value: ContextValue = {
    promo: cartMutation.isLoading || cartMutation.isError ? undefined : cartMutation.data?.promo,
    isLoading: cartMutation.isLoading,
    error: cartMutation.isError && !cartMutation.isLoading ? cartMutation.error : undefined,
    clear,
  };
  return <DeeplinkPromoContext.Provider value={value}>{props.children}</DeeplinkPromoContext.Provider>;
};

export const useDeeplinkPromoDisplayError = (displayError: (message: string) => void): void => {
  const { error } = useContext(DeeplinkPromoContext);

  useEffect(() => {
    if (error) {
      let { message } = error;
      try {
        // `messages` may have a nested JSON. Try to parse it to display prettier
        message = (JSON.parse(message) as { message?: string }[])
          .map((obj) => obj.message)
          .filter(Boolean)
          .join('\n');
        // eslint-disable-next-line no-empty
      } catch (e) {}

      displayError(message);
    }
  }, [error]);
};
