import { format, max, min, isAfter, parseISO, eachWeekOfInterval, endOfWeek, addWeeks } from 'date-fns';

const API_DATE_FORMAT = 'yyyy-MM-dd';

interface FetchInterval {
  start: string;
  end: string;
}

/**
 * Returns all intervals that may be used to make schedule fetch requests.
 * Interval strings are dates in the format required to make a request.
 */
export const getScheduleFetchIntervals = (
  startDateISO: string,
  endDateISO: string,
  maxAllowedWeeks: number,
): FetchInterval[] => {
  const weekStartsOn = 0; // sync requests across all locales
  const weeksToAdd = Math.max(maxAllowedWeeks - 1, 0);
  const reservationInterval = {
    start: parseISO(startDateISO),
    end: parseISO(endDateISO),
  };

  return eachWeekOfInterval(reservationInterval, { weekStartsOn })
    .filter((week, idx) => idx % maxAllowedWeeks === 0) // every N-th week
    .map((week, idx, arr) => {
      const firstWeek = week;
      const intervalStart = idx === 0 ? max([firstWeek, reservationInterval.start]) : firstWeek;

      const lastWeek = endOfWeek(addWeeks(week, weeksToAdd), { weekStartsOn });
      const intervalEnd = idx === arr.length - 1 ? min([lastWeek, reservationInterval.end]) : lastWeek;

      if (isAfter(intervalStart, intervalEnd)) {
        throw new Error('Negative interval'); // This should never happen
      }

      return {
        start: format(intervalStart, API_DATE_FORMAT),
        end: format(intervalEnd, API_DATE_FORMAT),
      };
    });
};
