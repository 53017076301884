import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { routes } from '@app/routeDefinitions';

/**
 * Will return route params much sooner than `react-router-dom > useParams` hook
 * Is a hack, that's required due to delayed title animation, that introduced race condition
 * @see src/Routes.tsx > TitleRoutes
 */
export const useFastRouteParams = <T = Record<string, string>, P = Partial<T>>(): P => {
  const location = useLocation();

  return useMemo(() => {
    return Object.values(routes).reduce<P>((memo, path) => {
      const match = matchPath(location.pathname, {
        path,
        exact: true,
        strict: false,
      });

      if (match) {
        return {
          ...match.params,
          ...memo,
        };
      }

      return memo;
    }, {} as P);
  }, [location]);
};
