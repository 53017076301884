import { SearchApi as ISearchApi, SearchApiIndex, HttpResponse, SearchResult } from '@water-web/types';

import { Namespace } from './namespace';

export class SearchApi extends Namespace implements ISearchApi {
  search(term = '', index: SearchApiIndex[] = [], brandId = null, type = null): Promise<HttpResponse<SearchResult[]>> {
    const search = {
      ...(term ? { term } : null),
      ...(index.length ? { index: index.join(',') } : null),
      ...(brandId ? { 'filters[brandId]': brandId } : null),
      ...(type ? { 'filters[type]': type } : null),
    };

    return this.http.get('/search', { search, resourceUrl: '/search' });
  }
}
