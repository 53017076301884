import { useEffect } from 'react';
import { ConsoleTracker, trackIncomingVisitors } from '@water-web/view';

import { config } from '@app/config';
import { useInitializedCart } from '@app/hooks';
import { Analytics } from '@app/analytics';

import { usePageviewAnalytics } from './hooks';

export const AnalyticsDisabledScripts = () => {
  const cart = useInitializedCart();

  useEffect(() => {
    const analytics = Analytics.getInstance();

    if (config.deploymentEnv !== 'production') {
      analytics.registerTracker(new ConsoleTracker());
    }

    trackIncomingVisitors(analytics);
  }, []);

  useEffect(() => {
    const acquisitionChannel = Analytics.getInstance().getAcquisitionChannel();
    if (!!cart && !!acquisitionChannel) {
      cart.setAcquisitionChannel(acquisitionChannel);
    }
  }, [cart]);

  usePageviewAnalytics();

  return null;
};
