import { parseISO, formatISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { RelatedDatesAndBarbers, RelatedDates } from '@water-web/types';

import { BaseModel } from './base';

export class RelatedDatesAndBarbersModel extends BaseModel<RelatedDatesAndBarbersModel> {
  protected dataValues: { shopTimeZone: string; requestedUtcTime: string; data: RelatedDatesAndBarbers };
  protected utcDateTimes: Date[];

  constructor(
    shopTimeZone: string,
    requestedUtcTime: Date,
    relatedDatesAndBarbers: RelatedDatesAndBarbers | RelatedDates,
  ) {
    super();

    this.dataValues.shopTimeZone = shopTimeZone;
    this.dataValues.requestedUtcTime = formatISO(requestedUtcTime);
    this.dataValues.data = {
      ...relatedDatesAndBarbers,
      barbers: (relatedDatesAndBarbers as RelatedDatesAndBarbers).barbers || [],
    };

    const shopTime = utcToZonedTime(requestedUtcTime, shopTimeZone);

    /**
     * to build a DateTime in UTC, we need to:
     * - take available Date in shop's time zone
     * - add requested Time in shop's time zone
     * - transform resulting DateTime into UTC time zone
     */

    // `shopDateString` is a shop's timezone date: `2020-05-28`
    this.utcDateTimes = relatedDatesAndBarbers.dates.map((shopDateString) => {
      const shopDate = parseISO(shopDateString);
      shopDate.setHours(shopTime.getHours(), shopTime.getMinutes(), shopTime.getSeconds(), 0);
      return zonedTimeToUtc(shopDate, shopTimeZone);
    });
  }

  getUtcDateTimes(): Date[] {
    return this.utcDateTimes;
  }

  getBarbers() {
    return this.dataValues.data.barbers;
  }

  toJson(): Record<string, unknown> {
    return this.dataValues;
  }
}
