import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
  RefObject,
} from 'react';
import { Brand } from '@water-web/types';
import { getDisplayReservationAdsFromBrand } from '@water-web/repository';

import { RoktAttributesInput, Rokt, RoktLauncher } from './types';
import { formatRoktAttributes } from './utils';

declare global {
  interface Window {
    Rokt: Rokt;
  }
}

interface RoktAdsContextValue {
  ready: boolean;
  placeAds: (attributes: RoktAttributesInput) => () => void;
  willShowRef: RefObject<boolean>;
}

interface RoktAdsProviderProps {
  accountId: string;
  sandbox?: boolean;
  disabled?: boolean;
  brand: Brand;
}

const RoktAdsContext = createContext<RoktAdsContextValue>(null);

export const useRoktAds = () => {
  return useContext(RoktAdsContext);
};

export const RoktAdsProvider = ({
  children,
  accountId,
  brand,
  sandbox = false,
  disabled = false,
}: PropsWithChildren<RoktAdsProviderProps>) => {
  const roktPromiseRef = useRef<Promise<void>>();
  const [launcher, setLauncher] = useState<RoktLauncher | null>(null);

  const shouldShowAds = getDisplayReservationAdsFromBrand(brand) && !disabled;
  const willShowRef = useRef<boolean>(shouldShowAds);

  useEffect(() => {
    willShowRef.current = shouldShowAds;
  }, [shouldShowAds]);

  useEffect(() => {
    let launcherInstance = null;

    (async () => {
      if (shouldShowAds) {
        let promise = Promise.resolve();

        if (!window.Rokt && !roktPromiseRef.current) {
          roktPromiseRef.current = new Promise<void>((resolve) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://apps.rokt.com/wsdk/integrations/launcher.js';
            (script as any).fetchpriority = 'high';
            script.crossOrigin = 'anonymous';
            script.async = true;
            script.id = 'rokt-launcher';

            const target = document.head || document.body;
            target.appendChild(script);

            script.onload = () => {
              resolve();
              roktPromiseRef.current = null;
            };
          });

          promise = roktPromiseRef.current;
        } else if (!window.Rokt && !!roktPromiseRef.current) {
          promise = roktPromiseRef.current;
        }

        await promise;

        launcherInstance = await window.Rokt.createLauncher({ accountId, sandbox });
        setLauncher(launcherInstance);
      }
    })();

    return () => {
      launcherInstance?.terminate();
      setLauncher(null);
    };
  }, [accountId, sandbox, shouldShowAds]);

  const placeAds = useCallback(
    (attributes: RoktAttributesInput) => {
      if (!launcher) {
        throw new Error('RoktAdsProvider: placeAds is executed before the launcher is ready. Check your integration.');
      }

      const selectionPromise = launcher.selectPlacements({
        attributes: formatRoktAttributes(attributes),
      });

      return () => {
        if (selectionPromise) {
          // remove all the Rokt placements
          selectionPromise.then((selection) => selection.close());
        }
      };
    },
    [launcher],
  );

  const contextValue = useMemo(() => {
    return {
      placeAds,
      ready: !!launcher,
      // whether rokt ads will show or not
      // it may change value over time because of feature flags lazy initialization, so must be checked dynamically
      willShowRef,
    };
  }, [launcher, placeAds]);

  return <RoktAdsContext.Provider value={contextValue}>{children}</RoktAdsContext.Provider>;
};
