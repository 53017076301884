import { Dispatch, SetStateAction } from 'react';

import { BooleanHandlers } from './types';

export const getBooleanHandlers = (setState: Dispatch<SetStateAction<boolean>>): BooleanHandlers => {
  return {
    toggle: () => setState((v) => !v),
    turnOn: () => setState(true),
    turnOff: () => setState(false),
    set: setState,
  };
};
