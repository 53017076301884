/// <reference types="node" />
import { UrlObject } from 'url';

import {
  CartModel,
  BrandModel,
  BarberModel,
  GiftCardModel,
  LocationModel,
  ReservationModel,
} from '@water-web/repository';

import { cartIdQuery } from '../../utils';

export class UrlBuilder {
  // prefixes are meant to indicate a purpose of action: book, gift-card, kiosk etc
  protected bookingPrefix = 'book';
  protected giftCardPrefix = 'gift-card';

  // segment is url block indicating an page and its connected entity id(optionally)
  private brandSegment = 'brands/[brandIdOrRoute]';
  private shopSegment = '[shopIdOrRoute]';
  private barberSegment = 'barber/[barberIdOrRoute]';
  private servicesSegment = 'services';
  private scheduleSegment = 'schedule';
  private productsSegment = 'products';
  private reservationSegment = 'view-reservation/[saleOrderId]';
  private waitingListSegment = 'waiting-list/[waitingListApplicationId]';
  private giftCardInfoSegment = 'view-gift-card/[saleOrderId]';

  private cart: CartModel;

  constructor(cart?: CartModel) {
    this.cart = cart;
  }

  private setCartId(cartId: string, query: Record<string, string>) {
    if (cartId) {
      // eslint-disable-next-line no-param-reassign
      query[cartIdQuery] = cartId;
    }
  }

  private createUrl(segments: string[]): string {
    return '/'.concat(segments.filter(Boolean).join('/'));
  }

  getHomeUrl(): UrlObject {
    const pathname = this.createUrl([]);
    return { pathname };
  }

  getBrandUrl(brand: BrandModel): UrlObject {
    const pathname = this.createUrl([this.brandSegment]);
    const query = {
      brandIdOrRoute: brand.getRoute() ?? brand.getId(),
    };

    return { pathname, query };
  }

  /**
   * @description
   * Opens brand page and refreshes cart with a data from API.
   * Should be used from the pages that don't have a populated cart(like view reservation or gift card info).
   */
  getBrandUrlWithRefresh(brandIdOrRoute: string): UrlObject {
    const pathname = this.createUrl([this.brandSegment]);
    const query = { brandIdOrRoute, refreshData: true };

    return { pathname, query };
  }

  getShopUrl(location: LocationModel, cartId?: string): UrlObject {
    const pathname = this.createUrl([this.bookingPrefix, this.shopSegment]);
    const query: Record<string, string> = {
      shopIdOrRoute: location.getRoute(),
    };

    return { pathname, query };
  }

  getServicesUrl(barber: BarberModel, cartId?: string): UrlObject {
    const reservation = this.cart.getActiveReservation();

    const pathname = this.createUrl([this.bookingPrefix, this.shopSegment, this.barberSegment, this.servicesSegment]);
    const query: Record<string, string> = {
      shopIdOrRoute: reservation.getShop().getRoute(),
      barberIdOrRoute: barber.getRoute(),
    };

    return { pathname, query };
  }

  getScheduleUrl(reservation: ReservationModel, cartId: string): UrlObject {
    const pathname = this.createUrl([this.bookingPrefix, this.shopSegment, this.barberSegment, this.scheduleSegment]);

    const query = {
      shopIdOrRoute: reservation.getShop().getRoute(),
      barberIdOrRoute: reservation.getBarber().getRoute(),
    };

    this.setCartId(cartId, query);

    return { pathname, query };
  }

  getProductsUrl(barber: BarberModel, cartId: string): UrlObject {
    const reservation = this.cart.getActiveReservation();

    const pathname = this.createUrl([this.bookingPrefix, this.shopSegment, this.barberSegment, this.productsSegment]);
    const query = {
      shopIdOrRoute: reservation.getShop().getRoute(),
      barberIdOrRoute: barber.getRoute(),
    };

    this.setCartId(cartId, query);

    return { pathname, query };
  }

  getReservationUrl(saleOrderId: string): UrlObject {
    const pathname = this.createUrl([this.reservationSegment]);
    const query = { saleOrderId };

    return { pathname, query };
  }

  /**
   * @description a universal URL builder for both assigned and unassigned gift card sales.
   * Any barber ust be set that's an unassigned gift card sale
   */
  getGiftCardSaleUrl(barber: BarberModel, cartId?: string): UrlObject {
    const reservation = this.cart.getActiveReservation();

    const pathname = this.createUrl([this.giftCardPrefix, this.shopSegment, this.barberSegment]);
    const query: Record<string, string> = {
      shopIdOrRoute: reservation.getShop().getRoute(),
      barberIdOrRoute: barber.getRoute(),
    };

    this.setCartId(cartId, query);

    return { pathname, query };
  }

  /**
   * @description page with a sold gift card information
   */
  getGiftCardInfoUrl(giftCard: GiftCardModel): UrlObject {
    const pathname = this.createUrl([this.giftCardInfoSegment]);
    const query: Record<string, string> = {
      saleOrderId: giftCard.getSaleOrderId(),
    };

    return { pathname, query };
  }

  getWaitingListUrl(waitingListApplicationId: string): UrlObject {
    return {
      pathname: this.createUrl([this.waitingListSegment]),
      query: { waitingListApplicationId },
    };
  }

  /**
   * The page where users select a time after being offered a slot
   */
  getWaitingListAttemptUrl(waitingListApplicationId: string, attemptId: string): UrlObject {
    return {
      pathname: this.createUrl([this.waitingListSegment, '[attemptId]']),
      query: { waitingListApplicationId, attemptId },
    };
  }

  getCartIdShallowRemover(router: UrlObject): UrlObject {
    return {
      pathname: router.pathname,
      query: Object.keys(router.query)
        .filter((key) => key !== cartIdQuery)
        .reduce(
          (memo, key) => ({
            ...memo,
            [key]: router.query[key],
          }),
          {},
        ),
    };
  }
}
