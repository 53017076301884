import { ServiceModel } from '@water-web/repository';

export const isServicesCategorySelected =
  (category: string) =>
  (service: ServiceModel): boolean => {
    if (category === 'all') {
      return true;
    }

    if (category === 'uncategorized') {
      return !service.getCategory();
    }

    return category === service.getCategory()?.id;
  };
