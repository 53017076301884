import { CartContext } from '@water-web/view';
import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { useCartController } from '../../../contexts/CartController/useCartController';
import { useCheckoutFlow } from '../../../contexts/CheckoutFlow';
import { toaster } from '../../molecules';

const NO_TIME_SINGLE = 'appointment.errors.nonMatchingHours';
const NO_TIME_GROUP = 'appointment.errors.oneOfTimeslotsNotAvailable';

export const InvalidTimeChecker = () => {
  const checkoutFlow = useCheckoutFlow();
  const { cart } = useContext(CartContext);
  const cartController = useCartController();
  const validationError = cart.getValidationError();
  const queryClient = useQueryClient();
  const reservations = cart.getReservations();

  const noMatchingHoursError = validationError?.code === NO_TIME_SINGLE || validationError?.code === NO_TIME_GROUP;

  useEffect(() => {
    if (noMatchingHoursError) {
      toaster.error(validationError?.message);
      checkoutFlow.cancel();
      cartController.setCartMode('scheduleEmpty');
      reservations.forEach((reservation) => reservation.setDateTime(null));
      queryClient.invalidateQueries(['schedule']);
    }
  }, [noMatchingHoursError, validationError?.message, reservations]);

  return null;
};
