import { ThemeColors } from '../../types';

const steps = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

const getColorVariations = (name: string, parts: number[], customPlural?: string) => {
  const plural = customPlural || `${name}s`;

  return {
    [name]: `rgb(${parts.join(',')})`,
    [plural]: steps.map((opacity) => `rgba(${parts.join(',')},${opacity})`),
  };
};

const defaultThemeColors = {
  primaryColor: 'rgb(0, 131, 255)',
  primaryColorDark: 'rgb(17, 85, 27)',
  primaryColorDarker: '#044107',
  primaryColorLight: '#1a721f',
  primaryFontColor: '#000000',
  secondaryColor: '#ffffff',
  linkColor: 'rgb(0, 107, 208)',
  ...getColorVariations('primaryFont', [0, 0, 0]),
  secondary: 'rgb(0, 0, 0)',
  ...getColorVariations('secondaryFont', [60, 60, 67]),
  ...getColorVariations('primaryFontOnPrimary', [255, 255, 255], 'primaryFontOnPrimaries'),

  cardBorder: 'rgba(218, 218, 220)',
  cardBorderFocused: 'rgb(225, 225, 225)',
  cardBackgroundFocused: 'rgb(255, 255, 255)',
  cardShadowFocused: 'rgba(0, 0, 0, 0.1)',
  cardLine: 'rgb(199, 199, 204)',

  anyBarberAvatarBackground: 'rgb(224, 224, 224)',
  replacementAvatarBackground: 'rgb(225, 225, 230)',

  buttonOutlinedBorder: 'rgba(218, 218, 220, 1)',
  buttonOutlinedBorderSelected: 'rgba(199, 199, 204, 0)',

  breakdownColorFont: 'rgb(186, 186, 186)',
  cartExpandCollapseBg: 'rgb(58, 58, 60)',
  cartExpandCollapseColor: 'rgb(164, 164, 171)',
  cartBg: 'rgb(0, 0, 0)',
  cardDefaultColor: '#ffffff',
  promocodeInputBg: 'rgb(38, 38, 38)',
  promocodeInputColor: 'rgb(255, 255, 255)',
  promocodeInputPlaceholderBg: 'rgb(38, 38, 38)',
  promocodeInputPlaceholderColor: 'rgb(97, 97, 99)',

  cartApptCardBg: 'rgb(29,29,29)',

  cartAuxBtnBorder: 'rgb(39,39,39)',
  cartAuxBtnBorderLight: 'rgb(222,222,226)',
  cartAuxText: 'rgb(153,153,153)',

  barberAvatarBg: 'rgb(87,87,87)',
  brandedBarberAvatarBg: 'rgb(125,125,129)',
  brandedBarberAvatarColor: 'rgb(184,184,188)',

  chrome: 'rgb(247, 247, 247)',
  chromeTitle: 'rgba(247, 247, 247, 0.85)',
  chromeTitleTransparent: 'rgba(247, 247, 247, 0)',
  cartChrome: 'rgb(251, 251, 251)',
  ...getColorVariations('systemBlack', [0, 0, 0]),
  systemGray9: 'rgb(198, 198, 200)',
  systemGray8: 'rgb(164, 164, 171)',
  systemGray7: 'rgb(58, 58, 60)',
  systemGray6: 'rgb(255, 255, 255)',
  systemGray5: 'rgb(235, 235, 235)',
  systemGray4: 'rgb(218, 218, 220)',
  ...getColorVariations('systemGray3', [186, 186, 186], 'systemGrays3'),
  systemGray2: 'rgb(142, 141, 146)',
  ...getColorVariations('systemGray1', [97, 97, 99], 'systemGrays1'),
  systemGray0: 'rgb(38, 38, 38)',
  systemWhite: '#ffffff',
  systemSuccessGreen: 'rgb(44, 175, 0)',

  // Texts
  primaryLabel: 'rgba(0, 0, 0, 1)',
  secondaryLabel: 'rgba(97, 97, 99, 1)',
  tertiaryLabel: 'rgba(60, 60, 67, 0.6)',
  quaternaryLabel: 'rgba(60, 60, 67, 0.3)',
  linkLabel: 'rgba(0, 107, 178, 1)',

  timeInactiveBg: 'rgb(230, 230, 230)',
  dayInactiveBg: 'rgb(224, 224, 224)',

  selectColorFont: 'rgb(28, 28, 30)',
  activeOptionBg: 'rgb(242, 242, 245)',

  availability: {
    0.25: 'rgb(0, 138, 229)',
    0.5: 'rgb(0, 107, 178)',
    0.75: 'rgb(0, 77, 128)',
    1: 'rgb(0, 46, 77)',
  },
  danger: 'rgb(255, 59, 48)',
  success: 'rgba(46, 178, 0, 1)',
  paid: 'rgba(44, 175, 0, 1)',
} as ThemeColors;

export default defaultThemeColors;
