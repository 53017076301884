import styled from 'styled-components';

import { Box } from '../../components/atoms/Box';

export const FlagsContainer = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background: #ffffff;
  font-family: sans-serif;
`;

export const CheckboxInput = styled.input`
  appearance: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const FlagLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #333;
  font-size: 18px;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
`;
