import { ReactNode, useState, ComponentType, UIEventHandler } from 'react';
import styled, { css } from 'styled-components';

const ScrollHeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1; // make it float above most of the body elements with "position: relative;" /  "position: absolute;"
`;

const HeadWrapper = styled.div`
  position: relative;
`;

const ScrollableContainerDefault = styled.div`
  // recommended default
  overflow-y: auto;

  // the rest is optional
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const HeaderBgDefault = styled.div<{ $transparent?: boolean }>`
  // recommended default
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  // the rest is optional
  background-color: #fff;
  ${(props) =>
    props.$transparent &&
    css`
      display: none;
    `};
`;

export type Props = {
  head: ReactNode;
  body: ReactNode;
  ScrollableContainerFn?: ComponentType<{ onScroll: UIEventHandler<HTMLDivElement> }>;
  HeaderBgFn?: ComponentType<{ $transparent?: boolean }>;
  className?: string;
};

export const ScrollableWithStickyHeader = (props: Props) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const ScrollableContainer = props.ScrollableContainerFn ?? ScrollableContainerDefault;
  const StickyHeaderBg = props.HeaderBgFn ?? HeaderBgDefault;

  return (
    <ScrollableContainer className={props.className} onScroll={(e) => setIsScrolled(e.currentTarget.scrollTop !== 0)}>
      <ScrollHeaderWrapper>
        <StickyHeaderBg $transparent={!isScrolled} />
        <HeadWrapper>{props.head}</HeadWrapper>
      </ScrollHeaderWrapper>

      {props.body}
    </ScrollableContainer>
  );
};
