import { PhaseConfig, PhaseHookArgs } from '../types';
import { collectPaymentOrShowCardInput } from './utils';

export const marketingConsents: PhaseConfig = {
  name: 'marketingConsents',
  init: (params: PhaseHookArgs) => {
    params.popupController.handlers.policies().open({
      set: 'notifications',
      onClose: () => null,
      onComplete: () => null,
    });
  },
  next: async (params: PhaseHookArgs) => {
    params.loadingHandlers.turnOn();
    params.inputBlockHandlers.turnOn();

    params.popupController.handlers.policies().close();

    collectPaymentOrShowCardInput(params);
  },
  cancel: (params: PhaseHookArgs) => {
    params.popupController.handlers.policies().close();
    params.setCheckoutPhase('userInfo');
  },
};
