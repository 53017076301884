import {
  Product,
  HttpResponse,
  ProductApiSearch,
  ProductApi as IProductApi,
  HttpGetOptions,
  ProductsWithCount,
} from '@water-web/types';

import { Namespace } from './namespace';

export class ProductApi extends Namespace implements IProductApi {
  async getByShopIdOrRoute(shopId: string, search?: ProductApiSearch): Promise<HttpResponse<Product[]>> {
    const products = await this.http.get<ProductsWithCount>(`/shop/${shopId}/product`, {
      search,
      resourceUrl: '/shop/:id/product',
    } as HttpGetOptions);

    if (products.error) {
      return {
        data: null,
        error: products.error,
      };
    }

    return {
      data: products.data.rows,
      error: null,
    };
  }
}
