import { useContext, useEffect } from 'react';

import { BrandedThemeContext } from '@app/contexts';
import { useBrand } from '@app/hooks/useBrand';

export const useSetBrandThemeEffect = (brandId?: string) => {
  const { setBrand } = useContext(BrandedThemeContext);
  const { brandModel } = useBrand(brandId);

  useEffect(() => {
    if (brandModel?.getId()) {
      setBrand(brandModel);
    }
  }, [brandModel?.getId()]);
};
