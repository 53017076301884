import { PropsWithChildren, useId, useContext, useEffect } from 'react';

import { PopupContext } from './PopupManager';
import { usePopupLevel } from './usePopupLevel';

/**
 * Will wrap and offset individual child of the `PopupRender`.
 */
export const PopupRenderWrapper = (props: PropsWithChildren<{ stackName: string }>) => {
  const id = useId();
  const { stacks, popupRegisterEffect } = useContext(PopupContext);

  useEffect(
    () =>
      popupRegisterEffect({
        stackName: props.stackName,
        id,
      }),
    [props.stackName, id],
  );

  const level = usePopupLevel(props.stackName, id);

  const { Wrapper, Fade } = stacks.find((item) => item.name === props.stackName);

  return (
    <>
      <Fade key="popup-fade" />
      <Wrapper key="popup-body" level={level}>
        {props.children}
      </Wrapper>
    </>
  );
};
