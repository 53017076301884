import { Switch } from '../Switch';
import { BodyText } from '../styles';
import { Flex, Box } from '../../../atoms';
import { SubsectionCard } from './SubsectionCard';
import { useAnimatedToggleSection } from '../hooks';
import { CookiesSectionDef, CookiesSubsectionDef } from '../types';
import { AnimatedArrow, ArrowButton, Section, SectionTitle } from './styles';

interface CookiesSectionProps {
  section: CookiesSectionDef;
  isEnabled: boolean;
  toggleEnabled: () => void;
  isExpanded: boolean;
  toggleExpanded: () => void;
  onSelectSubsection: (subsection: CookiesSubsectionDef) => void;
}

export const CookiesSection = (props: CookiesSectionProps) => {
  const { toggleAnimatedSection, AnimatedToggleSection } = useAnimatedToggleSection({
    isExpanded: props.isExpanded,
    toggleExpanded: props.toggleExpanded,
  });

  return (
    <Box pb={4}>
      <Flex as={Section} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <ArrowButton mr={2} onClick={toggleAnimatedSection}>
            <AnimatedArrow right={!props.isExpanded} />
          </ArrowButton>
          <SectionTitle>{props.section.name}</SectionTitle>
        </Flex>
        <Switch
          onChange={props.toggleEnabled}
          checked={props.section.isRequired || props.isEnabled}
          disabled={props.section.isRequired}
          dark
        />
      </Flex>
      <Section as={BodyText} mb={4}>
        {props.section.description}
      </Section>
      <AnimatedToggleSection>
        {props.section.subsections.map((subsection) => (
          <SubsectionCard
            key={subsection.name}
            subsection={subsection}
            onClick={() => props.onSelectSubsection(subsection)}
          />
        ))}
      </AnimatedToggleSection>
    </Box>
  );
};
