import { Shop, ShopApiSearch } from '@water-web/types';

import { Repository } from './base';
import { ShopModel } from '../models';
import { RepositoryResponse } from '../utils';

export class ShopRepository extends Repository {
  getFromJson(json: Shop): ShopModel {
    return new ShopModel(json);
  }

  async getByIdOrRoute(idOrRoute: string, ignoreInsta?: boolean): Promise<RepositoryResponse<ShopModel>> {
    const search: ShopApiSearch = {
      include: [
        'services',
        'photos',
        'address',
        'reward',
        'schedules',
        'waitingLists',
        'logo',
        'staticMap',
        'instagramImages',
      ],
    };
    const response = await this.api.shop.getByIdOrRoute(idOrRoute, search, ignoreInsta);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<ShopModel>;
    }

    return {
      data: new ShopModel(response.data),
      error: null,
    } as RepositoryResponse<ShopModel>;
  }
}
