export type CookieSection = 'necessary' | 'statistics';

export type SelectedCookieSections = {
  [key in CookieSection]?: boolean;
};

export type CookieConsentState = { version: number; date: string } & SelectedCookieSections;

export const isValidConsentState = (state: unknown): state is CookieConsentState => {
  if (typeof state !== 'object' || state === null) {
    return false;
  }

  if (typeof (state as CookieConsentState).version !== 'number') {
    return false;
  }

  if (typeof (state as CookieConsentState).date !== 'string') {
    return false;
  }

  return true;
};
