import {
  ValidatedCart,
  isAdjustmentPromo,
  isValidatedCartGiftCardPayment,
  isAdjustmentMembership,
  isValidatedCartCardPayment,
} from '@water-web/types';

import { Price } from '../types';
import { reducePrices, numberToPrice } from '../utils';
import { getServicesPriceExactFromCartReservation, getServicesPriceDraftFromCartReservation } from './cartReservation';

export const getServicesPriceExactFromCart = (cart: ValidatedCart): Price => {
  return reducePrices(cart.items.map(getServicesPriceExactFromCartReservation));
};

export const getServicesPriceDraftFromCart = (cart: ValidatedCart): Price => {
  return reducePrices(cart.items.map(getServicesPriceDraftFromCartReservation));
};

export const getTipsPriceFromCart = (cart: ValidatedCart): Price => {
  return reducePrices((cart.tips ?? []).map(({ amount }) => numberToPrice(amount)));
};

export const getPromoPriceFromCart = (cart: Pick<ValidatedCart, 'adjustments'>): Price => {
  return reducePrices(
    (cart.adjustments ?? []).filter(isAdjustmentPromo).map(({ amount }) => numberToPrice(Math.abs(amount))),
  );
};

export const getGiftCardPriceFromCart = (cart: Pick<ValidatedCart, 'payments'>): Price => {
  return reducePrices(
    (cart.payments ?? []).filter(isValidatedCartGiftCardPayment).map(({ amount }) => numberToPrice(amount)),
  );
};

export const getMembershipPriceFromCart = (cart: Pick<ValidatedCart, 'adjustments'>): Price => {
  return reducePrices(
    (cart.adjustments ?? []).filter(isAdjustmentMembership).map(({ amount }) => numberToPrice(Math.abs(amount))),
  );
};

export const getTotalExactFromCart = (cart: ValidatedCart): Price => {
  /**
   * The `breakdown.outstandingChargeAmount` is the total amount to be paid.
   * Except when card payment method added.
   * In that case, card amount holds the valid total.
   */
  return numberToPrice(
    cart.payments.find(isValidatedCartCardPayment)?.amount ?? cart.breakdown?.outstandingChargeAmount ?? 0,
  );
};

export const getTotalDraftFromCart = (cart: ValidatedCart): Price => {
  return reducePrices([getServicesPriceDraftFromCart(cart), getTipsPriceFromCart(cart)]);
};

/**
 * Checks if cart is ready to accept customer and payment details
 */
export const hasAppointmentsReadyForPaymentFromCart = (cart: ValidatedCart): boolean => {
  return (
    cart.new_appointments.length > 0 &&
    cart.new_appointments.every((appointment) => {
      return appointment.barberId && appointment.dateTime && appointment.services?.length > 0;
    })
  );
};

export const canApplyGiftPromoCodeFromCart = (cart: ValidatedCart): boolean => {
  return !cart.isReservation && !cart.isBookNoPay && hasAppointmentsReadyForPaymentFromCart(cart);
  // && not WaitList application
};

export const getGiftPromoCodeFromCart = (cart: ValidatedCart): string | undefined => {
  return cart.payments?.find(isValidatedCartGiftCardPayment)?.giftCard.code ?? cart.promoCode ?? undefined;
};

export const getAdvanceCancelMinsFromCart = (cart: ValidatedCart): number => {
  return Math.max(0, ...cart.items.map((item) => item.barber.advanceCancelMins));
};
