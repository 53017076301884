// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UniqFn<T> = (item: T) => any;

type FilterFn<T> = (item: T) => boolean;

export const uniqBy = <T>(uniqFn: UniqFn<T>): FilterFn<T> => {
  const memo = {};
  return (item: T) => {
    const id = uniqFn(item);

    if (!memo[id]) {
      memo[id] = true;
      return true;
    }

    return false;
  };
};

export const toChunks = <T>(items: T[], itemsPerChunk: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < items.length; i += itemsPerChunk) {
    const chunk: T[] = items.slice(i, i + itemsPerChunk);
    chunks.push(chunk);
  }

  return chunks;
};
