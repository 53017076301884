import { Component, PropsWithChildren } from 'react';

export class ChunkLoadingErrorBoundary extends Component<
  PropsWithChildren<{ onError?: () => void }>,
  { hasChunkLoadingError: boolean }
> {
  state = { hasChunkLoadingError: false };

  static getDerivedStateFromError(error: Error) {
    const isChunkLoadingError = error.name.includes('ChunkLoadError');
    return { hasChunkLoadingError: isChunkLoadingError };
  }

  componentDidCatch() {
    if (this.state.hasChunkLoadingError) {
      this.props.onError?.();
    }
  }

  render() {
    if (this.state.hasChunkLoadingError) {
      return null;
    }

    return this.props.children;
  }
}
