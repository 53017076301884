import { ThemeColors } from '../../types';

export const getBrandedColorsOverrides = (colors: ThemeColors): Partial<ThemeColors> => ({
  ...colors,
  breakdownColorFont: colors.systemGray1,
  cartExpandCollapseBg: colors.systemGray5,
  cartExpandCollapseColor: colors.systemGray1,
  cartBg: colors.cartChrome,
  cardDefaultColor: colors.primaryFont,
  promocodeInputBg: colors.systemGray6,
  promocodeInputColor: colors.primaryFont,
  promocodeInputPlaceholderBg: colors.systemGray5,
  promocodeInputPlaceholderColor: colors.secondaryFonts[6],
});
