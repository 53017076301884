/* eslint-disable camelcase */
import { EndpointMutation } from '../../request';

interface Payload {
  conversion_partner_id: string;
  /**
   * The Reserve with Google token, taken from the `rwd_token` query parameter, and persisted on the device.
   */
  rwg_token: string;
  /**
   * Did we book with a different Shop, from what was presented to the user, when we persisted the `rwg_token`?
   * `1` - different Shop.
   * `2` - same Shop.
   */
  merchant_changed: 1 | 2;
}

export class TrackReserveWithGoogleMutation extends EndpointMutation<void, Payload> {
  // standalone variable, as the `this.baseURL` value can't be read
  protected url: string;

  constructor(deploymentEnv: 'production' | string) {
    super();
    this.url =
      deploymentEnv === 'production'
        ? 'https://www.google.com/maps/conversion/collect'
        : 'https://www.google.com/maps/conversion/debug/collect';
  }

  async request(payload: Payload, signal?: AbortSignal): Promise<void> {
    try {
      // To bypass CORS check, send no `Content-Type: application/json` header, and don't attempt to read the response
      // Because of the parsing, we can't use `this.http.post` here
      await fetch(this.url, {
        method: 'POST',
        body: JSON.stringify(payload),
        signal,
      });
    } catch (error) {
      // ignore network error
    }
  }
}
