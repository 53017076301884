import { formatISO } from 'date-fns';
import {
  CustomerPayload,
  HttpResponse,
  ValidatedCart,
  ValidateCartPayload,
  isValidatedCartGiftCardPayment,
  isAdjustmentPromo,
} from '@water-web/types';

import { Repository } from './base';
import { CartCheckoutType, CartModel, GiftCardModel, ValidatedCartModel, WaitingListModel } from '../models';
import { canApplyGiftPromoCodeFromCart } from '../getters';
import { RepositoryResponse, ValidateCartDtoFactory } from '../utils';

const CACHE_LIMIT = 10;

interface ValidationCacheItem {
  key: string;
  /**
   * Date.now()
   */
  timestamp: number;
  promise: Promise<HttpResponse<ValidatedCart>>;
}

interface CacheItem {
  key: string;
  promise: Promise<HttpResponse<ValidatedCart>>;
}

export class CartRepository extends Repository {
  /**
   * cached requests, from old to new
   */
  private validationCache: ValidationCacheItem[] = [];
  /**
   * Latest cart create/update response
   */
  private cache: CacheItem | null = null;

  getValidatedCartFromJson(json: ValidatedCart): ValidatedCartModel {
    return new ValidatedCartModel(json);
  }

  /**
   * @deprecated Use `checkoutV2` instead
   */
  async checkout(
    cart: CartModel,
    cartId: string,
    botProtectionToken?: string,
  ): Promise<RepositoryResponse<ValidatedCartModel>> {
    const customer = cart.getCustomer();
    const payload = {
      customer: customer?.toJson() as unknown as CustomerPayload,
      isReservation: cart.getCheckoutType() === CartCheckoutType.bookProvideCard,
      isBookNoPay: cart.getCheckoutType() === CartCheckoutType.bookNoCard,
    };
    const shop = cart.getReservations()[0].getShop();

    const response = await this.api.cart.checkout(shop.getId(), cartId, payload, botProtectionToken);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: new ValidatedCartModel(response.data),
      error: null,
    };
  }

  /**
   * @deprecated Use `createOrUpdateV2` instead
   */
  async createOrUpdate(cart: CartModel, cartId?: string): Promise<RepositoryResponse<ValidatedCartModel>> {
    return cartId ? this.update(cart, cartId) : this.create(cart);
  }

  /**
   * @deprecated Use `createV2` instead
   */
  async create(cart: CartModel): Promise<RepositoryResponse<ValidatedCartModel>> {
    const shop = cart.getActiveReservation().getShop();
    const payload = ValidateCartDtoFactory.getDataForValidation(cart);
    const response = await this.api.cart.create(shop.getId(), payload);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: new ValidatedCartModel(response.data),
      error: null,
    };
  }

  /**
   * @deprecated Use `updateV2` instead
   */
  async update(cart: CartModel, cartId: string): Promise<RepositoryResponse<ValidatedCartModel>> {
    const shop = cart.getActiveReservation().getShop();
    const payload = ValidateCartDtoFactory.getDataForValidation(cart);
    const response = await this.api.cart.update(shop.getId(), cartId, payload);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    const validatedCartModel = new ValidatedCartModel(response.data);
    cart.setValidatedCart(validatedCartModel);

    return {
      data: validatedCartModel,
      error: null,
    };
  }

  async createV2(cartModel: CartModel): Promise<RepositoryResponse<CartModel>> {
    const shopId = cartModel.getActiveReservation().getShop().getId();
    const payload = ValidateCartDtoFactory.getDataForValidation(cartModel);
    const promise = this.getCreateUpdateV2Promise('create', shopId, '', payload);

    const response = await this.withCreateUpdateEffects(cartModel, promise);

    // let next `updateV2` with the same `shop`/`payload` hit the cache
    if (!response.error && this.cache?.promise === promise) {
      const cart = cartModel.getValidatedCart().getDataValues();
      this.cache = {
        key: this.getCacheKey(cart.shopId, cart.id, payload),
        promise,
      };
    }

    return response;
  }

  /**
   * The `cartModel` must be created by `createV2` earlier, and have `ValidatedCart` with `id` available
   */
  async updateV2(cartModel: CartModel, force = false): Promise<RepositoryResponse<CartModel>> {
    const cart = cartModel.getValidatedCart().getDataValues();
    const payload = ValidateCartDtoFactory.getDataForValidation(cartModel);
    const promise = this.getCreateUpdateV2Promise('update', cart.shopId, cart.id, payload, force);

    return this.withCreateUpdateEffects(cartModel, promise);
  }

  async createOrUpdateV2(cartModel: CartModel): Promise<RepositoryResponse<CartModel>> {
    return cartModel.getValidatedCart()?.getId() ? this.updateV2(cartModel) : this.createV2(cartModel);
  }

  /**
   * The `cartModel` must be created by `createV2` earlier, and have `ValidatedCart` with `id` available
   */
  async checkoutV2(cartModel: CartModel, botProtectionToken?: string): Promise<RepositoryResponse<ValidatedCartModel>> {
    const cart = cartModel.getValidatedCart().getDataValues();
    return this.checkout(cartModel, cart.id, botProtectionToken);
  }

  /**
   * @deprecated Use `CartQuery` instead
   */
  async getById(shopId: string, cartId: string): Promise<RepositoryResponse<ValidatedCartModel>> {
    const response = await this.api.cart.fetch(shopId, cartId);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: new ValidatedCartModel(response.data),
      error: null,
    };
  }

  /**
   * @deprecated Use `createV2` or `updateV2` instead
   */
  async validate(cart: CartModel, force = false): Promise<RepositoryResponse<CartModel>> {
    cart.setValidating(true);

    const shopId = cart.getActiveReservation().getShop().getId();
    const payload = ValidateCartDtoFactory.getDataForValidation(cart);
    const promise = this.getValidationPromise(shopId, payload, force);

    const response = await promise;
    if (response.error) {
      cart.setValidationError(response.error);
      cart.setValidating(false);
      return {
        data: null,
        error: response.error,
      };
    }

    const latestCacheItem = this.validationCache[this.validationCache.length - 1];
    if (latestCacheItem.promise === promise) {
      const validatedCart = new ValidatedCartModel(response.data);
      cart.setValidatedCart(validatedCart);
      cart.setValidationError(null);
    }

    cart.setValidating(false);

    return {
      data: cart,
      error: null,
    };
  }

  async checkoutWaitingList(cart: CartModel): Promise<RepositoryResponse<WaitingListModel>> {
    const customer = cart.getCustomer();
    const activeReservation = cart.getActiveReservation();
    const shop = activeReservation.getShop();
    const barber = activeReservation.getBarber();

    /**
     * @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
     * For now rest services after the first one are considered addons
     */
    const serviceIds = [activeReservation.getService(), ...activeReservation.getAddons()].map((serviceOrAddon) =>
      serviceOrAddon.getId(),
    );

    const payment = cart.getPaymentToken()
      ? {
          paymentToken: cart.getPaymentToken(),
        }
      : {
          paymentCardId: cart.getPaymentCardId(),
        };

    const response = await this.api.cart.addToWaitingList({
      shopId: shop.getId(),
      ...(barber.isAnyBarber() ? {} : { barberId: barber.getId() }),
      ...(customer.toJson() as unknown as CustomerPayload),
      day: formatISO(activeReservation.getWaitingListDate(), { representation: 'date' }),
      tipAmount: activeReservation.getTip()?.amount || 0,
      serviceIds,
      ...payment,
    });

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      error: null,
      data: new WaitingListModel(response.data),
    };
  }

  async checkoutGiftCardSale(cart: CartModel): Promise<RepositoryResponse<GiftCardModel>> {
    const customer = cart.getCustomer();
    const activeReservation = cart.getActiveReservation();
    const barber = activeReservation.getBarber();

    const response = await this.api.cart.sellGiftCard(activeReservation.getShop().getId(), {
      amount: activeReservation.getTotal(),
      customer: customer.toJson() as unknown as CustomerPayload,
      barberId: barber.isAnyBarber() ? null : barber.getId(),
      ...(cart.getPaymentToken()
        ? { paymentToken: cart.getPaymentToken() }
        : { paymentCardId: cart.getPaymentCardId() }),
    });

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      error: null,
      data: new GiftCardModel(response.data),
    };
  }

  /**
   * Checks a promo/loyalty/gift card and sets it to cart if valid
   * @deprecated Use `checkAndApplyDiscountV2` instead
   */
  async checkAndApplyDiscount(code: string, cart: CartModel): Promise<HttpResponse<CartModel>> {
    const reservation = cart.getActiveReservation();
    const barberId = ValidateCartDtoFactory.getReservationBarberId(reservation);

    if (!reservation.getDateTime()) {
      return {
        data: null,
        error: {
          message: 'Reservation date time is missing on discount check attempt',
          code: 'missing_date',
          status: 400,
        },
      };
    }

    const discountInfo = await this.api.cart.getDiscountByCode({
      promoCode: code,
      shopId: reservation.getShop().getId(),
      services: [reservation.getService(), ...reservation.getAddons()].map((serviceOrAddon) => ({
        id: serviceOrAddon.getId(),
      })),
      dateTime: formatISO(reservation.getDateTime()),
      ...(barberId ? { barberId } : null),
    });
    if (discountInfo.error) {
      return {
        data: null,
        error: discountInfo.error,
      };
    }

    // empty object in the giftCard field means this is not a gift cart
    // the only remaining option is promo so it's either one or another
    const isGiftCard = !!Object.keys(discountInfo.data.giftCard).length;
    cart.setPromoCode(isGiftCard ? null : code);
    cart.setGiftCardCode(isGiftCard ? code : null);

    return {
      data: cart,
      error: null,
    };
  }

  /**
   * Checks a promo/loyalty/gift card and sets it to cart if valid
   */
  // eslint-disable-next-line complexity
  async checkAndApplyDiscountV2(cartModel: CartModel, code: string): Promise<HttpResponse<CartModel>> {
    const cart = cartModel.getValidatedCart().getDataValues();

    if (!canApplyGiftPromoCodeFromCart(cart)) {
      return {
        data: null,
        error: {
          message: 'Reservation date time is missing on discount check attempt',
          code: 'missing_date',
          status: 400,
        },
      };
    }

    const payload = ValidateCartDtoFactory.getDataForValidation(cartModel);

    const applyAsPromo = !payload.promoCode || !!(payload.promoCode && payload.giftCardCode); // priority to promo type, as it can stick to cart as both `promo` and `giftCard`
    if (applyAsPromo) {
      payload.promoCode = code;
    } else {
      payload.giftCardCode = code;
    }

    const promise = this.getCreateUpdateV2Promise('update', cart.shopId, cart.id, payload);
    const { error, data: updatedCartModel } = await this.withCreateUpdateEffects(cartModel, promise);
    if (error) {
      return { error, data: null };
    }

    const updatedCart = updatedCartModel.getValidatedCart().getDataValues();

    // even if we applied code as a Promo, it could "stick" to the cart as Promo or a Gift Card
    const appliedGiftCard = updatedCart.payments?.find(isValidatedCartGiftCardPayment);
    if (appliedGiftCard?.giftCard.code.toUpperCase() === code.toUpperCase()) {
      cartModel.setGiftCardCode(code);
    }

    const appliedPromoCode = updatedCart.adjustments?.find(isAdjustmentPromo);
    if (appliedPromoCode?.name.toUpperCase() === code.toUpperCase()) {
      cartModel.setPromoCode(code);
    }

    return { error: null, data: cartModel };
  }

  /**
   * @description Checks if client is marked as prepay only. Emulate HttpError if validation fails
   * @deprecated Use `CustomerPrepayOnlyByMatchQuery` instead
   */
  // eslint-disable-next-line complexity
  async validateCustomer(cart: CartModel): Promise<HttpResponse<null>> {
    const reservation = cart.getActiveReservation();
    const customer = cart.getCustomer();

    const payload = {
      barberId: ValidateCartDtoFactory.getReservationBarberId(reservation),
      shopId: reservation.getShop().getId(),
      customer: customer.toJson() as unknown as CustomerPayload,
    };
    const response = await this.api.cart.validateCustomer(payload);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    if (response.data.customer?.error) {
      return {
        data: null,
        error: {
          code: 'PrepayOnlyCustomer',
          status: 400,
          message: response.data.customer.error,
        },
      };
    }

    return {
      data: null,
      error: null,
    };
  }

  private getValidationPromise(
    shopId: string,
    payload: ValidateCartPayload,
    forceRequest = false,
  ): Promise<HttpResponse<ValidatedCart>> {
    const key = JSON.stringify({
      shopId,
      payload,
    });

    const cacheItem = this.validationCache.find((item) => item.key === key);
    if (cacheItem && !forceRequest) {
      cacheItem.timestamp = Date.now();
      this.validationCache.sort((a, b) => a.timestamp - b.timestamp);
      return cacheItem.promise;
    }

    if (cacheItem && forceRequest) {
      cacheItem.timestamp = Date.now();
      this.validationCache = this.validationCache.filter((item) => item.key !== key);
    }

    const promise = this.api.cart.validate(shopId, payload);
    this.validationCache.push({
      key,
      timestamp: Date.now(),
      promise,
    });

    if (this.validationCache.length > CACHE_LIMIT) {
      this.validationCache = this.validationCache.slice(-CACHE_LIMIT);
    }

    return promise;
  }

  private async withCreateUpdateEffects(
    cartModel: CartModel,
    promise: Promise<HttpResponse<ValidatedCart>>,
  ): Promise<RepositoryResponse<CartModel>> {
    cartModel.setValidating(true);

    const { error, data } = await promise;
    const isLatestRequest = this.cache?.promise === promise;

    if (isLatestRequest) {
      cartModel.setValidating(false);
      cartModel.setValidationError(error || null);
    }

    if (error) {
      return { error, data: null };
    }

    if (isLatestRequest) {
      cartModel.setValidatedCart(new ValidatedCartModel(data));
    }

    return { error: null, data: cartModel };
  }

  private getCacheKey(shopId: string, cartId: string, payload: ValidateCartPayload): string {
    return JSON.stringify({ shopId, cartId, payload });
  }

  private getCreateUpdateV2Promise(
    requestType: 'create' | 'update',
    shopId: string,
    cartId: string,
    payload: ValidateCartPayload,
    forceRequest = false,
  ): Promise<HttpResponse<ValidatedCart>> {
    const key = this.getCacheKey(shopId, cartId, payload);

    const cacheItem = this.cache?.key === key ? this.cache : null;
    if (cacheItem && !forceRequest) {
      return cacheItem.promise;
    }

    const promise =
      requestType === 'create' ? this.api.cart.create(shopId, payload) : this.api.cart.update(shopId, cartId, payload);
    this.cache = { key, promise };

    return promise;
  }
}
