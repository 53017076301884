import { ReactNode, HTMLAttributes, useRef, useState, useEffect, memo } from 'react';

import { TestIdProps, withTestIdProp } from '../../../utils';
import { setTag, block, wrapperStyles, childrenStyles, spreaderStyles, sizerWrapperStyles } from './constants';
import { useResize } from './useResize';

type WhitelistedElementAttributes =
  | HTMLAttributes<HTMLDivElement>
  | HTMLAttributes<HTMLParagraphElement>
  | HTMLAttributes<HTMLAnchorElement>
  | HTMLAttributes<HTMLSpanElement>
  | HTMLAttributes<HTMLHeadingElement>;

interface Props extends TestIdProps {
  lines: number;
  children: string;
  className?: string;
  attributes?: WhitelistedElementAttributes;
  throttleRate?: number;
  tagName?: string;
  overflowNode?: ReactNode | '\u2026';
  onTruncationChange?: (isTruncated: boolean) => void;
}

/**
 * React Line clamp that won't get you fired.
 *
 * My boss once worked on an app that had a Javascript line clamp that truncated words.
 * Then one day the term "Cooking with Shiitake" made it into the UI and you can imagine how it got trimmed.
 * Trimming words is dangerous, don't risk it.
 * We've built a React component that handles this for you both responsively and responsibly.
 *
 * @see `shiitake` package https://github.com/bsidelinger912/shiitake
 */
export const Clamp = memo((props: Props) => {
  const allChildren = typeof props.children === 'string' ? props.children : '';

  const sizerElRef = useRef<HTMLElement>();
  const spreaderElRef = useRef<HTMLElement>();
  const testChildrenElRef = useRef<HTMLElement>();
  const handlingResize = useRef(true);
  const searchStart = useRef(0);
  const searchEnd = useRef(allChildren.length);

  const container = { tag: setTag(props.tagName) };

  const [testChildren, setTestChildren] = useState('');
  const [children, setChildren] = useState(allChildren); // TODO: do we really need render full on server any more???
  const [lastCalculatedWidth, setLastCalculatedWidth] = useState(-1);

  const testChildrenRange = (start: number, end: number): void => {
    searchStart.current = start;
    searchEnd.current = end;

    setTestChildren(allChildren.substring(0, end));
  };

  const calculationComplete = (): void => {
    let newChildren = allChildren;

    // are we actually trimming?
    if (testChildren.length < allChildren.length) {
      const words = testChildren.split(' ');
      if (words.length === 1) {
        newChildren = testChildren;
      } else {
        newChildren = words.slice(0, -1).join(' ');
      }
    }

    handlingResize.current = false;
    setChildren(newChildren);

    // if we  changed the length of the visible string, check if we're switching from truncated to
    // not-truncated or vice versa
    if (newChildren.length !== children.length) {
      const wasTruncatedBefore = children.length !== allChildren.length;
      const isTruncatedNow = newChildren.length !== allChildren.length;

      if (wasTruncatedBefore !== isTruncatedNow) {
        props.onTruncationChange?.(isTruncatedNow);
      }
    }
  };

  const checkHeight = (): void => {
    const contentHeight = testChildrenElRef.current.offsetHeight;
    const halfWay = Math.round((searchEnd.current - searchStart.current) / 2);
    const targetHeight = sizerElRef.current ? sizerElRef.current.offsetHeight : undefined;
    const linear = searchEnd.current - searchStart.current < 6;

    // do we need to trim?
    if (contentHeight > targetHeight) {
      // chunk/ trim down
      if (linear) {
        testChildrenRange(testChildren.length, testChildren.length - 1);
      } else {
        testChildrenRange(searchStart.current, searchEnd.current - halfWay);
      }

      // we've used all the characters in a window expand situation
    } else if (testChildren.length === allChildren.length) {
      calculationComplete();
    } else if (linear) {
      // if we just got here by decrementing one, we're good
      if (searchStart.current > searchEnd.current) {
        calculationComplete();
      } else {
        // window grew, increment up one
        testChildrenRange(testChildren.length, testChildren.length + 1);
      }
    } else {
      // chunk up, still in binary search mode
      testChildrenRange(searchEnd.current, searchEnd.current + halfWay);
    }
  };

  const startCalculation = (): void => {
    searchStart.current = 0;
    searchEnd.current = allChildren.length;
    setLastCalculatedWidth(spreaderElRef.current.offsetWidth);
    handlingResize.current = true;
    setTestChildren(allChildren);
  };

  const recalculate = (): void => {
    // this will kick off a sequence mimicking an initial calculation,
    // this is necessary because if we're currently showing all the children (no need to truncate)
    // then the effect below to check height won't kick in which it needs too.
    setTestChildren('');
  };

  const handleResize = (): void => {
    if (!spreaderElRef.current) {
      return;
    }

    if (spreaderElRef.current.offsetWidth !== lastCalculatedWidth && !handlingResize.current) {
      recalculate();
    }
  };

  useResize(handleResize, props.throttleRate);

  useEffect(() => {
    if (testChildren !== '') {
      recalculate();
    }
  }, [allChildren]);

  useEffect(() => {
    if (testChildren === '' && sizerElRef.current) {
      startCalculation();
    } else {
      checkHeight();
    }
  }, [testChildren, sizerElRef.current]);

  useEffect(() => {
    // this will skip this effect on the first render
    if (testChildren !== '') {
      recalculate();
    }
  }, [props.lines]);

  /* eslint-disable react/no-array-index-key */
  const vertSpacers: ReactNode[] = new Array(props.lines).fill(null).map((_, i) => (
    <span key={`character-${i}`} style={block}>
      W
    </span>
  ));
  /* eslint-enable react/no-array-index-key */

  const maxHeight = `${sizerElRef.current ? sizerElRef.current.offsetHeight : 0}px`;
  const overflow = testChildren.length < allChildren.length ? props.overflowNode ?? '\u2026' : null;

  return (
    <container.tag {...{ className: props.className ?? '', ...props.attributes, ...withTestIdProp(props) }}>
      <span
        style={{
          ...wrapperStyles,
          maxHeight,
        }}
      >
        <span className="shiitake-children" style={childrenStyles}>
          {children}
          {overflow}
        </span>

        <span ref={spreaderElRef} style={spreaderStyles} aria-hidden="true">
          {allChildren}
        </span>

        <span style={sizerWrapperStyles} aria-hidden="true">
          <span ref={sizerElRef} style={block}>
            {vertSpacers}
          </span>
          <span className="shiitake-test-children" ref={testChildrenElRef} style={block}>
            {testChildren}
            {overflow}
          </span>
        </span>
      </span>
    </container.tag>
  );
});
