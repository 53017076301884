import { useCallback } from 'react';
import { StripeElements } from '@stripe/stripe-js';
import { PaymentProcessor } from '@water-web/payments';

export interface UsePaymentInfoCollectorResult {
  collectDigitalWalletPaymentInfo: () => ReturnType<PaymentProcessor['payWithDigitalWallet']>;
  collectCardEntryPaymentInfo: () => ReturnType<PaymentProcessor['payWithCard']>;
}

interface UsePaymentInfoCollectorPayload {
  processor: PaymentProcessor;
  label: string;
  amount: number;
  stripeElements: StripeElements;
}

export const usePaymentInfoCollector = (payload: UsePaymentInfoCollectorPayload): UsePaymentInfoCollectorResult => {
  const collectDigitalWalletPaymentInfo = useCallback(async () => {
    return payload.processor.payWithDigitalWallet(payload.label, payload.amount);
  }, [payload.label, payload.amount, payload.processor]);

  const collectCardEntryPaymentInfo = useCallback(async () => {
    return payload.processor.payWithElements(payload.stripeElements);
  }, [payload.processor, payload.stripeElements]);

  return {
    collectDigitalWalletPaymentInfo,
    collectCardEntryPaymentInfo,
  };
};
