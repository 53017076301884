import { useEffect, useState } from 'react';
import { Barber, Brand, Shop } from '@water-web/types';
import { ANY_BARBER_ID_AND_ROUTE } from '@water-web/repository';
import { useApiQuery } from '@water-web/api';

import { barberRepository } from '@app/repository';
import { BrandHomeService, PublicBarberService, PublicShopService } from '@app/apiServices';

import { useFastRouteParams } from '../useFastRouteParams';

interface PageRouteParams {
  brandIdOrRoute?: string;
  shopIdOrRoute?: string;
  barberIdOrRoute?: string;
}

interface Result {
  failed: boolean;
  completed: boolean;
  brand?: Brand;
  shop?: Shop;
  barber?: Barber;
  isAnyBarber: boolean;
}

export const usePageInitialProps = (): Result => {
  const routeParams = useFastRouteParams<PageRouteParams>();

  const [brandIdOrRoute, setBrandIdOrRoute] = useState(routeParams.brandIdOrRoute);

  const brandQuery = useApiQuery({
    endpoint: BrandHomeService.byId,
    payload: {
      idOrRoute: brandIdOrRoute,
    },
    options: {
      enabled: !!brandIdOrRoute,
      select: (data) => {
        const { brand } = data;
        return brand as Brand;
      },
    },
  });

  const shopQuery = useApiQuery({
    endpoint: PublicShopService.byId,
    payload: {
      idOrRoute: routeParams.shopIdOrRoute,
    },
    options: {
      enabled: !!routeParams.shopIdOrRoute,
      select: (data) => data as unknown as Shop,
    },
  });

  useEffect(() => {
    if (shopQuery.data && !brandIdOrRoute) {
      setBrandIdOrRoute(shopQuery.data.brandId);
    }
  }, [brandIdOrRoute, shopQuery.data]);

  const isAnyBarber = routeParams.barberIdOrRoute === ANY_BARBER_ID_AND_ROUTE;
  const barberQuery = useApiQuery({
    endpoint: PublicBarberService.byId,
    payload: {
      shopIdOrRoute: routeParams.shopIdOrRoute,
      idOrRoute: routeParams.barberIdOrRoute,
    },
    options: {
      enabled: !!routeParams.shopIdOrRoute && !!routeParams.barberIdOrRoute && !isAnyBarber,
    },
  });

  useEffect(() => {
    if (!brandQuery.data) {
      return; // Nothing loaded here, we can skip this
    }

    const message = {
      type: 'squire_data_refresh',
      payload: {
        brand: brandQuery.data ?? null,
        shop: shopQuery.data ?? null,
        barber: barberQuery.data ?? null,
      },
    };

    window.parent?.postMessage?.(JSON.stringify(message), '*');
  }, [brandQuery?.data, shopQuery?.data, barberQuery?.data]);

  const requiredQueries = [];

  if (brandIdOrRoute || routeParams.shopIdOrRoute) {
    requiredQueries.push(brandQuery);
  }

  if (routeParams.shopIdOrRoute) {
    requiredQueries.push(shopQuery);
  }

  if (routeParams.barberIdOrRoute && !isAnyBarber) {
    requiredQueries.push(barberQuery);
  }

  return {
    failed: requiredQueries.some((query) => query.isError),
    completed: requiredQueries.every((query) => query.isFetched),
    brand: brandQuery.data,
    shop: shopQuery.data,
    barber: isAnyBarber ? (barberRepository.createAnyBarber().toJson() as unknown as Barber) : barberQuery.data,
    isAnyBarber,
  };
};
