import { Box, Flex } from '@water-web/view';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { appLayers } from '@app/appLayers';
import { Button } from '@app/components/atoms/Button';

export const StyledWidgetContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  overflow: hidden;

  font-family: 'SF Pro Text', 'Roboto', sans-serif;
`;

export const CloseButton = styled(Button.Close)`
  position: absolute;
  top: 14px;
  right: 6px;
  z-index: 2;
`;

const variants = {
  initial: ($isBack) => ({ x: $isBack ? '-100%' : '100%' }),
  animate: { x: 0 },
  exit: ($isBack) => ({ x: $isBack ? '100%' : '-100%' }),
};

type StyledWidgetContentProps = { $isBack?: boolean };
export const StyledWidgetContent = styled(Flex).attrs<StyledWidgetContentProps>((props) => ({
  as: motion.div,
  variants,
  exit: 'exit',
  animate: 'animate',
  initial: 'initial',
  transition: props.theme.animations.booking.pageTransition,
  custom: props.$isBack,
}))<StyledWidgetContentProps>`
  position: absolute;
  width: 100%;
  z-index: ${appLayers.content};
  overflow-y: auto;
  height: 100%;
`;

interface StyledWidgetTitleProps {
  dimmed?: boolean;
  hasBackground?: boolean;
}

export const StyledWidgetTitle = styled(Flex)<StyledWidgetTitleProps>`
  position: sticky;
  z-index: ${appLayers.title};
  transition: background-color ${({ theme }) => theme.animations.booking.pageTransition.duration}s;
  color: ${({ theme, dimmed }) => (dimmed ? theme.colors.secondaryFonts[6] : 'inherit')};

  ${({ theme, hasBackground }) => css`
    background-color: ${hasBackground ? theme.colors.chromeTitle : 'transparent'};
    backdrop-filter: ${hasBackground ? 'blur(4px)' : 'none'};
  `};
`;

export const StyledBackButton = styled(Button.Back).attrs((props) => ({
  as: motion.button,
  initial: { width: 0, opacity: 0 },
  animate: { width: 24, opacity: 0.3 },
  exit: { width: 0, opacity: 0 },
  transition: props.theme.animations.booking.pageTransition,
}))`
  color: ${({ theme }) => theme.colors.primaryFont};
`;

export const TooltipsMountingPoint = styled(Box)`
  width: 100vw;
  position: absolute;
  z-index: ${appLayers.tooltip};
  left: calc((max(100%, 375px) - 100vw) / 2);
`;

// Because page transitions take delay + transition time, we want to have the same
// time to blip the title. The title isn't animated as of right now, but it can't
// change immediately upon route change - so by combining this presence animattion
// that has delay in it, with AnimatePresence on each of the title routes, we're able
// to delay unmounting of the title until the page transition is done, and only after
// that mounitng the new title.
export const DelayedRenderTitleContainer = styled(Box).attrs((props) => ({
  as: motion.div,
  mode: 'wait',
  initial: { background: null }, // we need to animate something!
  animate: { background: null },
  exit: { background: null },
  transition: {
    delay: props.theme.animations.booking.pageTransition.delay + props.theme.animations.booking.pageTransition.duration,
    duration: props.theme.animations.booking.pageTransition.duration,
  },
}))``;

export const TopRightButtonsContainer = styled(Flex)`
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 2;
  gap: 8px;
`;

export const LanguagePickerContainer = styled(Box)<{ showCloseButton?: boolean }>`
  position: absolute;
  top: 22px;
  right: ${(props) => (props.showCloseButton ? '48px' : '16px')};
`;
