import {
  WaitingListServiceConstructor,
  SaleOrderServiceConstructor,
  UserServiceConstructor,
  NotificationsConsentConstructor,
  AppointmentServiceConstructor,
  NotificationsServiceConstructor,
  BarberServiceConstructor,
  CartServiceConstructor,
  BrandHomeConstructor,
  PublicShopConstructor,
  PublicBarberConstructor,
} from '@water-web/view';

import { config } from '@app/config';
import { getToken } from '@app/auth';

export const WaitingListService = WaitingListServiceConstructor(config.apiUrl);
export const SaleOrderService = SaleOrderServiceConstructor(config.apiUrl);
export const UserService = UserServiceConstructor({ baseUrl: config.apiUrl, getToken });
export const NotificationsConsent = NotificationsConsentConstructor(config.apiUrl);
export const AppointmentService = AppointmentServiceConstructor({ baseUrl: config.apiUrl, getToken });
export const NotificationsService = NotificationsServiceConstructor({ baseUrl: config.apiUrl, getToken });
export const BarberService = BarberServiceConstructor(config.apiUrl);
export const BrandHomeService = BrandHomeConstructor(config.apiUrl);
export const PublicShopService = PublicShopConstructor(config.apiUrl);
export const PublicBarberService = PublicBarberConstructor(config.apiUrl);
export const CartService = CartServiceConstructor({
  baseUrl: config.apiUrl,
  platform: config.mode.standalone ? 'Web' : 'Widget',
  getToken,
});
