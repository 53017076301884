import { WaitingListItem, WaitingListItemPersonal } from '@water-web/types';

import { BaseModel } from './base';

const isPersonalItem = (item: WaitingListItem): item is WaitingListItemPersonal => {
  return !!(item as unknown as WaitingListItemPersonal).customer;
};

export class WaitingListItemModel extends BaseModel<WaitingListItemModel> {
  protected dataValues: WaitingListItem;
  protected isPersonal: boolean;

  constructor(item: WaitingListItem) {
    super();

    this.isPersonal = isPersonalItem(item);
    this.dataValues = item;
  }

  /**
   * @description Indicates whether it's a viewer's item
   */
  getIsPersonal(): boolean {
    return this.isPersonal;
  }

  getNumberInLine(): number {
    return this.dataValues.numberInLine;
  }

  getService(): string {
    return this.dataValues.service;
  }

  getFirstName(): string {
    return isPersonalItem(this.dataValues) ? this.dataValues.customer.firstName : this.dataValues.firstName;
  }

  getLastName(): string {
    return isPersonalItem(this.dataValues) ? this.dataValues.customer.lastName : this.dataValues.lastName;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
