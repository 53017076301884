import {
  MinMax,
  Shop,
  Customer,
  ValidatedCart,
  isValidatedCartCardPayment,
  ValidatedCartTip,
  ValidatedCartItem,
  ValidatedCartSubItem,
  ValidatedCartCardInfo,
  isCartItemAnAppointment,
  isCartItemAGiftCard,
  isCartItemAProduct,
  isSubItemAService,
  isCartItemANewAppointment,
} from '@water-web/types';

import { getIsCoveredByGiftCardFromCartBreakdown } from '../getters';
import { BaseModel } from './base';

export class ValidatedCartModel extends BaseModel<ValidatedCartModel> {
  private items: Record<string, ValidatedCartItem> = {};
  protected reservations: ValidatedCartItem[];
  protected dataValues: Readonly<ValidatedCart>;

  constructor(validatedCart: ValidatedCart) {
    super();

    this.dataValues = { ...validatedCart };

    this.items = validatedCart.items.reduce((memo, item) => {
      return {
        ...memo,
        [item.id]: item,
      };
    }, {});

    this.reservations = validatedCart.items.filter(isCartItemAnAppointment);
  }

  getId(): string {
    return this.dataValues.id;
  }

  getShopId(): string {
    return this.dataValues.shop.id;
  }

  getFeeAmount(): number {
    return this.dataValues.breakdown.totalFee;
  }

  getTaxAmount(): number {
    return this.dataValues.taxAmount;
  }

  /**
   * @description
   * A full appointment's total before discounting. Doesn't change with time.
   */
  getTotal(): number {
    return this.dataValues.breakdown.totalChargeAmount;
  }

  /**
   * @description
   * Total that's need to be added to the cart's payments array as a card payment.
   * Different from getTotalLeftForPayment because of the way API charges cards.
   * Doesn't include tip and fees because they are charged separately on the API side.
   */
  getTotalLeftForCardPayment(): number {
    return this.dataValues.breakdown.amountLeftForPayment;
  }

  /**
   * @description
   * Total that we show to customers in the cart.
   * Includes all tips and fees, as well as considers discounts.
   */
  getTotalLeftForPayment(): number {
    return this.dataValues.breakdown.outstandingChargeAmount;
  }

  /**
   * @description
   * Total as a range. It doesn't include tips, fees and discount.
   * It's hard to use it at the place where `outstandingChargeAmount` was used as a single value
   */
  getTotalRange(): MinMax {
    return this.dataValues.breakdown.totalRange;
  }

  getCurrency(): string {
    return this.dataValues.currency;
  }

  getProducts(): ValidatedCartItem[] {
    return this.dataValues.items.filter(isCartItemAProduct);
  }

  getReservations(): ValidatedCartItem[] {
    return this.reservations;
  }

  getNewAppointments(): ValidatedCartItem[] {
    return this.reservations.filter(isCartItemANewAppointment);
  }

  /**
   * @description
   * Returns gift card items sold to customer
   */
  getGiftCardsForSale(): ValidatedCartItem[] {
    return this.dataValues.items.filter(isCartItemAGiftCard);
  }

  getShop(): Shop {
    return this.dataValues.shop;
  }

  getPayingCustomer(): Customer {
    return this.dataValues.payingCustomer;
  }

  getPayingCustomerId(): string | null {
    return this.dataValues.payingCustomer?.id || null;
  }

  getServiceForReservation(id: string): ValidatedCartSubItem | null {
    const services = this.getReservations()
      .find((item) => item.id === id)
      .subItems?.filter(isSubItemAService)
      .filter((item) => !item.parentId);

    return services && services.length ? services[0] : null;
  }

  /**
   * @see https://squire.atlassian.net/wiki/spaces/SE/pages/1341949546/Services+as+add-ons
   * For now rest services after the first one are considered addons
   */
  getAddonsForReservation(id: string): ValidatedCartSubItem[] | null {
    const services = this.getReservations()
      .find((item) => item.id === id)
      .subItems?.filter((item) => item.type === 'service' && !item.parentId);
    // original addons filter function
    // ?.filter((item) => item.type === 'service' && !!item.parentId);

    return services && services.length ? services.slice(1) : null;
  }

  getCustomerForReservation(id: string): Customer | null {
    const { customer } = this.getReservations().find((item) => item.id === id);
    return customer || null;
  }

  getTipAmount(): number {
    return this.dataValues.tips?.reduce((memo: number, t: ValidatedCartTip) => memo + t.amount, 0) ?? 0;
  }

  /**
   * @description Returns sale order payment card info or null if a sale was fully paid with a gift card
   */
  getPaymentCardInfo(): ValidatedCartCardInfo | null {
    return this.dataValues.payments.find(isValidatedCartCardPayment)?.cardInfo ?? null;
  }

  /**
   * @description
   * That's possible not to pay with card if a gift card covers total; but we need to differentiate
   * when a gift card covers everything, vs. when the total sum of order is 0 (free services) and no GC.
   */
  allPaymentCoveredByGiftCard(): boolean {
    return getIsCoveredByGiftCardFromCartBreakdown(this.dataValues.breakdown);
  }

  canBookWithoutCard(): boolean {
    return this.dataValues.breakdown.canBookNoPay;
  }

  canBookAndProvideCard(): boolean {
    return this.dataValues.breakdown.canReserve;
  }

  getIsReservation(): boolean {
    return this.dataValues.isReservation;
  }

  getIsBookNoPay(): boolean {
    return this.dataValues.isBookNoPay;
  }

  getDataValues(): Readonly<ValidatedCart> {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
