import { QueryKey } from 'react-query';
import { WaitingList } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

export interface WaitingListPayload {
  id: string;
}

export class WaitingListQuery extends EndpointQuery<WaitingList, WaitingListPayload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: WaitingListPayload): QueryKey {
    return queryKeys.waitingList(payload);
  }

  request(payload: WaitingListPayload, signal?: AbortSignal): Promise<WaitingList> {
    return this.http.get(`/v1/customer/waiting-list/${payload.id}`, {
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      search: {
        include: ['customer', 'services'],
      },
      signal,
      resourceUrl: '/customer/waiting-list/:id',
    });
  }
}
