import { CustomerModel } from '@water-web/repository';
import { AcquisitionChannel } from '@water-web/types';
import { AnalyticsEvent, AcquisitionChannelPayload, Tracker, CommonPayload } from '@water-web/view';

declare module '@water-web/view' {
  interface CommonPayload {
    platform: string;
    isTouchDevice: string;
  }
}

export class Analytics {
  static instance: Analytics = null;
  trackers: Tracker[] = [];

  commonPayload: CommonPayload;

  private acquisitionChannel: AcquisitionChannel | null = null;

  static getInstance(): Analytics {
    if (Analytics.instance === null) {
      Analytics.instance = new Analytics();

      // see `declare module './types'` above
      Analytics.instance.addCommonPayloadProp('platform', 'wow');
      Analytics.instance.addCommonPayloadProp(
        'isTouchDevice',
        String(typeof window !== 'undefined' && window.matchMedia('(pointer: coarse)').matches),
      );
    }
    return Analytics.instance;
  }

  registerTracker(tracker: Tracker): void {
    const isTrackerAlreadyRegistered = this.trackers.some((t) => t.getName() === tracker.getName());
    if (!isTrackerAlreadyRegistered) {
      this.trackers.push(tracker);
    }
  }

  /**
   * Don't forget to extend `CommonPayload` interface when calling this method
   * @example
   * declare module '@water-web/view' {
   *   interface CommonPayload {
   *     brandId: string;
   *   }
   * }
   */
  addCommonPayloadProp<T extends keyof CommonPayload>(propName: T, propValue: CommonPayload[T]): void {
    this.commonPayload = {
      ...this.commonPayload,
      [propName]: propValue,
    };
  }

  track<T extends { [key: string]: unknown }>(analyticsEvent: AnalyticsEvent, payload?: T): void {
    this.trackers.forEach((tracker) =>
      tracker.track?.(analyticsEvent, {
        ...(payload || {}),
        ...this.commonPayload,
      }),
    );
  }

  trackCustomerInfo(customer: CustomerModel): void {
    this.trackers.forEach((tracker) => tracker.trackCustomerInfo?.(customer));
  }

  page(location: string): void {
    this.trackers.forEach((tracker) => tracker.page?.(location, this.commonPayload));
  }

  identify(customerId: string): void {
    this.trackers.forEach((tracker) => tracker.identify?.(customerId));
  }

  setAcquisitionChannel(acquisitionChannel: AcquisitionChannel, payload?: AcquisitionChannelPayload): void {
    // I don't see any scenario where acquisition channel would need to change, so I only set it once
    if (!this.acquisitionChannel) {
      this.acquisitionChannel = acquisitionChannel;
      this.track(AnalyticsEvent.VISITOR_FROM_CITY_PAGES, payload as Record<string, unknown>);
    }
  }

  getAcquisitionChannel(): AcquisitionChannel | null {
    return this.acquisitionChannel;
  }
}
