/* eslint-disable no-underscore-dangle */
import {
  PaymentProcessorEnv,
  AvailableDigitalWallets,
  PaymentProcessorCountry,
  PaymentProcessorCurrency,
} from '../types';

declare global {
  interface Window {
    google: any;
    _squireGoogleApiPromise: Promise<void>;
  }
}

export interface BaseProcessorConfig {
  env: PaymentProcessorEnv;
  country: PaymentProcessorCountry;
  currency: PaymentProcessorCurrency;
}

export abstract class BaseProcessor {
  protected constructor(config: BaseProcessorConfig) {
    this.env = config.env;
    this.country = config.country;
    this.currency = config.currency;

    this.loadGooglePaymentsApi();
  }

  protected abstract type: string;
  protected env: PaymentProcessorEnv = null;
  protected country: PaymentProcessorCountry = null;
  protected currency: PaymentProcessorCurrency = null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private googlePaymentsApi: { PaymentsClient: typeof google.payments.api.PaymentsClient } | null = null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private googlePayCheckRequest: google.payments.api.IsReadyToPayRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
        },
      },
    ],
    existingPaymentMethodRequired: true,
  };

  private loadGooglePaymentsApi(): void {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      const isApiLoaded = !!window.google?.payments?.api;
      let apiLoadingPromise = window._squireGoogleApiPromise;
      if (!isApiLoaded && !apiLoadingPromise) {
        window._squireGoogleApiPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.src = 'https://pay.google.com/gp/p/js/pay.js';
          script.onload = () => resolve();
          document.head.appendChild(script);
        });

        apiLoadingPromise = window._squireGoogleApiPromise;
      }

      if (!isApiLoaded && apiLoadingPromise) {
        apiLoadingPromise.then(() => {
          this.googlePaymentsApi = window.google?.payments?.api;
          window._squireGoogleApiPromise = null;
        });
      }
    }
  }

  private async getGooglePaymentsApi(): Promise<BaseProcessor['googlePaymentsApi'] | null> {
    if (this.googlePaymentsApi) {
      return this.googlePaymentsApi;
    }

    const isApiLoaded = !!window.google?.payments?.api;
    const isApiLoading = !!window._squireGoogleApiPromise;
    if (!isApiLoaded && isApiLoading) {
      await window._squireGoogleApiPromise;
    }

    return window.google?.payments?.api || null;
  }

  /**
   * @description Returns the type of payment processor
   */
  getType(): string {
    return this.type;
  }

  async isGooglePayAvailable(): Promise<boolean> {
    const googlePaymentsApi = await this.getGooglePaymentsApi();
    const PaymentsClient = googlePaymentsApi?.PaymentsClient;
    if (!PaymentsClient) {
      console.error('Google payments API has to be passed to the constructor in order to use Google Pay.');
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const googlePayEnv: google.payments.api.Environment = this.env === 'production' ? 'PRODUCTION' : 'TEST';
    const paymentsClient = new PaymentsClient({ environment: googlePayEnv });

    try {
      const googlePayCheckResponse = await paymentsClient.isReadyToPay(this.googlePayCheckRequest);
      return !!googlePayCheckResponse?.result;
    } catch {
      return false;
    }
  }

  async isApplePayAvailable(): Promise<boolean> {
    return true;
  }

  async getAvailableDigitalWallets(): Promise<AvailableDigitalWallets> {
    const promises = [this.isApplePayAvailable(), this.isGooglePayAvailable()];
    const [applePay, googlePay] = await Promise.all(promises);

    return { applePay, googlePay };
  }

  async isWalletSupported(): Promise<boolean> {
    return Promise.all<Promise<boolean>[]>([
      BaseProcessor.prototype.isGooglePayAvailable.call(this),
      Promise.resolve()
        .then(() => (typeof window === 'undefined' ? false : window.ApplePaySession?.canMakePayments()))
        .catch(() => false),
    ]).then((results) => results.some(Boolean));
  }
}
