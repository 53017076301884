import { Box, Flex, Translate } from '@water-web/view';

import { Text } from '@app/components/atoms';

import { IconContainer, RotatingPendingIconCollapsible } from './styles';

export const ProcessingMessage = () => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <IconContainer>
        <RotatingPendingIconCollapsible />
      </IconContainer>

      <Box height="24px" mt={6}>
        <Text.Title1>
          <Translate i18nKey="pages.reservation.processing.0" />
        </Text.Title1>
      </Box>
    </Flex>
  );
};
