/* eslint-disable max-classes-per-file */
import { KeyValueStoreStrategy } from './types';

export class LocalStorageStrategy implements KeyValueStoreStrategy {
  isAvailable(): boolean {
    try {
      const test = '__storage_test__';
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  getName(): string {
    return 'localStorage';
  }

  setItem(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  getItem(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  clear(): void {
    window.localStorage.clear();
  }

  hasItem(key: string): boolean {
    return window.localStorage.getItem(key) !== null;
  }
}

export class SessionStorageStrategy implements KeyValueStoreStrategy {
  isAvailable(): boolean {
    try {
      const test = '__storage_test__';
      window.sessionStorage.setItem(test, test);
      window.sessionStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  getName(): string {
    return 'sessionStorage';
  }

  setItem(key: string, value: string): void {
    window.sessionStorage.setItem(key, value);
  }

  getItem(key: string): string | null {
    return window.sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  clear(): void {
    window.sessionStorage.clear();
  }

  hasItem(key: string): boolean {
    return window.sessionStorage.getItem(key) !== null;
  }
}

export class InMemoryStorage implements KeyValueStoreStrategy {
  private storage: Record<string, string> = {};

  isAvailable(): boolean {
    return true; // InMemoryStorage is always available
  }

  getName(): string {
    return 'inMemoryStorage';
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  getItem(key: string): string | null {
    return this.storage[key] || null;
  }

  removeItem(key: string): void {
    delete this.storage[key];
  }

  clear(): void {
    this.storage = {};
  }

  hasItem(key: string): boolean {
    return this.storage[key] !== undefined;
  }
}
