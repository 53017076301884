import { GiftCard, ValidatedCartItem } from '@water-web/types';

import { BaseModel } from './base';

export class GiftCardModel extends BaseModel<GiftCardModel> {
  protected dataValues: GiftCard;

  static fromValidatedCartItem(item: ValidatedCartItem): GiftCardModel {
    const giftCard = {
      code: item.name,
      initialBalance: item.priceWithoutTaxes,
      barberId: item.barberId,
    } as GiftCard;

    return new GiftCardModel(giftCard);
  }

  constructor(giftCard: GiftCard) {
    super();

    this.dataValues = { ...giftCard };
  }

  /**
   * @description
   * Returns gift card balance at the moment of purchase
   */
  getInitialAmount(): number {
    return this.dataValues.initialBalance;
  }

  /**
   * @description
   * 6-digit code to redeem a gift card
   */
  getCode(): string {
    return this.dataValues.code;
  }

  /**
   * @description
   * Returns a sale order id associated with a sale. Only present at the moment of sale.
   */
  getSaleOrderId(): string {
    return this.dataValues.customChargeId ?? null;
  }

  /**
   * @description
   * Returns true if a gift cart is limited to a specific barber
   */
  isBarberSpecific(): boolean {
    return !!this.dataValues.barberId;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
