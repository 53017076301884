import { QueryKey } from 'react-query';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

interface Payload {
  shopId: string;
  barberId: string;
  customer: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

interface Request {
  shopId: string;
  barberId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  validatePrepayOnly: true;
}

interface Response {
  customer?: {
    error?: string;
  };
}

export class CustomerPrepayOnlyByMatchQuery extends EndpointQuery<boolean, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.prepayOnlyByMatch(payload);
  }

  async request(payload: Payload, signal?: AbortSignal): Promise<boolean> {
    const response = await this.http.post<Response, Request>(
      '/v1/appointment/validate',
      {
        firstName: payload.customer.firstName,
        lastName: payload.customer.lastName,
        phone: payload.customer.phone,
        email: payload.customer.email,
        shopId: payload.shopId,
        barberId: payload.barberId,
        validatePrepayOnly: true,
      },
      {
        headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
        signal,
        resourceUrl: '/appointment/validate',
      },
    );

    return !!response.customer?.error;
  }
}
