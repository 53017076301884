import styled, { StyledComponent } from 'styled-components';
import { TextBase, TextBaseProps, VariantProps } from '@water-web/view';

import { SelectableProps } from '@app/components/types';
import { withAliases } from '@app/components/utils';

import { variants } from './variants';

export interface TextProps extends SelectableProps, VariantProps<keyof (typeof variants)['variants']> {}

const StyledText = styled(TextBase).attrs({
  $styledVariants: variants,
})`` as StyledComponent<'p', any, Omit<TextBaseProps, 'variants'> & TextProps>;

StyledText.defaultProps = {
  variant: 'p1Regular',
};

const aliases = {
  Title1: styled(StyledText).attrs<TextProps>({ variant: 'title1' })``,
  P1Bold: styled(StyledText).attrs<TextProps>({ variant: 'p1Bold' })``,
  P1Regular: styled(StyledText).attrs<TextProps>({ variant: 'p1Regular' })``,
  P2Bold: styled(StyledText).attrs<TextProps>({ variant: 'p2Bold' })``,
  P2Regular: styled(StyledText).attrs<TextProps>({ variant: 'p2Regular' })``,
  P3Bold: styled(StyledText).attrs<TextProps>({ variant: 'p3Bold' })``,
  P3Regular: styled(StyledText).attrs<TextProps>({ variant: 'p3Regular' })``,
  P4Bold: styled(StyledText).attrs<TextProps>({ variant: 'p4Bold' })``,
  P4Regular: styled(StyledText).attrs<TextProps>({ variant: 'p4Regular' })``,
  P5Bold: styled(StyledText).attrs<TextProps>({ variant: 'p5Bold' })``,
};

export const Text = withAliases(StyledText, aliases);
