import { cartRepository } from '@app/repository';
import { convertUrlToRoute } from '@app/utils';

import { updateMarketingConsent } from './utils';
import { PhaseConfig, PhaseHookArgs } from '../types';

export const processingWaitingList: PhaseConfig = {
  name: 'processingWaitingList',
  init: async (params: PhaseHookArgs) => {
    params.cartState.setLoader('pending');
    params.userActionsAnalytics.trackConfirmWaitlist();

    const waitlistCheckoutResult = await cartRepository.checkoutWaitingList(params.cartModel);

    if (waitlistCheckoutResult.error?.status === 409 && waitlistCheckoutResult.error?.code === 'Conflict') {
      params.setCheckoutPhase('waitingListExistenceError');
      return;
    }

    if (waitlistCheckoutResult.error) {
      params.userActionsAnalytics.trackWaitlistFailure();
      params.setCheckoutPhase('error');
      return;
    }

    const customerId = waitlistCheckoutResult.data.getCustomerId();
    await updateMarketingConsent(params, customerId);

    params.userActionsAnalytics.trackWaitlistSuccess(customerId);
    params.setCheckoutPhase('success');
    params.popupController.closeAll();

    const route = params.urlBuilder.getWaitingListUrl(waitlistCheckoutResult.data.getId());
    params.history.replace(convertUrlToRoute(route));
  },
  next: () => {
    // not available
  },
  cancel: () => {
    // not available
  },
};
