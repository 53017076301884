/* eslint-disable no-console */
import { CustomerModel } from '@water-web/repository';

import { Tracker, CommonPayload } from '../types';
import { AnalyticsEvent } from '../events';
import { GtmBookAppointmentEventPayload } from './types';

export class ConsoleTracker implements Tracker {
  gclidMixin: Pick<GtmBookAppointmentEventPayload, 'gclid'> = { gclid: '' };

  constructor() {
    if (typeof window !== 'undefined') {
      const gclid = new URLSearchParams(window.location.search).get('gclid');
      if (gclid) {
        this.gclidMixin = { gclid };
      }
    }
  }

  getName(): string {
    return 'ConsoleTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    console.log(`ConsoleTracker: track '${analyticsEvent}'`, payload);
  }

  trackCustomerInfo(customer: CustomerModel): void {
    console.log('ConsoleTracker: customer info', customer.toJson());
  }

  page(location: string, payload: CommonPayload): void {
    console.log(`ConsoleTracker: page '${location}'`, payload);
  }

  identify(customerId: string): void {
    console.log('ConsoleTracker: identified', customerId);
  }
}
