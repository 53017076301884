import { useState } from 'react';

import { SelectedCookieSections } from '../../../../types';

export interface UseExpandedCookieSectionsResult {
  expandedSections: SelectedCookieSections;
  toggleSectionExpandedHandlers: (sectionKey: string) => () => void;
}

export const useExpandedCookieSections = (): UseExpandedCookieSectionsResult => {
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSectionExpandedHandlers = (sectionKey: string) => () => {
    const newExpandedSections = {
      ...expandedSections,
      [sectionKey]: !expandedSections[sectionKey],
    };
    setExpandedSections(newExpandedSections);
  };

  return {
    expandedSections,
    toggleSectionExpandedHandlers,
  };
};
