import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from 'react-query';
import { Nullable } from '@water-web/types';

import { HttpError } from '../transport';
import { EndpointMutation } from '../request';

interface CombinedContext {
  endpointContext: unknown;
  optionsContext?: unknown;
}

interface ApiMutationConfig<
  ConcreteEndpointMutation extends EndpointMutation<
    Awaited<ReturnType<ConcreteEndpointMutation['request']>>,
    Parameters<ConcreteEndpointMutation['request']>[0]
  >,
> {
  endpoint: ConcreteEndpointMutation;
  options?: UseMutationOptions<
    Awaited<ReturnType<ConcreteEndpointMutation['request']>>,
    HttpError,
    Parameters<ConcreteEndpointMutation['request']>[0]
  >;
}

export const useApiMutation = <
  ConcreteEndpointMutation extends EndpointMutation<
    Awaited<ReturnType<ConcreteEndpointMutation['request']>>,
    Parameters<ConcreteEndpointMutation['request']>[0]
  >,
>(
  mutationConfig: ApiMutationConfig<ConcreteEndpointMutation>,
): UseMutationResult<
  Awaited<ReturnType<ConcreteEndpointMutation['request']>>,
  HttpError,
  Parameters<ConcreteEndpointMutation['request']>[0]
> => {
  const endpointOptions = mutationConfig.endpoint.getMeta();
  const mutationOptions: (typeof mutationConfig)['options'] = mutationConfig.options || {};
  const combinedMutationOptions: (typeof mutationConfig)['options'] = {
    ...endpointOptions, // order matters because we let hook options override endpoint options
    ...mutationOptions,
    mutationKey: mutationConfig.endpoint.getKey(),
  };

  const queryClient = useQueryClient();

  combinedMutationOptions.onMutate = async (
    payload: Parameters<ConcreteEndpointMutation['request']>[0],
  ): Promise<CombinedContext> => {
    return {
      endpointContext: await Promise.resolve().then(() =>
        mutationConfig.endpoint.afterMutationStart(queryClient, payload),
      ),
      optionsContext: await Promise.resolve().then(() => mutationConfig.options?.onMutate?.(payload)),
    };
  };

  combinedMutationOptions.onSuccess = async (
    data: Awaited<ReturnType<ConcreteEndpointMutation['request']>>,
    payload: Parameters<ConcreteEndpointMutation['request']>[0],
    context: unknown,
  ) => {
    await Promise.resolve().then(() =>
      mutationConfig.endpoint.afterMutationSuccess(
        queryClient,
        data,
        payload,
        (context as CombinedContext).endpointContext,
      ),
    );

    return mutationConfig.options?.onSuccess?.(data, payload, (context as CombinedContext).optionsContext);
  };

  combinedMutationOptions.onError = async (
    error: HttpError,
    payload: Parameters<ConcreteEndpointMutation['request']>[0],
    context: unknown,
  ) => {
    await Promise.resolve().then(() =>
      mutationConfig.endpoint.afterMutationError(
        queryClient,
        error,
        payload,
        (context as CombinedContext).endpointContext,
      ),
    );

    return mutationConfig.options?.onError?.(error, payload, (context as CombinedContext).optionsContext);
  };

  combinedMutationOptions.onSettled = async (
    data: Awaited<ReturnType<ConcreteEndpointMutation['request']>> | undefined,
    error: Nullable<HttpError>,
    payload: Parameters<ConcreteEndpointMutation['request']>[0],
    context: unknown,
  ) => {
    await Promise.resolve().then(() =>
      mutationConfig.endpoint.afterMutationSettled(
        queryClient,
        data,
        error,
        payload,
        (context as CombinedContext).endpointContext,
      ),
    );

    return mutationConfig.options?.onSettled?.(data, error, payload, (context as CombinedContext).optionsContext);
  };

  return useMutation((...args) => mutationConfig.endpoint.request(...args), combinedMutationOptions);
};
