import { MinMax } from './common';
import { Base } from './base';
import { Shop } from './shop';
import { Barber } from './barber';
import { Product } from './product';
import { Customer, CustomerPayload } from './customer';
import { Appointment } from './appointment';
import { CurrencyCode } from './currency';

export type AcquisitionChannel = 'SQUIRE' | 'Google' | 'Google Ads' | 'Meta Ads';

export interface ValidatedCartAvailableTip {
  percentage: number;
  amount: number;
}

export interface ValidatedCartCardInfo {
  brand: string;
  expiration: string;
  funding: string;
  last4: string;
}

export interface ValidatedCartCardPayment {
  type: 'card';
  amount: number;
  cardInfo: ValidatedCartCardInfo;
}

export interface ValidatedCartGiftCardPayment {
  type: 'gift_card';
  /**
   * positive number
   */
  amount: number;
  giftCard: {
    code: string;
    initialBalance: number;
    outstandingBalance: number;
  };
}

export type ValidatedCartPayment = ValidatedCartCardPayment | ValidatedCartGiftCardPayment;

export const isValidatedCartCardPayment = (payment: ValidatedCartPayment): payment is ValidatedCartCardPayment => {
  return payment.type === 'card';
};

export const isValidatedCartGiftCardPayment = (
  payment: ValidatedCartPayment,
): payment is ValidatedCartGiftCardPayment => {
  return payment.type === 'gift_card';
};

// TODO: describe payment shape once we get to the appointment info point
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ValidatedCartRefund {}

export interface ValidatedCartTip {
  id: string;
  barberId: string;
  amount: number;
  tipPercentage?: number | null;
}

export interface ServiceAdjustment {
  addToPrice: boolean;
  amount: number;
  exclude: boolean;
  isFee: boolean;
  name: string;
  rate: number;
  referenceId: string;
  type: string;
}

export interface ValidatedCartSubItem extends Base {
  adjustments?: ServiceAdjustment[];
  barberId?: string;
  barberPasscode?: string;
  bookedFromWaitingList: boolean;
  bookedWithAnyBarber?: boolean;
  categoryName: string;
  cost: number;
  customerId?: string;
  description: string;
  discountAmount: number;
  duration: number;
  name: string;
  order: number;
  priceWithTaxes: number;
  priceWithoutTaxes: number;
  promoAmount: number;
  quantity: number;
  referenceId: string;
  requiresPrepaid: boolean;
  taxAmount: number;
  taxBeforeAdjustments: number;
  tipAmount?: number;
  tipPercentage?: number;
  type: 'service';
  parentId?: string; // if type is 'service' and parentId is set then this is an addon
  barber?: Barber;
  services?: { id: string }[];
  /**
   * ISO 8601 date-time: `2023-10-01T17:00:00+02:00`
   */
  dateTime?: string | null;
  customer?: Customer;
  totalRange: MinMax;
  totalWithoutTaxesRange: MinMax;
  rangeDisplayPrice: MinMax;
}

export const isSubItemAService = (subItem: ValidatedCartSubItem): boolean => {
  return subItem.type === 'service';
};

export interface ValidatedCartItem extends Base {
  adjustments?: ServiceAdjustment[];
  barberId?: string;
  barberPasscode?: string;
  bookedFromWaitingList: boolean;
  bookedWithAnyBarber?: boolean;
  cost: number;
  customerId?: string;
  discountAmount: number;
  name: string;
  priceWithTaxes: number;
  priceWithoutTaxes: number;
  promoAmount: number;
  quantity: number;
  referenceId: string;
  taxAmount: number;
  taxBeforeAdjustments: number;
  tipAmount?: number;
  tipPercentage?: number;
  type: 'new_appointment' | 'appointment' | 'giftCard' | 'product';
  barber?: Barber;
  services?: { id: string }[];
  subItems?: ValidatedCartSubItem[];
  /**
   * ISO 8601 date-time: `2023-10-01T17:00:00+02:00`
   */
  dateTime?: string | null;
  // only for type: 'product'
  product?: Product;
  customer?: Customer;
  // only for type: 'appointment'
  appointment?: Appointment;
  // only in Cart, only for appointments
  defaultTip?: ValidatedCartAvailableTip;
  // only in Cart, only for appointments
  availableTips?: ValidatedCartAvailableTip[];
  totalRange: MinMax;
  totalWithoutTaxesRange: MinMax;
}

export const isCartItemAGiftCard = (item: ValidatedCartItem): boolean => {
  return item.type === 'giftCard';
};

export const isCartItemAProduct = (item: ValidatedCartItem): boolean => {
  return item.type === 'product';
};

export const isCartItemAnAppointment = (item: ValidatedCartItem): boolean => {
  return item.type === 'new_appointment' || item.type === 'appointment';
};

export const isCartItemANewAppointment = (item: ValidatedCartItem): boolean => {
  return item.type === 'new_appointment';
};

export interface ValidatedCartBreakdown {
  taxes: [];
  /**
   * Total that's need to be added to the cart's payments array as a card payment.
   * Different from getTotalLeftForPayment because of the way API charges cards.
   * Doesn't include tip and fees because they are charged separately on the API side.
   */
  amountLeftForPayment: number;
  customerBookingFee: number;
  waitingListFee: number;
  totalFee: number;
  discountAmount: number;
  promoAmount: number;
  /**
   * @description
   * A full appointment's total before discounting. Doesn't change with time.
   */
  totalChargeAmount: number;
  /**
   * Total that we show to customers in the cart.
   * Includes all tips and fees, as well as considers discounts.
   */
  outstandingChargeAmount: number;
  /**
   * Means can Pay In Person, no card required
   */
  canBookNoPay: boolean;
  /**
   * Means can Pay In Person, but with card provided
   */
  canReserve: boolean;
  /**
   * Total as a range. It doesn't include tips, fees and discount.
   */
  totalRange: MinMax;
  totalWithoutTaxesRange: MinMax;
  breakdownPrice: number;
  rangeDisplayPrice: MinMax;
}

export interface ValidateCartNewAppointment {
  customer?: CustomerPayload;
  customerId?: string;
  dateTime: string | null;
  barberId: string | null;
  barberPasscode: string | null;
  bookedWithAnyBarber: boolean;
  tipPercentage?: number;
  services: {
    id: string;
  }[];
  channel?: {
    name: AcquisitionChannel;
    shopId: string;
  };
}

export interface ValidatedCartAdjustmentMembership {
  /**
   * negative number
   */
  amount: number;
  referenceId: string;
  exclude: boolean;
  type: 'membership_discount';
  isFee: boolean;
  assignment: 'services';
  name: string;
  itemId: string;
  subItemId: string | null;
  membershipSubscriptionId: string;
  membershipId: string;
  quantity: number;
  itemName: string;
  totalQuantity: number;
  remainingQuantity: number;
}

export interface ValidatedCartAdjustmentBookingFee {
  /**
   * positive number
   */
  amount: number;
  exclude: boolean;
  isFee: boolean;
  type: 'customer_booking_fee';
}

export interface ValidatedCartAdjustmentPromo {
  /**
   * negative number
   */
  amount: number;
  exclude: boolean;
  type: 'promo';
  isFee: boolean;
  _isSquirePromo: boolean;
  redeemed: boolean;
  name: string;
}

type ValidatedCartAdjustment =
  | ValidatedCartAdjustmentMembership
  | ValidatedCartAdjustmentBookingFee
  | ValidatedCartAdjustmentPromo;

export const isAdjustmentMembership = (
  adjustment: ValidatedCartAdjustment,
): adjustment is ValidatedCartAdjustmentMembership => adjustment.type === 'membership_discount';

export const isAdjustmentBookingFee = (
  adjustment: ValidatedCartAdjustment,
): adjustment is ValidatedCartAdjustmentBookingFee => adjustment.type === 'customer_booking_fee';

export const isAdjustmentPromo = (adjustment: ValidatedCartAdjustment): adjustment is ValidatedCartAdjustmentPromo =>
  adjustment.type === 'promo';

interface PromoRuleExpiration {
  kind: 2;
  /**
   * ISO 8601 date-time: `2022-12-31T00:00:00.000Z`
   */
  endDate: string;
  /**
   * ISO 8601 date-time: `2022-12-26T19:32:35.993Z`
   */
  startDate: string;
}

type PromoRule = PromoRuleExpiration;

export const isPromoRuleExpiration = (rule: PromoRule): rule is PromoRuleExpiration => {
  return rule.kind === 2;
};

interface Promo {
  id: string;
  isBrandPromo: boolean;
  code: string;
  /**
   * 0 - FIXED.
   * 1 - PERCENT.
   * 2 - REFERRAL.
   */
  kind: 0 | 1 | 2;
  /**
   * is a percentage if `kind = 1` (percent)
   */
  value: number;
  rules: PromoRule[];
  isRewardPromo: boolean;
  isSquirePromo: boolean;
}

export interface ValidatedCart extends Base {
  isBookNoPay?: boolean;
  isReservation?: boolean;
  bookingInfo?: {
    canceled: boolean;
    canCancelBefore: string | null;
  };
  adjustments?: ValidatedCartAdjustment[];
  availableTips: Record<string, ValidatedCartAvailableTip[]>;
  barberId?: string;
  canCheckOut: boolean;
  currency: CurrencyCode;
  finishedOn?: string;
  items: ValidatedCartItem[];
  payments: ValidatedCartPayment[];
  refunds: ValidatedCartRefund[];
  shopId: string;
  state: 'new' | 'finished' | 'scheduled' | 'reserved';
  taxAmount: number;
  tips: ValidatedCartTip[];
  total: number;
  totalBeforeDiscount: number;
  totalForTip: number;
  breakdown: ValidatedCartBreakdown;
  promoCode: string;
  promo?: Promo;
  paymentOutstanding: number;
  // eslint-disable-next-line camelcase
  new_appointments: ValidateCartNewAppointment[];
  shop: Shop;
  payingCustomer?: Customer;
}
