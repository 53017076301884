import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useApiQuery, HttpError } from '@water-web/api';
import {
  WaitingList,
  WaitingListSlot,
  WaitingListStatus,
  WaitingListTimeSlot,
  BarberWithMeta,
  Shop,
} from '@water-web/types';
import { WaitingListModel, ShopModel, BarberModel } from '@water-web/repository';

import { PublicShopService, WaitingListService } from '@app/apiServices';
import { useBarber } from '@app/hooks/useBarber';
import { useFastRouteParams } from '@app/hooks/useFastRouteParams';

export const QUERY_PARAM_SLOT_ID = 'slotId';
export const QUERY_PARAM_RESCHEDULE_TIME = 'rescheduleTime';

export type WaitingListExtendedStatus = WaitingListStatus | 'active' | 'available' | 'selected' | 'rescheduleSelected';

export interface UseWaitingListPageDataResult {
  isLoading: boolean;
  waitingList: Readonly<WaitingList> | null;
  waitingListModel: WaitingListModel | null;
  waitingListTimeSlots: Readonly<WaitingListSlot> | null;
  confirmedTimeSlot: Readonly<WaitingListTimeSlot> | null;
  confirmedRescheduleTime: string | null;
  status: WaitingListExtendedStatus;
  shop: Readonly<Shop> | null;
  shopModel: ShopModel | null;
  barber: Readonly<BarberWithMeta> | null;
  barberModel: BarberModel | null;
  attemptId?: string;
  timeSlotsQueryError?: HttpError;
}

export const useWaitingListPageData = (): UseWaitingListPageDataResult => {
  const location = useLocation();
  const { waitingListApplicationId, attemptId } = useFastRouteParams<{
    waitingListApplicationId: string;
    attemptId?: string;
  }>();

  const slotId = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return decodeURIComponent(query.get(QUERY_PARAM_SLOT_ID) ?? '');
  }, [location.search]);
  const confirmedRescheduleTime = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return decodeURIComponent(query.get(QUERY_PARAM_RESCHEDULE_TIME) ?? '');
  }, [location.search]);

  const { data: waitingList, isLoading: isWaitingListLoading } = useApiQuery({
    endpoint: WaitingListService.getById,
    payload: { id: waitingListApplicationId },
    options: {
      enabled: !!waitingListApplicationId,
      select: (data) => data as WaitingList, // hack to get type annotated
      keepPreviousData: true,
    },
  });

  const waitingListModel = useMemo(() => {
    return waitingList ? new WaitingListModel(waitingList) : null;
  }, [waitingList]);

  const {
    data: waitingListTimeSlots,
    isLoading: isTimeSlotsRequestLoading,
    error: timeSlotsQueryError,
  } = useApiQuery({
    endpoint: WaitingListService.getTimeSlots,
    payload: {
      id: waitingListApplicationId,
      attemptId,
    },
    options: {
      enabled: !!(attemptId && waitingList) && !waitingList.status,
    },
  });

  const isTimeSlotsLoading = attemptId ? !waitingList || isTimeSlotsRequestLoading : false;

  useEffect(() => {
    if (waitingListModel && waitingListTimeSlots) {
      waitingListModel.setTimeSlots(waitingListTimeSlots.times);
    }
  }, [waitingListModel, waitingListTimeSlots]);

  const { data: shop, isLoading: isShopLoading } = useApiQuery({
    endpoint: PublicShopService.byId,
    payload: {
      idOrRoute: waitingList?.shopId,
    },
    options: {
      enabled: !!waitingList?.shopId,
      select: (data) => data as unknown as Shop,
    },
  });
  const shopModel = useMemo(() => {
    return !shop ? null : new ShopModel(shop);
  }, [shop]);

  const { data: barberModel, isLoading: isBarberLoading } = useBarber(waitingList?.shopId, waitingList?.barberId);

  const isLoading = isWaitingListLoading || isShopLoading || isBarberLoading || isTimeSlotsLoading;

  const confirmedTimeSlot = useMemo<WaitingListTimeSlot>(() => {
    if (isLoading || !slotId) {
      return null;
    }

    return waitingListTimeSlots?.times.find((slot) => {
      return slot.waitingListSlotId === slotId;
    });
  }, [isLoading, slotId, waitingListTimeSlots]);

  const status = useMemo<WaitingListExtendedStatus>(() => {
    if (isLoading || !waitingList) {
      return null;
    }

    if (waitingList?.status === 'expired' && confirmedRescheduleTime) {
      return 'rescheduleSelected';
    }

    if (waitingList?.status) {
      return waitingList?.status;
    }

    const haveSlots = waitingListTimeSlots?.times.length;

    if (waitingList && haveSlots && confirmedTimeSlot) {
      return 'selected';
    }

    if (waitingList && haveSlots) {
      return 'available';
    }

    return 'active';
  }, [isLoading, waitingList, waitingListTimeSlots, confirmedTimeSlot, confirmedRescheduleTime]);

  return {
    isLoading,
    waitingList: waitingList ?? null,
    waitingListModel,
    waitingListTimeSlots: waitingListTimeSlots ?? null,
    confirmedTimeSlot: confirmedTimeSlot ?? null,
    confirmedRescheduleTime,
    status,
    shop: shop ?? null,
    shopModel: shopModel ?? null,
    barber: barberModel?.getDataValues() ?? null,
    barberModel: barberModel ?? null,
    attemptId,
    timeSlotsQueryError,
  };
};
