import { ALLOWED_TRACKING_PARAMS } from '@water-web/types';

import { HttpConfig, HttpHeaders } from '../transport/types';

export const buildBotProtectionHeaders = (botProtectionToken?: string): Readonly<HttpHeaders> => {
  if (!botProtectionToken) {
    return {};
  }

  return {
    'cf-turnstile-response': botProtectionToken,
  };
};

export const SERVICE_ID_V2_HEADER_MIXIN: Readonly<HttpHeaders> = {
  'Squire-Version': '2022-09-02',
} as const;

export const buildTrackingHeaders = (trackingParams?: HttpConfig['trackingParams']): Readonly<HttpHeaders> => {
  if (!trackingParams || Object.keys(trackingParams).length === 0) {
    return {};
  }

  const headers: HttpHeaders = {};

  Object.keys(trackingParams)
    .filter((key) => ALLOWED_TRACKING_PARAMS.indexOf(key) !== -1)
    .filter((key) => !!trackingParams[key])
    .forEach((key) => {
      const formattedKey = key.replace(/_/g, '-').toLowerCase();
      headers[`x-${formattedKey}`] = trackingParams[key];
    });

  if (trackingParams.timezone) {
    headers['x-timezone'] = trackingParams.timezone;
  }

  return headers;
};
