import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import infoCircledIcon from './infoCircled.svg';

export const InfoCircled = styled(JustIcon).attrs({
  alt: 'infoCircled',
  icon: infoCircledIcon,
  width: '16px',
  height: '16px',
})`` as JustIconStyledComponent<IconStaticProps>;
