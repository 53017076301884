import { Translate } from '@water-web/view';

import { config } from '@app/config';
import { CartModeItemProps } from '@app/contexts/CartController/types';
import { Text } from '@app/components/atoms';

export const ScheduleDisclaimer = (props: CartModeItemProps) => {
  const reservation = props.cart.getActiveReservation();

  if (!props.expanded || !reservation?.getWaitingListDate()) {
    return null;
  }

  return (
    <Text.P4Regular
      color={config.mode.standalone ? '#565656' : 'tertiaryLabel'}
      textAlign="center"
      mt={4}
      letterSpacing={-0.078}
    >
      <Translate i18nKey="components.cart.appointments.waitListDisclaimer" />
    </Text.P4Regular>
  );
};
