import { getFirstCharacter } from './getFirstCharacter';

export const getShortName = (human: { firstName?: string; lastName?: string }): string => {
  const firstName = human.firstName || '';
  const lastName = human.lastName || '';

  if (!firstName) {
    return lastName;
  }

  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${getFirstCharacter(lastName)}.`;
};
