import { Service, HttpResponse, ServiceApiSearch, ServiceApi as IServiceApi } from '@water-web/types';

import { SERVICE_ID_V2_HEADER_MIXIN } from '../utils';
import { Namespace } from './namespace';

export class ServiceApi extends Namespace implements IServiceApi {
  getForShopByShopId(shopId: string, search?: ServiceApiSearch): Promise<HttpResponse<Service[]>> {
    return this.httpV2.get<Service[]>(`/shop/${shopId}/service`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:id/service',
    });
  }

  getForBarberByShopIdAndBarberId(
    shopId: string,
    barberId: string,
    search?: ServiceApiSearch,
  ): Promise<HttpResponse<Service[]>> {
    return this.httpV2.get<Service[]>(`/shop/${shopId}/barber/${barberId}/service`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:shopId/barber/:barberId/service',
    });
  }

  getForShopById(serviceId: string, shopId: string, search?: ServiceApiSearch): Promise<HttpResponse<Service>> {
    return this.httpV2.get<Service>(`/shop/${shopId}/service/${serviceId}`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:shopId/service/:serviceId',
    });
  }

  getForBarberById(
    serviceId: string,
    shopId: string,
    barberId: string,
    search?: ServiceApiSearch,
  ): Promise<HttpResponse<Service>> {
    return this.httpV2.get<Service>(`/shop/${shopId}/barber/${barberId}/service/${serviceId}`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:shopId/barber/:barberId/service/:serviceId',
    });
  }
}
