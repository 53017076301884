import { useContext } from 'react';
import { AppStateContext, RepositoryContext, Translate } from '@water-web/view';

import { Button, Text } from '@app/components/atoms';
import { useCheckoutFlow } from '@app/contexts/CheckoutFlow';
import { CartModeItemProps } from '@app/contexts/CartController/types';

export const ScheduleControls = (props: CartModeItemProps) => {
  const appState = useContext(AppStateContext);
  const { cartRepository } = useContext(RepositoryContext);

  const activeReservation = props.cart.getActiveReservation();
  const isTimeSelected = !!(activeReservation?.getDateTime() || activeReservation?.getWaitingListDate());

  const buttonTranslationKey = props.expanded
    ? 'components.cart.controls.continue'
    : 'components.cart.controls.timeChosen';

  const checkoutFlow = useCheckoutFlow();
  const proceed = () => {
    cartRepository.updateV2(props.cart, true).then(() => {
      checkoutFlow.next();
    });
  };

  const expand = () => {
    props.setCartState('expanded');
  };

  if (!isTimeSelected) {
    return null;
  }

  return (
    <Button
      $testId="btn:submit"
      height="48px"
      width="100%"
      onClick={props.expanded ? proceed : expand}
      disabled={!appState.cart.interactive || props.cart.isValidating()}
    >
      <Text.P2Bold>
        <Translate i18nKey={buttonTranslationKey} />
      </Text.P2Bold>
    </Button>
  );
};
