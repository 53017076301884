import { parseISO } from 'date-fns';
import { ScheduleItem } from '@water-web/types';

/**
 * @param schedules
 * @param day - ISO 8601 date: `2021-01-23`
 */
export const getIsDayEnabledFromSchedules = (
  schedules: Pick<ScheduleItem, 'workdays' | 'startDate' | 'endDate'>[] | undefined,
  day: string,
): boolean => {
  if (!schedules) {
    return false;
  }

  const jsWeekDayNumber = parseISO(day).getDay();

  const scheduleWeekDayNumberSunday = 6;
  const scheduleWeekDayNumber = jsWeekDayNumber - 1 < 0 ? scheduleWeekDayNumberSunday : jsWeekDayNumber - 1; // monday is 0

  return schedules.some(
    (schedule) =>
      schedule.workdays[scheduleWeekDayNumber]?.enabled &&
      (schedule.startDate ?? '') <= day &&
      (!schedule.endDate || day <= schedule.endDate),
  );
};
