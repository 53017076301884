import { QueryKey } from 'react-query';
import { NotificationsConsent } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  customerId: string;
  shopId: string;
}

type Response = NotificationsConsent;

export class NotificationsConsentByIdQuery extends EndpointQuery<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.byCustomerId(payload);
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    return this.http.get(`/v1/customer/${payload.customerId}/shop/${payload.shopId}/notifications-consent`, {
      signal,
      resourceUrl: '/customer/:customerId/shop/:shopId/notifications-consent',
    });
  }
}
