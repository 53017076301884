import { useContext } from 'react';

import { AppSettings, AppSettingsContext } from './AppSettingsContext';

export const useAppSettingsContext = (): AppSettings => {
  const config = useContext(AppSettingsContext);

  if (config === null) {
    throw new Error(
      'AppSettings config is not initialized. Make sure that useAppSettingsProvider is placed up the components tree.',
    );
  }

  return config;
};
