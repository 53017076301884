import {
  CustomerApi as ICustomerApi,
  HttpResponse,
  NotificationsConsent,
  CustomerMatchSearch,
  NotificationsConsentUpdate,
} from '@water-web/types';

import { Namespace } from './namespace';

export class CustomerApi extends Namespace implements ICustomerApi {
  getNotificationsConsentByMatch(
    shopId: string,
    search: CustomerMatchSearch,
  ): Promise<HttpResponse<NotificationsConsent>> {
    return this.http.post(`/shop/${shopId}/notifications-consent`, search, {
      resourceUrl: '/shop/:id/notifications-consent',
    });
  }

  updateNotificationsConsent(
    id: string,
    shopId: string,
    data: NotificationsConsentUpdate,
  ): Promise<HttpResponse<NotificationsConsent>> {
    return this.http.put(`/customer/${id}/shop/${shopId}/notifications-consent`, data, {
      resourceUrl: '/customer/:customerId/shop/:shopId/notifications-consent',
    });
  }
}
