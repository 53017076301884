export class Quantity<T, K = any> {
  private id: string = null;
  private quantity = 0;
  private model: T = null;
  private meta: K = null;

  constructor(initialQuantity: number, model: T, meta?: K) {
    this.model = model;
    this.meta = meta;
    this.quantity = initialQuantity;
    this.id = (Date.now() * Math.random()).toFixed(0);
  }

  increment(): Quantity<T, K> {
    this.quantity += 1;
    return this;
  }

  decrement(): Quantity<T, K> {
    this.quantity = this.quantity > 0 ? this.quantity - 1 : this.quantity;
    return this;
  }

  getId(): string {
    return this.id;
  }

  getModel(): T {
    return this.model;
  }

  getQuantity(): number {
    return this.quantity;
  }
}
