import { useMemo, useContext, ContextType } from 'react';
import { ValidatedCart } from '@water-web/types';
import {
  Price,
  PRICE_ZERO,
  reducePrices,
  numberToPrice,
  getServicesPriceDraftFromCart,
  getServicesPriceExactFromCart,
  getTipsPriceFromCart,
  getGiftCardPriceFromCart,
  getMembershipPriceFromCart,
  getTotalExactFromCart,
} from '@water-web/repository';

import { DeeplinkPromoContext } from '../contexts';

const getDeeplinkPromoPrice = (promo: ContextType<typeof DeeplinkPromoContext>['promo']): Price => {
  const moneyPrice = numberToPrice(promo.value);

  if (promo.kind === 1) {
    return { ...moneyPrice, isPercent: true };
  }

  return moneyPrice;
};

type Mode = 'draft' | 'final';

export type UseCartBreakdownKey =
  | 'services'
  | 'tips'
  | 'taxes'
  | 'fee'
  | 'taxesAndFees'
  | 'promo'
  | 'giftCard'
  | 'membership'
  | 'total';

export const useCartBreakdown = (cart: ValidatedCart, mode: Mode = 'final'): Map<UseCartBreakdownKey, Price | null> => {
  const { promo } = useContext(DeeplinkPromoContext);

  return useMemo(() => {
    const isDraftMode = mode === 'draft';

    const taxesPrice = numberToPrice(cart.taxAmount ?? 0);

    const feePrice = numberToPrice(cart.breakdown?.totalFee ?? 0);

    const draftPromoPrice = !promo ? null : getDeeplinkPromoPrice(promo);

    return new Map<UseCartBreakdownKey, Price>([
      ['services', isDraftMode ? getServicesPriceDraftFromCart(cart) : getServicesPriceExactFromCart(cart)],
      ['tips', getTipsPriceFromCart(cart)],
      ['taxes', isDraftMode ? null : taxesPrice],
      ['fee', isDraftMode ? null : feePrice],
      ['taxesAndFees', isDraftMode ? null : reducePrices([taxesPrice, feePrice])],
      ['promo', isDraftMode ? draftPromoPrice : numberToPrice(cart.breakdown?.promoAmount ?? 0)],
      ['giftCard', getGiftCardPriceFromCart(cart)],
      ['membership', isDraftMode ? null : getMembershipPriceFromCart(cart)],
      ['total', isDraftMode ? PRICE_ZERO : getTotalExactFromCart(cart)],
    ]);
  }, [cart, mode, promo]);
};
