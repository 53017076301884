import { Http, HttpHeaders } from '../transport';
import { AuthHeaders, GetToken } from './types';

export abstract class Endpoint<ClientModel, RequestParams = void> {
  protected _baseUrl = '';
  protected http: Http;

  protected get baseUrl(): string {
    // eslint-disable-next-line no-underscore-dangle
    return this._baseUrl;
  }

  protected set baseUrl(baseUrl: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._baseUrl = baseUrl;
    this.http = new Http({ baseUrl });
  }

  // requires the `getToken` to be implemented
  protected authenticated = false;
  protected getToken: GetToken;

  protected getAuthHeaders(): AuthHeaders {
    if (!this.getToken) {
      throw new Error('`getToken` not implemented');
    }

    const token = this.getToken();

    if (!token) {
      return {} as AuthHeaders;
    }

    return {
      Authorization: `JWT ${token}`,
    };
  }

  protected buildHeaders(headers?: HttpHeaders): HttpHeaders {
    let preparedHeaders: HttpHeaders = headers ? { ...headers } : {};

    if (this.authenticated) {
      const authHeaders = this.getAuthHeaders();
      preparedHeaders = {
        ...authHeaders,
        ...preparedHeaders,
      };
    }

    return preparedHeaders;
  }

  abstract request(payload?: RequestParams, signal?: AbortSignal): Promise<ClientModel>;
}
