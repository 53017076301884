import { ILogger } from './types';

export class Logger {
  private static loggers: ILogger[] = [console];

  static addLogger(logger: ILogger): void {
    Logger.loggers.push(logger);
  }

  private static runAllLoggers(method: keyof ILogger, message: string, context?: object): void {
    try {
      Logger.loggers.forEach((logger) => {
        logger[method](message, context);
      });
    } catch (error) {
      console.error('There was an error in the LoggerManager service', { method, error });
    }
  }

  static log(message: string, context?: object): void {
    Logger.runAllLoggers('log', message, context);
  }

  static info(message: string, context?: object): void {
    Logger.runAllLoggers('info', message, context);
  }

  static warn(message: string, context?: object): void {
    Logger.runAllLoggers('warn', message, context);
  }

  static error(message: string, context?: object): void {
    Logger.runAllLoggers('error', message, context);
  }
}
