import { Box, Flex, Translate } from '@water-web/view';
import { AnimatePresence } from 'framer-motion';

import { Text } from '@app/components/atoms';

import { IconContainer, SuccessCircle } from './styles';

export const SuccessMessage = () => {
  return (
    <Flex flexDirection="column" alignItems="center">
      <IconContainer>
        <AnimatePresence initial>
          <SuccessCircle />
        </AnimatePresence>
      </IconContainer>

      <Box height="24px" mt={6}>
        <Text.Title1 $testId="text:successMessage">
          <Translate i18nKey="pages.reservation.processingSuccess.0" />
        </Text.Title1>
      </Box>
    </Flex>
  );
};
