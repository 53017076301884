import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../../../../atoms/Icon/Icon';
import processingErrorIcon from './processingError.svg';

export const ProcessingError = styled(JustIcon).attrs({
  alt: 'processingError',
  icon: processingErrorIcon,
  width: '72px',
  height: '72px',
})`` as JustIconStyledComponent<IconStaticProps>;
