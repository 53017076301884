import { ValidatedCart } from '@water-web/types';
import { CartModel, ReservationModel, ValidatedCartModel } from '@water-web/repository';

export const setGcAmount = (amount: number, cart: CartModel, reservation: ReservationModel): void => {
  if (!cart || !reservation) {
    return;
  }

  reservation.setGiftCardSaleAmount(reservation.getGiftCardSaleAmount() === amount ? 0 : amount);

  const fakeValidatedCart = cart.getValidatedCartMock();
  const fakeValidatedCartModel = new ValidatedCartModel({
    ...fakeValidatedCart,
    breakdown: {
      ...fakeValidatedCart.breakdown,
      totalChargeAmount: amount,
      amountLeftForPayment: amount,
      outstandingChargeAmount: amount,
      rangeDisplayPrice: { min: amount, max: amount },
    },
    items: [],
    new_appointments: [],
  } as ValidatedCart);

  // fake validated cart so we have total available
  cart.setValidatedCart(fakeValidatedCartModel);
};
