import { QueryKey } from 'react-query';
import { User } from '@water-web/types';

import { EndpointQuery, GetToken } from '../../request';
import { queryKeys } from './queryKeys';

type Response = User | null;

export class UserCurrentQuery extends EndpointQuery<Response, void> {
  meta: EndpointQuery<Response, void>['meta'] = {
    staleTime: Infinity,
    cacheTime: Infinity,
  };

  constructor(config: { baseUrl: string; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  getKey(): QueryKey {
    return queryKeys.current();
  }

  async request(_, signal?: AbortSignal): Promise<Response> {
    if (!this.getToken()) {
      return null;
    }

    return this.http.get('/v1/user/session?include=customer', {
      signal,
      headers: this.buildHeaders(),
      resourceUrl: '/user/session',
    });
  }
}
