import { MutationKey, QueryClient } from 'react-query';
import { User } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';
import { buildBotProtectionHeaders } from '../../utils';

type Payload = {
  customerId: string;
  phoneVerificationToken: string;
  deviceId: string;
  botProtectionToken?: string;
};

type Response = {
  user: User;
  token: string;
};

export class UserLoginOtpSubmitOnPhoneMutation extends EndpointMutation<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  getKey(): MutationKey {
    return queryKeys.loginOtpSubmit();
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    const { botProtectionToken, ...rest } = payload;
    return this.http.post('/v1/code-login', rest, {
      signal,
      headers: this.buildHeaders(buildBotProtectionHeaders(botProtectionToken)),
      resourceUrl: '/code-login',
    });
  }

  afterMutationSuccess(queryClient: QueryClient, data: Response): void {
    queryClient.setQueryData(queryKeys.current(), data.user);
  }
}
