import { QueryKey } from 'react-query';
import { Notification } from '@water-web/types';

import { EndpointQuery, GetToken } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  customerId: string;
}

type Response = Notification[];

export class NotificationsByCustomerIdQuery extends EndpointQuery<Response, Payload> {
  constructor(config: { baseUrl: string; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.byCustomerId(payload);
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    return this.http.get(`/v1/customer/${payload.customerId}/notifications`, {
      signal,
      headers: this.buildHeaders(),
      search: {
        notificationType: 'appointmentReview',
        resourceType: 'appointment',
      },
      resourceUrl: '/customer/:id/notifications',
    });
  }
}
