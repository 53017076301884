import { initial } from './initial';
import { policies } from './policies';
import { userInfo } from './userInfo';
import { marketingConsents } from './marketingConsents';
import { paymentInfo } from './paymentInfo';
import { processingBooking } from './processingBooking';
import { processingWaitingList } from './processingWaitingList';
import { processingGiftCard } from './processingGiftCard';
import { waitingListExistenceError } from './waitingListExistenceError';
import { error } from './error';
import { success } from './success';
import { CheckoutPhase, PhaseConfig } from '../types';

const list = [
  initial,
  policies,
  userInfo,
  marketingConsents,
  paymentInfo,
  processingBooking,
  processingWaitingList,
  processingGiftCard,
  waitingListExistenceError,
  error,
  success,
];

export const phases: Record<CheckoutPhase, PhaseConfig> = list.reduce<Record<string, PhaseConfig>>((memo, item) => {
  return {
    ...memo,
    [item.name]: item,
  };
}, {});
