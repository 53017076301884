import { StyledProps } from 'styled-components';

/**
 * Will return a styling function for a given props 'key'.
 * The returned styling functions will take `StyledProps`, and return '<value>px' string.
 *
 * If value of 'props[key]' is 'number', it will be treated as 'styled-system' space.
 * If value of 'props[key]' is 'string', it should be of '<length-percentage>' CSS data type: '10px' for example.
 */
export const propToPx =
  <O>(key: keyof O) =>
  (props: StyledProps<Partial<O>>): string => {
    if (!key || !props[key]) {
      return '';
    }

    const value = props[key];

    if (typeof value === 'string') {
      // is a `<length-percentage>` CSS data type
      return value;
    }

    return `${props.theme.space[value as unknown as number] || value}px`;
  };
