import { BodyText } from '../styles';
import { CookiesSubsectionDef } from '../types';
import { Flex, Box, Translate } from '../../../atoms';
import { CookieInfoText, LabelText, SubsectionCardContainer, SubsectionIcon } from './styles';

interface SubsectionCardProps {
  subsection: CookiesSubsectionDef;
  onClick: () => void;
}

export const SubsectionCard = (props: SubsectionCardProps) => {
  return (
    <SubsectionCardContainer onClick={props.onClick}>
      <Flex>
        <SubsectionIcon width={8} height={8} mr={2}>
          <props.subsection.Icon />
        </SubsectionIcon>
        <Box>
          <BodyText fontWeight="600">{props.subsection.name}</BodyText>
          <LabelText mt={1}>
            <Translate i18nKey="components.cookieConsent.trackersCount" count={props.subsection.cookies.length} />
          </LabelText>
        </Box>
      </Flex>
      <CookieInfoText mt={2}>{props.subsection.description}</CookieInfoText>
    </SubsectionCardContainer>
  );
};
