import { Translate } from '../../atoms';

interface HrMinProps {
  children: number;
  short?: boolean;
}

export const HrMinDuration = (props: HrMinProps) => {
  const hour = 60;
  const [hours, minutes] = [Math.floor(props.children / hour), props.children % hour];

  if (minutes > 0 && hours > 0) {
    const key = props.short
      ? 'pages.barber.durationWithHoursAndMinutesShort'
      : 'pages.barber.durationWithHoursAndMinutes';
    return <Translate i18nKey={key} hours={hours} minutes={minutes} />;
  }

  if (hours === 1) {
    return <Translate i18nKey="pages.barber.durationHoursWithCount" count={hours} />;
  }

  if (hours > 0) {
    return <Translate i18nKey="pages.barber.durationHoursWithCount_plural" count={hours} />;
  }

  if (minutes === 1) {
    return <Translate i18nKey="pages.barber.durationMinutesWithCount" count={minutes} />;
  }

  if (minutes > 0) {
    return <Translate i18nKey="pages.barber.durationMinutesWithCount_plural" count={minutes} />;
  }

  return null;
};
