import { QueryKey } from 'react-query';
import { Brand } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

interface Payload {
  idOrRoute: string;
}

export interface BrandHome {
  brand: Brand;
  message: string;
}

const staticSearch = {
  include: ['shops', 'shops.staticMap', 'shops.address', 'shops.photos', 'shops.instagramImages'],
  nonSquireEcosystem: 'true',
};

export class BrandHomeQuery extends EndpointQuery<BrandHome, Payload> {
  constructor(baseUrl: string) {
    super();

    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.byId(payload);
  }

  request(payload: Payload, signal?: AbortSignal): Promise<BrandHome> {
    return this.http.get(`/v1/home/${payload.idOrRoute}`, {
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      search: staticSearch,
      signal,
      resourceUrl: '/home/:id',
    });
  }
}
