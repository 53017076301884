import { BrandLocation, IgImage, ShopAddress } from '@water-web/types';

import { getCoverPhotoUrlFromShop, getAliasFromShop } from '../getters';
import { BaseModel } from './base';

export interface LocationTypeAndCoordinates {
  type: string;
  coordinates?: { lng: number; lat: number };
}

export class LocationModel extends BaseModel<LocationModel> {
  protected dataValues: Readonly<BrandLocation>;
  protected locationTypeAndCoordinates: LocationTypeAndCoordinates;

  constructor(location: BrandLocation) {
    super();

    this.dataValues = {
      ...location,
    };

    this.locationTypeAndCoordinates = {
      ...location.location,
      coordinates: location.location.coordinates
        ? {
            lng: location.location.coordinates[1],
            lat: location.location.coordinates[0],
          }
        : null,
    };
  }

  getAddress(): ShopAddress {
    return this.dataValues.address as ShopAddress;
  }

  // TODO: drop default value after API fix
  get instagramImages(): IgImage[] {
    return (this.dataValues.instagramImages || []) as IgImage[];
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  getBrandId(): string {
    return this.dataValues.brandId;
  }

  getRoute(): string {
    return this.dataValues.route as string;
  }

  getName(): string {
    return this.dataValues.name as string;
  }

  getAlias(): string {
    return getAliasFromShop(this.dataValues);
  }

  getCoverPhotoUrl(): string | undefined {
    return getCoverPhotoUrlFromShop(this.dataValues);
  }

  getLocation(): LocationTypeAndCoordinates {
    return this.locationTypeAndCoordinates;
  }

  /* Returns null in case we should fall back to brand languages */
  getSupportedLanguages(): string[] | null {
    return this.dataValues.settings?.supportedLanguages;
  }

  getPreferredLanguage(): string {
    return this.dataValues.settings?.defaultLanguage || 'en';
  }

  getDistance(): null | number {
    return (this.dataValues.distance as number) || null;
  }

  getTimezone(): string {
    return this.dataValues.timezone as string;
  }

  getDataValues() {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
