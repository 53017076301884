import { HiddenInput, Toggle } from './styles';

export interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  dark?: boolean;
  onChange: (newChecked: boolean) => void;
}

export const Switch = (props: SwitchProps) => {
  const handleChange = () => {
    props.onChange(!props.checked);
  };

  return (
    <Toggle checked={props.checked} disabled={props.disabled} dark={props.dark}>
      <HiddenInput type="checkbox" checked={props.checked} disabled={props.disabled} onChange={handleChange} />
    </Toggle>
  );
};
