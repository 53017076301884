import { useContext, useMemo } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  StripeProcessor,
  PaymentProcessor,
  PaymentProcessorEnv,
  PaymentProcessorCountry,
  PaymentProcessorCurrency,
} from '@water-web/payments';
import { getCountryCodeByName, CartContext } from '@water-web/view';

import { config } from '@app/config';

declare global {
  interface Window {
    google: any;
  }
}

export const usePaymentProcessor = (): PaymentProcessor | null => {
  const { cart } = useContext(CartContext);
  const stripe = useStripe();

  const shop = cart.getActiveReservation()?.getShop();

  return useMemo(() => {
    if (!stripe || !shop) {
      return null;
    }

    return new StripeProcessor({
      env: config.deploymentEnv as PaymentProcessorEnv,
      country: getCountryCodeByName(shop.getAddress()?.country).toUpperCase() as PaymentProcessorCountry,
      currency: shop.getCurrency().toLowerCase() as PaymentProcessorCurrency,
      stripe,
    });
  }, [stripe, shop]);
};
