import { KeyValueStore, KeyValueStoreStrategy } from './types';
import { InMemoryStorage } from './strategies';

export class KVStore implements KeyValueStore {
  private store: KeyValueStore;

  constructor(stores: KeyValueStoreStrategy[]) {
    let availableStore = stores.find((store) => store.isAvailable());

    if (!availableStore) {
      const failedStores = stores.map((store) => store.getName()).join(', ');
      console.warn(`KVStore: no available storage options(tried ${failedStores}). Using in-memory storage.`);
      availableStore = new InMemoryStorage();
    }

    this.store = availableStore;
  }

  setItem(key: string, value: string): void {
    this.store.setItem(key, value);
  }

  getItem(key: string): string | null {
    return this.store.getItem(key);
  }

  removeItem(key: string): void {
    this.store.removeItem(key);
  }

  clear(): void {
    this.store.clear();
  }

  hasItem(key: string): boolean {
    return this.store.hasItem(key);
  }
}
