import styled, { css } from 'styled-components';

import { Input } from '../../../atoms';
import { consentConfig } from '../config';

interface ToggleProps {
  checked: boolean;
  disabled: boolean;
  dark: boolean;
}

export const Toggle = styled.label<ToggleProps>`
  cursor: pointer;
  text-indent: -9999px;
  width: 38px;
  height: 20px;
  background: ${(props) => (props.checked ? 'rgb(0, 107, 178)' : 'rgb(242, 242, 247)')};
  display: block;
  border-radius: 20px;
  position: relative;

  ${(props) =>
    props.dark &&
    css`
      background: ${props.checked ? 'rgb(0, 107, 178)' : consentConfig.borderColor};
    `}

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    transition: ${consentConfig.borderColor}s;

    ${(props) =>
      props.checked &&
      css`
        left: calc(100% - 2px);
        transform: translateX(-100%);
      `}

    ${(props) =>
      props.dark &&
      css`
        background: ${props.checked ? consentConfig.invertedBgColor : 'rgb(142, 142, 147)'};
      `}
  }

  &:active:after {
    width: 18px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 50%;
      cursor: default;
      pointer-events: none;
    `};

  @media (max-width: ${consentConfig.media.mobile}) {
    transform: scale(0.8);
  }
`;

export const HiddenInput = styled(Input)`
  height: 0;
  width: 0;
  margin: 0;
  visibility: hidden;
`;
