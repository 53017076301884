import { IconProps } from 'react-toastify';

import { ToastError } from './icons/ToastError';
import { ToastSuccess } from './icons/ToastSuccess';
import { ToastInfo } from './icons/ToastInfo';

export const ToastIcon = (props: IconProps) => {
  switch (props.type) {
    case 'error':
      return <ToastError />;
    case 'success':
      return <ToastSuccess />;
    case 'info':
      return <ToastInfo />;
    default:
      return null;
  }
};
