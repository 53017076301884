import { useApiQuery } from '@water-web/api';
import { CartModel } from '@water-web/repository';

import { isUserInfoValid } from '../../types';
import { CustomerServiceConstructor } from '../../apiServices';
import { useAppSettingsContext } from '../../contexts';

interface Result {
  bookAndPay: boolean;
  bookNoPay: boolean;
  bookNoPayAndProvideCard: boolean;
  isLoading: boolean;
  isPrepayOnlyCustomer: boolean;
}

export const useAvailableBookingTypes = (cartModel: CartModel): Result => {
  const appSettingsConfig = useAppSettingsContext();
  const customerService = CustomerServiceConstructor(appSettingsConfig.apiUrl);

  const customer = cartModel.getCustomer()?.getDataValues();
  const cart = cartModel.getValidatedCart()?.getDataValues();
  const { canReserve, canBookNoPay } = cart?.breakdown ?? {};

  const { data: isPrepayOnlyCustomer, isLoading } = useApiQuery({
    endpoint: customerService.prepayOnlyByMatch,
    payload: {
      shopId: cart.shopId,
      barberId: cart.new_appointments[0]?.barberId ?? undefined, // FIXME: check all appointments
      customer,
    },
    options: {
      initialData: false,
      enabled: canBookNoPay && isUserInfoValid(customer),
    },
  });

  return {
    bookAndPay: true, // always allowed
    bookNoPay: (!isPrepayOnlyCustomer && canBookNoPay) || false,
    bookNoPayAndProvideCard: (!isPrepayOnlyCustomer && canReserve) || false,
    isLoading,
    isPrepayOnlyCustomer,
  };
};
