import { QueryKey } from 'react-query';

export const queryKeys = {
  all: ['user'],
  current: (): QueryKey => [...queryKeys.all, 'current'],
  cards: (): QueryKey => [...queryKeys.all, 'cards'],
  loginOtpInit: (): QueryKey => [...queryKeys.all, 'login-otp-init'], // POST /v1/auth-code
  signup: (): QueryKey => [...queryKeys.all, 'signup'], // POST /v1/signup
  loginOtpSubmit: (): QueryKey => [...queryKeys.all, 'login-otp-submit'], // POST /v1/code-login
};
