import { QueryClient } from 'react-query';
import { WaitingListAppointment } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  id: string;
  dateTime: string;
  barberId: string;
}

export class WaitingListAcceptRescheduleMutation extends EndpointMutation<WaitingListAppointment, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<WaitingListAppointment> {
    return this.http.post(
      `/v1/book-from-waiting-list/${payload.id}`,
      {
        dateTime: payload.dateTime,
        barberId: payload.barberId,
      },
      {
        signal,
        headers: this.buildHeaders(),
        resourceUrl: '/book-from-waiting-list/:id',
      },
    );
  }

  async afterMutationSuccess(queryClient: QueryClient, data: WaitingListAppointment, payload: Payload): Promise<void> {
    await queryClient.invalidateQueries(queryKeys.waitingList({ id: payload.id }));
    await queryClient.invalidateQueries(queryKeys.timeSlots());
  }
}
