import { MinMax, Service } from '@water-web/types';

import { Price } from '../types';

export const getCostRangeFromService = (service: Partial<Pick<Service, 'costRange' | 'cost'>>): MinMax<number> => {
  return {
    min: service.costRange?.min ?? service.cost ?? 0,
    max: service.costRange?.max ?? service.cost ?? 0,
  };
};

export const getDurationRangeFromService = (
  service: Partial<Pick<Service, 'durationRange' | 'duration'>>,
): MinMax<number> => {
  return {
    min: service.durationRange?.min ?? service.duration ?? 0,
    max: service.durationRange?.max ?? service.duration ?? 0,
  };
};

/**
 * Will return precise service price for detailed breakdown with taxes in a separate line
 */
export const getPriceExactFromService = (
  service: Partial<Pick<Service, 'costWithoutTaxesRange' | 'costWithoutTaxes'>>,
): Price => {
  // always take `WithoutTaxes`
  return {
    min: service.costWithoutTaxesRange?.min ?? 0,
    max: service.costWithoutTaxesRange?.max ?? 0,
    fallback: service.costWithoutTaxes ?? 0,
  };
};

/**
 * Will return human-friendly service price for draft breakdowns and collapsed views, where taxes and fees not visible
 */
export const getPriceDraftFromService = (service: Partial<Pick<Service, 'costRange'>>): Price => {
  return {
    ...service.costRange,
    fallback: service.costRange.min,
  };
};

export const isStandaloneFromService = (
  service: Partial<Pick<Service, 'durationRange' | 'duration' | 'cost' | 'costRange'>>,
): boolean => {
  // `durationRange`/`costRange` is for the ranged services, if their `duration`/`const` is garbage
  return (
    !!(service.duration || (service.durationRange?.min && service.durationRange?.max)) && // has duration
    !!(service.cost || (service.costRange?.min && service.costRange?.max))
  ); // has cost
};
