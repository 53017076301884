import { useContext, PropsWithChildren } from 'react';

import { CookieConsentContext } from '../../../contexts';

export const RenderWhenCookieNotAccepted = (props: PropsWithChildren) => {
  const consent = useContext(CookieConsentContext);

  if (consent && consent.isSectionAccepted('statistics')) {
    return null;
  }

  return <>{props.children}</>;
};
