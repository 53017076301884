export interface Price {
  min: number | null;
  max: number | null;
  /**
   * If `isPercent == false`, the value is cents of the given currency: `100` is 1 USD.
   * If `isPercent === true`, the value is a percent: `100` is 100%.
   */
  fallback: number;
  isPercent?: boolean;
}

export const PRICE_ZERO: Readonly<Price> = {
  min: 0,
  max: 0,
  fallback: 0,
};
