import { Repository } from './base';
import { RepositoryResponse } from '../utils';

type RT = RepositoryResponse<boolean>;

export class SMSRepository extends Repository {
  async sendCommonLinkToApps(phone: string, botProtectionToken?: string): Promise<RT> {
    const response = await this.api.sms.sendCommonLinkToApps(phone, botProtectionToken);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: true,
      error: null,
    };
  }

  async sendBrandedLinkToApps(phone: string, brandId: string, botProtectionToken?: string): Promise<RT> {
    const response = await this.api.sms.sendBrandedLinkToApps(phone, brandId, botProtectionToken);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      };
    }

    return {
      data: true,
      error: null,
    };
  }
}
