import { SaleOrderSubItem } from '@water-web/types';

import { Price } from '../types';
import { numberToPrice } from '../utils';

/**
 * Will return human-friendly service price for draft breakdowns and collapsed views, where taxes and fees not visible
 */
export const getPriceDraftFromSaleOrderSubItem = (
  saleOrderSubItem: Pick<SaleOrderSubItem, 'adjustments' | 'priceWithTaxes' | 'priceWithoutTaxes'>,
): Price => {
  // if `addToPrice === true` >>> take `WithoutTaxes`
  // in other cases >>> take `WithTaxes`
  const addToPrice = !!saleOrderSubItem.adjustments?.filter((tax) => !tax.exclude && tax.addToPrice).length;

  return numberToPrice(addToPrice ? saleOrderSubItem.priceWithoutTaxes : saleOrderSubItem.priceWithTaxes);
};
