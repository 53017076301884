import { SearchResultModel } from '../models';
import { RepositoryResponse } from '../utils';
import { Repository } from './base';

export class SearchRepository extends Repository {
  async getLocations(term: string, brand?: string): Promise<RepositoryResponse<SearchResultModel[]>> {
    const response = await this.api.search.search(term, ['public_shops'], brand);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<SearchResultModel[]>;
    }

    return {
      data: response.data.map((item) => {
        return new SearchResultModel(item);
      }),
      error: null,
    } as RepositoryResponse<SearchResultModel[]>;
  }
}
