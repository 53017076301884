import { useState, useMemo } from 'react';

import { BooleanHandlers } from './types';
import { getBooleanHandlers } from './utils';

export type UseBooleanResponse = [boolean, BooleanHandlers];

export const useBoolean = (initialValue = false): UseBooleanResponse => {
  const [isOn, setIsOn] = useState(initialValue);

  const handlers = useMemo(() => getBooleanHandlers(setIsOn), [setIsOn]);

  return [isOn, handlers];
};
