import { PropsWithChildren, ComponentPropsWithRef, Children } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@app/components/atoms';
import { WidgetMenu } from '@app/components/atoms/Icon/WidgetMenu';

const StyledButton = styled(Button.WidgetMenu)<{ withChildren?: boolean }>`
  ${({ withChildren }) =>
    withChildren &&
    css`
      padding: 0 4px 0 11px;
    `}

  transition-property: background-color, border-color, box-shadow;
  transition-duration: ${(props) => props.theme.animations.booking.interaction}s;
`;

export const WidgetMenuButton = (props: PropsWithChildren<ComponentPropsWithRef<'button'>>) => {
  const { children, ...rest } = props;

  const withChildren = Children.toArray(children).some(Boolean);

  return (
    <StyledButton {...rest} $testId="btn:menu" withChildren={withChildren}>
      <WidgetMenu />
      {children}
    </StyledButton>
  );
};
