/* eslint-disable no-underscore-dangle */
import { lazy, StrictMode, Suspense, useEffect } from 'react';
import {
  DatadogService,
  ExternalConfiguration,
  Logger,
  MountFullstory,
  RenderWhenCookieNotAccepted,
} from '@water-web/view';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import { config } from '@app/config';
import { initAuthErrorHandler, verifyTokenLifespan, migrateToken } from '@app/auth';
import { repository } from '@app/repository';
import {
  WidgetContainer,
  WidgetTitleContainer,
  AnalyticsScripts,
  Toaster,
  InvalidTimeChecker,
  AnalyticsDisabledScripts,
  FeatureFlagSwitcher,
} from '@app/components';
import { RenderWhenCookieAccepted } from '@app/components/organisms/CookieConsent';

import { AppContextProvider } from './AppContextProvider';
import { Routes, TitleRoutes } from './Routes';

const AppLevelPopups = lazy(() =>
  import('./AppLevelPopups')
    .then((module) => ({ default: module.AppLevelPopups }))
    .catch(() => ({ default: () => null })),
);

ExternalConfiguration.setRepository(repository);
ExternalConfiguration.setConfig({ apiUrl: config.apiUrl });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 1000 * 60 * 60, // 1hr
    },
  },
});

initAuthErrorHandler(queryClient);
migrateToken(); // FIXME: should be removed after January 20, 2025
verifyTokenLifespan();

const FULLSTORY_CONFIG = {
  orgId: config.analytics.fullstory.orgId,
};

DatadogService.init({
  clientToken: process.env.PUBLIC_DATADOG_TOKEN,
  service: process.env.PUBLIC_DATADOG_SERVICE,
  version: process.env.VERSION,
  environment: process.env.PUBLIC_DEPLOYMENT_ENV,
  sessionSampleRate: process.env.PUBLIC_DATADOG_SAMPLE_RATE,
  traceSampleRate: process.env.PUBLIC_DATADOG_TRACE_SAMPLE_RATE,
  appId: process.env.PUBLIC_DATADOG_APP_ID,
});

if (DatadogService.getLogger()) {
  Logger.addLogger(DatadogService.getLogger());
}

const App = () => {
  useEffect(() => {
    import('./sentry').then(({ initSentry }) => {
      initSentry({
        dsn: config.sentry.dsn,
        release: config.sentry.release,
        deploymentEnv: config.deploymentEnv,
      });
    });

    return () => queryClient.clear();
  }, []);

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={window._squireInitialData}>
          <AppContextProvider>
            <MountFullstory options={FULLSTORY_CONFIG} />
            <Toaster />
            <WidgetContainer>
              <WidgetTitleContainer>
                <TitleRoutes />
              </WidgetTitleContainer>
              <FeatureFlagSwitcher />
              <Routes />
              <Suspense fallback={null}>
                <AppLevelPopups />
              </Suspense>
            </WidgetContainer>
            <RenderWhenCookieAccepted>
              <AnalyticsScripts />
            </RenderWhenCookieAccepted>
            <RenderWhenCookieNotAccepted>
              <AnalyticsDisabledScripts />
            </RenderWhenCookieNotAccepted>
            <InvalidTimeChecker />
          </AppContextProvider>
        </Hydrate>
      </QueryClientProvider>
    </StrictMode>
  );
};

export default App;
