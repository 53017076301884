import { useContext } from 'react';
import { Shop } from '@water-web/types';
import { CartContext } from '@water-web/view';

import { useWaitingListPageData } from '@app/pages/waitingList/hooks/useWaitingListPageData';

import { useBrand } from '../useBrand';
import { useReservationModel } from '../useReservationModel';

interface ShopInfoValues {
  brandId?: string | null;
  shop?: Shop | null;
  brandGaProperty?: string | null;
}

export const useAnalyticsShopInfo = (): ShopInfoValues => {
  // take data from whatever hook is available: `packages/mobile/src/Routes.tsx`
  const { cart } = useContext(CartContext);
  const reservationPageData = useReservationModel();
  const waitingListPageData = useWaitingListPageData();

  const brandId =
    reservationPageData?.saleOrder?.shop.brandId ||
    waitingListPageData?.shop?.brandId ||
    cart?.getBrand()?.getId() ||
    null;

  const brand = useBrand(brandId);

  const shop =
    reservationPageData?.shop ||
    waitingListPageData?.shop ||
    cart?.getActiveReservation()?.getShop()?.getDataValues() ||
    null;

  return {
    brandId,
    shop,
    brandGaProperty: brand?.brandModel?.getDataValues()?.settings.gaMeasurementId,
  };
};
