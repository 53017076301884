import { AppTFunction } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDateFormats = (t: AppTFunction) => ({
  /**
   * @example Sep 16th
   */
  shortDateFormat: t('dateFormats.shortDateFormat'),

  /**
   * @example Sun, Sep 1, 9:30am
   */
  fullDateFormat: t('dateFormats.fullDateFormat'),

  /**
   * @example Sep
   */
  shortMonthName: t('dateFormats.shortMonthName'),

  /**
   * @example August
   */
  longMonthName: t('dateFormats.longMonthName'),

  /**
   * @example 7
   */
  dayNumberSingle: t('dateFormats.dayNumberSingle'),

  /**
   * @example 07
   */
  dayNumberDouble: t('dateFormats.dayNumberDouble'),

  /**
   * @example 9/16
   */
  monthSlashDay: t('dateFormats.monthSlashDay'),

  /**
   * @example August 30, 2021
   */
  dateAndYear: t('dateFormats.dateAndYear'),

  /**
   * @example Jul 15, 2024
   */
  dateAndYearShort: t('dateFormats.dateAndYearShort'),

  /**
   * @example Monday, January 7
   */
  dayDateShort: t('dateFormats.dayDateShort'),

  /**
   * @example October 2022
   */
  scheduleCalendarHeader: t('dateFormats.scheduleCalendarHeader'),

  /**
   * @example M, T, W, T, F, S, S
   */
  bookingCalendarWeekDay: t('dateFormats.bookingCalendarWeekDay'),

  /**
   * @example Mon, Tue, Wed, Thu, Fri, Sat, Sun
   */
  calendarLineWeekDay: t('dateFormats.calendarLineWeekDay'),

  /**
   * @example Monday, Tuesday, ..., Sunday
   */
  anotherDateWeekDay: t('dateFormats.anotherDateWeekDay'),

  /**
   * @example Jan 14 at 3:30 PM
   */
  rateExperienceDateTime: t('dateFormats.rateExperienceDateTime'),
});
