import styled, { StyledComponent } from 'styled-components';

import { VariantProps } from './withVariantProp';

const capitalize = <S extends string>(string: S): Capitalize<S> => {
  return (string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<S>;
};

type Variants = { [K: string]: unknown };
type Component<V> = StyledComponent<any, any, VariantProps<keyof V>>;
// eslint-disable-next-line no-use-before-define
type ComponentAliases<C extends Component<V>, V extends Variants> = { [K in keyof V as Capitalize<string & K>]: C };

// eslint-disable-next-line no-use-before-define
export const aliasesFromVariants = <C extends Component<V>, V extends Variants>(
  component: C,
  variants: V,
): ComponentAliases<C, V> => {
  return Object.keys(variants).reduce(
    (obj, key) => {
      const variant = styled(component).attrs<VariantProps<keyof V>>({ variant: key })``;

      return {
        ...obj,
        [capitalize(key)]: variant,
      };
    },
    {} as ComponentAliases<C, V>,
  );
};
