import { CartModel, ReservationModel } from '@water-web/repository';

import { setGcAmount } from './setGcAmount';

export const preselectedGcFromUrl = (cart: CartModel, reservation: ReservationModel): void => {
  if (reservation) {
    const urlParams = new URLSearchParams(window.location.search);
    const amount = parseInt(urlParams.get('preselect'), 10);
    if (amount) {
      setGcAmount(amount, cart, reservation);
    }
  }
};
