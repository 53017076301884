import { createContext, PropsWithChildren, useState, useEffect, useMemo } from 'react';
import { useBoolean } from '@water-web/view';

import { config } from '@app/config';

declare global {
  interface Window {
    gapi: unknown;
  }
}

const GAPI_LIBRARIES = new Set<'client' | 'auth2'>();
export const googleApiContextRegisterLibrary = (library: 'client' | 'auth2') => {
  GAPI_LIBRARIES.add(library);
};

const OAUTH_SCOPES = new Set<string>();
export const googleApiContextRegisterScope = (scope: string) => {
  OAUTH_SCOPES.add(scope);
};

const onGapiLoad = async (onInit: () => void) => {
  if (!config.googleApiKey && GAPI_LIBRARIES.has('client')) {
    // eslint-disable-next-line no-console
    console.log('The `googleApiKey` is missing');
    return;
  }
  if (!config.googleApiClientId && GAPI_LIBRARIES.has('auth2')) {
    // eslint-disable-next-line no-console
    console.log('The `googleApiClientId` is missing');
    return;
  }
  if (!GAPI_LIBRARIES.size || !OAUTH_SCOPES.size) {
    return;
  }

  const scope = Array.from(OAUTH_SCOPES.values()).join(' ');

  if (GAPI_LIBRARIES.has('client')) {
    await window.gapi.client.init({
      apiKey: config.googleApiKey,
      clientId: config.googleApiClientId,
      scope,
    });
  } else if (GAPI_LIBRARIES.has('auth2')) {
    await new Promise((resolve, reject) => {
      window.gapi.auth2
        .init({
          fetch_basic_profile: false,
          client_id: config.googleApiClientId,
          scope,
        })
        .then(resolve, reject);
    });
  }

  onInit();
};

export interface GoogleApiContextValue {
  client?: typeof window.gapi.client;
  auth2?: gapi.auth2.GoogleAuth;
  grantedScopes: Set<string>;
  isSignedIn: boolean;
}

export const GoogleApiContext = createContext<GoogleApiContextValue>(null);

export const GoogleApiContextProvider = (props: PropsWithChildren) => {
  const [isInitialized, { turnOn: setIsInitializedOn }] = useBoolean();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [grantedScopes, setGrantedScopes] = useState<Set<string>>(new Set());

  useEffect(() => {
    const libraries = Array.from(GAPI_LIBRARIES.values());
    window.gapi?.load(libraries.join(':'), () => onGapiLoad(setIsInitializedOn));
  }, [(typeof window === 'undefined' ? null : window)?.gapi]);

  useEffect(() => {
    if (isInitialized) {
      const auth2 = window.gapi.auth2.getAuthInstance();

      auth2.isSignedIn.listen(setIsSignedIn);

      auth2.currentUser.listen((user) => {
        setGrantedScopes(new Set((user.getGrantedScopes() || '').split(' ')));
      });
    }
  }, [isInitialized]);

  const value: GoogleApiContextValue = useMemo(() => {
    return {
      client: isInitialized ? window.gapi.client : null,
      auth2: isInitialized ? window.gapi.auth2?.getAuthInstance() : null,
      isSignedIn,
      grantedScopes,
    };
  }, [isInitialized, isSignedIn, grantedScopes]);

  return <GoogleApiContext.Provider value={value}>{props.children}</GoogleApiContext.Provider>;
};
