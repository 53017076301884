import { toaster } from '@app/components/molecules/Toaster';

import { PhaseConfig, PhaseHookArgs } from '../types';

export const waitingListExistenceError: PhaseConfig = {
  name: 'waitingListExistenceError',
  init: (params: PhaseHookArgs) => {
    params.setCheckoutPhase(params.fallbackCheckoutPhase);
    params.cartState.resetLoader();
    toaster.error(params.t('pages.waitingList.errorAlreadyInList'));
  },
  next: () => {
    // not available
  },
  cancel: () => {
    // not available
  },
};
