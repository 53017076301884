import { useState, useRef, useCallback, Dispatch } from 'react';

interface ImmutableRefObject<T> {
  readonly current: T;
}

type UseStateWithRefResponse<T> = [T, Dispatch<T>, ImmutableRefObject<T>];

export const useStateWithRef = <T>(initialState: T): UseStateWithRefResponse<T> => {
  const [value, setValueState] = useState<T>(initialState);
  const valueRef = useRef<T>(initialState);

  const setValue = useCallback((newValue: T) => {
    valueRef.current = newValue;
    setValueState(typeof newValue === 'function' ? () => newValue : newValue);
  }, []);

  return [value, setValue, valueRef];
};
