import { useContext, useMemo } from 'react';
import { AppStateContext, Translate } from '@water-web/view';

import { Button, Text } from '@app/components/atoms';
import { CartModeItemProps } from '@app/contexts/CartController/types';
import { useCheckoutFlow } from '@app/contexts/CheckoutFlow';

export const GiftCardControls = (props: CartModeItemProps) => {
  const appState = useContext(AppStateContext);
  const checkoutFlow = useCheckoutFlow();

  const onButtonClick = useMemo(() => {
    if (props.expanded) {
      return checkoutFlow.next;
    }

    return () => {
      props.setCartState('expanded');
    };
  }, [props.expanded, props.setCartState]);

  return (
    <Button
      height="48px"
      width="100%"
      onClick={onButtonClick}
      disabled={!appState.cart.interactive}
      $testId="btn:submit"
    >
      <Text.P2Bold>
        <Translate i18nKey="pages.giftCard.continue" />
      </Text.P2Bold>
    </Button>
  );
};
