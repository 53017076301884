import { QueryClient } from 'react-query';
import { SaleOrder } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

interface Tip {
  /**
   * To replace previously set tip, it's `id` should be provided.
   * If `id` is not provided, `amount` will get added to the previously set tip of a given barber.
   *
   * FIXME: There should be no `id` in payload at all.
   * This API quirk was introduced intentionally, only to support iPad app ¯\_(ツ)_/¯
   * @see https://squire.atlassian.net/browse/IN-1242
   * @see https://squirtech.slack.com/archives/C042QF5A2V6/p1715847930703729?thread_ts=1715694328.928249&cid=C042QF5A2V6
   */
  id?: string;
  barberId: string;
  amount: number;
  tipPercentage: number | null;
}
interface MutationData {
  /**
   * List of changed tips.
   * To remove previously set tip, set `amount` to `0`.
   */
  tips: Tip[];
}

interface Payload extends MutationData {
  id: string;
}

export class SaleOrderTipsMutation extends EndpointMutation<SaleOrder, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<SaleOrder> {
    const { id, ...data } = payload;
    return this.http.put<SaleOrder, MutationData>(`/v1/customer-sale/${id}/tip`, data, {
      signal,
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      resourceUrl: '/customer-sale/:id/tip',
    });
  }

  async afterMutationSuccess(queryClient: QueryClient, data: SaleOrder, payload: Payload): Promise<void> {
    queryClient.setQueryData(queryKeys.saleOrder({ id: payload.id }), data);
  }
}
