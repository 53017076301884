import { useMemo } from 'react';
import { startOfDay, addDays, endOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ReservationModel } from '@water-web/repository';

export interface UseReservationIntervalResponse {
  start: Date;
  end: Date;
}

export const useReservationInterval = (reservations: ReservationModel[]): UseReservationIntervalResponse => {
  const reservationsIds = reservations.map((reservation) => reservation.getId());

  // "today" in shop's timezone
  const start = useMemo<Date>(() => {
    if (!reservations.length) {
      return new Date();
    }

    const timeZone = reservations[0].getShop().getTimezone();
    return startOfDay(utcToZonedTime(new Date(), timeZone));
  }, [reservationsIds.toString()]);

  const end = useMemo<Date>(() => {
    if (!reservations.length) {
      return endOfDay(start);
    }

    const advanceBookDays = reservations.map((reservation) => {
      if (reservation.getIsAnyBarber()) {
        return reservation.getShop().getAdvanceBookDays();
      }

      return reservation.getBarber().getAdvanceBookDays();
    });

    return endOfDay(addDays(start, Math.max(1, Math.min(...advanceBookDays)) - 1));
  }, [reservationsIds.toString(), start]);

  return useMemo(
    () => ({
      start,
      end,
    }),
    [start, end],
  );
};
