import { ValidatedCartSubItem } from '@water-web/types';

import { Price } from '../types';

/**
 * Will return precise service price for detailed breakdown with taxes in a separate line
 */
export const getPriceExactFromCartService = (service: ValidatedCartSubItem): Price => {
  // always take `WithoutTaxes`
  return {
    min: service.totalWithoutTaxesRange.min,
    max: service.totalWithoutTaxesRange.max,
    fallback: service.priceWithoutTaxes,
  };
};

/**
 * Will return human-friendly service price for draft breakdowns and collapsed views, where taxes and fees not visible
 */
export const getPriceDraftFromCartService = (service: Pick<ValidatedCartSubItem, 'rangeDisplayPrice'>): Price => {
  return {
    ...service.rangeDisplayPrice,
    fallback: service.rangeDisplayPrice.min,
  };
};
