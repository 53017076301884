import { SaleOrder, isSaleOrderGiftCardPayment, isSaleOrderCardPayment } from '@water-web/types';

import { getIsCanceledFromSaleOrderItem } from './saleOrderItem';

export const getIsSomeCanceledFromSaleOrder = (saleOrder?: Pick<SaleOrder, 'state' | 'items'>): boolean => {
  return (
    saleOrder?.state === 'canceled' ||
    saleOrder?.items
      .filter((item) => item.type === 'appointment' || item.type === 'new_appointment')
      .some(getIsCanceledFromSaleOrderItem)
  );
};

export const getTotalExactFromSaleOrder = (saleOrder: Pick<SaleOrder, 'breakdown' | 'payments'>): number => {
  /**
   * Gift card is treated as a regular payment(just like a card payment), not as a discount.
   * So we need to subtract it from total to make sure we're not confusing customers
   */
  return saleOrder.breakdown.totalChargeAmount - (saleOrder.payments.find(isSaleOrderGiftCardPayment)?.amount ?? 0);
};

export const isPaidFromSaleOrder = (saleOrder: Pick<SaleOrder, 'payments'>): boolean => {
  return saleOrder.payments.some(
    (payment) => isSaleOrderCardPayment(payment) && (payment.captured || payment.state === 'succeeded'),
  );
};
