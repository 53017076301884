import { Shop, Photo, CurrencyCode } from '@water-web/types';

import { getTipPercentagesFromShop } from '../getters';
import { LocationModel } from './location';
import { BarberModel } from './barber';
import { ServiceModel } from './service';
import { ProductModel } from './product';

export class ShopModel extends LocationModel {
  private barbers: BarberModel[] = null;
  private services: ServiceModel[] = null;
  private products: ProductModel[] = null;
  protected dataValues: Readonly<Shop>;
  protected tipPercentages: number[];

  constructor(shop: Shop) {
    super(shop);

    this.dataValues = {
      ...shop,
    };

    this.initTipPercentages();
  }

  isAnyBarberAvailable(): boolean {
    return !!this.dataValues.allowBookWithAnyBarber;
  }

  setBarbers(barbers: BarberModel[]): ShopModel {
    this.barbers = barbers;
    return this;
  }

  getBarbers(): BarberModel[] {
    return this.barbers;
  }

  setServices(services: ServiceModel[]): ShopModel {
    this.services = services;
    return this;
  }

  getServices(): ServiceModel[] {
    return this.services;
  }

  getCurrency(): CurrencyCode {
    return this.dataValues.currency;
  }

  getPhone(): string {
    return this.dataValues.phone;
  }

  /**
   * @description
   * Returns shop logo as a Photo object if present
   */
  getLogo(): null | Photo {
    const logo = this.dataValues.logo as Photo;
    return logo ?? null;
  }

  setProducts(products: ProductModel[]): ShopModel {
    this.products = products;
    return this;
  }

  getProducts(): ProductModel[] {
    return this.products;
  }

  isAdultsOnly(): boolean {
    return this.dataValues.adultsOnly;
  }

  isMultipleServicesEnabled(): boolean {
    return this.dataValues.allowMultipleServices;
  }

  getAdvanceBookDays(): number {
    return this.dataValues.advanceBookDays;
  }

  isRentalShop(): boolean {
    return this.dataValues.kind === 0;
  }

  private areGiftCardSalesEnabled(): boolean {
    return this.dataValues.customerGiftCardEnabled;
  }

  /**
   * @description
   * MUST ONLY BE USED THROUGH RESERVATION MODEL
   * if a shop can sell gift cards assigned to specific barbers
   *
   * @see reservation model for usage
   */
  areAssignedGiftCardSalesEnabled(): boolean {
    return this.areGiftCardSalesEnabled() && !!this.dataValues.barberSpecificGC;
  }

  /**
   * @description
   * MUST ONLY BE USED THROUGH RESERVATION MODEL
   * if a shop can sell gift cards without a barber assigned
   *
   * @see reservation model for usage
   */
  areUnassignedGiftCardSalesEnabled(): boolean {
    return this.areGiftCardSalesEnabled() && !this.isRentalShop();
  }

  /**
   * Shop settings allow booking without providing bank card
   */
  allowBookWithoutCard(): boolean {
    return this.dataValues.bookNoPay;
  }

  /**
   * Shop settings allow booking without paying
   * but still require a bank card
   */
  allowBookAndProvideCard(): boolean {
    return this.dataValues.allowBookAndReserve;
  }

  /**
   * Only valid, if barber has passcodes required
   * Use the `ReservationModel.isPasscodeRequired(shop, barber)` method
   */
  isPasscodeEnabled(): boolean {
    return this.dataValues.bookingCodeEnabled;
  }

  canShareGiftCardsWithinBrand(): boolean {
    return this.dataValues.settings?.shareGiftCards ?? false;
  }

  getTipPercentages(): number[] {
    return this.tipPercentages;
  }

  toJson(): Record<string, unknown> {
    const barbersJson = this.barbers?.map((barber) => barber.toJson());
    return {
      ...this.dataValues,
      barbers: barbersJson,
    };
  }

  getDataValues(): Readonly<Shop> {
    return this.dataValues;
  }

  protected initTipPercentages(): void {
    this.tipPercentages = getTipPercentagesFromShop(this.dataValues);
  }
}
