import { ValidatedCartSubItem, ValidatedCartItem, ValidatedCart } from './validated_cart';

export type SaleOrderSubItem = Omit<ValidatedCartSubItem, 'rangeDisplayPrice'>;

export const isSaleOrderSubItem = (subItem: SaleOrderSubItem | ValidatedCartSubItem): subItem is SaleOrderSubItem => {
  return !(subItem as ValidatedCartSubItem).rangeDisplayPrice;
};

export interface SaleOrderItem extends Omit<ValidatedCartItem, 'subItems'> {
  subItems?: SaleOrderSubItem[];
}

export const isSaleOrderItem = (item: SaleOrderItem | ValidatedCartItem): item is SaleOrderItem => {
  return (item as SaleOrderItem).subItems.some(isSaleOrderSubItem);
};

interface SaleOrderCardInfo {
  brand: string;
  expiration: string;
  funding: string;
  last4: string;
}

interface SaleOrderCardPayment {
  type: 'card';
  amount: number;
  cardInfo: SaleOrderCardInfo;
  state: 'succeeded' | 'new';
  captured: boolean;
}

interface SaleOrderGiftCardPayment {
  type: 'gift_card';
  // positive number
  amount: number;
  giftCard: {
    code: string;
    initialBalance: number;
    outstandingBalance: number;
  };
}

type SaleOrderPayment = SaleOrderCardPayment | SaleOrderGiftCardPayment;

export const isSaleOrderCardPayment = (payment: SaleOrderPayment): payment is SaleOrderCardPayment => {
  return payment.type === 'card';
};

export const isSaleOrderGiftCardPayment = (payment: SaleOrderPayment): payment is SaleOrderGiftCardPayment => {
  return payment.type === 'gift_card';
};

export interface SaleOrder extends Omit<ValidatedCart, 'state' | 'items' | 'payments'> {
  canApplyLateTipping: boolean;
  state: 'finished' | 'scheduled' | 'reserved' | 'canceled';
  items: SaleOrderItem[];
  payments: SaleOrderPayment[];
}

export const isSaleOrder = (saleOrderOrCart: SaleOrder | ValidatedCart): saleOrderOrCart is SaleOrder => {
  return (saleOrderOrCart as SaleOrder).items.some(isSaleOrderItem);
};
