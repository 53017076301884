import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { LatLng } from '../../types';
import { UserAgentContext } from '../UserAgent';

export type UserLocationContextValue = {
  isNavigationSupported: boolean;
  permission: PermissionState;
  getLocation: (force?: boolean) => Promise<LatLng>;
  location: LatLng;
};

export const UserLocationContext = createContext<UserLocationContextValue>(null);

export const UserLocationContextProvider = (props: PropsWithChildren) => {
  const userAgent = useContext(UserAgentContext);
  const pendingRequestPromiseRef = useRef<Promise<LatLng>>(null);
  const [isNavigationSupported, setIsNavigationSupported] = useState(false);
  const [permission, setPermission] = useState<PermissionState>('prompt');
  const permissionRef = useRef<PermissionState>('prompt');
  const [location, setLocation] = useState<LatLng>(null);

  const queryPermission = useCallback(async () => {
    if (userAgent.isSafari) {
      return permissionRef.current;
    }

    try {
      const permissionResult = await navigator.permissions.query({ name: 'geolocation' });
      permissionResult.onchange = function onPermissionResultChange() {
        // eslint-disable-next-line react/no-this-in-sfc
        permissionRef.current = this.state;
        setPermission(permissionRef.current);
      };
      permissionRef.current = permissionResult.state;
    } catch (e) {
      setIsNavigationSupported(false);
    }

    setPermission(permissionRef.current);

    return permissionRef.current;
  }, [userAgent, setPermission]);

  const requestLocation = useCallback(async () => {
    if (!isNavigationSupported) {
      throw new Error('Navigation not supported');
    }

    if (!userAgent) {
      throw new Error('Missing user-agent');
    }

    await queryPermission();
    if (permissionRef.current === 'denied') {
      // TODO: ask to change browser settings
      throw new Error('User denied Geolocation');
    }

    const latLng: LatLng = await new Promise((res, rej) => {
      // Safari will:
      // - prompt permission if not blocked
      // - throw error if location is blocked, or location prompt denied
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (userAgent.isSafari && permission !== 'granted') {
            permissionRef.current = 'granted';
            setPermission(permissionRef.current);
          }

          return res({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        rej,
        {
          enableHighAccuracy: false,
          timeout: 10000, // it may take infinite time in some circumstances
          maximumAge: Infinity,
        },
      );
    }).catch((e: GeolocationPositionError) => {
      if (userAgent.isSafari && e.code === 1) {
        permissionRef.current = 'denied';
        setPermission(permissionRef.current);
      }
      throw e;
    });

    setLocation(latLng);
    return latLng;
  }, [isNavigationSupported, userAgent, queryPermission]);

  const getLocation = useCallback<UserLocationContextValue['getLocation']>(
    async (force?: boolean) => {
      if (!force && location) {
        return location;
      }

      if (pendingRequestPromiseRef.current) {
        return pendingRequestPromiseRef.current;
      }

      pendingRequestPromiseRef.current = requestLocation().finally(() => {
        pendingRequestPromiseRef.current = null;
      });
      return pendingRequestPromiseRef.current;
    },
    [location, requestLocation],
  );

  const [value, setValue] = useState<UserLocationContextValue>({
    isNavigationSupported,
    permission,
    getLocation,
    location,
  });

  useEffect(() => {
    // update `isNavigationSupported` in browser
    const newIsNavigationSupported = !!window?.navigator?.geolocation;
    setIsNavigationSupported(newIsNavigationSupported);
    if (newIsNavigationSupported && userAgent) {
      queryPermission();
    }
  }, [userAgent, queryPermission]);

  useEffect(() => {
    setValue({
      isNavigationSupported,
      permission,
      getLocation,
      location,
    });
  }, [isNavigationSupported, permission, getLocation, location]);

  return <UserLocationContext.Provider value={value}>{props.children}</UserLocationContext.Provider>;
};
