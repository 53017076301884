import { persistentKVStore, LocalStorageTTL } from '@water-web/view';

import { KEY, TTL } from './const';

export const OLD_KEY = 'token';
const MIGRATION_TTL = Math.floor(Math.random() * (TTL / 2)) + 1;

/**
 * Make users with an old auth token re-auth gradually
 * The old token lifespan is limited `TTL / 2` randomly for that
 */
export const migrateToken = () => {
  const oldToken = persistentKVStore.getItem(OLD_KEY);
  if (!oldToken) {
    return;
  }

  const tempStorage = new LocalStorageTTL({ key: KEY, ttl: MIGRATION_TTL });
  tempStorage.set(oldToken);
  persistentKVStore.removeItem(OLD_KEY);
};
