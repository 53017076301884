import {
  BookingUrlBuilderContext,
  CartContext,
  CartStateContext,
  useAnyBarberPicker,
  useBoolean,
  usePaymentInfoHandler,
  BankCardContext,
  useFeatureFlags,
  DeeplinkPromoContext,
} from '@water-web/view';
import { createContext, useEffect, useMemo, useState, useRef, useContext, useCallback, PropsWithChildren } from 'react';

import { PopupContext } from '@app/components/molecules/Popup/context';
import { useTranslator, useUserActionsAnalytics, usePaymentProcessor, useHistoryTyped } from '@app/hooks';

import { phases } from './phases';
import { CheckoutPhase, PhaseConfig, PhaseHookArgs } from './types';

export interface CheckoutFlowContextValue {
  isInputBlocked: boolean;
  isLoading: boolean;
  next: () => void;
  cancel: () => void;
}

export const CheckoutFlowContext = createContext<CheckoutFlowContextValue>(null);

export const CheckoutFlowContextProvider = (props: PropsWithChildren) => {
  const { webLogin, mobileCheckoutV2 } = useFeatureFlags();
  const [isInputBlocked, inputBlockHandlers] = useBoolean(false);
  const [isLoading, loadingHandlers] = useBoolean(false);
  const [checkoutPhase, setCheckoutPhaseState] = useState<CheckoutPhase>('initial');
  const [fallbackCheckoutPhase, setFallbackCheckoutPhase] = useState<CheckoutPhase>('initial');
  const activePhaseRef = useRef<PhaseConfig>(phases[checkoutPhase]);
  const t = useTranslator();

  const { cart: cartModel } = useContext(CartContext);
  const { popupController } = useContext(PopupContext);
  const cartState = useContext(CartStateContext);

  const paymentProcessor = usePaymentProcessor();
  const collectPayment = usePaymentInfoHandler(paymentProcessor, cartModel);
  const pickAnyBarber = useAnyBarberPicker(cartModel);
  const history = useHistoryTyped();
  const urlBuilder = useContext(BookingUrlBuilderContext);
  const userActionsAnalytics = useUserActionsAnalytics();
  const { cardInfo: newCard, setPaymentInfo } = useContext(BankCardContext);
  const { clear: resetDeeplinkPromo } = useContext(DeeplinkPromoContext);

  const setCheckoutPhase = (phase: CheckoutPhase, message?: string) => {
    setCheckoutPhaseState(phase);
    if (!message) {
      cartState.setLoaderMessage(null);
      return;
    }

    cartState.setLoaderMessage(message);
  };

  const phaseHookArgs = useMemo<PhaseHookArgs>(() => {
    return {
      cartModel,
      popupController,
      setCheckoutPhase,
      collectPayment,
      cartState,
      fallbackCheckoutPhase,
      setFallbackCheckoutPhase,
      pickAnyBarber,
      inputBlockHandlers,
      loadingHandlers,
      history,
      urlBuilder,
      t,
      userActionsAnalytics,
      newCard,
      resetNewCard: () => setPaymentInfo(undefined),
      resetDeeplinkPromo,
      openCheckout:
        webLogin && mobileCheckoutV2
          ? () => popupController.handlers.checkoutFormV2().open()
          : () => popupController.handlers.checkoutForm().open(),
    };
  }, [
    cartModel,
    popupController,
    setCheckoutPhase,
    collectPayment,
    cartState,
    t,
    userActionsAnalytics,
    newCard,
    resetDeeplinkPromo,
    webLogin,
    mobileCheckoutV2,
  ]);

  useEffect(() => {
    const nextPhase = phases[checkoutPhase];
    const didPhaseChange = activePhaseRef.current.name !== nextPhase.name;

    if (didPhaseChange) {
      activePhaseRef.current = nextPhase;
    }

    if (didPhaseChange && nextPhase.init) {
      nextPhase.init(phaseHookArgs);
    }
  }, [checkoutPhase, phaseHookArgs]);

  const next = useCallback(() => {
    const activePhase = phases[checkoutPhase];
    activePhase.next(phaseHookArgs);
  }, [checkoutPhase, phaseHookArgs]);

  const cancel = useCallback(() => {
    const activePhase = phases[checkoutPhase];
    activePhase.cancel(phaseHookArgs);
  }, [checkoutPhase, phaseHookArgs]);

  const value = { isInputBlocked, isLoading, next, cancel };

  return <CheckoutFlowContext.Provider value={value}>{props.children}</CheckoutFlowContext.Provider>;
};
