import { User } from '@water-web/types';
import { QueryClient } from 'react-query';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';
import { buildBotProtectionHeaders } from '../../utils';

type Payload = {
  idToken: string;
  botProtectionToken?: string;
};

type Response = {
  token: string;
  user: User;
};

export class UserSignupByAppleMutation extends EndpointMutation<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    return this.http.post(
      `/v1/apple-signup?include=customer&identity_token=${payload.idToken}`,
      { kind: 0 },
      {
        signal,
        headers: this.buildHeaders(buildBotProtectionHeaders(payload.botProtectionToken)),
        resourceUrl: '/apple-signup',
      },
    );
  }

  afterMutationSuccess(queryClient: QueryClient, data: Response): void {
    queryClient.setQueryData(queryKeys.current(), data.user);
  }
}
