import { QueryClient } from 'react-query';

import { queryKeys as notificationsQueryKeys } from '../notifications/queryKeys';
import { EndpointMutation, GetToken } from '../../request';

interface RequestData {
  appointmentRating: 1 | 2 | 3 | 4 | 5;
  appointmentReview: string | null;
}

interface Payload extends RequestData {
  id: string;
}

type Response = void;

export class AppointmentReviewMutation extends EndpointMutation<Response, Payload> {
  constructor(config: { baseUrl: string; getToken: GetToken }) {
    super();
    this.baseUrl = config.baseUrl;
    this.getToken = config.getToken;
    this.authenticated = true;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    const { id, appointmentRating, appointmentReview } = payload;
    const headers = this.buildHeaders();

    return this.http.put<Response, RequestData>(
      `/v1/appointment/${id}/rating`,
      {
        appointmentRating,
        appointmentReview: appointmentReview ?? undefined,
      },
      { signal, headers, resourceUrl: '/appointment/:id/rating' },
    );
  }

  async afterMutationSettled(queryClient: QueryClient): Promise<void> {
    await queryClient.invalidateQueries(notificationsQueryKeys.all);
  }
}
