import { ValidatedCartAvailableTip, ValidatedCartItem } from '@water-web/types';

import { Price } from '../types';
import { getCostRangeFromService } from '../getters';
import { getAvailableTips, reducePrices, numberToPrice } from '../utils';
import { ShopModel } from './shop';
import { BarberModel } from './barber';
import { BaseModel } from './base';
import { ServiceModel } from './service';
import { AddonModel } from './addon';
import { CustomerModel } from './customer';

type ReservationTipType = 'percentage' | 'amount';

export interface ReservationTip {
  /**
   * amount in cents
   */
  amount: number;
  type: ReservationTipType;
  percentage: number | null;
}

export class ReservationModel extends BaseModel<ReservationModel> {
  protected barber: BarberModel = null;
  protected anyBarberCandidate: BarberModel = null;
  protected bookedWithAnyBarber = false;
  protected shop: ShopModel = null;
  protected service: ServiceModel = null;
  protected addons: AddonModel[] = [];
  protected readonly customer: CustomerModel = null;
  /**
   * UTC DateTime
   */
  protected dateTime: Date = null;
  protected tip: ReservationTip = null;
  protected waitingListShopDate: Date = null;
  protected giftCardSaleAmount: number = null; // for selling gift cards, not applying
  protected validatedPasscode: string | null = null;

  constructor() {
    super();

    this.dataValues.id = (Date.now() * Math.random()).toFixed(0);
    this.customer = new CustomerModel({
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    });
  }

  getId(): string {
    return this.dataValues.id as string;
  }

  getAppointmentId(): string | null {
    return this.dataValues.appointment?.id || null;
  }

  getTotal(): number {
    const serviceCost = this.service?.getCost() || 0;
    const addonsCost = this.addons?.reduce((acc, addon) => acc + addon.getCost(), 0) || 0;

    return serviceCost + addonsCost + this.getGiftCardSaleAmount();
  }

  getTotalRange(): Price {
    const prices = [...this.addons, this.service]
      .filter(Boolean)
      .map((item) => ({
        ...getCostRangeFromService(item.getDataValues()),
        fallback: item.getDataValues().cost,
      }))
      .concat(numberToPrice(this.getGiftCardSaleAmount()));

    return reducePrices(prices);
  }

  /**
   * Will return precise services price for detailed breakdown with taxes in a separate line
   */
  getServicesExact(): Price {
    const prices = [...this.addons, this.service].filter(Boolean).map((item) => item.getPriceExact());

    return reducePrices(prices);
  }

  /**
   * Will return human-friendly services price for draft breakdowns where taxes and fees not visible
   */
  getServicesDraft(): Price {
    const prices = [...this.addons, this.service].filter(Boolean).map((item) => item.getPriceDraft());

    return reducePrices(prices);
  }

  getTotalForTips(): number {
    const serviceCost = this.service?.getCostForTips() || 0;
    const addonsCost = this.addons?.reduce((acc, addon) => acc + addon.getCostForTips(), 0) || 0;

    return serviceCost + addonsCost + this.getGiftCardSaleAmount();
  }

  setShop(shop: ShopModel): ReservationModel {
    this.shop = shop;
    return this;
  }

  getShop(): ShopModel {
    return this.shop;
  }

  isAnyBarberAllowed(): boolean {
    return this.shop.isAnyBarberAvailable() || this.shop.areUnassignedGiftCardSalesEnabled();
  }

  setBarber(barber: BarberModel | null, bookedWithAnyBarber = false): ReservationModel {
    if (barber?.isAnyBarber() && !this.isAnyBarberAllowed()) {
      throw new Error('Shop doesn\'t support "any barber"');
    }

    if (!barber || this.barber?.getId() !== barber.getId()) {
      this.setValidatedPasscode(null);
    }

    if (!barber?.isAnyBarber()) {
      this.setAnyBarberCandidate(null);
    }

    this.barber = barber;
    this.bookedWithAnyBarber = bookedWithAnyBarber;

    return this;
  }

  getBarber(): BarberModel | null {
    return this.barber;
  }

  setAnyBarberCandidate(barber: BarberModel | null): ReservationModel {
    if (barber?.isAnyBarber()) {
      throw new Error('Candidate for "any barber" flow can\'t be another "any barber');
    }

    if (barber && !this.shop.isAnyBarberAvailable()) {
      throw new Error('Shop doesn\'t support "any barber"');
    }

    this.anyBarberCandidate = barber;

    return this;
  }

  getAnyBarberCandidate(): BarberModel | null {
    return this.anyBarberCandidate;
  }

  /**
   * @description Indicates if any barber is currebtly selected. Turns to false once a random barber is assigned.
   */
  getIsAnyBarber(): boolean {
    return !!this.barber?.isAnyBarber();
  }

  /**
   * @description Indicates if the user taken the any barber flow.
   * Remains true even after a random barber is assigned.
   */
  getBookedWithAnyBarber(): boolean {
    return this.bookedWithAnyBarber;
  }

  setService(service: ServiceModel | null): ReservationModel {
    this.service = service;
    return this;
  }

  getService(): ServiceModel | null {
    return this.service;
  }

  canAddMultipleServices(): boolean {
    const barber = this.getBarber();
    const shop = this.getShop();

    if (!barber || barber.isAnyBarber()) {
      return shop.isMultipleServicesEnabled() ?? false;
    }

    return barber.isMultipleServicesEnabled() ?? false;
  }

  /**
   * Valid while we use services as addons. Once we switch to real addons, this needs to be revised
   */
  canAddAddons(): boolean {
    return this.canAddMultipleServices();
  }

  setAddons(addons: AddonModel[]): ReservationModel {
    this.addons = addons;
    return this;
  }

  addAddon(addon: AddonModel): ReservationModel {
    this.addons = [...this.addons, addon];
    return this;
  }

  removeAddon(addon: AddonModel): ReservationModel {
    this.addons = this.addons.filter((a) => a.getId() !== addon.getId());
    return this;
  }

  clearAddons(): ReservationModel {
    this.addons = [];
    return this;
  }

  getAddons(): AddonModel[] {
    return this.addons;
  }

  getCustomer(): CustomerModel {
    return this.customer;
  }

  isCustomerNameValid(): boolean {
    return this.getCustomer()?.getFullName()?.length > 1;
  }

  /**
   * Time of service with addons in minutes
   */
  getDuration(): number {
    const serviceDuration = this.getService()?.getDuration() || 0;
    const addonsDurations = this.getAddons().map((addon) => addon.getDuration() || 0);

    return [...addonsDurations, serviceDuration].reduce((total, duration) => total + duration, 0);
  }

  setDateTime(utcTime: Date): ReservationModel {
    this.dateTime = utcTime;
    return this;
  }

  /**
   * @returns UTC DateTime
   */
  getDateTime(): Date {
    return this.dateTime;
  }

  getWaitingListDate(): Date | null {
    return this.waitingListShopDate;
  }

  setWaitingListDate(shopDate: Date | null): ReservationModel {
    this.waitingListShopDate = shopDate;
    return this;
  }

  isEarlyTippingEnabled(): boolean {
    const barber = this.getIsAnyBarber() ? this.getAnyBarberCandidate()?.getDataValues() : this.barber?.getDataValues();

    return !!barber && barber.earlyTipping;
  }

  getAvailableTips(): ValidatedCartAvailableTip[] {
    if (!this.isEarlyTippingEnabled() || !this.shop) {
      return [];
    }

    return getAvailableTips(
      this.shop.getTipPercentages(),
      this.getTotalForTips(),
      this.shop.getDataValues().roundUpTips,
    );
  }

  setTipFromPreset(preset: ValidatedCartAvailableTip): ReservationModel {
    if (!this.isEarlyTippingEnabled()) {
      console.error('Tipping is not allowed for reservation');
      return this;
    }

    this.tip = {
      type: 'percentage',
      amount: preset.amount,
      percentage: preset.percentage,
    };

    return this;
  }

  setCustomTip(amount: number): ReservationModel {
    if (!this.isEarlyTippingEnabled()) {
      console.error('Tipping is not allowed for reservation');
      return this;
    }

    this.tip = {
      amount,
      type: 'amount',
      percentage: null,
    };

    return this;
  }

  setTipFromCart(amount: number): ReservationModel {
    const matchingPreset = this.getAvailableTips().find((tip) => tip.amount === amount);

    if (matchingPreset) {
      this.setTipFromPreset(matchingPreset);
    } else {
      this.setCustomTip(amount);
    }

    return this;
  }

  resetTip(): ReservationModel {
    this.tip = null;
    return this;
  }

  getTip(): ReservationTip | null {
    return this.tip;
  }

  setDefaultTipFromValidatedReservation(validatedReservation?: ValidatedCartItem): ReservationModel {
    if (!this.isEarlyTippingEnabled()) {
      return this;
    }

    if (validatedReservation?.defaultTip) {
      this.setTipFromPreset(validatedReservation.defaultTip);
      return this;
    }

    if (this.getIsAnyBarber()) {
      // take second tip as the default
      this.setTipFromPreset(this.getAvailableTips()[1]);
      return this;
    }

    this.setCustomTip(0);
    return this;
  }

  /**
   * @description
   * indicates if that's possible to add a barber specific gift card to a reservation
   */
  canSellAssignedGiftCard(): boolean {
    // handle any barber case which is actually an unassigned sale
    if (this.getIsAnyBarber()) {
      return this.canSellUnassignedGiftCard();
    }

    return this.shop?.areAssignedGiftCardSalesEnabled() ?? false;
  }

  /**
   * @description
   * indicates if that's possible to add an any barber gift card to a reservation
   */
  canSellUnassignedGiftCard(): boolean {
    return this.shop?.areUnassignedGiftCardSalesEnabled() ?? false;
  }

  /**
   * @description returns the minimal available for purchase gift card amount
   */
  getMinGiftCardAmount(): number {
    return 5000;
  }

  /**
   * @description returns available for purchase gift card amount presets in cents
   */
  getGiftCardTiers(): number[] {
    return [5000, 10000, 20000, 30000, 50000, 75000, 100000];
  }

  getGiftCardSaleAmount(): number {
    return Number(this.giftCardSaleAmount);
  }

  setGiftCardSaleAmount(amount: number): ReservationModel {
    this.giftCardSaleAmount = amount;
    return this;
  }

  setValidatedPasscode(passcode: string | null): ReservationModel {
    this.validatedPasscode = passcode;
    return this;
  }

  getValidatedPasscode(): string | null {
    return this.validatedPasscode;
  }

  getCanCustomerCancel(): boolean {
    const barber = this.getIsAnyBarber() ? this.getAnyBarberCandidate() : this.getBarber();
    return barber?.getDataValues().canCustomerCancel;
  }

  getAdvanceCancelMins(): number {
    const barber = this.getIsAnyBarber() ? this.getAnyBarberCandidate() : this.getBarber();
    return barber?.getDataValues().advanceCancelMins ?? 0;
  }

  /**
   * @description
   * returns false if either final duration or price are in range(happens only in any barber case)
   * true if final price and duration are fixed
   */
  arePriceAndDurationFinal(): boolean {
    if (!this.getIsAnyBarber() || !!this.getAnyBarberCandidate()) {
      return true;
    }

    const isServiceFinal = this.getService()?.arePriceAndDurationFinal() ?? true;
    const areAddonsFinal = this.getAddons().every((addon) => addon.arePriceAndDurationFinal());
    return isServiceFinal && areAddonsFinal;
  }

  static isPasscodeRequired(shop: ShopModel, barber: BarberModel): boolean {
    return !!(shop.isPasscodeEnabled() && barber.isRequirePasscode());
  }

  toJson(): Record<string, unknown> {
    throw new Error('Not implemented');
  }
}
