import { NotificationsConsent } from '@water-web/types';

import { EndpointMutation } from '../../request';

type Update<Dict = NotificationsConsent['showPrompt']> = {
  [K in keyof Dict]?: Partial<Dict[K]>;
};

interface Payload {
  customerId: string;
  shopId: string;
  update: Update;
}

type Response = NotificationsConsent;

export class NotificationsConsentUpdateMutation extends EndpointMutation<Response, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Response> {
    return this.http.put<Response, Update>(
      `/v1/customer/${payload.customerId}/shop/${payload.shopId}/notifications-consent`,
      payload.update,
      { signal, resourceUrl: '/customer/:customerId/shop/:shopId/notifications-consent' },
    );
  }
}
