import { ValidatedCartAvailableTip } from '@water-web/types';

export const getAvailableTips = (
  percentages: number[],
  totalForTips: number,
  roundTips = true,
): ValidatedCartAvailableTip[] => {
  return percentages.map((percentage) => ({
    percentage,
    amount: roundTips
      ? Math.ceil((totalForTips / 100) * (percentage / 100)) * 100 // just like on the backend
      : Math.ceil(totalForTips * (percentage / 100) * 100) / 100,
  }));
};
