import { QueryKey } from 'react-query';

export const queryKeys = {
  all: ['publicShop'],
  byId: (payload: { idOrRoute: string }): QueryKey => [...queryKeys.all, payload.idOrRoute],
  byShopAndBarberId: (payload: { shopIdOrRoute: string }): QueryKey => [
    ...queryKeys.all,
    payload.shopIdOrRoute,
    'barbers',
  ],
};
