import { ProductApiSearch } from '@water-web/types';

import { Repository } from './base';
import { ProductModel, ReservationModel } from '../models';
import { RepositoryResponse } from '../utils';

export class ProductRepository extends Repository {
  // TODO: update once products source business logic is confirmed
  // currently it always returns shop products but behavior might be different for rental shops
  // where each barber can their own products
  async getProductsForReservation(reservation: ReservationModel): Promise<RepositoryResponse<ProductModel[]>> {
    const shop = reservation.getShop();

    if (shop.getProducts()) {
      return {
        data: shop.getProducts(),
        error: null,
      };
    }

    const products = await this.getByShopIdOrRoute(shop.getId());
    shop.setProducts(products.data);
    return products;
  }

  async getByShopIdOrRoute(shopIdOrRoute: string): Promise<RepositoryResponse<ProductModel[]>> {
    const search: ProductApiSearch = {
      include: ['photos', 'productBrand'],
      sortBy: 'name',
    };

    const response = await this.api.product.getByShopIdOrRoute(shopIdOrRoute, search);

    if (response.error) {
      return {
        data: null,
        error: response.error,
      } as RepositoryResponse<ProductModel[]>;
    }

    return {
      data: response.data.map((product) => new ProductModel(product)),
      error: null,
    } as RepositoryResponse<ProductModel[]>;
  }
}
