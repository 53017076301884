import { PhaseConfig, PhaseHookArgs } from '../types';
import { collectPaymentOrShowCardInput } from './utils';

export const userInfo: PhaseConfig = {
  name: 'userInfo',
  init: (params: PhaseHookArgs) => {
    params.setFallbackCheckoutPhase('userInfo');
    params.openCheckout();
    params.userActionsAnalytics.trackPaymentOpen();
  },
  next: (params: PhaseHookArgs) => {
    params.loadingHandlers.turnOn();
    params.inputBlockHandlers.turnOn();

    const customer = params.cartModel.getCustomer();

    const shouldShowMarketingConsents =
      customer.getShouldShowEmailPromotionConsent() || customer.getShouldShowSmsPromotionConsent();

    if (shouldShowMarketingConsents) {
      params.loadingHandlers.turnOff();
      params.inputBlockHandlers.turnOff();
      params.setCheckoutPhase('marketingConsents');
      return;
    }

    collectPaymentOrShowCardInput(params);
  },
  cancel: (params: PhaseHookArgs) => {
    params.setCheckoutPhase('initial');
  },
};
