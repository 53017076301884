import { Extension } from '@water-web/types';

import { BaseModel } from './base';

export class ExtensionModel extends BaseModel<ExtensionModel> {
  protected dataValues: Readonly<Extension>;

  constructor(extension: Extension) {
    super();

    this.dataValues = {
      ...extension,
    };
  }

  getBrandRoute(): string {
    return this.dataValues.route as string;
  }

  getDataValues(): Readonly<Extension> {
    return this.dataValues;
  }

  toJson(): Record<string, unknown> {
    return { ...this.dataValues };
  }
}
