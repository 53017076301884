import { motion } from 'framer-motion';
import styled from 'styled-components';
import { PropsWithChildren, ComponentType } from 'react';

import { Box, BoxProps } from '../../atoms';

interface ScalePresenceProps extends BoxProps {
  as?: string | ComponentType;
  $animateEnter?: boolean;
  $animateExit?: boolean;
  $duration?: number;
  $enterDuration?: number;
  $exitDuration?: number;
  $delay?: number;
  $enterDelay?: number;
  $exitDelay?: number;
  [key: string]: unknown;
}

const AnimationContainer = styled(Box).attrs<ScalePresenceProps>((props) => ({
  as: typeof props.as === 'string' ? motion[props.as] : motion(props.as, { forwardMotionProps: true }),
  initial: {
    opacity: props.$animateEnter ? 0 : 1,
    scale: props.$animateEnter ? 0.8 : 1,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: props.$animateExit ? 0 : 1,
    scale: props.$animateExit ? 0.8 : 1,
    transition: {
      duration: props.$exitDuration ?? props.$duration ?? props.theme.animations.booking.pageTransition.duration,
      delay: props.$exitDelay ?? props.$delay ?? 0,
    },
  },
  transition: {
    duration: props.$enterDuration ?? props.$duration ?? props.theme.animations.booking.pageTransition.duration,
    delay: props.$enterDelay ?? props.$delay ?? 0,
  },
}))<ScalePresenceProps>``;

export const ScalePresence = (props: PropsWithChildren<ScalePresenceProps>) => {
  return <AnimationContainer {...props} />;
};

ScalePresence.defaultProps = {
  $animateEnter: true,
  $animateExit: true,
  as: 'div',
};
