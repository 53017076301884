import { persistentKVStore } from '../persistence';
import { TTLGuard } from './TTLGuard';

const IS_SSR = typeof window === 'undefined';

/**
 * Save anything on LocalStorage with a limited lifespan
 */
export class LocalStorageTTL<T = any> {
  protected key: string;
  protected guard: TTLGuard<T>;

  constructor({ key, ttl = 30 }: { key: string; /* Lifespan in days */ ttl?: number }) {
    this.key = key;
    this.guard = new TTLGuard<T>(ttl);
  }

  /**
   * Will return `null` if there's no record, it has expired, or parsing failed.
   * Will try to remove record if it has expired or parsing failed.
   */
  get(): T | null {
    if (IS_SSR) {
      return null;
    }

    try {
      const containerString = persistentKVStore.getItem(this.key);
      if (!containerString) {
        return null;
      }

      const container = JSON.parse(containerString);
      const { data, expiredOrMalformed } = this.guard.extract(container);
      if (expiredOrMalformed) {
        this.remove();
        return null;
      }

      return data;
    } catch (e) {
      // if only there was a way to log an error
      return null;
    }
  }

  set(data: T): void {
    if (IS_SSR) {
      return;
    }

    persistentKVStore.setItem(this.key, JSON.stringify(this.guard.pack(data)));
  }

  remove(): void {
    if (IS_SSR) {
      return;
    }

    persistentKVStore.removeItem(this.key);
  }
}
