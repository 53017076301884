import { QueryClient } from 'react-query';
import { Appointment } from '@water-web/types';

import { EndpointMutation } from '../../request';
import { queryKeys } from './queryKeys';

interface Payload {
  id: string;
  appointmentId: string;
  cancelGroup?: boolean;
}

export class SaleOrderAppointmentRefundMutation extends EndpointMutation<Appointment, Payload> {
  constructor(baseUrl: string) {
    super();
    this.baseUrl = baseUrl;
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Appointment> {
    return this.http.post(
      `/v1/cancel-appointment/${payload.appointmentId}`,
      { cancelGroup: payload.cancelGroup },
      { signal, resourceUrl: '/cancel-appointment/:id' },
    );
  }

  async afterMutationSuccess(queryClient: QueryClient, data: Appointment, payload: Payload): Promise<void> {
    // the response is the updated ValidatedCart['items'][*]['appointment'] object,
    // but I suspect the whole `sale order` should be invalidated, because top-level props could get affected
    await queryClient.invalidateQueries(queryKeys.saleOrder({ id: payload.id }));
  }
}
