/**
 * Only use if characters are 1-byte long.
 */
export const getAbbreviation = (word: string, maxLetters = Infinity): string => {
  return (word || '')
    .split(' ')
    .map((item) => item.charAt(0).toUpperCase())
    .join('')
    .slice(0, maxLetters);
};

/**
 * Only use if characters are 1-byte long.
 */
export const truncate = (str: string, maxLength = Infinity, endingStr = '...'): string => {
  if (!str || str.length <= maxLength) {
    return str;
  }
  return `${str.substr(0, maxLength - endingStr.length)}${endingStr}`;
};

/**
 * Only use if 1st character is 1-byte long.
 * Otherwise, use `<Text textTransform="capitalize">`
 */
export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
