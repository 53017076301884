import { KVStore } from './kv-store';
import { LocalStorageStrategy, SessionStorageStrategy, InMemoryStorage } from './strategies';

const localStorageStrategy = new LocalStorageStrategy();
const sessionStorageStrategy = new SessionStorageStrategy();
const inMemoryStorageStrategy = new InMemoryStorage();

/**
 * persistentKVStore prioritizes data persistence across browser sessions.
 * It tries to use localStorage, falling back to sessionStorage, then in-memory storage if necessary.
 * Ideal for long-term data like user preferences or auth tokens.
 * Use as a direct replacement for localStorage.
 */
export const persistentKVStore = new KVStore([localStorageStrategy, sessionStorageStrategy, inMemoryStorageStrategy]);

/**
 * sessionKVStore is designed for temporary session-based data storage.
 * It primarily uses sessionStorage, with fallbacks to in-memory storage and then localStorage.
 * Suitable for data that should not persist beyond the current session, like temporary form data.
 * Use as a direct replacement for sessionStorage.
 */
export const sessionKVStore = new KVStore([sessionStorageStrategy, inMemoryStorageStrategy, localStorageStrategy]);

/**
 * inMemoryKVStore is a lightweight, in-memory-only storage.
 * It does not persist data between page reloads or sessions.
 * Use for temporary data that does not need to be saved between page loads and does not require UI updates.
 */
export const inMemoryKVStore = new KVStore([inMemoryStorageStrategy]);
