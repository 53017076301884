import { useContext, PropsWithChildren } from 'react';

import { CookieConsentContext } from '../../../contexts';

// wrap everything that needs to be protected by cookie consent with it
export const RenderWhenCookieAccepted = (props: PropsWithChildren) => {
  const consent = useContext(CookieConsentContext);

  if (!consent || !consent.isSectionAccepted('statistics')) {
    return null;
  }

  return <>{props.children}</>;
};
