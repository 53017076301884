import {
  HttpResponse,
  CartApi as ICartApi,
  ValidatedCart,
  ValidateCartPayload,
  CheckoutCartPayload,
  AddToWaitingListPayload,
  WaitingList,
  GiftCardSalePayload,
  GiftCard,
  DiscountInfo,
  ValidateCustomerPayload,
  ValidateCustomerResponse,
  isInvalidPromo,
  WithHttpPlatform,
  GetDiscountByCodePayload,
} from '@water-web/types';

import { SERVICE_ID_V2_HEADER_MIXIN, buildBotProtectionHeaders } from '../utils';
import { Namespace } from './namespace';

export class CartApi extends Namespace implements ICartApi {
  create(shopId: string, data: ValidateCartPayload): Promise<HttpResponse<ValidatedCart>> {
    return this.httpV2.post<ValidatedCart, WithHttpPlatform<ValidateCartPayload>>(
      `/shop/${shopId}/cart`,
      this.httpV2.attachPlatform(data),
      {
        headers: SERVICE_ID_V2_HEADER_MIXIN,
        resourceUrl: '/shop/:id/cart',
      },
    );
  }

  update(shopId: string, cartId: string, data: ValidateCartPayload): Promise<HttpResponse<ValidatedCart>> {
    return this.httpV2.put<ValidatedCart, WithHttpPlatform<ValidateCartPayload>>(
      `/shop/${shopId}/cart/${cartId}`,
      this.httpV2.attachPlatform(data),
      {
        headers: SERVICE_ID_V2_HEADER_MIXIN,
        resourceUrl: '/shop/:shopId/cart/:cartId',
      },
    );
  }

  fetch(shopId: string, cartId: string): Promise<HttpResponse<ValidatedCart>> {
    return this.httpV2.get<ValidatedCart>(`/shop/${shopId}/cart/${cartId}`, {
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:shopId/cart/:cartId',
    });
  }

  validate(shopId: string, data: ValidateCartPayload): Promise<HttpResponse<ValidatedCart>> {
    return this.httpV2.post<ValidatedCart, WithHttpPlatform<ValidateCartPayload>>(
      `/shop/${shopId}/sale/validate`,
      this.httpV2.attachPlatform(data),
      {
        headers: SERVICE_ID_V2_HEADER_MIXIN,
        resourceUrl: '/shop/:id/sale/validate',
      },
    );
  }

  checkout(
    shopId: string,
    cartId: string,
    data: CheckoutCartPayload,
    botProtectionToken?: string,
  ): Promise<HttpResponse<ValidatedCart>> {
    return this.httpV2.post<ValidatedCart, WithHttpPlatform<CheckoutCartPayload>>(
      `/shop/${shopId}/cart/${cartId}/checkout`,
      this.httpV2.attachPlatform(data),
      {
        headers: {
          ...SERVICE_ID_V2_HEADER_MIXIN,
          ...buildBotProtectionHeaders(botProtectionToken),
        },
        resourceUrl: '/shop/:shopId/cart/:cartId/checkout',
      },
    );
  }

  addToWaitingList(data: AddToWaitingListPayload): Promise<HttpResponse<WaitingList>> {
    return this.http.post<WaitingList, WithHttpPlatform<AddToWaitingListPayload>>(
      '/customer/waiting-list',
      this.http.attachPlatform(data),
      {
        headers: { ...SERVICE_ID_V2_HEADER_MIXIN },
        resourceUrl: '/customer/waiting-list',
      },
    );
  }

  sellGiftCard(shopId: string, data: GiftCardSalePayload): Promise<HttpResponse<GiftCard>> {
    return this.http.post<GiftCard, WithHttpPlatform<GiftCardSalePayload>>(
      `/shop/${shopId}/customer-gift-card`,
      this.http.attachPlatform(data),
      {
        resourceUrl: '/shop/:shopId/customer-gift-card',
      },
    );
  }

  // don't be misled by the promoCode name. In practice, it may be promo/loyalty/gift cart
  async getDiscountByCode(payload: GetDiscountByCodePayload): Promise<HttpResponse<DiscountInfo>> {
    const response = await this.http.post<DiscountInfo, GetDiscountByCodePayload>('/appointment/validate', payload, {
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/appointment/validate',
    });

    if (response.error) {
      return response;
    }

    // API is inconsistent and returns a 400 error when checking invalid promo code without a list of services
    // and if a list of service ids passed, it's going to return 200 with an error in promo object
    // we hide this inconsistency under the API layer and simulate the same error
    if (isInvalidPromo(response.data.promo)) {
      return {
        data: null,
        error: {
          status: 400,
          code: 'Bad Request',
          message: response.data.promo.error,
        },
      };
    }

    return response;
  }

  // check if client is marked as prepay only
  validateCustomer(data: ValidateCustomerPayload): Promise<HttpResponse<ValidateCustomerResponse>> {
    const payload = {
      shopId: data.shopId,
      barberId: data.barberId,
      ...data.customer,
      validatePrepayOnly: true,
    };
    return this.http.post('/appointment/validate', payload, {
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/appointment/validate',
    });
  }
}
