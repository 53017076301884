import { Box, Translate } from '@water-web/view';
import { PropsWithChildren } from 'react';

import { AppLocale } from '@app/i18n';
import { Text } from '@app/components/atoms';
import { InfoCircled } from '@app/components/atoms/Icon/InfoCircled';

import { Tooltip } from '../Tooltip';
import { IconContainer } from './styled';

const TextWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Text.P5Bold as={Box} selected textTransform="uppercase" textAlign="left" px={3} py={2} width="140px">
      {children}
    </Text.P5Bold>
  );
};

const IconWithTooltip = ({ children }: PropsWithChildren) => {
  return (
    <Tooltip
      placement="bottom"
      tooltipAlignment="center"
      arrowAlignment="end"
      arrowShift={-33}
      tooltipOffset={4}
      tooltipShift={-25}
      spaceToRightSide={0}
      borderRadius="8px"
      tooltip={<TextWrapper>{children}</TextWrapper>}
      fadeOnly
    >
      <IconContainer>
        <InfoCircled />
      </IconContainer>
    </Tooltip>
  );
};

export const ShopPartialTranslation = (props: { locale: AppLocale }) => {
  return (
    <IconWithTooltip>
      <Translate i18nKey={`pages.shop.thisShopNotFullTranslation.${props.locale}`} />
    </IconWithTooltip>
  );
};
