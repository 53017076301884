import { useQuery, UseQueryResult, QueryKey } from 'react-query';

import { HttpError } from '../transport';
import { EndpointQuery } from '../request';
import { UseEndpointQueryOptions } from './types';

interface ApiQueryConfig<
  ConcreteEndpointQuery extends EndpointQuery<
    Awaited<ReturnType<ConcreteEndpointQuery['request']>>,
    Parameters<ConcreteEndpointQuery['request']>[0]
  >,
  DataModel = Awaited<ReturnType<ConcreteEndpointQuery['request']>>,
> {
  endpoint: ConcreteEndpointQuery;
  payload?: Parameters<ConcreteEndpointQuery['request']>[0];
  options?: UseEndpointQueryOptions<ConcreteEndpointQuery, DataModel>;
}

export const useApiQuery = <
  ConcreteEndpointQuery extends EndpointQuery<
    Awaited<ReturnType<ConcreteEndpointQuery['request']>>,
    Parameters<ConcreteEndpointQuery['request']>[0]
  >,
  DataModel = Awaited<ReturnType<ConcreteEndpointQuery['request']>>,
>(
  queryConfig: ApiQueryConfig<ConcreteEndpointQuery, DataModel>,
): UseQueryResult<DataModel, HttpError> => {
  const endpointOptions = queryConfig.endpoint.getMeta();
  const queryOptions: (typeof queryConfig)['options'] = queryConfig.options || {};
  const combinedQueryOptions: (typeof queryConfig)['options'] = {
    ...endpointOptions, // order matters because we let hook options override endpoint options
    ...queryOptions,
  };

  const queryKey: QueryKey = queryConfig.endpoint.getKey(queryConfig.payload);

  return useQuery(
    queryKey,
    ({ signal }) => queryConfig.endpoint.request(queryConfig.payload, signal),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    combinedQueryOptions as any,
  );
};
