import { createGlobalStyle } from 'styled-components';

const DocumentStyles = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    
  }
  
  html {
    box-sizing: border-box;
    font-size: 16px;
    background-color: transparent;
  }
  
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: "SF Pro Text", "Roboto", sans-serif;
    background: ${({ theme }) => theme.colors.chrome};
  }
  
  #root {
    position: relative;
    background: ${({ theme }) => theme.colors.chrome};
    @media screen and (min-width: 376px) {
      width: min(100%, 375px);
      margin: 0 auto;
    }
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
    font-family: inherit;
    outline: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  button, input {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* disable 300ms click delay on touch devices, that exists to enable zoom by double-tap */
  [role="button"] {
      touch-action: manipulation;
  }
`;

export default DocumentStyles;
