import {
  Stripe,
  StripeCardElement,
  CreateTokenCardData,
  PaymentRequestOptions,
  PaymentRequestTokenEvent,
  StripeElements,
  StripeError,
} from '@stripe/stripe-js';

import { BaseProcessor } from '../base';
import { StripeDigitalWalletPaymentResult, StripePaymentResult, StripeProcessorConfig, StripeUser } from './types';

export class StripeProcessor extends BaseProcessor {
  protected type = 'stripe';

  constructor(config: StripeProcessorConfig) {
    super(config);

    this.stripe = config.stripe;
  }

  private stripe: Stripe = null;

  private createPaymentRequestInfo(label: string, amount: number): PaymentRequestOptions {
    return {
      country: this.country,
      currency: this.currency,
      total: { label, amount },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    };
  }

  async isGooglePayAvailable(): Promise<boolean> {
    const testAmount = 100;
    const paymentRequestInfo = this.createPaymentRequestInfo('Availability check', testAmount);
    const paymentRequest = this.stripe.paymentRequest(paymentRequestInfo);
    let canMakePaymentPromise = null;

    try {
      canMakePaymentPromise = paymentRequest.canMakePayment().then((result) => {
        return !!result && !result.applePay;
      });
    } catch {
      return false;
    }

    const results = await Promise.all([canMakePaymentPromise, super.isGooglePayAvailable()]);
    return results.every(Boolean);
  }

  async isApplePayAvailable(): Promise<boolean> {
    const testAmount = 100;
    const paymentRequestInfo = this.createPaymentRequestInfo('Availability check', testAmount);
    const paymentRequest = this.stripe.paymentRequest(paymentRequestInfo);
    let canMakePaymentPromise = null;

    try {
      canMakePaymentPromise = paymentRequest.canMakePayment().then((result) => {
        return !!result?.applePay;
      });
    } catch {
      return false;
    }

    const results = await Promise.all([canMakePaymentPromise, super.isApplePayAvailable()]);
    return results.every(Boolean);
  }

  /**
   * @deprecated Use payWithElements instead
   */
  async payWithCard(cardElement: StripeCardElement): Promise<StripePaymentResult> {
    const options: CreateTokenCardData = {
      currency: this.currency,
      address_country: this.country,
    };

    const { token, error } = await this.stripe.createToken(cardElement, options);
    const payload = token ? { paymentToken: token.id } : null;
    return {
      token,
      payload,
      error: error || null,
      processor: this.getType(),
    };
  }

  /**
   * @description Takes the elements object and handles the payment process for whatever element is present
   */
  async payWithElements(elements: StripeElements): Promise<StripePaymentResult> {
    const { error: integrationError } = this.validateElementsIntegration(elements);
    if (integrationError) {
      return {
        token: null,
        payload: null,
        error: integrationError,
        processor: this.getType(),
      };
    }

    const cardElement = elements.getElement('card');
    if (cardElement) {
      return this.payWithCard(cardElement);
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      return {
        token: null,
        payload: null,
        error: submitError,
        processor: this.getType(),
      };
    }

    const { error, paymentMethod: token } = await this.stripe.createPaymentMethod({
      elements,
    });

    const payload = token ? { paymentToken: token.id } : null;
    return {
      token,
      payload,
      error: error || null,
      processor: this.getType(),
    };
  }

  /**
   * Pay with Apple Pay or Google Pay
   */
  async payWithDigitalWallet(label: string, amount: number): Promise<StripeDigitalWalletPaymentResult> {
    const paymentRequestInfo = this.createPaymentRequestInfo(label, amount);
    const paymentRequest = this.stripe.paymentRequest(paymentRequestInfo);
    const canMakePayment = await paymentRequest.canMakePayment();

    return new Promise((resolve) => {
      if (!canMakePayment) {
        resolve({
          token: null,
          payload: null,
          error: {
            type: 'validation_error',
            message: 'This payment method is not available',
          },
          cancelled: false,
          processor: this.getType(),
        });
      }

      paymentRequest.show();

      paymentRequest.on('token', (event) => {
        resolve({
          token: event.token,
          payload: {
            paymentToken: event.token.id,
            customer: this.getUserFromTokenEvent(event),
          },
          error: null,
          cancelled: false,
          processor: this.getType(),
        });

        event.complete('success');
      });

      paymentRequest.on('cancel', () => {
        resolve({
          token: null,
          payload: null,
          error: null,
          cancelled: true,
          processor: this.getType(),
        });
      });
    });
  }

  private getUserFromTokenEvent(event: PaymentRequestTokenEvent): StripeUser {
    const name = event.payerName.trim();
    const splitIdx = name.indexOf(' ');
    const firstName = name.substring(0, splitIdx);
    const lastName = name.substring(splitIdx + 1);

    return {
      firstName,
      lastName,
      phone: event.payerPhone,
      email: event.payerEmail,
    };
  }

  private validateElementsIntegration(elements?: StripeElements): { error: StripeError | null } {
    const cardElement = elements?.getElement('card');
    const paymentElement = elements?.getElement('payment');
    const hasAtLeastOneElement = !!cardElement || !!paymentElement;

    if (!hasAtLeastOneElement) {
      return {
        error: {
          message: 'Integration error',
          type: 'api_error',
        },
      };
    }

    return {
      error: null,
    };
  }
}
