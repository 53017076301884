interface InvalidPromo {
  error: string;
}

type PromoInfo = {
  validationNote?: string;
};

export const isInvalidPromo = (promo: PromoInfo | InvalidPromo): promo is InvalidPromo => {
  return 'error' in promo;
};

export interface DiscountInfo {
  giftCard: {
    amount?: number;
    remainingAmount?: number;
    applyMetadata?: {
      giftCardTipsEnabled: boolean;
      coversTotal: boolean;
      coversOnlyCost: boolean;
      coversTipAndPartOfCost: boolean;
    };
  };
  promo: InvalidPromo | PromoInfo;
}
