import { useEffect, useRef } from 'react';

interface UseTimeoutsResult {
  add: (fn: () => void, ms?: number) => number;
  remove: (id: number) => void;
}

/**
 * Set timeouts safely, they'll be cleared on hook destruction
 *
 * @example
 * const Popup = ({onSelect, closeSelf}) => {
 *   const timeouts = useTimeouts(); // use hook
 *
 *   const onClick = () => {
 *      // use callback immediately
 *     onSelect();
 *
 *     // will call `closeSelf` later
 *     // if Popup was closed by other means, timeout will be cleared
 *     timeouts.add(closeSelf, 5000);
 *   };
 *
 *   return (
 *     <button onClick={onClick}> Click me! </button>
 *   );
 * }
 */
export const useTimeouts = (): UseTimeoutsResult => {
  const timeouts = useRef(new Set<number>());
  const methods = useRef<UseTimeoutsResult>({
    add: (fn, ms = 0) => {
      const id = setTimeout(() => {
        timeouts.current.delete(id);
        fn();
      }, ms) as unknown as number;
      return id;
    },
    remove: (id) => {
      if (timeouts.current.has(id)) {
        clearTimeout(id);
        timeouts.current.delete(id);
      }
    },
  });

  useEffect(() => () => {
    timeouts.current.forEach((id) => {
      clearTimeout(id);
    });
  });

  return methods.current;
};
