import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { ShopModel, BarberModel } from '@water-web/repository';

import { RepositoryContext, AppStateContext } from '../../contexts';

export interface UseShopBarbersResponse {
  value: BarberModel[] | null;
  loading: boolean;
}

export const useShopBarbers = (shop: ShopModel): UseShopBarbersResponse => {
  const { barberRepository } = useContext(RepositoryContext);
  const appState = useContext(AppStateContext);
  const [loading, setLoading] = useState(false);
  const [shopBarbers, setShopBarbers] = useState(shop.getBarbers() || []);

  const load = useCallback(async () => {
    if (shop.getBarbers()) {
      setShopBarbers(shop.getBarbers());
      return;
    }

    setLoading(true);
    appState.addAppLoader();
    const result = await barberRepository.getAllByShopIdOrRoute(shop.getId());
    setLoading(false);
    appState.removeAppLoader();

    if (!result.error) {
      setShopBarbers(result.data);
    }
  }, [barberRepository, shop.getId(), appState.addAppLoader, appState.removeAppLoader]);

  useEffect(() => {
    load();
  }, [load]);

  return useMemo(
    () => ({
      loading,
      value: shopBarbers,
    }),
    [loading, shopBarbers],
  );
};
