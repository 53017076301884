import {
  Shop,
  HttpResponse,
  ShopApiSearch,
  ShopApi as IShopApi,
  ShopApiTimeSearch,
  ScheduleTimeRangeDay,
  Brand,
  RelatedDates,
  ScheduleTimeRangeDayWithConflicts,
} from '@water-web/types';

import { SERVICE_ID_V2_HEADER_MIXIN } from '../utils';
import { Namespace } from './namespace';

export class ShopApi extends Namespace implements IShopApi {
  // IG images are missing in the /shop/:idOrRoute endpoint
  // so we make a hack to get IG images from the brand home endpoint till API is not updated
  async getByIdOrRoute(idOrRoute: string, search?: ShopApiSearch, ignoreInsta?: boolean): Promise<HttpResponse<Shop>> {
    const pureShopResponse = await this.http.get<Shop>(`/shop/${idOrRoute}`, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:id',
    });
    if (pureShopResponse.error) {
      return pureShopResponse;
    }

    if (ignoreInsta) {
      pureShopResponse.data.instagramImages = [];
    } else {
      const { id: shopId, brandId } = pureShopResponse.data;
      const brandHomeWithSingleShopResponse = await this.http.get<{ brand: Brand }>(`/home/${brandId}`, {
        search: { shopId, nonSquireEcosystem: 'true', ignoreShowOnCustomApp: 'true' },
        resourceUrl: '/home/:id',
      });

      if (brandHomeWithSingleShopResponse.error) {
        return {
          data: null,
          error: brandHomeWithSingleShopResponse.error,
        };
      }

      pureShopResponse.data.instagramImages =
        brandHomeWithSingleShopResponse.data.brand.shops[0]?.instagramImages ?? [];
    }

    return pureShopResponse;
  }

  getScheduleTimeRange(
    idOrRoute: string,
    start: string,
    end: string,
    services: string[],
    search?: ShopApiTimeSearch,
  ): Promise<HttpResponse<ScheduleTimeRangeDay[] | ScheduleTimeRangeDayWithConflicts[]>> {
    const path = `/shop/${idOrRoute}/schedule-time-range/${start}/${end}/${services.join(';')}`;
    return this.http.get<ScheduleTimeRangeDay[] | ScheduleTimeRangeDayWithConflicts[]>(path, {
      search,
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:id/schedule-time-range/:start/:end/:services',
    });
  }

  /**
   * Get dates, when there's any shop barber, that can provide a given service on a given time
   */
  getRelatedDates(idOrRoute: string, dateTime: string, services: string[]): Promise<HttpResponse<RelatedDates>> {
    const path = `/shop/${idOrRoute}/related-dates/${dateTime}/${services.join(';')}`;
    return this.http.get(path, {
      headers: SERVICE_ID_V2_HEADER_MIXIN,
      resourceUrl: '/shop/:id/related-dates/:dateTime/:services',
    });
  }
}
