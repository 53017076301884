import { useCallback, useMemo, useState, useRef, useEffect } from 'react';

interface ErrorConfig {
  message?: string;
  autoClose?: boolean;
  autoCloseTimeout?: number;
}

const defaultConfig: ErrorConfig = {
  message: '',
  autoClose: true,
  autoCloseTimeout: 3000,
};

export interface ErrorHandlers {
  set: (config: ErrorConfig) => void;
  reset: () => void;
  active: boolean;
  message: string;
}

export const useErrorHandlers = (): ErrorHandlers => {
  const [error, setError] = useState<ErrorConfig>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const clearErrorTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const reset = useCallback(() => {
    clearErrorTimeout();
    setError(null);
  }, []);

  const set = useCallback((config = defaultConfig) => {
    setError((state) => {
      if (state && state.message === config.message) {
        return state;
      }

      clearErrorTimeout();
      const isAutoClosable = config?.autoClose ?? defaultConfig.autoClose;
      if (isAutoClosable) {
        timeoutRef.current = setTimeout(reset, config?.autoCloseTimeout ?? defaultConfig.autoCloseTimeout);
      }

      return config;
    });
  }, []);

  useEffect(() => {
    return () => clearErrorTimeout();
  }, []);

  return useMemo(() => {
    return {
      set,
      reset,
      active: !!error,
      message: error?.message ?? '',
    };
  }, [error]);
};
