import { useContext } from 'react';
import { AppStateContext, Translate } from '@water-web/view';

import { Button, Text } from '@app/components/atoms';
import { CartModeItemProps } from '@app/contexts/CartController/types';

import { useChooseTimeCallback } from './useChooseTimeCallback';

export const AddonsControls = (props: CartModeItemProps) => {
  const appState = useContext(AppStateContext);
  const chooseTime = useChooseTimeCallback(props.cart);
  const reservations = props.cart.getReservations();
  const canProceed = reservations.every((r) => !!r.getService());

  if (!canProceed) {
    return null;
  }

  return (
    <Button
      height="48px"
      width="100%"
      $testId="btn:chooseTime"
      onClick={chooseTime}
      disabled={!appState.cart.interactive}
    >
      <Text.P2Bold>
        <Translate i18nKey="components.cart.controls.chooseTime" />
      </Text.P2Bold>
    </Button>
  );
};
