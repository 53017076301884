import {
  HttpResponse,
  SaleOrderApi as ISaleOrderApi,
  ValidatedCart,
  ReschedulePayload,
  RescheduledAppointment,
} from '@water-web/types';

import { buildBotProtectionHeaders } from '../utils';
import { Namespace } from './namespace';

export class SaleOrderApi extends Namespace implements ISaleOrderApi {
  getById(id: string): Promise<HttpResponse<ValidatedCart>> {
    return this.http.get<ValidatedCart>(`/customer-sale/${id}`, {
      resourceUrl: '/customer-sale/:id',
    });
  }

  refundItem(itemId: string, saleOrderId: string): Promise<HttpResponse<ValidatedCart>> {
    return this.http.post<ValidatedCart, void>(`/customer-sale/${saleOrderId}/refund/${itemId}`, undefined, {
      resourceUrl: '/customer-sale/:saleOrderId/refund/:itemId',
    });
  }

  rescheduleItem(
    appointmentId: string,
    data: ReschedulePayload,
    botProtectionToken?: string,
  ): Promise<HttpResponse<RescheduledAppointment>> {
    return this.http.put<RescheduledAppointment, ReschedulePayload>(`/reschedule-appointment/${appointmentId}`, data, {
      headers: {
        ...buildBotProtectionHeaders(botProtectionToken),
      },
      resourceUrl: '/reschedule-appointment/:id',
    });
  }
}
