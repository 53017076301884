export const StripeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16">
      <g>
        <path
          d="M223.6,166.9c0-19.6,16.3-27.2,42.5-27.2c38.1,0,86.6,11.7,124.7,32.2v-118C349.3,37.3,307.8,31,266.2,31 C164.6,31,96.8,84.1,96.8,172.8c0,138.8,190.5,116.3,190.5,176.1c0,23.2-20.1,30.7-48.1,30.7c-41.5,0-95.1-17.1-137.2-40v112.9 c43.3,18.7,89.9,28.4,137.1,28.5c104.2,0,176-44.8,176-135C415.2,196.5,223.6,223.3,223.6,166.9L223.6,166.9z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export const CloudflareIcon = () => {
  return (
    <svg width="16" height="8" viewBox="0 0 256 116" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M176.332,109.3483 C177.925,104.0373 177.394,98.7263 174.739,95.5393 C172.083,92.3523 168.365,90.2283 163.585,89.6973 L71.17,88.6343 C70.639,88.6343 70.108,88.1033 69.577,88.1033 C69.046,87.5723 69.046,87.0413 69.577,86.5103 C70.108,85.4483 70.639,84.9163 71.701,84.9163 L164.647,83.8543 C175.801,83.3233 187.486,74.2943 191.734,63.6723 L197.046,49.8633 C197.046,49.3313 197.577,48.8003 197.046,48.2693 C191.203,21.1823 166.772,0.9993 138.091,0.9993 C111.535,0.9993 88.697,17.9953 80.73,41.8963 C75.419,38.1783 69.046,36.0533 61.61,36.5853 C48.863,37.6473 38.772,48.2693 37.178,61.0163 C36.647,64.2033 37.178,67.3903 37.71,70.5763 C16.996,71.1073 0,88.1033 0,109.3483 C0,111.4723 0,113.0663 0.531,115.1903 C0.531,116.2533 1.593,116.7843 2.125,116.7843 L172.614,116.7843 C173.676,116.7843 174.739,116.2533 174.739,115.1903 L176.332,109.3483 Z"
        fill="black"
      />
      <path
        d="M205.5436,49.8628 L202.8876,49.8628 C202.3566,49.8628 201.8256,50.3938 201.2946,50.9248 L197.5766,63.6718 C195.9836,68.9828 196.5146,74.2948 199.1706,77.4808 C201.8256,80.6678 205.5436,82.7918 210.3236,83.3238 L229.9756,84.3858 C230.5066,84.3858 231.0376,84.9168 231.5686,84.9168 C232.0996,85.4478 232.0996,85.9788 231.5686,86.5098 C231.0376,87.5728 230.5066,88.1038 229.4436,88.1038 L209.2616,89.1658 C198.1076,89.6968 186.4236,98.7258 182.1746,109.3478 L181.1116,114.1288 C180.5806,114.6598 181.1116,115.7218 182.1746,115.7218 L252.2826,115.7218 C253.3446,115.7218 253.8756,115.1908 253.8756,114.1288 C254.9376,109.8798 255.9996,105.0998 255.9996,100.3188 C255.9996,72.7008 233.1616,49.8628 205.5436,49.8628"
        fill="black"
      />
    </svg>
  );
};

export const GoogleIcon = () => {
  return (
    <svg widths="16" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#000"
        stroke="none"
        style={{ fill: 'var(--fill, #000)' }}
        d="m15.9914 8.1497c0-.65551-.0545-1.13386-.1723-1.62992h-7.65992v2.95865h4.49622c-.0906.73527-.5801 1.84257-1.6679 2.58667l-.0153.099 2.4219 1.8335.1678.0163c1.5411-1.3907 2.4295-3.437 2.4295-5.8642z"
      />
      <path
        fill="#000"
        stroke="none"
        style={{ fill: 'var(--fill, #000)' }}
        d="m8.15943 15.9451c2.20277 0 4.05207-.7087 5.40277-1.9311l-2.5745-1.9489c-.6889.4695-1.61358.7972-2.82827.7972-2.15747 0-3.9886-1.3907-4.64135-3.31298l-.09568.00794-2.518372 1.90454-.032934.0895c1.341616 2.6043 4.097406 4.3938 7.288336 4.3938z"
      />
      <path
        fill="#000"
        stroke="none"
        style={{ fill: 'var(--fill, #000)' }}
        d="m3.51818 9.54912c-.17224-.49606-.27191-1.02761-.27191-1.5768 0-.54925.09967-1.08073.26284-1.57679l-.00456-.10565-2.549922-1.93514-.083429.03878c-.552943 1.08073-.87022244 2.29435-.87022244 3.5788 0 1.28446.31727944 2.49798.87022244 3.57878z"
      />
      <path
        fill="#000"
        stroke="none"
        style={{ fill: 'var(--fill, #000)' }}
        d="m8.1594 3.08269c1.53197 0 2.5654.64665 3.1546 1.18705l2.3025-2.19689c-1.4141-1.284453-3.2543-2.07285-5.4571-2.07285-3.19092 0-5.94669 1.78937-7.288306 4.39371l2.637916 2.00201c.66181-1.92225 2.49293-3.31303 4.65039-3.31303z"
      />
    </svg>
  );
};

export const FacebookIcon = () => {
  return (
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.63845 0H3.72062C2.58252 0 1.31663 0.478669 1.31663 2.12839C1.32219 2.70322 1.31663 3.25373 1.31663 3.8733H0V5.96844H1.35737V12H3.85162V5.92864H5.4979L5.64686 3.86742H3.80864C3.80864 3.86742 3.81275 2.9505 3.80864 2.68423C3.80864 2.03229 4.487 2.06963 4.5278 2.06963C4.85061 2.06963 5.47827 2.07057 5.63939 2.06963V0H5.63845Z"
        fill="black"
      />
    </svg>
  );
};

export const ArrowIcon = (props: { className?: string }) => {
  return (
    <svg className={props.className} width="13" height="7" viewBox="0 0 13 7" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.64941 6.98438C6.92236 6.98438 7.15723 6.87646 7.3667 6.66699L12.1211 1.79199C12.2861 1.62695 12.375 1.42383 12.375 1.18262C12.375 0.6875 11.9814 0.293945 11.4927 0.293945C11.2515 0.293945 11.0229 0.38916 10.8452 0.566895L6.65576 4.8833L2.45996 0.566895C2.28223 0.395508 2.06006 0.293945 1.8125 0.293945C1.32373 0.293945 0.930176 0.6875 0.930176 1.18262C0.930176 1.42383 1.01904 1.62695 1.18408 1.79834L5.93848 6.66699C6.16064 6.88281 6.38281 6.98438 6.64941 6.98438Z" />
    </svg>
  );
};
