import { useState } from 'react';

import { cookieSections } from '../cookies';
import { CookiesSection } from './CookiesSection';
import { SubsectionDetails } from './SubsectionDetails';
import { SelectedCookieSections } from '../../../../types';
import { DetailsTabParentContainer, DetailsTabScrollContainer } from './styles';

interface DetailsTabProps {
  selectedSections: SelectedCookieSections;
  toggleSectionHandlers: (sectionKey: string) => () => SelectedCookieSections;
  expandedSections: SelectedCookieSections;
  toggleSectionExpandedHandlers: (sectionKey: string) => () => void;
}

export const DetailsTab = (props: DetailsTabProps) => {
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  return (
    <DetailsTabParentContainer>
      <DetailsTabScrollContainer>
        {selectedSubsection ? (
          <SubsectionDetails subsection={selectedSubsection} onGoBack={() => setSelectedSubsection(null)} />
        ) : (
          cookieSections.map((section) => (
            <CookiesSection
              key={section.key}
              section={section}
              isEnabled={props.selectedSections[section.key]}
              toggleEnabled={props.toggleSectionHandlers(section.key)}
              isExpanded={props.expandedSections[section.key]}
              toggleExpanded={props.toggleSectionExpandedHandlers(section.key)}
              onSelectSubsection={setSelectedSubsection}
            />
          ))
        )}
      </DetailsTabScrollContainer>
    </DetailsTabParentContainer>
  );
};
