import { useEffect } from 'react';

import { useDebounce } from '../../../hooks';

const defaultThrottleRate = 200;

export const useResize = (handler: () => unknown, throttleRate = defaultThrottleRate): void => {
  const debouncedHandleResize = useDebounce(handler, throttleRate);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);
};
