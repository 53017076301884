import { datadogLogs } from '@datadog/browser-logs';

import { ILogger } from '../logger';

export class DatadogLogger implements ILogger {
  private logToDatadog(method: keyof ILogger, message: string, context?: object): void {
    datadogLogs.logger[method](message, context);
  }

  log(message: string, context?: object): void {
    this.logToDatadog('log', message, context);
  }

  info(message: string, context?: object): void {
    this.logToDatadog('info', message, context);
  }

  warn(message: string, context?: object): void {
    this.logToDatadog('warn', message, context);
  }

  error(message: string, context?: object): void {
    this.logToDatadog('error', message, context);
  }
}
