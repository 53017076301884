import { useCallback, useMemo, useState } from 'react';

import {
  PopupPreset,
  PopupAlias,
  PopupPayloadByAlias,
  PopupComponentByAlias,
  PopupHandlerByAlias,
  PopupController,
  PopupHandler,
  ShowBackdrop,
} from './types';
import * as popupComponents from './popups';
import { preloadPopup } from './popups';

const usePopupHandlers = <A extends PopupAlias, P = PopupPayloadByAlias<A>>(
  type: A,
  updater: (t: A, p?: P) => void,
): (() => PopupHandler<P>) => {
  return useMemo(
    () => () => {
      preloadPopup(type);
      return {
        open: ((payload: P) => updater(type, payload)) as PopupHandler<P>['open'],
        close: () => updater(null, null),
        preload: () => preloadPopup(type),
      };
    },
    [],
  );
};

export const usePopupController = (): PopupController => {
  // one-at-a-time popup
  const [primaryPopup, setPrimaryPopup] = useState<PopupAlias>(null);
  const [primaryPopupPayload, setPrimaryPopupPayload] = useState(null);
  const primaryPopupUpdater = useCallback((type: PopupAlias, payload?: any) => {
    if (type) {
      setPrimaryPopupPayload(payload);
      setPrimaryPopup(type);
    } else {
      setPrimaryPopup(type);
      setPrimaryPopupPayload(payload);
    }
  }, []);

  // popup that can be placed over one-at-a-time popup
  const [secondaryPopup, setSecondaryPopup] = useState<PopupAlias>(null);
  const [secondaryPopupPayload, setSecondaryPopupPayload] = useState(null);
  const secondaryPopupUpdater = useCallback((type: PopupAlias, payload?: any) => {
    if (type) {
      setSecondaryPopupPayload(payload);
      setSecondaryPopup(type);
    } else {
      setSecondaryPopup(type);
      setSecondaryPopupPayload(payload);
    }
  }, []);

  const closeAll = useCallback(() => {
    primaryPopupUpdater(null, null);
    secondaryPopupUpdater(null, null);
  }, []);

  const locationInfo = usePopupHandlers('locationInfo', secondaryPopupUpdater);
  const barberInfo = usePopupHandlers('barberInfo', secondaryPopupUpdater);
  const locationRequest = usePopupHandlers('locationRequest', secondaryPopupUpdater);
  const locationDenied = usePopupHandlers('locationDenied', secondaryPopupUpdater);
  const phoneCode = usePopupHandlers('phoneCode', secondaryPopupUpdater);
  const policies = usePopupHandlers('policies', secondaryPopupUpdater);
  const checkoutForm = usePopupHandlers('checkoutForm', primaryPopupUpdater);
  const checkoutFormV2 = usePopupHandlers('checkoutFormV2', primaryPopupUpdater);
  const cardForm = usePopupHandlers('cardForm', secondaryPopupUpdater);
  const addCode = usePopupHandlers('addCode', secondaryPopupUpdater);
  const appLink = usePopupHandlers('appLink', primaryPopupUpdater);
  const barberPasscode = usePopupHandlers('barberPasscode', primaryPopupUpdater);
  const waitingListTimeSlots = usePopupHandlers('waitingListTimeSlots', primaryPopupUpdater);
  const waitingListReschedule = usePopupHandlers('waitingListReschedule', primaryPopupUpdater);
  const reschedule = usePopupHandlers('reschedule', primaryPopupUpdater);
  const cancelAppointment = usePopupHandlers('cancelAppointment', primaryPopupUpdater);

  const popups: {
    [A in PopupAlias]: [PopupComponentByAlias<A>, PopupPreset, () => PopupHandlerByAlias<A>, ShowBackdrop];
  } = {
    locationInfo: [popupComponents.LocationInfoPopup, 'bottom', locationInfo, true],
    barberInfo: [popupComponents.BarberInfoPopup, 'bottom', barberInfo, true],
    locationRequest: [popupComponents.LocationRequestPopup, 'bottom', locationRequest, true],
    locationDenied: [popupComponents.LocationDeniedPopup, 'bottom', locationDenied, true],
    policies: [popupComponents.PoliciesPopup, 'bottom', policies, true],
    checkoutForm: [popupComponents.CheckoutFormPopup, 'bottomFullHeight', checkoutForm, true],
    checkoutFormV2: [popupComponents.CheckoutFormPopup, 'bottom', checkoutFormV2, true],
    cardForm: [popupComponents.CardFormPopup, 'bottom', cardForm, true],
    addCode: [popupComponents.AddCodePopup, 'bottom', addCode, true],
    phoneCode: [popupComponents.PhoneCodePopup, 'phoneCode', phoneCode, true],
    appLink: [popupComponents.AppLinkPopup, 'top', appLink, true],
    barberPasscode: [popupComponents.PasscodePopup, 'bottom', barberPasscode, true],
    waitingListTimeSlots: [popupComponents.WaitingListTimeSlotsPopup, 'bottom', waitingListTimeSlots, true],
    waitingListReschedule: [popupComponents.WaitingListReschedulePopup, 'bottom', waitingListReschedule, true],
    reschedule: [popupComponents.ReschedulePopup, 'reschedule', reschedule, true],
    cancelAppointment: [popupComponents.CancelAppointmentPopup, 'bottom', cancelAppointment, true],
  };

  return useMemo(() => {
    const nullProps = Array(4).fill(null);
    const primaryProps = popups[primaryPopup] ?? nullProps;
    const secondaryProps = popups[secondaryPopup] ?? nullProps;
    return {
      primary: {
        popup: primaryPopup,
        payload: primaryPopupPayload,
        component: primaryProps[0],
        preset: primaryProps[1],
        handler: primaryProps[2]?.(),
        showBackdrop: primaryProps[3],
      },
      secondary: {
        popup: secondaryPopup,
        payload: secondaryPopupPayload,
        component: secondaryProps[0],
        preset: secondaryProps[1],
        handler: secondaryProps[2]?.(),
        showBackdrop: secondaryProps[3],
      },
      closeAll,
      handlers: {
        locationInfo,
        barberInfo,
        locationRequest,
        locationDenied,
        policies,
        checkoutForm,
        checkoutFormV2,
        phoneCode,
        cardForm,
        appLink,
        barberPasscode,
        waitingListTimeSlots,
        waitingListReschedule,
        reschedule,
        addCode,
        cancelAppointment,
      },
    } as PopupController;
  }, [popups, primaryPopup, secondaryPopup]);
};
