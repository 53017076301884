import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useContext, PropsWithChildren } from 'react';
import { useFeatureFlags } from '@water-web/view';

import { config } from '@app/config';
import { Button } from '@app/components/atoms';
import { WidgetMenuButton } from '@app/components/molecules/WidgetMenuButton';
import { CARD_SHADOW_LENGTH_X_UNITS } from '@app/components/molecules/Card';
import { useCartController } from '@app/contexts/CartController/useCartController';
import { useBackButtonHandler, useUser, useUserActionsAnalytics, useHistoryTyped } from '@app/hooks';

import { SignInOpenContext } from '../../organisms/SignIn/SignInOpenContext';
import { LanguagePicker } from '../LanguagePicker';
import { WidgetContainerContext } from './WidgetContainerContext';
import {
  CloseButton,
  StyledBackButton,
  StyledWidgetTitle,
  TopRightButtonsContainer,
  LanguagePickerContainer,
} from './styles';
import { SignInAvatarDefault } from './icons/SignInAvatarDefault';

const NO_LANGUAGE_PICKER_PAGES = ['/view-reservation', '/view-gift-card', 'waiting-list'];

export const WidgetTitleContainer = ({ children }: PropsWithChildren) => {
  const { webLogin: signInEnabled } = useFeatureFlags();
  const cartController = useCartController();
  const currentUser = useUser();
  const { open: openSignIn } = useContext(SignInOpenContext);
  const { backUrl, isScrolled } = useContext(WidgetContainerContext);
  const history = useHistoryTyped();
  const location = useLocation();
  const analytics = useUserActionsAnalytics();

  const showLanguagePicker = !NO_LANGUAGE_PICKER_PAGES.some((path) => location.pathname.includes(path));

  const closeWidget = () => {
    window.parent.postMessage?.('close_squire_widget', '*');

    // eslint-disable-next-line no-underscore-dangle
    const originalHref = (window as any)._originalFrameHref;
    if (originalHref && originalHref !== window.location.href) {
      window.location.href = originalHref;
    }
  };

  const onBackClick = useBackButtonHandler();

  const handleBackClick = () => {
    history.replace(backUrl, { isBack: true });
    onBackClick();
  };

  const handleBurgerClick = () => {
    analytics.trackBurgerOpen();
    openSignIn();
  };

  const isBehind = ['expanded', 'editing'].includes(cartController.cartState);
  const showCloseButton = config.mode.iframe && !isBehind;

  return (
    <StyledWidgetTitle
      px={CARD_SHADOW_LENGTH_X_UNITS}
      py={6}
      dimmed={isBehind}
      hasBackground={isScrolled}
      $testId="container:title"
    >
      <AnimatePresence initial>
        {backUrl && <StyledBackButton $testId="btn:back" onClick={handleBackClick} />}
      </AnimatePresence>
      {children}

      {signInEnabled ? (
        <TopRightButtonsContainer>
          {showLanguagePicker && !isBehind && (
            <WidgetMenuButton onClick={handleBurgerClick}>
              {/* TODO: mock user avatar */}
              {!!currentUser && <SignInAvatarDefault />}
            </WidgetMenuButton>
          )}
          {showCloseButton && <Button.WidgetClose onClick={closeWidget} />}
        </TopRightButtonsContainer>
      ) : (
        <>
          <AnimatePresence initial>
            {showLanguagePicker && !isBehind && (
              <LanguagePickerContainer showCloseButton={showCloseButton}>
                <LanguagePicker useOldStyle />
              </LanguagePickerContainer>
            )}
          </AnimatePresence>

          {showCloseButton && <CloseButton onClick={closeWidget} />}
        </>
      )}
    </StyledWidgetTitle>
  );
};
