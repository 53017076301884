import { useTheme } from 'styled-components';
import { motion } from 'framer-motion';

const draw = {
  hidden: { pathLength: 0 },
  visible: (duration) => ({
    pathLength: 1,
    transition: {
      pathLength: { type: 'spring', duration, delay: duration / 2, bounce: 0 },
    },
  }),
};

export const SuccessCheckmark = () => {
  const theme = useTheme();

  return (
    <motion.svg width="24" height="18" viewBox="0 0 24 18" initial="hidden" animate="visible" fill="none">
      <motion.path
        d="M2 8.5 8.69 15 22 2"
        stroke="currentColor"
        strokeWidth="3"
        variants={draw}
        custom={theme.animations.booking.interaction}
      />
    </motion.svg>
  );
};
