import { css } from 'styled-components';

export interface EllipsisProps {
  ellipsis?: boolean;
}

export const ellipsis = (props: EllipsisProps): ReturnType<typeof css> =>
  props.ellipsis &&
  css`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
