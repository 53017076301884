import { Base } from './base';
import { Service } from './service';

export enum AppointmentStatus {
  initial = 0,
  canceled = 1,
  arrived = 2,
}

export interface Appointment extends Base {
  totalCost: number;
  costWithTaxes: number;
  costWithoutTaxes: number;
  totalWithTip: number;
  totalCostNoTaxesAndDiscount: number;
  totalCostNoDiscount: number;
  totalCostWithoutTaxes: number;
  taxAmount: number;
  refundedAmount: number;
  maxRefundAmount: number;
  dateTime: string;
  endTime: string;
  duration: number;
  status: AppointmentStatus;
  tipAmount: number;
  bookedWithAnyBarber: boolean;
  bookedFromWaitingList: boolean;
  confirmationCode: string;
  paymentStatus: string;
  saleOrderId: string;
  barberId: string;
  customerId: string;
  shopId: string;
  // eslint-disable-next-line camelcase
  appointment_service?: Service[];
}
