import { LocalStorageTTL } from '@water-web/view';

import { KEY, TTL } from './const';

const TTL_THRESHOLD = 1;

// Run on app start once to token verify token remaining lifespan is enough to finish booking session
export const verifyTokenLifespan = () => {
  const tempStorage = new LocalStorageTTL({ key: KEY, ttl: TTL - TTL_THRESHOLD });
  if (!tempStorage.get()) {
    tempStorage.remove();
  }
};
