import { motion } from 'framer-motion';
import styled, { StyledProps } from 'styled-components';
import { PropsWithChildren, ComponentType } from 'react';

import { Box, BoxProps } from '../../atoms';

export interface FadeInOutPresenceProps extends BoxProps {
  as?: string | ComponentType;
  $animateEnter?: boolean;
  $animateExit?: boolean;
  $duration?: number;
  $enterDuration?: number;
  $exitDuration?: number;
  $delay?: number;
  $enterDelay?: number;
  $exitDelay?: number;
  [key: string]: unknown;
}

const getAnimationFromProps = (props: StyledProps<FadeInOutPresenceProps>) => ({
  initial: {
    opacity: props.$animateEnter ? 0 : 1,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: props.$animateExit ? 0 : 1,
    transition: {
      duration: props.$exitDuration ?? props.$duration ?? props.theme.animations.booking.interaction,
      delay: props.$exitDelay ?? props.$delay ?? 0,
    },
  },
  transition: {
    duration: props.$enterDuration ?? props.$duration ?? props.theme.animations.booking.interaction,
    delay: props.$enterDelay ?? props.$delay ?? 0,
  },
});

const AnimationContainer = styled(Box).attrs((props: StyledProps<FadeInOutPresenceProps>) => ({
  as: typeof props.as === 'string' ? motion[props.as] : motion(props.as, { forwardMotionProps: true }),
  ...getAnimationFromProps(props),
}))``;

export const FadeInOutPresence = (props: PropsWithChildren<FadeInOutPresenceProps>) => {
  return <AnimationContainer {...props} />;
};

FadeInOutPresence.defaultProps = {
  $animateEnter: true,
  $animateExit: true,
  as: 'div',
};
