import { CartCheckoutType } from '@water-web/repository';
import { exerciseBotProtectionToken } from '@water-web/view';

import { cartRepository } from '@app/repository';
import { convertUrlToRoute } from '@app/utils';

import { updateMarketingConsent } from './utils';
import { PhaseConfig, PhaseHookArgs } from '../types';

export const processingBooking: PhaseConfig = {
  name: 'processingBooking',
  init: async (params: PhaseHookArgs) => {
    params.cartState.setLoader('pending');

    if (params.cartModel.getCheckoutType() === CartCheckoutType.bookAndPay) {
      params.userActionsAnalytics.trackConfirmPayment();
    } else {
      params.userActionsAnalytics.trackConfirmReservation();
    }

    params.pickAnyBarber();

    const cartUpdateResult = await cartRepository.updateV2(params.cartModel, true);
    if (cartUpdateResult.error) {
      params.setCheckoutPhase('error', cartUpdateResult.error.message);
      return;
    }

    const checkoutResult = await cartRepository.checkoutV2(params.cartModel, exerciseBotProtectionToken());
    if (checkoutResult.error) {
      if (params.cartModel.getCheckoutType() === CartCheckoutType.bookAndPay) {
        params.userActionsAnalytics.trackPaymentFailure();
      } else {
        params.userActionsAnalytics.trackReservationFailure();
      }

      // make `cart.breakdown.outstandingChargeAmount` correct again to capture new payment token correctly
      params.cartModel.setPaymentToken(null);
      params.cartModel.setProceedWithExistingCard(false);
      await cartRepository.updateV2(params.cartModel, true);

      params.setCheckoutPhase('error', checkoutResult.error.message);
      return;
    }

    const customerId = checkoutResult.data.getPayingCustomerId();
    await updateMarketingConsent(params, customerId);

    const eventPayload = {
      customerId,
      currency: checkoutResult.data.getCurrency(),
      totalWithTips: checkoutResult.data.getTotal(),
      appointmentId: checkoutResult.data.getId(),
    };

    if (params.cartModel.getCheckoutType() === CartCheckoutType.bookAndPay) {
      params.userActionsAnalytics.trackPaymentSuccess(eventPayload);
    } else {
      params.userActionsAnalytics.trackReservationSuccess(eventPayload);
    }

    params.setCheckoutPhase('success');
    params.popupController.closeAll();

    const route = params.urlBuilder.getReservationUrl(checkoutResult.data.getId());
    params.history.replace(convertUrlToRoute(route));
  },
  next: () => {
    // not available
  },
  cancel: () => {
    // not available
  },
};
