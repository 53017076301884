export const TestErrorPage = () => {
  const onClick = () => {
    throw new Error('Test error page');
  };

  return (
    <div>
      <h1>Test Error Page</h1>
      <button type="button" onClick={onClick}>
        Throw Error
      </button>
    </div>
  );
};
