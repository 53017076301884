import { AppTFunction } from './types';

/**
 * Provides custom formats for relative dates
 */
export const getFormatRelative = (t: AppTFunction): Pick<Locale, 'formatRelative'> => {
  const relativeFormats = new Map([
    ['today', t('dateRelativeFormats.today')],
    ['tomorrow', t('dateRelativeFormats.tomorrow')],
    ['nextWeek', t('dateRelativeFormats.nextWeek')],
    ['other', t('dateRelativeFormats.other')],
  ]);

  return {
    formatRelative: (token) => relativeFormats.get(token),
  };
};
