import { QueryKey } from 'react-query';
import { Professional } from '@water-web/types';

import { EndpointQuery } from '../../request';
import { SERVICE_ID_V2_HEADER_MIXIN } from '../../utils';
import { queryKeys } from './queryKeys';

export interface Payload {
  shopIdOrRoute: string;
}

export class ShopBarbersQuery extends EndpointQuery<Professional[], Payload> {
  constructor(baseUrl: string) {
    super();

    this.baseUrl = baseUrl;
  }

  getKey(payload: Payload): QueryKey {
    return queryKeys.byShopId(payload);
  }

  request(payload: Payload, signal?: AbortSignal): Promise<Professional[]> {
    return this.http.get(`/v1/shop/${payload.shopIdOrRoute}/professional`, {
      signal,
      headers: this.buildHeaders(SERVICE_ID_V2_HEADER_MIXIN),
      search: {
        include: ['photos', 'days_on', 'days_off', 'reward', 'schedules'],
        sortBy: 'order:asc,createdAt:desc',
      },
      resourceUrl: '/shop/:id/professional',
    });
  }
}
