import { useCallback, useContext, useRef, useState } from 'react';
import { Brand, HttpError } from '@water-web/types';
import { getHasCustomAppsFromBrand } from '@water-web/repository';

import { RepositoryContext } from '../../contexts';
import { exerciseBotProtectionToken } from '../../components/organisms/BotProtectedAction/utils';

interface AppLinkSenderResponse {
  state: 'idle' | 'busy' | 'sent' | 'error';
  sendAppLink: (phone: string, brand?: Brand) => void;
  data: boolean;
  error: HttpError;
  isSendingAvailable: boolean;
}

interface AppLinkSenderConfig {
  autoReset?: boolean;
  autoResetTimeout?: number;
}

const defaultConfig: AppLinkSenderConfig = {
  autoReset: true,
  autoResetTimeout: 5000,
};

export const useAppLinkSender = (senderConfig = defaultConfig): AppLinkSenderResponse => {
  const { smsRepository } = useContext(RepositoryContext);
  const [state, setState] = useState<'idle' | 'busy' | 'sent' | 'error'>('idle');
  const availabilityRef = useRef(true);
  const dataRef = useRef(null);
  const errorRef = useRef(null);

  const sendAppLink = useCallback(async (phone: string, brand?: Brand) => {
    if (!availabilityRef.current) {
      console.error('Sending app link is not available at the moment.');
      return;
    }

    availabilityRef.current = false;
    setState('busy');

    const response = await (() => {
      if (getHasCustomAppsFromBrand(brand)) {
        return smsRepository.sendBrandedLinkToApps(phone, brand.id, exerciseBotProtectionToken());
      }

      return smsRepository.sendCommonLinkToApps(phone, exerciseBotProtectionToken());
    })();

    availabilityRef.current = true;
    dataRef.current = response.data;
    errorRef.current = response.error;

    const nextState = response.error ? 'error' : 'sent';
    setState(nextState);

    if (senderConfig?.autoReset) {
      setTimeout(() => setState('idle'), senderConfig.autoResetTimeout ?? defaultConfig.autoResetTimeout);
    }
  }, []);

  return {
    state,
    sendAppLink,
    data: dataRef.current,
    error: errorRef.current,
    isSendingAvailable: availabilityRef.current,
  };
};
