import { useState, useCallback, useRef, useMemo, useContext } from 'react';
import { BrandModel, LocationModel } from '@water-web/repository';

import { UserLocationContext } from '../../../contexts';
import { config, ExternalConfiguration } from '../../../config';
import { specialGMESearch } from './specialGMESearch';

export enum BrandSources {
  default,
  nearby,
  search,
}

interface UseBrandLocationsSourceResult {
  source: BrandSources;
  isLoading: boolean;
  locations: LocationModel[];
  loadDefault: () => void;
  loadNearby: () => void;
  loadSearch: (query: string) => void;
}

type UseBrandLocationsSource = (brand: BrandModel) => UseBrandLocationsSourceResult;

export const useBrandLocationsSource: UseBrandLocationsSource = (brand) => {
  const { getLocation } = useContext(UserLocationContext);
  const { searchRepository } = ExternalConfiguration.getRepository();

  const [source, setSource] = useState(BrandSources.default);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState(brand.getLocations());
  const currentPromiseRef = useRef<Promise<LocationModel[]>>(null);

  const getNearby = useCallback(async () => {
    const userLatLng = await getLocation();
    const response = await brand.getLocationsNearby(Number(userLatLng.lat), Number(userLatLng.lng));
    if (response.error) {
      throw response.error;
    }

    return response.data;
  }, [brand, getLocation]);

  const makeSearch = useCallback(
    async (query: string) => {
      const response = await searchRepository.getLocations(query, brand.getId());

      if (response.error) {
        throw response.error;
      }

      const shopIds = response.data.reduce((ids, item) => {
        ids.add(item.getShopId());
        return ids;
      }, new Set<string>());

      if (brand.getId() === config.brandIds.gme) {
        return specialGMESearch(shopIds);
      }

      return brand.getLocations().filter((location) => {
        return shopIds.has(location.getId());
      });
    },
    [brand],
  );

  const loaderBySource: Map<BrandSources, (...args: unknown[]) => Promise<LocationModel[]>> = useMemo(() => {
    return new Map([
      [BrandSources.default, async () => brand.getLocations()],
      [BrandSources.nearby, getNearby],
      [BrandSources.search, makeSearch],
    ]);
  }, [brand, getNearby, makeSearch]);

  const load = useCallback(
    async (sourceToLoad: BrandSources, ...args) => {
      setIsLoading(true);
      setSource(sourceToLoad);

      const promise = loaderBySource
        .get(sourceToLoad)(...args)
        .catch(() => {
          setSource(BrandSources.default);
          return loaderBySource.get(BrandSources.default)(...args);
        });
      currentPromiseRef.current = promise;

      const newLocations = await promise;
      if (currentPromiseRef.current !== promise) {
        return; // exit if other source was requested to load
      }

      setLocations(newLocations);
      setIsLoading(false);
    },
    [loaderBySource],
  );

  return {
    source,
    isLoading,
    locations,
    loadDefault: load.bind(null, BrandSources.default),
    loadNearby: load.bind(null, BrandSources.nearby),
    loadSearch: load.bind(null, BrandSources.search),
  };
};
