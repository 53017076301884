import { createContext, Dispatch, SetStateAction, useState, PropsWithChildren } from 'react';

type BookingStateContextValue = {
  bookingStarted: boolean;
  setBookingStarted: Dispatch<SetStateAction<boolean>>;
};

export const BookingStateContext = createContext<BookingStateContextValue>({
  bookingStarted: false,
  setBookingStarted: () => null,
});

export const BookingStateContextProvider = ({ children }: PropsWithChildren) => {
  const [bookingStarted, setBookingStarted] = useState(false);
  return (
    <BookingStateContext.Provider value={{ bookingStarted, setBookingStarted }}>
      {children}
    </BookingStateContext.Provider>
  );
};
