import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../../../../atoms/Icon/Icon';
import processingPendingIcon from './processingPending.svg';

export const ProcessingPending = styled(JustIcon).attrs({
  alt: 'processingPending',
  icon: processingPendingIcon,
  width: '62px',
  height: '62px',
})`` as JustIconStyledComponent<IconStaticProps>;
