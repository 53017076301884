import { Service, ValidatedCart } from '@water-web/types';
import { BarberModel, CartModel, CartModelPopulationData } from '@water-web/repository';

import { ExternalConfiguration } from '../config';

export const populateInitialCartData = (
  cart: CartModel,
  removeCartId: () => void,
  pageInitialProps?: Record<string, any>,
): void => {
  if (!pageInitialProps) {
    return;
  }

  const { barberRepository, brandRepository, cartRepository, serviceRepository, shopRepository } =
    ExternalConfiguration.getRepository();

  const dataForPopulation: CartModelPopulationData = {};

  const brandJson = pageInitialProps.brand;
  if (brandJson) {
    dataForPopulation.brand = brandRepository.getFromJson(brandJson);
  }

  const cartJson: ValidatedCart = pageInitialProps.cart;
  if (cartJson) {
    dataForPopulation.cart = cartRepository.getValidatedCartFromJson(cartJson);
  } else if (typeof window !== 'undefined') {
    // make sure there's no outdated cart id
    removeCartId();
  }

  const shopJson = pageInitialProps.shop;
  if (shopJson) {
    dataForPopulation.shop = shopRepository.getFromJson(shopJson);
  }

  const barbersJson = pageInitialProps.barbers;
  if (barbersJson) {
    dataForPopulation.barbers = barbersJson.map((json) => barberRepository.getFromJson(json));
  }

  const barberJson = pageInitialProps.barber;
  if (barberJson) {
    dataForPopulation.barber = barberRepository.getFromJson(barberJson);
  }

  const shopServicesJson = pageInitialProps.shopServices;
  if (shopServicesJson) {
    dataForPopulation.shopServices = shopServicesJson.map((s) => serviceRepository.getFromJson(s));
  }

  const barberServicesJson = pageInitialProps.servicesByBarberId;
  if (barberServicesJson) {
    dataForPopulation.servicesByBarberId = Object.entries(barberServicesJson).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: (value as Service[]).map((s) => serviceRepository.getFromJson(s)),
      }),
      {} as CartModelPopulationData['servicesByBarberId'],
    );
  }

  cart.populate(dataForPopulation);

  if (dataForPopulation.barber) {
    // eslint-disable-next-line prefer-destructuring
    const barber: BarberModel = dataForPopulation.barber;
    const latestApiReservation = cart.getReservations().slice(-1)[0];
    const isSameIdWithLatest = barber.getId() === latestApiReservation?.getBarber()?.getId();

    if (!isSameIdWithLatest) {
      const reservation = cart.addReservation();
      reservation.setShop(dataForPopulation.shop);
      reservation.setBarber(barber);
    }
  }

  const reservations = cart.getReservations();
  if (!reservations.length) {
    const reservation = cart.addReservation();
    if (dataForPopulation.shop) {
      reservation.setShop(dataForPopulation.shop);
    }
    if (dataForPopulation.barber) {
      reservation.setBarber(dataForPopulation.barber);
    }
    cart.setActiveReservation(reservation);
  }

  const latestReservation = cart.getReservations()[reservations.length - 1];
  cart.setActiveReservation(latestReservation);
};
