import { css } from 'styled-components';

import { EllipsisProps } from './ellipsis';

export interface EllipsisFadeProps extends EllipsisProps {
  fadeColor?: boolean;
}

export const ellipsisFade = (props: EllipsisFadeProps): ReturnType<typeof css> =>
  props.ellipsis &&
  props.fadeColor &&
  css`
    text-overflow: clip;
    position: relative;
    top: 0;
    mask-image: linear-gradient(90deg, #000 calc(100% - 1em), rgba(0, 0, 0, 0));
    padding-right: 1em;
  `;
