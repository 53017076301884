import { Box, Flex, Translate } from '@water-web/view';

import { Text } from '@app/components/atoms';

import { ErrorButton, ErrorIconContainer } from './styles';
import { ProcessingError } from './icons/ProcessingError';

interface Props {
  message?: string;
  onClick?: () => void;
}

export const ErrorMessage = (props: Props) => {
  const { message, onClick } = props;
  const [title, ...description] = (message || '').split('\n');

  return (
    <Flex flexDirection="column" alignItems="center">
      <ErrorIconContainer>
        <ProcessingError />
      </ErrorIconContainer>

      <Box mt={6} px={2}>
        <Text.Title1 lineHeight="24px" textAlign="center" $testId="text:errorMessage">
          {!!message && title}
          {!message && <Translate i18nKey="pages.reservation.processingError.0" />}
        </Text.Title1>
      </Box>

      {!!description.length && (
        <Box mt={2} maxWidth="280px" textAlign="center" $testId="text:errorDescription">
          {description.join(' ')}
        </Box>
      )}

      {onClick && (
        <ErrorButton $testId="btn:ok" onClick={onClick}>
          OK
        </ErrorButton>
      )}
    </Flex>
  );
};
