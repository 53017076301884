import { useState, createContext, useRef, PropsWithChildren } from 'react';
import onChange from 'on-change';
import { CartModel } from '@water-web/repository';

import { populateInitialCartData } from '../../utils';

interface ContextValue {
  cart: CartModel;
}

export const CartContext = createContext<ContextValue>({
  cart: null,
});

interface CartContextProviderProps {
  initialData?: Record<string, any>;
}

export const CartContextProvider = (props: PropsWithChildren<CartContextProviderProps>) => {
  const [value, setValue] = useState<ContextValue>(() => {
    // update wrapper on mutation
    const cart = onChange(new CartModel(), () => setValue({ cart }));
    return { cart };
  });

  const hydrationRef = useRef(false);

  if (!hydrationRef.current && props.initialData) {
    const noop = () => null;
    populateInitialCartData(value.cart, noop, props.initialData);
    hydrationRef.current = true;
  }

  return <CartContext.Provider value={value}>{props.children}</CartContext.Provider>;
};
