import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { CartContext } from '@water-web/view';

import { LocaleContext } from '@app/contexts';
import { AppLocale } from '@app/i18n';

import { ShopPartialTranslation } from './TooltipContents';

interface Props {
  locale: AppLocale;
}

// Add this to every supported language menu items!
export const FullTranslationIndicator = (props: Props) => {
  const { locale } = props;
  const router = useLocation();
  const localeContext = useContext(LocaleContext);
  const { supportedLanguages } = localeContext;

  // The contents of the dropdown are kinda dynamic.
  // When we are on list of shops, we display the indicator based on the supported
  // languages of a brand.
  // However, as we have a shop selected, we will now display the indicator based on
  // if the selected shop supports a given language; so the indicators will
  // come and go depending on the selected shop & which step of reservation
  // we're in.
  const { cart } = useContext(CartContext);
  const activeShop = cart?.getActiveReservation?.()?.getShop();
  const supportedLangsOfActiveShop = activeShop?.getSupportedLanguages();
  const shopsSupportsTheSameAsBrand = supportedLangsOfActiveShop === null;
  const doesCurrentShopSupportsLocale = shopsSupportsTheSameAsBrand || supportedLangsOfActiveShop?.includes(locale);

  const isOnShopSelectionPage = router.pathname.startsWith('/brands/');
  const hasFullSupport = supportedLanguages[locale];

  // Short circuit if no warning needed - all shops support all languages
  // If we're on the brand page, we DONT display the indicator - its already in LocationCard
  if (hasFullSupport || isOnShopSelectionPage) {
    return null;
  }

  // If we're not on brand level (shop selector), then we have a shop selected. Short circuit
  // if they support given language
  if (doesCurrentShopSupportsLocale) {
    return null;
  }

  return <ShopPartialTranslation locale={locale} />;
};
