import { SearchResult as ISearchResult } from '@water-web/types';

import { BaseModel } from './base';

export class SearchResultModel extends BaseModel<SearchResultModel> {
  constructor(searchResult: ISearchResult) {
    super();

    this.dataValues.id = searchResult.id;
    this.dataValues.type = searchResult.type;
    this.dataValues.shopId = searchResult.shopId;
    this.dataValues.result = searchResult.result;
    this.dataValues.source = searchResult.source;
  }

  getId(): ISearchResult['id'] {
    return this.dataValues.id as ISearchResult['id'];
  }

  getType(): ISearchResult['type'] {
    return this.dataValues.type as ISearchResult['type'];
  }

  getShopId(): ISearchResult['shopId'] {
    return this.dataValues.shopId as ISearchResult['shopId'];
  }

  getResult(): ISearchResult['result'] {
    return this.dataValues.result as ISearchResult['result'];
  }

  getSource(): ISearchResult['source'] {
    return this.dataValues.source as ISearchResult['source'];
  }

  toJson(): Record<string, unknown> {
    throw new Error('Not implemented');
  }
}
