import styled from 'styled-components';

import { IconStaticProps, JustIcon, JustIconStyledComponent } from '../Icon';
import widgetCloseIcon from './widgetClose.svg';

export const WidgetClose = styled(JustIcon).attrs({
  alt: 'widgetClose',
  icon: widgetCloseIcon,
  width: '10px',
  height: '10px',
})`` as JustIconStyledComponent<IconStaticProps>;
