import { createContext, useEffect, useState, PropsWithChildren, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { DeeplinkPromoContext } from '@water-web/view';

export type ViewMode = 'singleShop' | 'singleBarber' | null;

type ViewModeContextValue = {
  viewMode: ViewMode;
};

export const ViewModeContext = createContext<ViewModeContextValue>({
  viewMode: null,
});

export const ViewModeContextProvider = ({ children }: PropsWithChildren) => {
  const { promo, error, isLoading } = useContext(DeeplinkPromoContext);
  const [viewMode, setViewMode] = useState<ViewMode>(null);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryViewMode = query.get('viewMode') as ViewMode;
  const isVisitWithDeeplinkPromo = !!(isLoading || promo || error);

  useEffect(() => {
    if (queryViewMode) {
      setViewMode(queryViewMode);
      return;
    }
    if (isVisitWithDeeplinkPromo) {
      setViewMode('singleShop');
    }
  }, [queryViewMode, isVisitWithDeeplinkPromo]);

  return <ViewModeContext.Provider value={{ viewMode }}>{children}</ViewModeContext.Provider>;
};
