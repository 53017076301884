/* eslint-disable no-underscore-dangle */
import { inMemoryKVStore } from '../../../persistence';

export interface PopupPosition {
  top: number;
  left: number;
  arrowTop: number;
  arrowLeft: number;
  arrowAngle: 0 | 90 | 180 | 270;
}

export type PopupPlacement = 'top' | 'right' | 'bottom' | 'left';
export type PopupAlignment = 'start' | 'center' | 'end';

interface GetPopupPositionParams {
  trigger?: HTMLElement;
  popup?: HTMLElement;
  placement: PopupPlacement;
  alignment: PopupAlignment;
}

interface AlignmentParams {
  triggerRect: DOMRect;
  popupRect: DOMRect;
  alignment: PopupAlignment;
}

const getVerticalPlacementLeftPosition = (params: AlignmentParams): number => {
  const { triggerRect, popupRect, alignment } = params;

  if (alignment === 'start') {
    return triggerRect.left;
  }

  if (alignment === 'end') {
    return triggerRect.left + triggerRect.width - popupRect.width;
  }

  return triggerRect.left + (triggerRect.width - popupRect.width) / 2;
};

const getHorizontalPlacementTopPosition = (params: AlignmentParams): number => {
  const { triggerRect, popupRect, alignment } = params;

  if (alignment === 'start') {
    return window.scrollY + triggerRect.top;
  }

  if (alignment === 'end') {
    return window.scrollY + triggerRect.top - triggerRect.height;
  }

  return window.scrollY + triggerRect.top + (triggerRect.height - popupRect.height) / 2;
};

export const getPopupPosition = (params: GetPopupPositionParams): PopupPosition => {
  const { trigger, popup, alignment, placement } = params;

  if (!trigger || !popup) {
    // hide the popup from view if its position cannot be calculated(yet)
    return {
      top: -1000,
      left: -1000,
      arrowTop: 0,
      arrowLeft: 0,
      arrowAngle: 0,
    };
  }

  const triggerRect = trigger.getBoundingClientRect();
  const popupRect = popup.getBoundingClientRect();

  const popupOffset = 14;
  const arrowHeight = 6;
  const arrowWidth = 16;

  switch (placement) {
    case 'bottom':
      return {
        top: window.scrollY + triggerRect.bottom + popupOffset,
        left: getVerticalPlacementLeftPosition({
          triggerRect,
          popupRect,
          alignment,
        }),
        arrowTop: window.scrollY + triggerRect.bottom + popupOffset - arrowHeight,
        arrowLeft: triggerRect.left + (triggerRect.width - arrowWidth) / 2,
        arrowAngle: 0,
      };
    case 'left':
      return {
        top: getHorizontalPlacementTopPosition({
          triggerRect,
          popupRect,
          alignment,
        }),
        left: triggerRect.left - popupRect.width - popupOffset,
        arrowTop: window.scrollY + triggerRect.top + (triggerRect.height - arrowHeight) / 2,
        arrowLeft: triggerRect.left - popupOffset - arrowHeight,
        arrowAngle: 90,
      };
    case 'right':
      return {
        top: getHorizontalPlacementTopPosition({
          triggerRect,
          popupRect,
          alignment,
        }),
        left: triggerRect.right + popupOffset,
        arrowTop: window.scrollY + triggerRect.top + (triggerRect.height - arrowHeight) / 2,
        arrowLeft: triggerRect.left + triggerRect.width + arrowHeight / 2,
        arrowAngle: 270,
      };
    default:
    case 'top':
      return {
        top: window.scrollY + triggerRect.top - popupRect.height - popupOffset,
        left: getVerticalPlacementLeftPosition({
          triggerRect,
          popupRect,
          alignment,
        }),
        arrowTop: window.scrollY + triggerRect.top - popupOffset,
        arrowLeft: triggerRect.left + (triggerRect.width - arrowWidth) / 2,
        arrowAngle: 180,
      };
  }
};

const containerIdPrefix = 'turnstile-container';
export const buildContainerId = (action: string): string => `${containerIdPrefix}-${action}`;

export const ensureApiLoaded = (siteKey: string): void => {
  const isApiLoaded = !!window.turnstile;
  const isApiLoading = !!window._turnstileLoadingPromise;
  if (!isApiLoaded && !isApiLoading) {
    window._turnstileLoadingPromise = new Promise((resolve) => {
      const invisibleVerificationElement = document.createElement('div');

      invisibleVerificationElement.id = buildContainerId('global');
      invisibleVerificationElement.className = 'cf-turnstile';
      invisibleVerificationElement.dataset.sitekey = siteKey;
      invisibleVerificationElement.style.display = 'none';
      invisibleVerificationElement.style.visibility = 'hidden';

      document.body.appendChild(invisibleVerificationElement);

      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.onload = () => {
        resolve();
        window._turnstileLoadingPromise = null;
      };
      document.body.appendChild(script);
    });
  }
};

export const getApiInstance = async (): Promise<any> => {
  const isApiLoaded = !!window.turnstile;
  const apiLoadingPromise = window._turnstileLoadingPromise;
  if (!isApiLoaded && apiLoadingPromise) {
    await apiLoadingPromise;
  }

  return window.turnstile;
};

export const readBotProtectionToken = (): string | null => {
  let token: string | null = inMemoryKVStore.getItem('cf-turnstile-response');

  const allActiveContainers = document.querySelectorAll(`[id^="${containerIdPrefix}"]`);
  for (let i = 0; i < allActiveContainers.length; i += 1) {
    const container = allActiveContainers[i];
    try {
      const containersToken = window.turnstile?.getResponse(`#${container.id}`);
      if (containersToken) {
        token = containersToken;
        break;
      }
    } catch (e) {
      console.error(`Error reading bot protection token for the element with id: ${container.id}`);
      console.error(e);
    }
  }

  return token;
};

export const exerciseBotProtectionToken = () => {
  const token = readBotProtectionToken();

  if (token) {
    inMemoryKVStore.removeItem('cf-turnstile-response');
    const allActiveContainers = document.querySelectorAll(`[id^="${containerIdPrefix}"]`);
    for (let i = 0; i < allActiveContainers.length; i += 1) {
      const container = allActiveContainers[i];
      try {
        window.turnstile?.reset(`#${container.id}`);
      } catch (e) {
        console.error(`Error resetting bot protection token for the element with id: ${container.id}`);
        console.error(e);
      }
    }
  }

  return token;
};

export const waitForBotProtectionToken = async (): Promise<string> => {
  let token = readBotProtectionToken();

  if (token) {
    return token;
  }

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      token = readBotProtectionToken();
      if (readBotProtectionToken()) {
        clearInterval(interval);
        resolve(token);
      }
    }, 100);
  });
};
