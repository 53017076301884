/* eslint-disable import/no-duplicates */
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useCallback, PropsWithChildren } from 'react';
import { Locale } from 'date-fns';
import { enUS, frCA } from 'date-fns/locale';

import { AppLocale, geti18n, getPersistedLocale, saveUserLocale } from '@app/i18n';
import { LocaleSupport } from '@app/types';

import { LocaleContext, LocaleContextValue } from './LocaleContext';
import { getDateFormats } from './getDateFormats';
import { getTimeFormat } from './getTimeFormat';
import { getFormatRelative } from './getFormatRelative';

const getDateFnsLocaleByLanguage = (language: AppLocale | string): Locale => {
  switch (language) {
    case AppLocale.fr:
      return frCA;
    case AppLocale.en:
    default:
      return enUS;
  }
};

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const i18n = geti18n();
  const { t } = useTranslation();

  const persistedLocale = getPersistedLocale();

  const [locale, setLocale] = useState<AppLocale>(persistedLocale || ((i18n.language as AppLocale) ?? AppLocale.en));
  const defaultDateLocale = getDateFnsLocaleByLanguage(locale);
  const [dateFnsLocale, setDateLocale] = useState<Locale>(defaultDateLocale);
  const [supportedLanguages, setSupportedLanguages] = useState<LocaleSupport>(null);

  const changeLocale = useCallback(
    (nextLocale: AppLocale) => {
      if (nextLocale === i18n.language) {
        return;
      }

      i18n.changeLanguage(nextLocale, () => {
        setLocale(nextLocale);
        setDateLocale(getDateFnsLocaleByLanguage(nextLocale));
      });
      saveUserLocale(nextLocale);
    },
    [locale],
  );

  const value = useMemo<LocaleContextValue>(() => {
    return {
      t,
      locale,
      setLocale: changeLocale,
      supportedLanguages,
      setSupportedLanguages: (langs: LocaleSupport) => setSupportedLanguages(langs),
      dateFnsLocale: {
        ...dateFnsLocale,
        ...getFormatRelative(t),
      },
      dateFormats: getDateFormats(t),
      timeFormat: getTimeFormat(t),
    };
  }, [locale, changeLocale, supportedLanguages, t, dateFnsLocale]);

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
