import styled, { keyframes, StyledProps } from 'styled-components';

import { Box, Flex } from '../../atoms';
import { LoadingPlaceholderProps } from './types';

const frames = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
`;

const gradientCenter = 50; // in percent
const transparent = 'rgba(0, 0, 0, 0)';
const getGradient =
  (gradientHalfWidth: number) =>
  (props: StyledProps<unknown>): string =>
    [
      '120deg',
      `${transparent} 0%`,
      `${transparent} ${gradientCenter - gradientHalfWidth}%`,
      `${props.theme.colors.systemGray4} ${gradientCenter}%`,
      `${transparent} ${gradientCenter + gradientHalfWidth}%`,
      `${transparent} 100%`,
    ].join(', ');

const Shimmer = styled(Box)`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(${getGradient(17)});

  animation: ${frames} 1s linear infinite;
`;

export const StyledLoadingPlaceholder = styled(Flex).attrs({
  bg: 'systemGray5',
  children: <Shimmer />,
})<LoadingPlaceholderProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: 12px;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  border-radius: 16px;
  isolation: isolate; // fix shimmer leak through the rounded corners
`;
