import * as CSS from 'csstype';
import styled, { css } from 'styled-components';
import {
  style,
  color,
  space,
  display,
  fontSize,
  textAlign,
  textStyle,
  fontWeight,
  lineHeight,
  letterSpacing,
  ColorProps,
  SpaceProps,
  DisplayProps,
  FontSizeProps,
  TextAlignProps,
  TextStyleProps,
  FontWeightProps,
  LineHeightProps,
  LetterSpacingProps,
  ResponsiveValue,
} from 'styled-system';

import { TestIdProps, withTestIdProp, WithVariantProps, VariantProps, withVariantProp } from '../../../utils';
import { EllipsisProps, ellipsis } from './ellipsis';
import { EllipsisFadeProps, ellipsisFade } from './ellipsisFade';
import { MaxLinesProps, maxLines } from './maxLines';

const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
});

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration',
});

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'textTransform',
});

const resetMargin = () => css`
  margin: 0;
`;

export interface TextBaseProps
  extends TestIdProps,
    ColorProps,
    SpaceProps,
    DisplayProps,
    FontSizeProps,
    TextAlignProps,
    TextStyleProps,
    FontWeightProps,
    LineHeightProps,
    LetterSpacingProps,
    EllipsisProps,
    EllipsisFadeProps,
    MaxLinesProps,
    VariantProps<unknown>,
    WithVariantProps {
  whiteSpace?: ResponsiveValue<CSS.Property.WhiteSpace>;
  textDecoration?: ResponsiveValue<CSS.Property.TextDecoration>;
  textTransform?: ResponsiveValue<CSS.Property.TextTransform>;
}

const TextP = styled('p').attrs(withTestIdProp)<TextBaseProps>``;

// will compile static variants definitions into finite amount of CSS classes
const TextBaseStatic = styled(TextP)(resetMargin, withVariantProp);

export const TextBase = styled(TextBaseStatic)(
  // will compile dynamic props into infinite amount of CSS classes
  display,
  textStyle,
  color,
  space,
  fontSize,
  fontWeight,
  letterSpacing,
  lineHeight,
  textAlign,
  whiteSpace,
  textDecoration,
  textTransform,
  ellipsis,
  ellipsisFade,
  maxLines,
);
