import { Shop } from '@water-web/types';

import { AnalyticsEvent } from '../events';
import { CommonPayload, isPaymentEvent, Tracker } from '../types';
import { CommonBookAppointmentEventPayload } from './types';

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
    _fbq: (...args: any[]) => void;
  }
}

export class BrandMetaTracker implements Tracker {
  metaTrackerEvents = new Set<AnalyticsEvent>([
    AnalyticsEvent.PAYMENT_SUCCESS,
    AnalyticsEvent.RESERVATION_SUCCESS,
    AnalyticsEvent.WAITLIST_SUCCESS,
    AnalyticsEvent.GC_PURCHASE_SUCCESS,
  ]);

  getName(): string {
    return 'BrandMetaTracker';
  }

  track(analyticsEvent: AnalyticsEvent, payload: CommonPayload): void {
    const exit = !window.fbq || !this.metaTrackerEvents.has(analyticsEvent);

    if (exit) {
      return;
    }

    if (isPaymentEvent(analyticsEvent, payload)) {
      const p = payload as typeof payload & { shop: Shop }; // FIXME: need to put `addCommonPayloadProp('shop', shop);` call and `CommonPayload` extension into `view` package
      if (!p.shop) {
        console.error('Missing shop in analytics payload. This should not happen');
      }

      const totalWithDecimals = (payload.totalWithTips / 100).toFixed(2);
      const eventPayload: CommonBookAppointmentEventPayload = {
        value: totalWithDecimals,
        currency: payload.currency,
        transaction_id: payload.appointmentId,
        shop_name: p.shop.name,
        shop_route: p.shop.route,
      };

      window.fbq('trackCustom', 'book_appointment', eventPayload);
    }
  }
}
