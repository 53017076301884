import { Translate } from '../../atoms';
import { HrMinDuration } from '../HrMinDuration';

interface DurationRangeProps {
  fallback: number;
  shortFallback?: boolean;
  min?: number | null;
  max?: number | null;
}

export const DurationRange = (props: DurationRangeProps) => {
  const isMinOrMaxMissing = [props.min, props.max].some((duration) => {
    return !duration && duration !== 0;
  });

  const isMinEqualsMax = props.min === props.max;
  const shouldUseFallback = isMinOrMaxMissing || isMinEqualsMax;

  if (shouldUseFallback) {
    return <HrMinDuration short={props.shortFallback}>{props.fallback}</HrMinDuration>;
  }

  return <Translate i18nKey="pages.barber.durationMinutesWithCount_plural" count={`${props.min} – ${props.max}`} />;
};

DurationRange.defaultProps = {
  shortFallback: true,
};
